/** Helpers import section */
import axios from 'axios'

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'
import { COMPANY_IDENTIFIER_FERSA, COMPANY_PTM } from '../helpers/ResourcesHelper'

export const IS_SAVING = 'IS_SAVING'
export const SET_PLANES_LIST = 'SET_PLANES_LIST'
export const SET_SPECIFICATIONS_LIST = 'SET_SPECIFICATIONS_LIST'
export const IS_LOADING_PART_NUMBER = 'IS_LOADING_PART_NUMBER'
export const GET_INFORMATION_PART_NUMBER = 'GET_INFORMATION_PART_NUMBER'
export const SET_PART_NUMBERS_BY_QUERY = 'SET_PART_NUMBERS_BY_QUERY'

/**
 * Action is saving
 */
function isSavingPartNumber(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}

/**
 * Action to set list the plots to show
 * @param {*} plots
 */
function setPlotsPartNumber(specPlanos) {
	return {
		type: SET_PLANES_LIST,
		specPlanos: specPlanos,
	}
}

/**
 * Action to set list especifications
 * @param {*} spects
 */
function setSpecificationsPartNumber(spects) {
	return {
		type: SET_SPECIFICATIONS_LIST,
		spects: spects,
	}
}

/**
 * Action to start load part number historial
 */
function startLoadPartNumber(isLoading) {
	return {
		type: IS_LOADING_PART_NUMBER,
		isLoading: isLoading,
	}
}

/**
 * Action to get informaiton from part number
 * @param {*} memo
 */
function gtInformationPartNumber(partNumber) {
	return {
		type: GET_INFORMATION_PART_NUMBER,
		partNumber: partNumber,
	}
}

/**
 * Action to set part numbers by query
 * @param {*} partNumbers
 */
function setPartNumbersByQuery(partNumbers) {
	return {
		type: SET_PART_NUMBERS_BY_QUERY,
		partNumbers: partNumbers,
	}
}

/**
 * Action to get plot by part number, spec or planes
 * @param {*} specPlano
 * @param {*} revision
 * @param {*} kind
 */
export function getPlotsByPartNumber(specPlano, revision, kind, formatype) {
	return function (dispatch, getState) {
		dispatch(isSavingPartNumber(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${
			process.env.REACT_APP_SIP_SUPPLIERS
		}/partnumbers/getplotsbypartnumber?specPlano=${specPlano}&revision=${revision}&tokenCompany=${selectedCompany.get(
			'token'
		)}&kind=${kind}&formatype=${formatype}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let items = []
					/// Get informaiton from list json (new implementation portal plot)
					if (
						selectedCompany.get('get_company_identifier') === COMPANY_IDENTIFIER_FERSA ||
						selectedCompany.get('get_company_identifier') === COMPANY_PTM
					) {
						items = serverResponse.data
					} else {
						let result = document.createElement('html')
						result.innerHTML = serverResponse.data

						var values = result.getElementsByTagName('option')
						for (var i = 0; i < values.length; i++) {
							items.push(values[i].value)
						}
					}
					dispatch(setPlotsPartNumber(items))
					return Promise.resolve(items)
				} else {
					dispatch(isSavingPartNumber(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingPartNumber(false))
				console.error('Error getting gets plots by part number list', error)
				return Promise.reject(error)
			})
	}
}

/**
 * Action to get plot by part number, spect or planes
 * @param {*} specPlano
 * @param {*} revision
 */
export function getEspectByPartNumber(specPlano, revision) {
	return function (dispatch, getState) {
		dispatch(isSavingPartNumber(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${
			process.env.REACT_APP_SIP_SUPPLIERS
		}/partnumbers/espects?specPlano=${specPlano}&revision=${revision}&tokenCompany=${selectedCompany.get('token')}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let items = []

					/// Get informaiton from list json (new implementation portal plot)
					if (selectedCompany.get('get_company_identifier') === COMPANY_PTM) {
						items = serverResponse.data
					} else {
						let result = document.createElement('html')
						result.innerHTML = serverResponse.data

						var values = result.getElementsByTagName('a')
						for (var i = 0; i < values.length; i++) {
							items.push(values[i].innerText)
						}
					}
					dispatch(setSpecificationsPartNumber(items))
					return Promise.resolve(items)
				} else {
					dispatch(isSavingPartNumber(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingPartNumber(false))
				console.error('Error getting gets plots by part number list', error)
				return Promise.reject(error)
			})
	}
}

/**
 * Action get part number information data from backend
 */
export function getPartNumberInformationDataFromBackend(partNumber) {
	return function (dispatch, getState) {
		dispatch(startLoadPartNumber(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierToken = ''
		let userType = ''
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierToken = getState().oidc.user.profile.company_token
		}
		if (getState().oidc.user.profile && getState().oidc.user.profile.user_type) {
			userType = getState().oidc.user.profile.user_type
		}
		let data = {
			CompanyToken: selectedCompany.get('token'),
			PartNumberCode: partNumber,
			SupplierToken: supplierToken,
			UserType: userType,
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/trazability`

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(gtInformationPartNumber(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to get information from part number history', error.response)
				dispatch(startLoadPartNumber(false))
				return Promise.reject()
			})
	}
}

/**
 * Action to serch part numbers by Query
 * @param {*} query
 */
export function searchByPartNumbersByQury(query) {
	return function (dispatch, getState) {
		dispatch(startLoadPartNumber(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		console.log('selectedCompany', selectedCompany.toJS())
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/partNumbers/query`
		let data = {
			CompanyToken: selectedCompany.get('token'),
			PartNumberCode: query,
			CompanyCenter: selectedCompany.get('center'),
			CompanyCenterFin: selectedCompany.get('center_fin'),
		}
		console.log("command", data)
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					console.log('PLANE RESPONSE', response.data)

					dispatch(setPartNumbersByQuery(response.data))
					return Promise.resolve(response.data)
				}
			})
			.catch((error) => {
				dispatch(startLoadPartNumber(false))
				console.error(error)
			})
	}
}

export function downloadPlotArtFiles(namePlot, companyToken, kind, formatype) {
	return function () {
		const url = `${process.env.REACT_APP_SIP_SUPPLIERS}/partnumbers/${namePlot}/${companyToken}/${kind}/${formatype}`
		return axios
			.get(url, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/octet-stream',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `${namePlot}`
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				console.error('Error downloading plot file, it may doesn´t exist', error)
				return Promise.reject()
			})
	}
}
