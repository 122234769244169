import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

/** Material UI import section */
import {
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	withStyles,
	Chip,
	Avatar,
	Fab,
	IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import FileIcon from '@material-ui/icons/AttachFile'
import RemoveIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'

/** Custom component import section */
/** Resources import section */
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'
import { ConsingmentNoteFilesType } from '../../../store/helpers/AppConstants'

const UploadEvidenceMerchandise = ({
	files = [],
	onAddingButtonClick = () => console.warn('onAddingButtonClick is not defined!'),
	onDownloadFiles = () => console.warn('onDownloadFiles is not defined'),
	downloadingFiles = false,
	onRemoveFiles = () => console.warn('onRemoveFiles is not defined'),
	onDeleteEvidenceMerchansedise,
	classes,
	canBeAdd,
	deliveryCode,
	isSaving,
	onSeeGetAcknowledgmentFileByName,
}) => {
	const optionsDisplayed = files && files.length > 0
	return (
		<>
			<List>
				<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
					<ListItemText
						secondary={
							<Typography component='span' variant='body2' color='textPrimary'>
								<strong>{<Translate id='consignmentNote.show.evidences' />} </strong>
							</Typography>
						}
					/>
					<ListItemSecondaryAction>
						{canBeAdd && (
							<Tooltip title={<Translate id='consignmentNote.show.evidences' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => onAddingButtonClick(deliveryCode, files)}
								>
									<AddIcon />
								</Fab>
							</Tooltip>
						)}
						{optionsDisplayed && (
							<Tooltip title={<Translate id='common.downloadReport' />}>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='secondary'
									onClick={() => onDownloadFiles(deliveryCode)}
									disabled={downloadingFiles}
								>
									<DownloadIcon />
								</Fab>
							</Tooltip>
						)}
						{canBeAdd && optionsDisplayed && (
							<Tooltip
								title={
									'Si seleccionas esta opción eliminaras todos los archivos, imágenes de evidencias de la entrega en el portal.'
								}
							>
								<Fab
									className={classes.marginIcon}
									size='small'
									color='primary'
									onClick={() => onRemoveFiles(deliveryCode)}
									disabled={isSaving}
								>
									<RemoveIcon />
								</Fab>
							</Tooltip>
						)}
					</ListItemSecondaryAction>
				</ListItem>
				{files.length === 0 && (
					<ListItem divider>
						<ListItemText
							secondary={
								<Typography component='span' variant='body2' color='textPrimary'>
									{<Translate id='consignmentNote.show.evidences' />}
								</Typography>
							}
						/>
					</ListItem>
				)}
				<div className={classes.attachmentsContainer}>
					{files.map((evidence, index) => {
						return (
							<Chip
								key={index}
								avatar={
									<Avatar size='small'>
										<FileIcon />
									</Avatar>
								}
								label={<Typography color='primary'>{evidence}</Typography>}
								style={{ margin: '5px' }}
								onClick={() =>
									onSeeGetAcknowledgmentFileByName(
										deliveryCode,
										evidence,
										ConsingmentNoteFilesType.EVIDENCE_DELIVERY
									)
								}
								disabled={downloadingFiles}
								onDelete={canBeAdd?() =>
									onDeleteEvidenceMerchansedise(
										deliveryCode,
										evidence,
										ConsingmentNoteFilesType.EVIDENCE_DELIVERY
									):null
								}
								deleteIcon={
									canBeAdd && (
										<IconButton>
											<RemoveIcon size='small' color='primary' disabled={downloadingFiles} />
										</IconButton>
									)
								}
							/>
						)
					})}
				</div>
			</List>
		</>
	)
}

UploadEvidenceMerchandise.propTypes = {
	files: PropTypes.array,
	onAddingButtonClick: PropTypes.func,
	onDownloadFiles: PropTypes.func.isRequired,
	downloadingFiles: PropTypes.bool.isRequired,
	onRemoveFiles: PropTypes.func.isRequired,
}

const styles = (theme) => ({
	attachmentsContainer: {
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 4,
		paddingLeft: theme.spacing.unit,
	},
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 80,
	},
	marginIcon: {
		marginLeft: '5px',
	},
})

export default withStyles(styles, { withTheme: true })(UploadEvidenceMerchandise)
