import { createSelector } from 'reselect'
import { toSafeJS } from '../helpers/ImmutableHelper'

const getIndexState = (state) => state.transportTender
const getIndexProfile = (state) => state.profile

const getTransportTender = (state) => state.transportTender.get('transportTender')
const getTransportTenderCount = (state) => state.transportTender.get('transportTenderCount')
const getTransportTenderfilter = (state) => state.transportTender

/**
 * Get Transport tenders list selector
 */
export const getTransportTendersSelector = createSelector([getIndexState], (indexState) => {
	const transportTender = indexState.get('transportTender')

	return transportTender ? toSafeJS(transportTender) : []
})
export const getIsLoadingSavingSelector = createSelector([getIndexState], (indexState) => {
	const isSaving = indexState.get('isSaving')
	return isSaving
})

export const getWineCellarSelector = createSelector([getIndexState], (indexState) => {
	const wineCellar = indexState.getIn(['wineCellarPagination', 'items'])
	return wineCellar ? toSafeJS(wineCellar) : []
})
export const getTypeShipmentSelector = createSelector([getIndexState], (indexState) => {
	const typeShipment = indexState.get('typeShipment')
	return typeShipment ? toSafeJS(typeShipment) : []
})
export const getErrorTransportTenderFileSelector = createSelector([getIndexState], (indexState) => {
	const errorTransportTenderFile = indexState.get('errorTransportTenderFile')
	return errorTransportTenderFile ? toSafeJS(errorTransportTenderFile) : []
})
export const getTransportTypeSelector = createSelector([getIndexState], (indexState) => {
	const transportType = indexState.get('transportType')
	return transportType ? toSafeJS(transportType) : []
})
export const getCarrierInformationSelector = createSelector([getIndexState], (indexState) => {
	const carrierInformation = indexState.get('carrierInformation')
	return carrierInformation ? toSafeJS(carrierInformation) : null
})
export const getIsLoadingTransportTenderSelector = createSelector([getIndexState], (indexState) => {
	const isLoadingTransportTender = indexState.get('isLoadingTransportTender')
	return isLoadingTransportTender
})
export const getIsProposalSelector = createSelector([getIndexState], (indexState) => {
	const isProposal = indexState.get('isProposal')
	return isProposal
})

export const getTransportTenderSelector = createSelector([getTransportTender], (release) =>
	release ? toSafeJS(release) : []
)

export const getIsTransportTenderCountSelector = createSelector([getTransportTenderCount], (release) => release)
export const getTransportTenderfilterSelector = createSelector([getTransportTenderfilter], (release) =>
	toSafeJS(release)
)
export const getTransportTenderDetailSelector = createSelector([getIndexState], (indexState) => {
	const detail = indexState.get('detail')
	return toSafeJS(detail)
})

export const getSelectedCompanySelector = createSelector([getIndexProfile], (indexState) => {
	const selectedCompany = indexState.get('selectedCompany')
	return selectedCompany ? toSafeJS(selectedCompany) : []
})

export const getEventsScheduleSelector = createSelector([getIndexState], (indexState) =>
	indexState && indexState.get('eventsSchedule') ? toSafeJS(indexState.get('eventsSchedule')) : []
)
export const getIsloadingDownloadLog = createSelector([getIndexState], (indexState) => {
	const isLoading = indexState.get('isLoadingDownloadCarrierLog')
	return isLoading
})
export const getTransportZones = createSelector([getIndexState], (indexState) => {
	const transportZone = indexState.get('transportZone')
	return transportZone ? toSafeJS(transportZone) : []
})
export const getErrorTransportZoneFileSelector = createSelector([getIndexState], (indexState) => {
	const errorTransportZoneFile = indexState.get('errorTransportZoneFile')
	return errorTransportZoneFile ? toSafeJS(errorTransportZoneFile) : []
})
export const getErrorTransportTypeFileSelector = createSelector([getIndexState], (indexState) => {
	const errorTransportTypeFile = indexState.get('errorTransportTypeFile')
	return errorTransportTypeFile ? toSafeJS(errorTransportTypeFile) : []
})
export const getErrorTariffSAPFile = createSelector([getIndexState], (indexState) => {
	const errorTariffSAPFile = indexState.get('errorTariffSAPFile')
	return errorTariffSAPFile ? toSafeJS(errorTariffSAPFile) : []
})
export const getErrorRequirementsCarrierFile = createSelector([getIndexState], (indexState) => {
	const errorRequirementsCarrierFile = indexState.get('errorRequirementsCarrierFile')
	return errorRequirementsCarrierFile ? toSafeJS(errorRequirementsCarrierFile) : []
})
export const getErrorWineCellarFile = createSelector([getIndexState], (indexState) => {
	const errorWineCellarFile = indexState.get('errorWineCellarFile')
	return errorWineCellarFile ? toSafeJS(errorWineCellarFile) : []
})
export const getAllWineCellars = createSelector([getIndexState], (indexState) => {
	const wineCellars = indexState.get('wineCellar')
	return wineCellars ? toSafeJS(wineCellars) : []
})
export const getAllClients = createSelector([getIndexState], (indexState) => {
	const clientsCatalog = indexState.get('clientsCatalog')
	return clientsCatalog ? toSafeJS(clientsCatalog) : []
})
export const getIsLoadingCodeFromSAP = createSelector([getIndexState], (indexState) => {
	const isLoadingCodeFromSAP = indexState.get('isLoadingCodeFromSAP')
	return isLoadingCodeFromSAP
})
export const getMaterialSap = createSelector([getIndexState], (indexState) => {
	const materialSap = indexState.get('materialSap') ? indexState.get('materialSap').toJS() : null
	return materialSap
})
export const getErrorClientsFile = createSelector([getIndexState], (indexState) => {
	const errorClientsFile = indexState.get('errorClientsFile')
	return errorClientsFile ? toSafeJS(errorClientsFile) : []
})
export const getIsLoadingWinners = createSelector([getIndexState], (indexState) => {
	const isLoadingWinners = indexState.get('isLoadingWinners')
	return isLoadingWinners ? toSafeJS(isLoadingWinners) : false
})
export const getWinnersTransportTenders = createSelector([getIndexState], (indexState) => {
	const winners = indexState.get('winnersTransportTenders')
	return winners ? toSafeJS(winners) : []
})

export const getErrorManeuversFile = createSelector([getIndexState], (indexState) => {
	const errorManeuversFile = indexState.get('errorManeuversFile')
	return errorManeuversFile ? toSafeJS(errorManeuversFile) : []
})


export const getTransportTenderDetailWinnerSelector = createSelector([getIndexState], (indexState) => {
	const detail = indexState.get('detailWinner')
	return toSafeJS(detail)
})