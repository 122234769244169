/** React component section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
/** Material-UI imports section */
import { CardContent, Typography, IconButton, Checkbox, withStyles, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

/** Import component section */
import TransportTypePanel from './TransportTypePanel'
import { TransportTendeStatus } from '../../../store/helpers/AppConstants'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import {
	CalendarToday,
	Info
} from '@material-ui/icons'

const TransportTenderInformation = (props) => {
	const {
		classes,
		transportTenderdetail,
		transportType,
		isInternalUser,
		setOpenEditDialog,
		islodigSaving,
		typeShipmentsOptions,
		setOpenWineCellarDateDialog,
		onOpenCalculateTarif,
	} = props

	// const classes = styles()
	const [openPanel, setOpenPanel] = useState({ open: false, isEdit: false })
	const typeShipment = typeShipmentsOptions.find((x) => x.value === transportTenderdetail.TypeShipmentId)
	// const TransportZoneWithMaxKM = () => {
	// 	const transportZone = transportTenderdetail.Destinations.map((destination) => destination.TransportZone)
	// 	return transportZone && transportZone.length > 0
	// 		? transportZone.reduce((maxKm, zone) => {
	// 				return zone.KM > maxKm.KM ? zone : maxKm
	// 		  })
	// 		: null
	// }

	// const concatTenderEndDate = () => {
	// 	const fechaParteFecha = transportTenderdetail.TenderEndDate
	// 		? moment(transportTenderdetail.TenderEndDate)
	// 		: moment()

	// 	const fechaParteHora = transportTenderdetail.TenderEndTime
	// 		? moment(transportTenderdetail.TenderEndTime, ' HH:mm A')
	// 		: moment()

	// 	console.log('hora fin', fechaParteFecha)
	// 	console.log('fechaFin', fechaParteHora)

	// 	const fechaConcatenada = fechaParteFecha
	// 		.hour(fechaParteHora.hour())
	// 		.minute(fechaParteHora.minute())
	// 		.second(fechaParteHora.second())

	// 	return fechaConcatenada
	// }
	const onGetTimeRestant = () => {
		const fechaInicio = moment()
		const fechaFin = moment(transportTenderdetail.TransportTenderExpirationDate)
		const diferencia = moment.duration(fechaFin.diff(fechaInicio))
		if (fechaInicio.isAfter(fechaFin)) {
			return '¡Está licitación ha expirado!'
		} else {
			const dias = diferencia.days()
			const horas = diferencia.hours()
			const minutos = diferencia.minutes()
			const segundos = diferencia.seconds()
			return ` ${dias} días, ${horas} horas, ${minutos} minutos, ${segundos} segundos`
		}
	}
	return (
		<CardContent>
			<div className={classes.textLicitacion}>
				<Typography variant='body2' display='block' className={classes.textLicitacion}>
					<strong>Tiempo restante</strong>
					{`${onGetTimeRestant()}`}
				</Typography>{' '}
			</div>
			<div className={classes.headerContainer}>
				<Typography variant='body2' display='block'>
					<strong>
						<Translate id='carrierTenders.detail.shipmentID' /> {'  '}
					</strong>
					{transportTenderdetail.ShipmentId}
				</Typography>{' '}
				<Typography variant='body2' display='block'>
					<strong>
						<Translate id='carrierTenders.detail.typeShipment' />: {'  '}
					</strong>
					{typeShipment ? typeShipment.label : ''}
				</Typography>{' '}
				{isInternalUser && (
					<div className={classes.editContainer}>
						<Typography variant='body2' display='block'>
							<Translate id='carrierTenders.edit' />
							<IconButton size='small' onClick={() => setOpenEditDialog(true)} disabled={islodigSaving}>
								<EditIcon color='primary' />
							</IconButton>
						</Typography>{' '}
					</div>
				)}
			</div>
			<div className={classes.requirementService}>
				<Typography variant='body2' display='block'>
					<strong>
						{<Translate id='carrierTenders.detail.applicationCreationDate' />}
						{'  '}
					</strong>
				</Typography>
				{transportTenderdetail.DateServiceRequest
					? moment(transportTenderdetail.DateServiceRequest).format('DD/MM/YYYY')
					: '-'}
				{'  '}
				<div className={classes.chipTime}>
					{transportTenderdetail.StartTime ? moment(transportTenderdetail.StartTime).format('HH:mm A') : '-'}{' '}
					- {transportTenderdetail.EndTime ? moment(transportTenderdetail.EndTime).format('HH:mm A') : '-'}
				</div>
			</div>
			<div className={classes.wineCellarContainer}>
				<div>
					<Typography variant='body2' display='block'>
						<strong>{<Translate id='carrierTenders.wineCellar' />}</strong>
					</Typography>{' '}
					<Typography variant='body2' display='block'>
						{transportTenderdetail.WineCellarName} | {transportTenderdetail.WineCellarDirection}
					</Typography>{' '}
				</div>
				<div className={classes.wineCellerDateContent}>
					{isInternalUser &&
						transportTenderdetail.Status !== TransportTendeStatus.DRAFT &&
						transportTenderdetail.Proposals.length > 0 && (
							<Button onClick={() => setOpenWineCellarDateDialog(true)}>
								<Typography variant='caption' className={classes.wineCellerDateTitle}>
									<Translate id={'carrierTenders.deals.dateCellar'} />
								</Typography>
								<CalendarToday className={classes.colorIcon} />
							</Button>
						)}
				</div>
			</div>
			<div className={classes.programmer}>
				<Typography variant='body2' display='block'>
					<strong>{<Translate id='carrierTenders.detail.programmer' />}:</strong>{' '}
					{transportTenderdetail.ProgrammerName && transportTenderdetail.ProgrammerName.length > 0
						? transportTenderdetail.ProgrammerName
						: '-'}
				</Typography>{' '}
			</div>
			<div>
				<Typography variant='body2' display='block'>
					<strong>{<Translate id='carrierTenders.detail.remarks' />}</strong>
				</Typography>{' '}
				<Typography variant='body2' display='block'>
					{transportTenderdetail.ServiceDescription}
				</Typography>{' '}
			</div>

			<div className={classes.rateContainer}>
				{isInternalUser && (
					<div className={classes.flexSpaceBetween}>
						<Typography variant='body2' display='block'>
							<strong>{<Translate id='carrierTenders.showBaseRateCarrier' />}:</strong>{' '}
						</Typography>{' '}
						<Checkbox value='status' disabled checked={transportTenderdetail.ShowBaseRate} />
					</div>
				)}
				{isInternalUser && (
					<div className={classes.flexSpaceBetween}>
						<Typography variant='body2' display='block'>
							<strong>{<Translate id='carrierTenders.openRateTender' />}:</strong>{' '}
						</Typography>{' '}
						<Checkbox value='status' disabled checked={transportTenderdetail.OpenRateTender} />
					</div>
				)}
				<div className={classes.flexSpaceBetween}>
					<Typography variant='body2' display='block'>
						<strong>{<Translate id='carrierTenders.tenderEndDate' />}:</strong>{' '}
						{transportTenderdetail.TenderEndDate
							? moment(transportTenderdetail.TenderEndDate).format('DD/MM/YYYY')
							: '-'}
						{transportTenderdetail.TenderEndTime
							? moment(transportTenderdetail.TenderEndTime).format(' HH:mm A')
							: '-'}
					</Typography>{' '}
				</div>
			</div>
			<div className={classes.rateContainer}>
				{(transportTenderdetail.ShowBaseRate || isInternalUser) && (
					<div className={classes.flexSpaceBetween}>
						<Typography variant='body2' display='block'>
							<strong>{<Translate id='carrierTenders.detail.tarif' />}:</strong>
							{transportTenderdetail.FeeAmount
								? transportTenderdetail.FeeAmount.toLocaleString('es-MX', {
										style: 'currency',
										currency: 'MXN',
								  })
								: '$0 MXN'}
						</Typography>
						<IconButton onClick={() => onOpenCalculateTarif(true)} fontSize={'small'}>
							<Info className={classes.colorIcon} fontSize='small' />
						</IconButton>
					</div>
				)}{' '}
				<div />
				<div>
					<Typography variant='body2' display='block'>
						<strong>{'Folio letra'}: </strong>
						{transportTenderdetail && `${transportTenderdetail.FolioLetter}`}
					</Typography>
				</div>{' '}
			</div>
			{isInternalUser &&
				transportTenderdetail.TransportTypeCode === '' &&
				(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
					transportTenderdetail.Status === TransportTendeStatus.SENT_TO_CARRIER) && (
					<div className={classes.addTransportTypeContainer}>
						<div
							className={classes.addTransportTypeTitle}
							onClick={() => setOpenPanel({ open: true, isEdit: true })}
						>
							<Typography variant='body2' display='block'>
								{<Translate id='carrierTenders.detail.addTransportType' />}
							</Typography>{' '}
							<EditIcon className={classes.addTransportTypeIcon} color='primary' />
						</div>
					</div>
				)}

			{transportTenderdetail.TransportTypeCode !== '' && (
				<div className={classes.containerTransportTypeContainer}>
					<div className={classes.titleTypeTransport}>
						<Typography variant='body2' display='block'>
							<strong>
								{<Translate id='carrierTenders.detail.typeTransport' />}: {'  '}
							</strong>{' '}
							{transportTenderdetail.TransportType.Type} {'  '}|{' '}
							{transportTenderdetail.TransportType.Code} |{' '}
							{transportTenderdetail.TransportType.Description}
						</Typography>{' '}
					</div>
					{isInternalUser &&
						(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
							transportTenderdetail.Status === TransportTendeStatus.SENT_TO_CARRIER) && (
							<div className={classes.editContainer}>
								<Typography variant='body2' display='block'>
									<Translate id='carrierTenders.edit' />
									<IconButton
										size='small'
										onClick={() => setOpenPanel({ open: true, isEdit: false })}
									>
										<EditIcon color='primary' />
									</IconButton>
								</Typography>{' '}
							</div>
						)}
				</div>
			)}

			{openPanel.open && (
				<TransportTypePanel
					onClose={() => setOpenPanel({ open: false, isEdit: false })}
					transportType={transportType}
					isEdit={openPanel.isEdit}
					transportTenderdetail={transportTenderdetail}
				/>
			)}
		</CardContent>
	)
}
TransportTenderInformation.propTypes = {
	islodigSaving: propTypes.bool,
}
const styles = (theme) => ({
	headerContainer: {
		display: 'grid',
		gridTemplateColumns: '3fr 2fr 2fr 1fr',
	},
	textLicitacion: {
		color: 'red !important',
		// borderLeft: '1px solid rgba(191, 12, 61, 0.5)',
	},
	editContainer: {
		marginTop: '-10px',
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '-10px',
	},
	programmer: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	wineCellarContainer: {
		display: 'grid',
		gridTemplateColumns: '2fr 1fr',
	},
	addTransportTypeContainer: {
		backgroundColor: '#F9F9F9',
		height: '50px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	containerTransportTypeContainer: {
		backgroundColor: '#F9F9F9',
		height: '50px',
		display: 'grid',
		gridTemplateColumns: '20px 8fr 1fr',
		alignItems: 'center',
	},
	addTransportTypeTitle: {
		display: 'flex',
		cursor: 'pointer',
	},
	addTransportTypeIcon: {
		margin: '-5px 0px 0px 5px',
	},
	titleTypeTransport: {
		gridColumn: '2',
		display: 'flex',
	},

	rateContainer: {
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 3fr',
		gridGap: '20px',
	},
	flexSpaceBetween: {
		display: 'flex',
		alignItems: 'center',
	},
	requirementService: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: '8px',
	},
	chipTime: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.light,
		borderRadius: '8px',
		height: '25px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '3px',
		marginLeft: '8px',
	},
	wineCellerDateContent: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	wineCellerDateTitle: {
		whiteSpace: 'nowrap',
		paddingRight: '8px',
	},
	colorIcon: {
		color: theme.palette.primary.main,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(TransportTenderInformation))
