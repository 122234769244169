import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import ShippingIcon from '@material-ui/icons/ShoppingCart'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../common/DataTable'
import SearchInput from '../common/SearchInput/SearchInput'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import {
    	getExportRequest
		} from '../../store/exportRequest/ExportRequestAction'
import { useHistory } from 'react-router-dom'
import LoadingPage from '../common/LoadingPage'
import { onGetBackgroundColorChip, onGetStatusDescriptionChip } from '../../store/helpers/ExportRequestHelper'
import { Chip } from '@material-ui/core'

const Index = (props) => {
	let history = useHistory()

	const dispatch = useDispatch()
	const userProfile = useSelector((state) => state.oidc.user)
	
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	
	
	const [sortCriteria, setSortCriteria] = useState({
		by: 'CreationDate',
		ascending: false,
	})	

	
	const handleSortCriteriaChange = (newSortBy, newSortAscending) => {
		setSortCriteria({
			by: newSortBy,
			ascending: newSortAscending
		});
	}
	const isLoadingList = useSelector((state) => state.exportRequest.get('isLoadingList'));
	const allExportRequest = useSelector((state) => state.exportRequest.get('exportRequestList').toArray());
	const exportRequestDataBefore = allExportRequest[1];
	let exportRequestData  = [];
	if (exportRequestDataBefore) 
	{
		exportRequestData = exportRequestDataBefore.map(item => item.toJS()).toArray();
	}
	const[query, setQuery] = useState('');
	useEffect(() => {
		dispatch(getExportRequest(query, sortCriteria.by, sortCriteria.ascending, page, rowsPerPage))
	}, [dispatch, query, sortCriteria, page, rowsPerPage])
	const exportRequestCount = allExportRequest[0]

	const handlePageChange = (newPage, newRowsPerPage) => {
		setRowsPerPage((prevRowsPerPage) => {
			const pageToSet = prevRowsPerPage !== newRowsPerPage ? 0 : newPage
			setPage(pageToSet)
			return newRowsPerPage
		})
	}

	const onRendercustomCell = (dataSource, item) => {
		switch (dataSource) {
			case 'Status': {
				return (
					<Chip
						label={<Translate id={onGetStatusDescriptionChip(item.Status)} />}
						style={{
							borderRadius: '5px',
							width: '100%',
							height: '25px',
							backgroundColor: onGetBackgroundColorChip(item.Status),
						}}
						color='inherit'
					/>
				)
			}
			default:
				return ''
		}
	}

	if (!userProfile) return null

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ShippingIcon />
					</Avatar>
				}
				action={
					<div>
						<SearchInput 
						onChangeSearchValue={setQuery} 
						value={query} 
						/>
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.exportRequests' />{' '}
					</Typography>
				}
			/>
			<CardContent>
				{isLoadingList === 0 ? 
				<LoadingPage/> :
				<DataTable
				isIndex
				configuration={TableConfiguration()}
				data={exportRequestData}
				totalRows={exportRequestCount}
				page={page}
				rowsPerPage={rowsPerPage}
				onChangePage={handlePageChange}
				sortBy={sortCriteria.by}
				sortAscending={sortCriteria.ascending}
				onChangeSortCriteria={handleSortCriteriaChange}
				onRowClick={(rowData) => {
					history.push(`/exportRequests/${rowData.token}`)
				}}
				showFilters
				onRenderCellItem={onRendercustomCell}
				/>
				}
				
				
				
			</CardContent>
		</Card>
	)
}

Index.propTypes = {}

function TableConfiguration() {
	let columns = [
		{
			header: 'Status',
			dataSource: 'Status',
			isSortable: false
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'CreationDate',
			isSortable: true
		},
		{
			header: <Translate id='common.folioSIT' />,
			dataSource: 'SITFolio',
			isSortable: true
		},
		{
			header: <Translate id='common.orderType' />,
			dataSource: 'OrderType',
			isSortable: true
		},
		{
			header: <Translate id='common.transportType' />,
			dataSource: 'TransportType',
			isSortable: true
		},
		{
			header: <Translate id='common.incoterm' />,
			dataSource: 'Incoterm',
			isSortable: true
		},
		{
			header: <Translate id='common.incotermPlace' />,
			dataSource: 'IncotermPlace',
			isSortable: true
		},
	]

	return { columns }
}

export default withauthenticatedLayout(Index)
