//Import react section
import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
//Import mui section
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import PublicIcon from '@material-ui/icons/Public'
import { Badge, Divider, Fab, Grid, IconButton } from '@material-ui/core'
import { Message } from '@material-ui/icons'

//import component section
import ListDocumentsComponentAdd from '../../../common/ListDocumentsComponentAdd'
import TabPanel from '../../../common/TabPanel'
import EmptyView from '../show/EmptyView'
import { ExportRequestStatus, canBeEditExportRequestInformation } from '../../../../store/helpers/ExportRequestHelper'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'

function TabContainer({ children, dir }) {
	return (
		<Typography component='div' dir={dir} style={{ padding: 8 }}>
			{children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
	dir: PropTypes.string.isRequired,
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},

	titleGral: {
		marginTop: '1rem',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	titlesTab: {
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	sendReview: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'column',
	},
	sendReviewBtns: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '1rem',
		marginTop: '1rem',
	},
})

class TabsInShow extends React.Component {
	state = {
		value: 0,
	}

	static getDerivedStateFromProps(props, state) {
		let tabIndices = []
		let propsArray = [
			'nationalProviderData',
			'mxProviderData',
			'usaForeignProviderData',
			'foreignTransportProviderData',
		]

		for (let i = 0; i < propsArray.length; i++) {
			if (props[propsArray[i]]) {
				tabIndices.push(i)
			}
		}
		return { ...state, tabIndices }
	}

	handleChange = (event, newValue) => {
		this.setState({ value: this.state.tabIndices[newValue] })
	}

	handleChangeIndex = (index) => {
		this.setState({ value: index })
	}

	render() {
		// const actualTab = this.state.tabIndices.indexOf(this.state.value)

		const { classes, userProfile, onUploadFileInItem } = this.props
		const {
			nationalProviderData,
			mxProviderData,
			foreignTransportProviderData,
			usaForeignProviderData,
			onDeleteFilesFromItem,
			exportRequest,
			// onSynchronizeSit,
			isSavingFile,
			isLoadingItem,
			isLoadingItemStatus,
			onSetStatusItem,
			onOpenPanelHistoryMesages,
			confirmPasswordConfigModalFromItem,
			setConfirmPasswordConfigModalFromItem,
			// shouldDestroyComponent,
			// setShouldDestroyComponent,
		} = this.props
		let isEditableProviderNational = canBeEditExportRequestInformation(nationalProviderData, userProfile)

		let isEditableProviderMx = canBeEditExportRequestInformation(mxProviderData, userProfile)
		let isEditableProviderForeign = canBeEditExportRequestInformation(usaForeignProviderData, userProfile)
		let isEditableProviderForeignTransport = canBeEditExportRequestInformation(
			foreignTransportProviderData,
			userProfile
		)
		let isExportRequestUsa = exportRequest.Region && exportRequest.Region === 'USA'
		// const propsArray = [
		// 	'nationalProviderData',
		// 	'mxProviderData',
		// 	'usaForeignProviderData',
		// 	'foreignTransportProviderData',
		// ]

		// let actualExportRequestType
		// let actualExportRequestStatus
		// let isVisibleBtn = false
		// let actualMessages = []

		// if (this.props[propsArray[actualTab]]) {
		// 	actualExportRequestType = this.props[propsArray[actualTab]].ExportRequestType
		// 	actualExportRequestStatus = this.props[propsArray[actualTab]].Status
		// 	actualMessages = this.props[propsArray[actualTab]].ExportRequestItemHistories
		// 	if (this.props[propsArray[actualTab]].Status !== ExportRequestStatus.Complete) isVisibleBtn = true
		// }

		const onRenderAditionalActionButtons = (supplierItem) => {
			return (
				<Grid item xs={3}>
					<div className={classes.sendReview}>
						<div className={classes.sendReviewBtns}>
							<Fab
								variant='extended'
								size='large'
								color='primary'
								// onClick={() => {
								// 	onSynchronizeSit()
								// 	onSetStatusItem(actualExportRequestType, 2, "")
								// 	}
								// }
								onClick={() => {
									setConfirmPasswordConfigModalFromItem({
										opened: !confirmPasswordConfigModalFromItem.opened,
										item: supplierItem,
									})
									// setShouldDestroyComponent(false)
								}}
								disabled={
									isLoadingItem ||
									isLoadingItemStatus ||
									supplierItem.Status === ExportRequestStatus.Complete
								}
								data-cy='btn-available-operations'
							>
								<Translate id='common.sendToReview' />
							</Fab>
							{supplierItem.ExportRequestItemHistories && (
								<Badge badgeContent={supplierItem.ExportRequestItemHistories.length} color='primary'>
									<IconButton
										onClick={() =>
											onOpenPanelHistoryMesages(supplierItem.ExportRequestItemHistories)
										}
									>
										<Message />
									</IconButton>
								</Badge>
							)}
						</div>
					</div>
				</Grid>
			)
		}

		return (
			<div className={classes.root}>
				<Tabs
					value={this.state.tabIndices.indexOf(this.state.value)}
					onChange={this.handleChange}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'
					style={{
						height: '5rem',
					}}
				>
					{nationalProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.transportProvider' />
								</div>
							}
						/>
					)}
					{mxProviderData && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.nationalProvider' />
								</div>
							}
						/>
					)}
					{usaForeignProviderData && isExportRequestUsa && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.foreignProvider' />
								</div>
							}
						/>
					)}

					{foreignTransportProviderData && isExportRequestUsa && (
						<Tab
							style={{ minWidth: '50px', maxWidth: '25%' }}
							className={classes.tab}
							label={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<PublicIcon />
									<Translate id='common.foreignTransportProvider' />
								</div>
							}
						/>
					)}
				</Tabs>

				{/* {nationalProviderData || mxProviderData || usaForeignProviderData || foreignTransportProviderData
					? isVisibleBtn && (
							<div className={classes.sendReview}>
								<div className={classes.sendReviewBtns}>
									<Fab
										variant='extended'
										size='small'
										color='primary'
										// onClick={() => {
										// 	onSynchronizeSit()
										// 	onSetStatusItem(actualExportRequestType, 2, "")
										// 	}
										// }
										onClick={() => {
											setConfirmPasswordConfigModalFromItem({
												opened: !confirmPasswordConfigModalFromItem.opened,
											})
											setShouldDestroyComponent(false)
										}}
										disabled={
											isLoadingItem ||
											isLoadingItemStatus ||
											actualExportRequestStatus === ExportRequestStatus.Complete
										}
										data-cy='btn-available-operations'
									>
										<Translate id='common.sendToReview' />
									</Fab>
								</div>
								<div className={classes.sendReviewBtns}>
									{actualMessages && (
										<Badge badgeContent={actualMessages.length} color='primary'>
											<IconButton onClick={() => onOpenPanelHistoryMesages(actualMessages)}>
												<Message />
											</IconButton>
										</Badge>
									)}
								</div>
							</div>
					  )
					: null} */}
				{nationalProviderData || mxProviderData || usaForeignProviderData || foreignTransportProviderData ? (
					<>
						{nationalProviderData && (
							<TabPanel value={this.state.value} index={0}>
								<Grid container>
									<Grid item xs={9}>
										<Typography className={classes.titlesTab} color='primary'>
											<Translate id='common.transportProviderInfo' />
										</Typography>
										{nationalProviderData && (
											<>
												<Typography>
													{'Código de proveedor: '}
													{nationalProviderData.SupplierCode}
													{'Nombre de proveedor: '}
													{nationalProviderData.SupplierName}
												</Typography>
												<Typography>{nationalProviderData.SupplierEmail}</Typography>
											</>
										)}
									</Grid>
									{onRenderAditionalActionButtons(nationalProviderData)}
								</Grid>
								<ListDocumentsComponentAdd
									folderPathIdentifier='ConsignmentNoteFiles'
									title='common.waybillXmlPdf'
									documents={nationalProviderData.ConsignmentNoteFiles}
									editable={isEditableProviderNational}
									item={nationalProviderData}
									onUploadFileInItem={onUploadFileInItem}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
									isSavingFile={isSavingFile}
								/>

								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={nationalProviderData.InvoiceServicesFiles}
									editable={isEditableProviderNational}
									onUploadFileInItem={onUploadFileInItem}
									item={nationalProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<Divider />
								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								{/* <GeneralFiles dataDetail={dataDetail} /> */}

								<Divider />
							</TabPanel>
						)}
						{mxProviderData && (
							<TabPanel value={this.state.value} index={1}>
								<Grid container>
									<Grid item xs={9}>
										<Typography className={classes.titlesTab} color='primary'>
											<Translate id='common.nationalProviderInfo' />
										</Typography>
										{mxProviderData && (
											<>
												<Typography>
													{mxProviderData.SupplierCode} {mxProviderData.SupplierName}
												</Typography>
												<Typography>{mxProviderData.SupplierEmail}</Typography>
											</>
										)}
									</Grid>
									{onRenderAditionalActionButtons(mxProviderData)}
								</Grid>
								{/* <Typography className={classes.titlesTab}>
									<Translate id='common.nationalProviderInfo' />
								</Typography>
								{mxProviderData && (
									<>
										<Typography>
											{mxProviderData.SupplierCode} {mxProviderData.SupplierName}
										</Typography>
										<Typography>{mxProviderData.SupplierEmail}</Typography>
									</>
								)} */}
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='ExportPedimentoFiles'
									title='common.exportDeclaration'
									documents={mxProviderData.ExportPedimentoFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='OtherFiles'
									title='common.otherFiles'
									documents={mxProviderData.OtherFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='DODAFiles'
									title='common.doda'
									documents={mxProviderData.DODAFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={mxProviderData.InvoiceServicesFiles}
									editable={isEditableProviderMx}
									onUploadFileInItem={onUploadFileInItem}
									item={mxProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>

								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								{/* <GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}
						{usaForeignProviderData && (
							<TabPanel value={this.state.value} index={2}>
								{/* <Typography className={classes.titlesTab}>
									<Translate id='common.foreignProviderInfo' />​
								</Typography>
								{usaForeignProviderData && (
									<>
										<Typography>
											{usaForeignProviderData.SupplierCode} {usaForeignProviderData.SupplierName}
										</Typography>
										<Typography>{usaForeignProviderData.SupplierEmail}</Typography>
									</>
								)} */}

								<Grid container>
									<Grid item xs={9}>
										<Typography className={classes.titlesTab} color='primary'>
											<Translate id='common.foreignProviderInfo' />
										</Typography>
										{usaForeignProviderData && (
											<>
												<Typography>
													{usaForeignProviderData.SupplierCode}{' '}
													{usaForeignProviderData.SupplierName}
												</Typography>
												<Typography>{usaForeignProviderData.SupplierEmail}</Typography>
											</>
										)}
									</Grid>
									{onRenderAditionalActionButtons(usaForeignProviderData)}
								</Grid>

								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='EntryFiles'
									title='common.entry'
									documents={usaForeignProviderData.EntryFiles}
									editable={isEditableProviderForeign}
									onUploadFileInItem={onUploadFileInItem}
									item={usaForeignProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='InvoiceServicesFiles'
									title='common.serviceInvoice'
									documents={usaForeignProviderData.InvoiceServicesFiles}
									editable={isEditableProviderForeign}
									onUploadFileInItem={onUploadFileInItem}
									item={usaForeignProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>

								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>

								{/* <GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}

						{foreignTransportProviderData && (
							<TabPanel value={this.state.value} index={3}>
								{/* <Typography className={classes.titlesTab}>
									<Translate id='common.foreignProviderInfoTab' />​
								</Typography>
								{foreignTransportProviderData && (
									<>
										<Typography>
											{foreignTransportProviderData.SupplierCode}{' '}
											{foreignTransportProviderData.SupplierName}
										</Typography>
										<Typography>{foreignTransportProviderData.SupplierEmail}</Typography>
									</>
								)}

								{onRenderAditionalActionButtons(nationalProviderData)} */}
								<Grid container>
									<Grid item xs={9}>
										<Typography className={classes.titlesTab} color='primary'>
											<Translate id='common.foreignProviderInfoTab' />
										</Typography>
										{foreignTransportProviderData && (
											<>
												<Typography>
													{foreignTransportProviderData.SupplierCode}{' '}
													{foreignTransportProviderData.SupplierName}
												</Typography>
												<Typography>{foreignTransportProviderData.SupplierEmail}</Typography>
											</>
										)}
									</Grid>
									{onRenderAditionalActionButtons(foreignTransportProviderData)}
								</Grid>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='PODFiles'
									title='common.POD'
									documents={foreignTransportProviderData.PODFiles}
									editable={isEditableProviderForeignTransport}
									onUploadFileInItem={onUploadFileInItem}
									item={foreignTransportProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<ListDocumentsComponentAdd
									isSavingFile={isSavingFile}
									folderPathIdentifier='AmericanFreightInvoiceFiles'
									title='common.americanFreightInvoice'
									documents={foreignTransportProviderData.AmericanFreightInvoiceFiles}
									editable={isEditableProviderForeignTransport}
									onUploadFileInItem={onUploadFileInItem}
									item={foreignTransportProviderData}
									onDeleteFilesFromItem={onDeleteFilesFromItem}
								/>
								<Typography className={classes.titleGral}>
									<Translate id='common.generalFiles' />
								</Typography>
								{/* <GeneralFiles dataDetail={dataDetail} /> */}
							</TabPanel>
						)}

						{confirmPasswordConfigModalFromItem.opened && (
							<ConfirmPasswordDialog
								open={confirmPasswordConfigModalFromItem.opened}
								title={<Translate id='common.confirmOperation' />}
								message1={<Translate id='common.messageSentToReview' />}
								message2={<Translate id='common.enterPassword' />}
								onClickButtonConfirm={(message) => {
									onSetStatusItem(
										confirmPasswordConfigModalFromItem.item.ExportRequestType,
										2,
										message.comments
									)
								}}
								onClose={() =>
									setConfirmPasswordConfigModalFromItem({
										opened: !confirmPasswordConfigModalFromItem.opened,
									})
								}
								isSaving={isLoadingItem || isLoadingItemStatus}
								isVisibleComments={true}
								commentRequired={true}
							/>
						)}
					</>
				) : (
					<EmptyView />
				)}
			</div>
		)
	}
}

TabsInShow.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
}

export default connect()(withStyles(styles, { withTheme: true })(TabsInShow))
