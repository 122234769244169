/** Helpers import section */
import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import { onGetBlobType } from '../helpers/BlobTypeHelper'
import { descriptionStatus, backgroundColorTableStatus } from '../helpers/ConsigmentNoteHelper'

export const START_LOADING_CONSIGNMENTNOTE_LIST = 'START_LOADING_CONSIGNMENTNOTE_LIST'
export const SET_CONSIGNMENTNOTE_LIST = 'SET_CONSIGNMENTNOTE_LIST'
export const SET_CONSIGNMENTNOTE_COUNT = 'SET_CONSIGNMENTNOTE_COUNT'
export const IS_SAVING_CONSIGNMENTNOTE = 'IS_SAVING_CONSIGNMENTNOTE'
export const ADD_CONSIGNMENTNOTE = 'ADD_CONSIGNMENTNOTE'
export const SET_CONSIGNMENTNOTE = 'SET_CONSIGNMENTNOTE'
export const IS_DOWNLOADING_CONSIGNMENTNOTE = 'IS_DOWNLOADING_CONSIGNMENTNOTE'
export const UPDATE_CONSIGNMENTNOTE = 'UPDATE_CONSIGNMENTNOTE'
export const ADD_CONSIGNMENTNOTE_MATERIAL = 'ADD_CONSIGNMENTNOTE_MATERIAL'
export const ADD_CONSIGNMENTNOTE_DESTINATION = 'ADD_CONSIGNMENTNOTE_DESTINATION'
export const SET_DELIVERIES = 'SET_DELIVERIES'
export const SET_CUPONS_FERRY = 'SET_CUPONS_FERRY'
export const SET_CUPON_FERRY = 'SET_CUPON_FERRY'
export const SET_FERRY_CUPONS_PAGINATED = 'SET_FERRY_CUPONS_PAGINATED'
export const SET_FERRY_CUPONS_MANAGERS_ERRORS = 'SET_FERRY_CUPONS_MANAGERS_ERRORS'
export const DELETE_FERRY_CUPONS_MANAGERS = 'DELETE_FERRY_CUPONS_MANAGERS'
export const DELETE_FERRY_CUPON = 'DELETE_FERRY_CUPON'

/**
 * Action to set SET_FERRY_CUPONS_PAGINATED list
 * @param {*} SET_FERRY_CUPONS_PAGINATED
 */
function setFerryCuponsPaginatedList(dataList) {
	return {
		type: SET_FERRY_CUPONS_PAGINATED,
		cuponsFerryManager: dataList,
	}
}
function deleteFerryCuponAction(id) {
	return {
		type: DELETE_FERRY_CUPON,
		id,
	}
}

function deleteFerryCuponMaanager(id) {
	return {
		type: DELETE_FERRY_CUPONS_MANAGERS,
		id,
	}
}



function setFerryCuponsErrors(errors) {
	return {
		type: SET_FERRY_CUPONS_MANAGERS_ERRORS,
		errors: errors,
	}
}

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_CONSIGNMENTNOTE_LIST,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action to set CONSIGNMENTNOTE list
 * @param {*} CONSIGNMENTNOTE
 */
function setConsignmentNoteList(dataList) {
	return {
		type: SET_CONSIGNMENTNOTE_LIST,
		dataList: dataList,
	}
}

/**
 * Action to set count
 * @param {*} count
 */
function setTotalCount(count) {
	return {
		type: SET_CONSIGNMENTNOTE_COUNT,
		count,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING_CONSIGNMENTNOTE,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setConsignmentNote(consignmentNote) {
	return {
		type: SET_CONSIGNMENTNOTE,
		consignmentNote,
	}
}

/**
 * is loading report
 * @param {*} isDonwloading
 */
function isDonwloadingReport(isDonwloading) {
	return {
		type: IS_DOWNLOADING_CONSIGNMENTNOTE,
		isDonwloading,
	}
}

/**
 * add material
 * @param {*} item
 */
function setMaterialAction(deliverieId, item) {
	return {
		type: ADD_CONSIGNMENTNOTE_MATERIAL,
		item: item,
		deliverieId: deliverieId,
	}
}

/**
 * Action to set SET_DELIVERIES list
 * @param {*} SET_DELIVERIES
 */
function setDeliveriesList(dataList) {
	return {
		type: SET_DELIVERIES,
		dataList: dataList,
	}
}

function setCuponsFerryList(cuponsFerry) {
	return {
		type: SET_CUPONS_FERRY,
		cuponsFerry: cuponsFerry,
	}
}

function setCuponFerry(cuponFerry) {
	return {
		type: SET_CUPON_FERRY,
		cuponFerry: cuponFerry,
	}
}

/**
 * Action load consignment Note from company backend
 * @param {*} query
 * @param {*} orderBy
 * @param {*} sortAscending
 * @param {*} page
 * @param {*} rowsPerPage
 */
export function loadAllConsignmentNoteFromBackEnd(queryParameters, translate) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, START_LOADING_CONSIGNMENTNOTE_LIST))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/search`
			let command = {
				BusinessUnitCode: selectedCompany.get('document_identifier'),
				PageNumber:
					queryParameters && queryParameters.pageNumber ? parseInt(queryParameters.pageNumber) + 1 : 1,
				PageSize: queryParameters && queryParameters.rowsPerPage ? parseInt(queryParameters.rowsPerPage) : 20,
				Query: queryParameters && queryParameters.query ? queryParameters.query : '',
				Sort: {
					By: queryParameters && queryParameters.sortBy ? queryParameters.sortBy : 'Folio',
					Direction:
						queryParameters && queryParameters.sortDirection ? queryParameters.sortDirection : 'desc',
				},
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						let mappedResponse = serverResponse.data.items.map((consignmentNote) => {
							consignmentNote.color_status_table = backgroundColorTableStatus(consignmentNote.status)
							consignmentNote.status = descriptionStatus(
								consignmentNote.status,
								translate,
								consignmentNote.source
							)
							return consignmentNote
						})
						dispatch(setTotalCount(serverResponse.data.total))
						dispatch(setConsignmentNoteList(mappedResponse))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingCommon(false, START_LOADING_CONSIGNMENTNOTE_LIST))
					console.error('Error getting consignment Note list by company', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isLoadingCommon(false, START_LOADING_CONSIGNMENTNOTE_LIST))
		}
	}
}

/**
 * Action to add consignment Note
 * @param {*} itemModel
 */
export function addConsignmentNote(itemModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_CONSIGNMENTNOTE))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes`
			let command = {
				TransportCode: itemModel ? itemModel.transportCode : '',
				CarrierCode: itemModel ? itemModel.transport.code : '',
				CarrierName: itemModel ? itemModel.transport.name : '',
				SenderCode: selectedCompany ? selectedCompany.get('company_code') : '',
				SenderName: selectedCompany ? selectedCompany.get('name') : '',
				SenderRFC: selectedCompany ? selectedCompany.get('rfc') : '',
				CompanyCode: selectedCompany.get('company_code'),
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE))
						return Promise.resolve(serverResponse.data.id)
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE))
					console.error('Error in add consignment Note', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE))
		}
	}
}

/**
 * Action to load CONSIGNMENTNOTE
 * @param {*} token
 */
export function loadConsignmentNoteDetail(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setConsignmentNote(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_CONSIGNMENTNOTE))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_CONSIGNMENTNOTE))
				console.error('Error getting consignment Note', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action to upload files to consignment Note
 *
 * @export
 * @param {string} token
 * @param {object} files
 * @returns
 */
export function uploadFilesConsignmentNote(token, files) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPointValid = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${token}/attachments/valid`
		let endPointSave = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${token}/attachments`
		let formData = new FormData()
		for (var index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPointValid, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					return axios
						.post(endPointSave, formData, configuration)
						.then((serverResponse) => {
							dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
							if (serverResponse.status === 200) {
								return Promise.resolve()
							}
						})
						.catch((error) => {
							dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
							console.error('Error to upload files consignment Note', error.response)
							return Promise.reject(error.response.data)
						})
				} else {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.reject()
				}
			})
			.catch((error) => {
				console.error('Error to upload files consignment Note', error.response)
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				return Promise.reject(error.response.data)
			})
	}
}

/**
 * Download consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const downloadConsignmentNoteAttachments = (id) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${id}/attachments`
			const response = await axios.get(endPoint, config)
			const blob = new Blob([response.data], { type: 'application/octet-stream' })
			const url = window.URL.createObjectURL(blob)
			const linkEl = document.createElement('a')
			linkEl.href = url
			linkEl.download = `${id}.zip`
			linkEl.click()
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

export const downloadAllAcknowledgmentZipFile = (allCodes, dateConfiguration, downloadType) => {
	return async (dispatch, getState) => {
		try {
			dispatch(isSavingCommon(true, 'Download'))

			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/acknowledgmentFile/all`
			let data = {
				Codes: allCodes,
				EndDate: dateConfiguration.endDate,
				StartDate: dateConfiguration.startDate,
				DownloadType: downloadType,
			}
			const response = await axios.post(endPoint, data, config)
			if (response.status === 200) {
				const blob = new Blob([response.data], { type: 'application/zip' })
				const url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = 'Acuses de recibo.zip'
				a.click()
				dispatch(isSavingCommon(false, 'Download'))
			}
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			dispatch(isSavingCommon(false, 'Download'))

			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Delete consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const deleteConsignmentNoteAttachments = (id) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${id}/attachments`
			await axios.delete(endPoint, config)
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 *Download report of Consignment Note
 *
 * @export
 * @returns
 */
export function downloadConsignmentNoteExcel(token) {
	return (dispatch, getState) => {
		dispatch(isDonwloadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${token}/report`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_carta_porte.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading Consignment Note', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to upload files to consignment Note
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadAdditionaCostConsignmentNote(token, files) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${token}/addicionalexpensefiles`
		let formData = new FormData()
		for (let index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error to upload files consignment Note', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Download AdditionaCostConsignmentNote note zip attachments
 * @param {string} id
 * @returns
 */
export const downloadAdditionaCostConsignmentNote = (id) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${id}/addicionalexpensefiles`
			const response = await axios.get(endPoint, config)
			const blob = new Blob([response.data], { type: 'application/octet-stream' })
			const url = window.URL.createObjectURL(blob)
			const linkEl = document.createElement('a')
			linkEl.href = url
			linkEl.download = `${id}.zip`
			linkEl.click()
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Action to upload files to consignment Note
 *
 * @export
 * @param {*} quoteToken
 * @param {*} files
 * @returns
 */
export function uploadAcknowledgmentFiles(consignmentNoteId, recipientCode, files, deliveryCode) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteId}/deliveries/${recipientCode}/acknowledgmentreceipts/${deliveryCode}`
		let formData = new FormData()
		for (let index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error to upload files consignment Note', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Download consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const downloadAcknowledgmentFiles = (consignmentNoteId, deliveryId, recipientCode, deliveryCode) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteId}/deliveries/${deliveryId}/${recipientCode}/acknowledgmentreceipts/${deliveryCode}`
			const response = await axios.get(endPoint, config)
			const blob = new Blob([response.data], { type: 'application/octet-stream' })
			const url = window.URL.createObjectURL(blob)
			const linkEl = document.createElement('a')
			linkEl.href = url
			linkEl.download = `${deliveryId}.zip`
			linkEl.click()
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

export const downloadEvidenceDeliveryFiles = (consignmentNoteId, deliveryId, recipientCode, deliveryCode) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteId}/deliveries/${deliveryId}/${recipientCode}/evidenceDelivery/${deliveryCode}`
			const response = await axios.get(endPoint, config)
			const blob = new Blob([response.data], { type: 'application/octet-stream' })
			const url = window.URL.createObjectURL(blob)
			const linkEl = document.createElement('a')
			linkEl.href = url
			linkEl.download = `${deliveryId}.zip`
			linkEl.click()
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Download consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const uploadEvidenceDelivery = (consignmentNoteId, recipientCode, files, deliveryCode) => {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteId}/deliveries/${recipientCode}/evidenceDelivery/${deliveryCode}`
		let formData = new FormData()
		for (let index = 0; index < files.length; index++) {
			formData.append('file', files[index])
		}
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error to upload files consignment Note', error.response)
				return Promise.reject()
			})
	}
}

export const getAcknowledgmentFileByName = (
	consignmentNoteId,
	deliveryId,
	recipientCode,
	deliveryCode,
	fileName,
	attachmentType
) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteId}/seeFiles`
			let command = {
				ConsignmentNoteId: consignmentNoteId,
				DeliveryId: deliveryId,
				RecipientCode: recipientCode,
				AttachmentType: attachmentType,
				DeliveryCode: deliveryCode,
				FileName: fileName,
			}
			let serverResponse = await axios.post(endPoint, command, {
				responseType: 'arraybuffer',
				headers: config.headers,
			})
			let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
			if (serverResponse.status === 200) {
				let fileBlog = new Blob([serverResponse.data], {
					type: onGetBlobType(fileExtension),
				})
				let url = window.URL.createObjectURL(fileBlog)
				return Promise.resolve(url)
			}
			// const blob = new Blob([response.data], { type: 'application/octet-stream' })
			// const url = window.URL.createObjectURL(blob)
			// const linkEl = document.createElement('a')
			// linkEl.href = url
			// linkEl.download = `${deliveryId}.zip`
			// linkEl.click()
			return Promise.resolve('Ok')
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

export const deleteEvidenceDeliveryFromBackend = (
	consignmentNoteId,
	deliveryId,
	recipientCode,
	deliveryCode,
	fileName,
	attachmentType
) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			config.responseType = 'arraybuffer'
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/evidenceDelivery`
			let command = {
				ConsignmentNoteId: consignmentNoteId,
				DeliveryId: deliveryId,
				RecipientCode: recipientCode,
				AttachmentType: attachmentType,
				DeliveryCode: deliveryCode,
				FileName: fileName,
			}
			return axios.post(endPoint, command, config).then((serverResponse) => {
				if (serverResponse.status === 200) {
					window.location.reload()
					return Promise.resolve()
				}
			})
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Delete consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const deleteAdditionalExpenseAttachments = (id) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${id}/addicionalexpensefiles`
			await axios.delete(endPoint, config)
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Delete consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const deleteAcknowledgmentFiles = (consignmentNoteRequestId, deliveryId, recipientCode, deliveryCode) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/deliveries/${deliveryId}/${recipientCode}/acknowledgmentreceipts/${deliveryCode}`
			await axios.delete(endPoint, config)
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Delete consignment note zip attachments
 * @param {string} id
 * @returns
 */
export const deleteEvidencDeliveryFiles = (consignmentNoteRequestId, deliveryId, recipientCode, deliveryCode) => {
	return async (dispatch, getState) => {
		try {
			const config = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			const endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/deliveries/${deliveryId}/${recipientCode}/evidenceDelivery/${deliveryCode}`
			await axios.delete(endPoint, config)
		} catch (exp) {
			console.error(exp)
			return Promise.reject(exp.reject)
		}
	}
}

/**
 * Action to change status
 * @param {*} status
 * @param {*} consignmentNoteRequestId
 * @param {*} comments
 */
export function changeStatusFromBackEnd(status, consignmentNoteRequestId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/status`
		let command = {
			Status: status,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error update estatus consignmentNoteReques', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action from create devolution
 * @param {*} consignmentNoteRequestId
 * @param {*} delivery
 * @param {*} item
 * @returns
 */
export function createDevolutionFromBackEnd(consignmentNoteRequestId, delivery, item, confirmQuality) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/devolution`
		let command = {
			RequestId: consignmentNoteRequestId,
			DeliveryRFC: delivery.recipient ? delivery.recipient.rfc : '',
			DeliveryCode: delivery.recipient ? delivery.recipient.code : '',
			MaterialCode: item.code,
			ConfirmQuality: confirmQuality,
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error update estatus consignmentNoteReques', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action to Create material
 * @param {*} itemModel
 */
export function createMaterial(consignmentNoteRequestId, delivery, item) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_CONSIGNMENTNOTE_MATERIAL))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/material`
			let command = {
				RequestId: consignmentNoteRequestId,
				DeliveryId: delivery ? delivery.Id : '',
				DeliveryRFC: delivery.recipient ? delivery.recipient.rfc : '',
				DeliveryCode: delivery.recipient ? delivery.recipient.code : '',
				MaterialCode: item.code,
				Description: item.description,
				BaseMeasurementUnit: item.baseMeasurementUnit,
				Position: item.position,
				GrossWeight: item ? item.grossWeight : 0,
				WeightMeasurementUnit: item.weightMeasurementUnit,
				ProductCode: item.productCode,
				Quantity: item ? item.quantity : 0,
				UnitCode: item.unitCode,
				HazardousMaterialCode: item.hazardousMaterialCode,
				PackagingCode: item.packagingCode,
				WeightInKg: item ? item.weightInKg : 0,
				RequestCode: item.requestCode,
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_MATERIAL))
						dispatch(setMaterialAction(delivery.Id, serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_MATERIAL))
					console.error('Error in add material', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_MATERIAL))
		}
	}
}

export function deleteMaterial(consignmentNoteRequestId, deliveryId, materialToken) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/material/${deliveryId}/${materialToken}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error delete material information', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function createDestination(consignmentNoteRequestId, delivery) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_CONSIGNMENTNOTE_DESTINATION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/deliveries`
			let command = {
				RequestId: consignmentNoteRequestId,
				Code: delivery ? delivery.Code : '',
				Name: delivery ? delivery.Name : '',
				RFC: delivery ? delivery.RFC : '',
				Street: delivery ? delivery.Street : '',
				ExternalNumber: delivery ? delivery.ExternalNumber : '',
				Suburb: delivery ? delivery.Suburb : '',
				StateCode: delivery ? delivery.StateCode : '',
				CountryCode: delivery ? delivery.CountryCode : '',
				ZipCode: delivery ? delivery.ZipCode : '',
				DeliveryCode: delivery ? delivery.RequestCode : '',
				Town: delivery ? delivery.Town : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_DESTINATION))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_DESTINATION))
					console.error('Error in add destination', error.response)
					return Promise.reject(error.response)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_CONSIGNMENTNOTE_DESTINATION))
		}
	}
}

export function deleteDestination(consignmentNoteRequestId, deliveryId) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/${consignmentNoteRequestId}/delivery/${deliveryId}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error delete destination information', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 *Download report of Consignment Note
 *
 * @export
 * @returns
 */
export function downloadReportRequests() {
	return function (dispatch, getState) {
		dispatch(isDonwloadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/report/inprocess`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let command = {
			BusinessUnitCode: selectedCompany.get('company_code'),
		}
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_cartas_porte_en_proceso.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report Consignment Note', error.response)
				return Promise.reject()
			})
	}
}

export function downloadPendingFolioReport(dateConfiguration) {
	return function (dispatch, getState) {
		dispatch(isDonwloadingReport(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let businessUnitCode = selectedCompany.get('company_code')
		let command = {
			RequestedBy: '',
			BusinessUnitCode: businessUnitCode,
			BaseFilePath: '',
			TemplatePath: '',
			EndDate: dateConfiguration.endDate,
			StartDate: dateConfiguration.startDate,
		}
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/report/pendingFolioReport`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDonwloadingReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'ReporteFoliosPendientes.xlsx'
					a.click()
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error al to download report ratings', error)
				return Promise.reject()
			})
	}
}
export function loadDeliveriesRequestFromBackEnd() {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let companyCode = selectedCompany.get('document_identifier')
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/deliveries/${companyCode}`
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setDeliveriesList(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					console.error('Error getting consignment Note list by company', error.response)
					return Promise.reject(error.response)
				})
		} else {
		}
	}
}

/**
 * Generate report from consigment note by folio
 * @returns
 */
export function generateReport(token) {
	return function (dispatch, getState) {
		dispatch(isDonwloadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/download/consignmentnote/${token}`
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let command = {
			BusinessUnitCode: selectedCompany.get('company_code'),
		}
		return axios
			.post(endPoint, command, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDonwloadingReport(false))
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReport(false))
				console.error('Error downloading report Consignment Note', error.response)
				return Promise.reject()
			})
	}
}

export function updateDeliveryFromBackEnd(consignmentNoteRequestId, delivery) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/deliveries/update`
			let command = {
				RequestId: consignmentNoteRequestId,
				RecipientCode: delivery ? delivery.RecipientCode : '',
				DeliveryDate: delivery ? delivery.DeliveryDate : '',
				EntryDate: delivery ? delivery.EntryDate : '',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						return Promise.resolve()
					}
				})
				.catch((error) => {
					console.error('Error in update delivery', error.response)
					return Promise.reject(error.response)
				})
		}
	}
}

export function updatePropertyNotesFromBackEnd(consignmentNoteRequestId, data) {
	return function (dispatch, getState) {
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/update`
			let command = {
				RequestId: consignmentNoteRequestId,
				Notes: data,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						return Promise.resolve()
					}
				})
				.catch((error) => {
					console.error('Error in update consignmentNoteReques', error.response)
					return Promise.reject(error.response)
				})
		}
	}
}

export function AddCuponFerry(cuponFerryModel, transportCode) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/ferryCupon`
		let data = {
			...cuponFerryModel,
			TransportCode: transportCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCuponFerry(serverResponse.data))
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				console.error('Error create derry cupon', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function editCuponFerry(cuponFerryModel, id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/ferryCupon`
		let data = {
			...cuponFerryModel,
			FerryCuponId: id,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCuponFerry(serverResponse.data))
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				console.error('Error create derry cupon', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function getAllCuponsFerry(transportCode) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, UPDATE_CONSIGNMENTNOTE))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/ferryCupon/${transportCode}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('serverResponse.data=>>>>>>>>>>>>>>>>>>>>>getAllCuponsFerry', serverResponse.data)
					dispatch(setCuponsFerryList(serverResponse.data))
					dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, UPDATE_CONSIGNMENTNOTE))
				console.error('Error get transport tender information by transport code', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function setStatusCuponFerry(item, toStatus) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/ferryCupon/status`
		let data = {
			Id: item.id,
			Status: toStatus,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCuponFerry(serverResponse.data))
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				console.error('Error create derry cupon', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function loadFerryCuponManagerPaginatedCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/FerryCuponManagers`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					console.log("transformResponseData", transformResponseData)
					dispatch(setFerryCuponsPaginatedList(transformResponseData))
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				return Promise.reject()
			})
	}
}

export function validateFerryCuponManagerTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/FerryCuponManagers/template/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFerryCuponsErrors(serverResponse.data))
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function importFerryCuponManagerCatalog(file) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/FerryCuponManagers/template/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, SET_CUPON_FERRY))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				console.error('Error add tariff sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function downloadFerryCuponManagerTemplate() {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, SET_CUPON_FERRY))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/FerryCuponManagers/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Maniobras.xlsx'
					a.click()
				}
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				return Promise.resolve()
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isSavingCommon(false, SET_CUPON_FERRY))
				return Promise.reject()
			})
	}
}

export function deleteFerryCuponManager(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, DELETE_FERRY_CUPONS_MANAGERS))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/FerryCuponManagers/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteFerryCuponMaanager(id))
					dispatch(isSavingCommon(false, DELETE_FERRY_CUPONS_MANAGERS))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, DELETE_FERRY_CUPONS_MANAGERS))
				return Promise.reject()
			})
	}
}


export function deleteFerryCupon(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, DELETE_FERRY_CUPONS_MANAGERS))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONSIGNMENTNOTE}/consignmentnotes/ferryCupon/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(deleteFerryCuponAction(id))
					dispatch(isSavingCommon(false, DELETE_FERRY_CUPONS_MANAGERS))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, DELETE_FERRY_CUPONS_MANAGERS))
				return Promise.reject()
			})
	}
}
