import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import NoticesOfChangeIcon from '@material-ui/icons/SwapHorizTwoTone'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import Tooltip from '@material-ui/core/Tooltip'

import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import NoticeItemDialog from './components/NoticeItemDialog'
import SendRequestDialog from './components/SendRequestDialog'
import AttachmentsDialog from '../common/AttachmentsDialog'

/** Import actions */
import {
	getNoticeChangeInformationDataFromBackend,
	updateDetailPartnumberPlaneName,
	addSupplierToNoticeChangeItem,
	removeSupplierFromNoticeChangeItem,
	deleteDetailPlaneName,
	SET_NOTICE_ITEM_SELECTED,
	SET_NOTICE_ITEMS_SELECTED,
	getSelectedNoticeItem,
	IS_LOADING_NOTICE_CHANGE,
	onAddSampleRequestSupplier,
	onRemoveSampleRequestSupplier,
	sendQuotesRequestFromNoticeChange,
	addSupplierToChangeNotificationItem,
	removeSupplierFromChangeNotificationItem,
	updateDetailPartNumberStage,
	onAddComment,
	onAddFiles,
	onRemoveFiles,
	toggleNoticeOfChangePartNumberActivation,
	addSupplierToChangeNotificationMassiveItem,
	removeSupplierFromChangeNotificationMassive,
	addSupplierToNoticeChangeItemMassive,
	removeSupplierFromNoticeChangeMassive,
	onAddSampleRequestSupplierMassive,
	onRemoveSampleRequestSupplierMassive,
} from '../../store/noticeChanges/NoticeChangeAction'
import { downloadPlotArtFiles } from '../../store/partNumbers/PartNumberAction'

import MassiveNoticeItemsDialog from './components/MassiveNoticeItemsDialog'
import NoticeChangeDetails from './components/NoticeChangeDetails'
import InformationNoticeCard from './components/InformationNoticeCard'
import { COMPANY_IDENTIFIER_IMBERA_MX, COMPANY_IDENTIFIER_IMBERA_WARRANTY } from '../../store/helpers/ResourcesHelper'

/**
 * Component to create notice from SIT
 */
class Create extends Component {
	/**
	 * Create an instance notice
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			folioNotice: '',
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			showDeletePlaneName: false,
			// Notice item options
			showNoticeItemDialog: false,
			showNoticeSendRequests: false,
			showAttachmentsDialog: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		this.props.startNoticeChange()
		//const search = localStorage.getItem("noticeOfChangeFolio");
		// const folio = search.split('=')[1]
		// this.onUpdateFolioNoticeChange(folio)
		// this.getInformationNoticeChange()
	}

	/**
	 * Funcion on update folio to get notice change
	 * @param {*} folio
	 */
	onUpdateFolioNoticeChange(folio) {
		this.setState({
			folioNotice: folio,
		})
	}

	/**
	 * on key Down to get folio
	 * @param {*} event
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') {
			this.getInformationNoticeChange()
		}
	}

	/**
	 * Get action to get information notice change
	 */
	getInformationNoticeChange() {
		this.props
			.getNoticeChangeInformationData(this.state.folioNotice)
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: false,
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToAdd' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On memo item clicked open the dialog
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onNoticeItemClick(item) {
		const { noticechange } = this.props
		let noticeItemSelected = noticechange.details.find((noticeItem) => {
			return noticeItem.token === item.token
		})
		this.props.setNoticeItemSelected(item.token)
		this.setState({
			noticeItemSelected,
			showNoticeItemDialog: true,
		})
	}

	/**
	 * onCloseModalItem
	 *
	 * @memberof Create
	 */
	onCloseModalItem(modalName) {
		this.setState({
			[modalName]: false,
			noticeItemSelected: null,
		})
	}

	/**
	 * On memo item clicked open the dialog
	 *
	 * @param {*} item
	 * @memberof Create
	 */
	onMenuOptionItemClick(modalName, item, selectedTokens = []) {
		const { noticechange } = this.props
		let noticeItemSelected
		if (item.token !== 'selection') {
			noticeItemSelected = noticechange.details.find((noticeItem) => {
				return noticeItem.token === item.token
			})
			this.props.setNoticeItemSelected(item.token)
			this.setState({
				noticeItemSelected,
				selectedTokens,
				[modalName]: true,
			})
		} else {
			this.setState({
				selectedTokens,
				[modalName]: true,
			})
		}
	}

	/**
	 * On item plot selected
	 *
	 * @param {*} plotSelected
	 * @memberof Create
	 */
	onPlotSelected(item, plotSelected) {
		this.props
			.updateDetailPlane(item.token, plotSelected)
			.then(() => {})
			.catch(() => {
				console.log('onPlotSelected::failed')
			})
	}

	/**
	 * onSupplierNoticeSelected
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @param {boolean} [isMassive=false]
	 * @memberof Create
	 */
	onSupplierNoticeSelected(item, supplier, isMassive = false) {
		if (!isMassive) this.props.addSupplierToChangeNotificationItem(item, supplier)
		else this.props.addSupplierToChangeNotificationMassiveItem(this.state.selectedTokens, supplier)
	}

	/**
	 * onRemoveNoticeSupplier
	 *
	 * @param {*} item
	 * @param {*} supplierToken
	 * @memberof Create
	 */
	onRemoveNoticeSupplier(item, supplierToken) {
		this.props.removeSupplierFromChangeNotificationItem(item, supplierToken)
	}

	onRemoveNoticeSupplierMassive(supplierToken) {
		return this.props.removeSupplierFromChangeNotificationMassive(this.state.selectedTokens, supplierToken)
	}

	/**
	 * onSupplierSelected
	 *
	 * @param {*} item
	 * @param {*} supplier
	 * @memberof Create
	 */
	onSupplierSelected(item, supplier) {
		this.props.addSupplierToNoticeChangeItem(item, supplier)
	}

	onSupplierSelectedMassive(supplier) {
		return this.props.addSupplierToNoticeChangeMassive(this.state.selectedTokens, supplier)
	}

	/**
	 * On remove supplier
	 *
	 * @param {*} item
	 * @param {*} supplierToken
	 * @memberof Create
	 */
	onRemoveSupplier(item, supplierToken) {
		this.props.removeSupplierFromNoticeChangeItem(item, supplierToken)
	}

	onRemoveSupplierMassive(supplierToken) {
		return this.props.removeSupplierFromNoticeChangeMassive(this.state.selectedTokens, supplierToken)
	}

	/**
	 * On add sample request to supplier to notice change
	 */
	onAddSampleRequestSupplier(item, quote) {
		this.props.onAddSampleRequestSupplier(item, quote)
	}

	onAddSampleRequestSupplierMassive(supplier) {
		return this.props.onAddSampleRequestSupplierMassive(this.state.selectedTokens, supplier)
	}

	/**
	 * On remove sample request to supplier to notice change
	 * @param {*} item
	 * @param {*} supplierToken
	 */
	onRemoveSampleRequestSupplier(item, supplierToken) {
		return this.props.onRemoveSampleRequestSupplier(item, supplierToken)
	}

	onRemoveSampleRequestSupplierMassive(supplierToken) {
		return this.props.onRemoveSampleRequestSupplierMassive(this.state.selectedTokens, supplierToken)
	}

	/**
	 * on Send requests on click notice change
	 */
	onSendRequestClick() {
		this.setState({
			showNoticeSendRequests: true,
		})
	}

	/**
	 * onCloseSendRequestDialog
	 *
	 * @memberof Create
	 */
	onCloseSendRequestDialog() {
		this.setState({
			showNoticeSendRequests: false,
		})
	}

	/**
	 * Get action to send request notice change
	 */
	sendQuotesRequestFromNoticeChange() {
		this.props
			.sendQuotesRequestFromNoticeChange()
			.then(() => {
				this.setState({
					showAddWorker: false,
					showToView: true,
					showToaster: false,
				})
				this.props.history.push(`/noticechanges/${this.props.noticechange.token}`)
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Method to call onAddComment action
	 *
	 * @param {*} item
	 * @param {*} comments
	 * @memberof Create
	 */
	onAddComment(item, comments, requieredPlotArt) {
		this.props.onAddComment(item, comments, requieredPlotArt)
	}

	onDownloadPlotFile(lot, companyToken, kind, formatype) {
		return this.props
			.downloadPlotArtFiles(lot, companyToken, kind, formatype)
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorNotExtiFile' />,
					toasterVariant: 'warning',
				})
				return Promise.reject()
			})
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		if (this.props.isLoadingNoticeChange === true) {
			return <LoadingPage />
		} else if (this.props.noticechange !== null) {
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<InformationNoticeCard {...this.props} />
					</Grid>
					<Grid item xs={12}>
						<NoticeChangeDetails
							onMenuOptionItemClick={this.onMenuOptionItemClick}
							{...this.props}
							downloadPlotFiles={this.onDownloadPlotFile}
						/>
					</Grid>
				</Grid>
			)
		}
	}

	/**
	 * Render component notice
	 */
	render() {
		const { classes } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<NoticesOfChangeIcon />
						</Avatar>
					}
					action={
						<Tooltip title={<Translate id='noticeChanges.sendQuotes' />}>
							<IconButton
								color='primary'
								value
								onClick={() => {
									this.onSendRequestClick()
								}}
							>
								<SendIcon />
							</IconButton>
						</Tooltip>
					}
					title={
						<Typography variant='button'>
							<Translate id='noticeChanges.requestNoticeChange' />{' '}
						</Typography>
					}
				/>
				<CardContent>
					<Grid container spacing={24}>
						<List>
							<ListItem>
								<ListItemText
									primary={
										<React.Fragment>
											<TextField
												fullWidth
												autoFocus
												value={this.state.folioNotice}
												className={classes.textField}
												label={<Translate id='noticeChanges.folioNotice' />}
												onKeyDown={this.onKeyDown}
												onChange={(event) => {
													this.onUpdateFolioNoticeChange(event.target.value)
												}}
											/>
											<br />
											<br />
										</React.Fragment>
									}
									secondary={
										<React.Fragment>
											{<Translate id='noticeChanges.messageToAddNotice' />}
										</React.Fragment>
									}
								/>
							</ListItem>
						</List>
					</Grid>
					{this.getCardContent()}
				</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				{this.props.selectedNoticeItem &&
					this.state.selectedTokens &&
					this.state.selectedTokens.length <= 0 && (
						<NoticeItemDialog
							item={this.props.selectedNoticeItem}
							show={this.state.showNoticeItemDialog}
							onClose={() => this.onCloseModalItem('showNoticeItemDialog')}
							onPlotSelected={this.onPlotSelected}
							onSupplierNoticeSelected={this.onSupplierNoticeSelected}
							onRemoveNoticeSupplier={this.onRemoveNoticeSupplier}
							onSupplierSelected={this.onSupplierSelected}
							onRemoveSupplier={this.onRemoveSupplier}
							onAddSampleRequestSupplier={this.onAddSampleRequestSupplier}
							onRemoveSampleRequestSupplier={this.onRemoveSampleRequestSupplier}
							onAddComment={this.onAddComment}
							companyImbera={
								this.props.noticechange &&
								(
									this.props.noticechange.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX ||
									this.props.noticechange.company_identifier === COMPANY_IDENTIFIER_IMBERA_WARRANTY
								)
							}
						/>
					)}
				{
					//Modal to update massive items
					this.state.selectedTokens && this.state.selectedTokens.length > 0 && (
						<MassiveNoticeItemsDialog
							show={this.state.showNoticeItemDialog}
							onClose={() => this.onCloseModalItem('showNoticeItemDialog')}
							onSupplierNoticeSelected={this.onSupplierNoticeSelected}
							onRemoveNoticeSupplier={this.onRemoveNoticeSupplierMassive}
							onSupplierSelected={this.onSupplierSelectedMassive}
							onRemoveSupplier={this.onRemoveSupplierMassive}
							onAddSampleRequestSupplier={this.onAddSampleRequestSupplierMassive}
							onRemoveSampleRequestSupplier={this.onRemoveSampleRequestSupplierMassive}
							companyImbera={
								this.props.noticechange &&
								this.props.noticechange.company_identifier === COMPANY_IDENTIFIER_IMBERA_MX
							}
						/>
					)
				}
				<AttachmentsDialog
					show={this.state.showAttachmentsDialog}
					item={this.props.selectedNoticeItem ? this.props.selectedNoticeItem : {}}
					uploadFiles={this.props.onAddFiles}
					removeFiles={this.props.onRemoveFiles}
					onClose={() => this.onCloseModalItem('showAttachmentsDialog')}
					dropzoneText={this.props.translate('dropzone.dropzoneBaseText')}
				/>

				{this.state.showNoticeSendRequests && (
					<SendRequestDialog
						show={this.state.showNoticeSendRequests}
						onClose={this.onCloseSendRequestDialog}
						sendQuotesRequestFromNoticeChange={this.sendQuotesRequestFromNoticeChange}
						isSaving={this.props.isSaving}
					/>
				)}
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let noticechange = state.noticechanges.get('noticechange')
	return {
		isLoadingNoticeChange: state.noticechanges.get('isLoadingNoticeChange'),
		noticechange: noticechange ? noticechange.toJS() : null,
		selectedNoticeItem: getSelectedNoticeItem(state),
		isUploading: state.noticechanges.get('isUploading'),
		isSaving: state.noticechanges.get('isSaving'),
		translate: getTranslate(state.localize),
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		getNoticeChangeInformationData: (folio) => {
			return dispatch(getNoticeChangeInformationDataFromBackend(folio))
		},
		startNoticeChange: () => {
			dispatch({
				type: IS_LOADING_NOTICE_CHANGE,
				isLoadingNoticeChange: false,
			})
		},
		updateDetailPlane(detailToken, planeName) {
			return dispatch(updateDetailPartnumberPlaneName(detailToken, planeName))
		},
		deleteDetailPlaneName: (detailToken, planeName) => {
			return dispatch(deleteDetailPlaneName(detailToken, planeName))
		},
		setNoticeItemSelected: (noticeItemToken) => {
			dispatch({ type: SET_NOTICE_ITEM_SELECTED, noticeItemToken })
		},
		setNoticeItemsSelected: (selectedTokens) => {
			dispatch({ type: SET_NOTICE_ITEMS_SELECTED, selectedTokens })
		},
		addSupplierToChangeNotificationItem: (item, supplier) => {
			return dispatch(addSupplierToChangeNotificationItem(item, supplier))
		},
		addSupplierToChangeNotificationMassiveItem: (partNumberTokens, supplier) => {
			return dispatch(addSupplierToChangeNotificationMassiveItem(partNumberTokens, supplier))
		},
		removeSupplierFromChangeNotificationItem: (item, supplierToken) => {
			return dispatch(removeSupplierFromChangeNotificationItem(item, supplierToken))
		},
		removeSupplierFromChangeNotificationMassive: (partNumberTokens, supplierToken) => {
			return dispatch(removeSupplierFromChangeNotificationMassive(partNumberTokens, supplierToken))
		},
		addSupplierToNoticeChangeItem: (item, supplier) => {
			return dispatch(addSupplierToNoticeChangeItem(item, supplier))
		},
		addSupplierToNoticeChangeMassive: (partNumberTokens, supplier) => {
			return dispatch(addSupplierToNoticeChangeItemMassive(partNumberTokens, supplier))
		},
		removeSupplierFromNoticeChangeItem: (item, supplierToken) => {
			return dispatch(removeSupplierFromNoticeChangeItem(item, supplierToken))
		},
		removeSupplierFromNoticeChangeMassive: (partNumberTokens, supplierToken) => {
			return dispatch(removeSupplierFromNoticeChangeMassive(partNumberTokens, supplierToken))
		},
		onAddSampleRequestSupplier: (memoPartNumber, quote) => {
			return dispatch(onAddSampleRequestSupplier(memoPartNumber, quote))
		},
		onAddSampleRequestSupplierMassive: (partNumberTokens, supplier) => {
			return dispatch(onAddSampleRequestSupplierMassive(partNumberTokens, supplier))
		},
		onRemoveSampleRequestSupplier: (memoPartNumber, supplierToken) => {
			return dispatch(onRemoveSampleRequestSupplier(memoPartNumber, supplierToken))
		},
		onRemoveSampleRequestSupplierMassive: (partNumberTokens, supplierToken) => {
			return dispatch(onRemoveSampleRequestSupplierMassive(partNumberTokens, supplierToken))
		},
		sendQuotesRequestFromNoticeChange: () => {
			return dispatch(sendQuotesRequestFromNoticeChange())
		},
		updateStage(partNumberToken, stage) {
			return dispatch(updateDetailPartNumberStage(partNumberToken, stage))
		},
		toggleNoticeOfChangePartNumberActivation(partNumberToken) {
			return dispatch(toggleNoticeOfChangePartNumberActivation(partNumberToken))
		},
		onAddComment: (item, comments, requieredPlotArt) => {
			return dispatch(onAddComment(item, comments, requieredPlotArt))
		},
		onAddFiles: (files, tokenPartNumber) => {
			return dispatch(onAddFiles(files, tokenPartNumber))
		},
		onRemoveFiles: (fileName, tokenPartNumber) => {
			return dispatch(onRemoveFiles(fileName, tokenPartNumber))
		},
		downloadPlotArtFiles: (lot, companyToken, kind, formatype) => {
			return dispatch(downloadPlotArtFiles(lot, companyToken, kind, formatype))
		},
	}
}

const styles = (theme) => ({
	toolbar: {
		display: 'flex',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	button: {
		margin: theme.spacing.unit,
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	grow: {
		flexGrow: 1,
	},
	formControl: {
		margin: theme.spacing.unit * 3,
	},
	supplierBadge: {
		padding: `0 ${theme.spacing.unit * 2}px`,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Create)))
)
