import { fromJS } from 'immutable';
import * as exportRequestAction from './ExportRequestAction';

export const initialState = fromJS({
    isLoadingList: false,
    exportRequestList: [],
    isLoadingItem: false,
    isLoadingItemStatus: false,
    exportRequestItem: [],
    isSavingFile: false,

})

export default function exportRequestReducer(state = initialState, action) {
    switch (action.type) {
        case exportRequestAction.IS_LOADING_EXPORT_REQUEST: {
            return state.merge({
                isLoadingList: action.isLoading
            })
        }
        case exportRequestAction.SET_EXPORT_REQUEST_LIST: {
            return state.set('exportRequestList', fromJS(action.exportRequestList));
        }
        case exportRequestAction.IS_LOADING_EXPORT_REQUEST_ITEM: {
            return state.merge({
                isLoadingItem: action.isLoading
            })
        }
        case exportRequestAction.SET_EXPORT_REQUEST_ITEM: {
            return state.set('exportRequestItem', fromJS(action.exportRequestItem));
        }
        case exportRequestAction.IS_SAVING_EXPORT_REQUEST_FILE: {
            return state.merge({
                isSavingFile: action.isSaving
            })
        }
        case exportRequestAction.IS_LOADING_EXPORT_REQUEST_ITEM_STATUS: {
            return state.merge({
                isLoadingItemStatus: action.isLoading
            })
        }
        default:
            return state
    }
}