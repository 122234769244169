import propTypes from 'prop-types'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { withRouter } from "react-router-dom"
/**Import MaterialUi section */
import {
    Button,
    List, ListItem, ListItemText,
    TextField
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
/**Import components section */


const ViewComponent = (props) => {
    const {
        item,
        onEditRequest,
    } = props
    const [control, setcontrol] = useState({
        token: item.token,
        advance_agent_customs_payment: item.advance_agent_customs_payment,
        advance_agent_customs_reference: item.advance_agent_customs_reference,
        observations: item.observations,
    })
    const isSaving = useSelector((state) => state.shippingOrders.get('isSaving'))
    const onUpdateRequestControl = () => {
        onEditRequest(control)
    }

    const updateProperty = (property, value) => {
        let controlUpdate = { ...control }
        controlUpdate[property] = value
        setcontrol(controlUpdate)
    }

    return (
        <div>
            <List dense>
                <ListItem>
                    <ListItemText
                        primary={<Translate id='importRequests.customsBrokerReference' />}
                        secondary={
                            <TextField
                                fullWidth
                                id='outlined-size-small'
                                label={''}
                                defaultValue={item.advance_agent_customs_reference ? item.advance_agent_customs_reference : ''}
                                onChange={(event) => {
                                    updateProperty('advance_agent_customs_reference', event.target.value)
                                }}
                                variant='outlined'
                            />}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={<Translate id='importRequests.customsBrokerAdvance' />}
                        secondary={
                            <TextField
                                fullWidth
                                id='outlined-size-small'
                                label={''}
                                defaultValue={item.advance_agent_customs_payment ? item.advance_agent_customs_payment : ''}
                                onChange={(event) => {
                                    updateProperty('advance_agent_customs_payment', event.target.value)
                                }}
                                variant='outlined'
                                InputProps={{
                                    startAdornment: '$',
                                  }}
                            />}
                    />

                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={<Translate id='common.comments' />}
                        secondary={
                            <TextField
                                component="span"
                                fullWidth
                                id='outlined-size-small'
                                label={''}
                                defaultValue={item.observations ? item.observations : ''}
                                onChange={(event) => {
                                    updateProperty('observations', event.target.value)
                                }}
                                variant='outlined'
                                // multiline
                                // rows={4}
                                // rowsMax={3}
                            />}
                    />
                </ListItem>
                <ListItem >
                    <Button
                        onClick={() => onUpdateRequestControl()}
                        data-cy='btn-save'
                        type='button'
                        color='primary'
                        variant='outlined'
                        fullWidth
                        disabled={isSaving}
                    >
                        <Translate id='common.save' />
                    </Button>
                </ListItem>
                <br />

            </List>
        </div>
    )
}

const styles = theme => ({
    containerInformation: {
        maxHeight: `calc(100vh - ${165}px)`,
        minHeight: `calc(100vh - ${165}px)`,
        overflowY: "auto",
        padding: "10px",
    },
    containerBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    widthBtnSaved: {
        minWidth: "200px",
    },
    divider: {
        marginRight: "10px",
    },
    bgRed: {
        backgroundColor: 'red'
    }
});

ViewComponent.propTypes = {

    /**
     * open and closedlateral panel for add an new user in customer
     */
    onCloseButtonClick: propTypes.func,
    /**
     * Selected item data
     */
    item: propTypes.object,
}

ViewComponent.defaultProps = {
    onCloseButtonClick: () =>
        console.warn('Callback [onCloseButtonClick] no defined'),
    isSaving: false,
    item: {}
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))