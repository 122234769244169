///Import react section
import { withRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import TransportTenderTable from './createTransportTender/TransportTenderTable'
import CalendarComponent from './components/CalendarComponent'
/**Store Import component section */
import {
	getTransportTenderfilterSelector,
	getTransportTendersSelector,
	getEventsScheduleSelector,
	getAllWineCellars,
	getIsLoadingSavingSelector,
} from '../../store/transportTender/transportTenderSelector'
import { TransportTendeStatus } from '../../store/helpers/AppConstants'
import {
	loadTransportTenderBackEnd,
	loadMaterialLoadingScheduleFromBackEnd,
	loadAllWineCellars,
	downloadTransportsInTransit,
	changeMultipleTransportTendersStatus,
} from '../../store/transportTender/transportTenderAction'

import Toaster from '../common/Toaster'

import TransportTenderHeader from './components/TransportTenderHeader'
import CreateTransportTender from './createTransportTender/CreateTransportTender'
import {
	getSupplierOptions,
	getSuppliersTypesOptionsSelect,
	getWineCellarOptions,
} from '../../store/helpers/TransportTenderHelper'
import { getAllSupplierSelector } from '../../store/suppliers/SupplierSelectors'
import { getSuppliersAreCarriers } from '../../store/suppliers/SupplierActions'
import SynchronizeWinnerInSap from './components/synchronizeWinnersInSap/SynchronizeWinnersInSAP'
import IncidentsReportDialog from './components/incidentsReport/incidentsReportDialog'
import DownloadInTransitFolio from './components/DownloadInTransitFolio'
import CalendarEventDialog from './components/CalendarEventDialog'

const TransportTenderIndex = () => {
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const queryParams = queryString.parse(location.search)

	const transportTenderdata = useSelector((state) => getTransportTendersSelector(state))
	const transportTenderinfo = useSelector((state) => getTransportTenderfilterSelector(state))
	const eventsSchedule = useSelector((state) => getEventsScheduleSelector(state))
	const isLoading = useSelector((state) => getIsLoadingSavingSelector(state))
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	const wineCellars = useSelector((state) => getAllWineCellars(state))
	const wineCellarOptions = getWineCellarOptions(wineCellars)
	const supplierContract = useSelector((state) => getAllSupplierSelector(state))
	const optionsSupplier = getSupplierOptions(
		supplierContract.supplierCarriers ? supplierContract.supplierCarriers : []
	)
	const [openDialog, setOpenDialog] = useState(false)
	const [activeTab, setActiveTab] = useState(0)
	const [showIndex, setShowIndex] = useState(true)
	const [openModalReport, setOpenModalReport] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: '',
		toasterVariant: '',
	})
	const [wineCellarSelected, setWineCellerSelected] = useState(null)
	const [supplierSelected, setSupplierSelected] = useState(null)
	const [openSychWinnerSap, setOpenSychWinnerSap] = useState(false)
	const [openIncidentDialog, setOpenIncidentDialog] = useState(false)
	const [openCalendarItem, setOpenCalendarItem] = useState({ open: false, item: null })
	const [selectedTokens, setSelectedTokens] = useState([])
	const supplierCode = supplierSelected && supplierSelected.code ? supplierSelected.code : ''
	const wineCellarCode = wineCellarSelected && wineCellarSelected.name ? wineCellarSelected.name : ''
	const profileSupplier = userProfile && userProfile.profile ? userProfile.profile : ''

	/** Get the information if you are an internal user*/
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const onOpenCalendarOption = (item) => {
		setOpenCalendarItem({ open: !openCalendarItem.open, item: item })
	}
	const internalUserTabFilter = (activeTab) => {
		let description = 'SendCarrier'

		switch (activeTab) {
			case 0:
				description = 'Draft'
				break
			case 1:
				description = 'SendCarrier'
				break
			case 2:
				description = 'SecondRound'
				break
			case 3:
				description = 'InEvaluation'
				break
			case 4:
				description = 'Expired'
				break
			case 5:
				description = 'SubmittedLog'
				break
			// more cases can be added as needed
			default:
				description = 'Draft'
		}
		return description
	}

	const externalUserTabFilter = (activeTab) => {
		let description = 'SendCarrier'
		switch (activeTab) {
			case 0:
				description = 'SendCarrier'
				break
			// case 1:
			// 	description = 'SecondRound'
			// 	break
			case 1:
				description = 'Participated'
				break
			// case 3:
			// 	description = 'InEvaluation'
			// 	break
			// case 4:
			// 	description = 'Expired'
			// 	break
			case 2:
				description = 'SubmittedLog'
				break
			// more cases can be added as needed
			default:
				description = 'SendCarrier'
		}
		return description
	}

	let isVisibleColumnLinkTracking = () => {
		if (isInternalUser()) {
			return internalUserTabFilter(queryParams ? parseInt(queryParams.activeTab) : activeTab)
		} else {
			return externalUserTabFilter(queryParams ? parseInt(queryParams.activeTab) : activeTab)
		}
	}
	const getIndexActions = () => {
		const queryParams = queryString.parse(location.search)
		/**Get all tenders if you are an internal user */
		if (isInternalUser()) {
			let filterTab = internalUserTabFilter(queryParams ? parseInt(queryParams.activeTab) : activeTab)
			dispatch(loadTransportTenderBackEnd(queryParams, undefined, TransportTendeStatus.UNDEFINED, filterTab))
			dispatch(loadMaterialLoadingScheduleFromBackEnd(supplierCode, wineCellarCode, isInternalUser()))
		} else {
			let filterTab = externalUserTabFilter(queryParams ? parseInt(queryParams.activeTab) : activeTab)

			/**Get tenders by supplier number */
			const profileSupplierCode =
				profileSupplier && profileSupplier.company_code ? profileSupplier.company_code : ''
			dispatch(
				loadTransportTenderBackEnd(
					queryParams,
					profileSupplierCode,
					TransportTendeStatus.SENT_TO_CARRIER,
					filterTab
				)
			)
			dispatch(loadMaterialLoadingScheduleFromBackEnd(profileSupplierCode, wineCellarCode, isInternalUser()))
		}
	}
	/**Use effect section */
	useEffect(() => {
		getIndexActions()
	}, [dispatch, location.search, activeTab])

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		if (queryParams && queryParams.activeTab) {
			setActiveTab(parseInt(queryParams.activeTab))
		}
	}, [dispatch, location.search])

	useEffect(() => {
		dispatch(loadAllWineCellars())
		dispatch(getSuppliersAreCarriers())
	}, [])

	useEffect(() => {
		let profileCode = null
		profileCode =
			userProfile && userProfile.profile && userProfile.profile.preferred_username
				? userProfile.profile.preferred_username.split('-')[0]
				: ''
		let supplierFindByUser = supplierContract.supplierCarriers
			? supplierContract.supplierCarriers.find((supplier) => supplier.code === profileCode)
			: null
		let supplierOptionByUser = supplierFindByUser ? getSuppliersTypesOptionsSelect(supplierFindByUser) : null
		if (supplierOptionByUser) {
			setSupplierSelected(supplierOptionByUser)
		}
	}, [supplierContract, userProfile])
	/**On change search value */
	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) {
			queryParams.query = searchQuery
			queryParams.pageNumber = 1
		} else {
			delete queryParams.query
			delete queryParams.pageNumber
		}

		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangeModeView = () => {
		setShowIndex(!showIndex)
	}
	const onSearch = () => {
		dispatch(loadMaterialLoadingScheduleFromBackEnd(supplierCode, wineCellarCode, isInternalUser()))
	}
	const onReset = () => {
		let supplierCodeReset = supplierCode
		if (isInternalUser()) {
			setSupplierSelected(null)
			supplierCodeReset = null
		}
		setWineCellerSelected(null)
		dispatch(loadMaterialLoadingScheduleFromBackEnd(supplierCodeReset, null, isInternalUser()))
	}
	const onResetIndex = () => getIndexActions()
	const onChangeTab = (event, value) => {
		const queryParams = queryString.parse(location.search)
		queryParams.activeTab = value
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onDonwloadInTransitFolioReport = (startDate, endDate) => {
		dispatch(downloadTransportsInTransit(startDate, endDate)).then(() => {
			setOpenModalReport(false)
		})
	}

	const sendMultipleTransportTendersToSupplierStatus = () => {
		return dispatch(changeMultipleTransportTendersStatus(selectedTokens))
			.then((_) => {
				window.location.reload()
			})
			.catch((error) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: error ? (
						<Translate id={error} />
					) : (
						<Translate id='carrierTenders.error.errorSubmittingBid' />
					),
					toasterVariant: 'error',
				})
			})
	}

	const onSelectedChecks = (event, item) => {
		event.stopPropagation() // Detener la propagación aquí
		let existItem = selectedTokens.find((d) => d === item.Id)
		if (existItem) {
			let newObjects = [...selectedTokens]
			newObjects = newObjects.filter((x) => x !== item.Id)
			setSelectedTokens(newObjects)
		} else {
			let newObjects = [...selectedTokens]
			newObjects.push(item.Id)
			setSelectedTokens(newObjects)
		}
	}

	return (
		<Card>
			<CardContent>
				<TransportTenderHeader
					setOpenDialog={setOpenDialog}
					onChangeSearchValue={onChangeSearchValue}
					queryParams={queryParams}
					isInternalUse={isInternalUser()}
					onChangeModeView={onChangeModeView}
					showIndex={showIndex}
					onSearch={onSearch}
					wineCellarOptions={wineCellarOptions}
					setWineCellerSelected={setWineCellerSelected}
					wineCellarSelected={wineCellarSelected}
					optionsSupplier={optionsSupplier}
					setSupplierSelected={setSupplierSelected}
					supplierSelected={supplierSelected}
					onReset={onReset}
					profileSupplier={profileSupplier}
					onOpenWinnersInSap={setOpenSychWinnerSap}
					setOpenIncidentDialog={setOpenIncidentDialog}
					setOpenModalReport={setOpenModalReport}
					activeTab={activeTab}
					onChangeTab={onChangeTab}
					searchQuery={queryParams.query ? queryParams.query : ''}
					selectedTokens={selectedTokens}
					isLoading={isLoading}
					sendMultipleTransportTendersToSupplierStatus={sendMultipleTransportTendersToSupplierStatus}
				/>

				{showIndex && (
					<TransportTenderTable
						transportTenderdata={transportTenderdata}
						transportTenderinfo={transportTenderinfo}
						isInternalUser={isInternalUser()}
						isVisibleColumnLinkTracking={isVisibleColumnLinkTracking()}
						onSelectedChecks={onSelectedChecks}
						selectedTokens={selectedTokens}
						activeTab={activeTab}
					/>
				)}
				{!showIndex && (
					<CalendarComponent events={eventsSchedule} onOpenCalendarOption={onOpenCalendarOption} />
				)}
			</CardContent>
			{openDialog && (
				<CreateTransportTender
					setOpenDialog={setOpenDialog}
					openDialog={openDialog}
					transportTenderinfo={transportTenderinfo}
					setToasterMessageConfig={setToasterMessageConfig}
					transportTenderdetail={false}
				/>
			)}

			<SynchronizeWinnerInSap
				open={openSychWinnerSap}
				onClose={() => setOpenSychWinnerSap(false)}
				onResetIndex={onResetIndex}
			/>
			<IncidentsReportDialog open={openIncidentDialog} onClose={() => setOpenIncidentDialog(false)} />
			<DownloadInTransitFolio
				open={openModalReport}
				onClose={() => setOpenModalReport(false)}
				onDownloadReport={onDonwloadInTransitFolioReport}
				isSaving={isLoading}
			/>

			<CalendarEventDialog {...openCalendarItem} onOpenCalendarOption={onOpenCalendarOption} />
			<Toaster
				message={toasterMessageConfig.toasterMessage}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

export default withauthenticatedLayout(withRouter(TransportTenderIndex))
