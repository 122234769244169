import React from 'react'
import { Translate } from 'react-localize-redux'
import { IndirectPriceQuoteSupplierStatus } from './AppConstants'
import { IsUserSupplierAdministrator } from './SessionHelper'

export function AvailableSupplierOperationsConfiguration(onOpenConfirmDialog) {
	let columns = []
	columns.push({
		value: IndirectPriceQuoteSupplierStatus.QUOTED,
		title: <Translate id={onTitleStatusDialog(IndirectPriceQuoteSupplierStatus.QUOTED)} />,
		onClick: () => onOpenConfirmDialog(IndirectPriceQuoteSupplierStatus.QUOTED),
		isIconMaterial: true,
		description: <Translate id={onMessageStatusDialog(IndirectPriceQuoteSupplierStatus.QUOTED)} />,
	})

	columns.push({
		value: IndirectPriceQuoteSupplierStatus.CANCELLED,
		title: <Translate id={onTitleStatusDialog(IndirectPriceQuoteSupplierStatus.CANCELLED)} />,
		onClick: () => onOpenConfirmDialog(IndirectPriceQuoteSupplierStatus.CANCELLED),
		isIconMaterial: true,
		description: <Translate id={onMessageStatusDialog(IndirectPriceQuoteSupplierStatus.CANCELLED)} />,
	})
	return columns
}

/**
 * Get title to chage status
 * @returns
 */
export function onTitleStatusDialog(status) {
	switch (status) {
		case IndirectPriceQuoteSupplierStatus.CANCELLED:
			return 'loadIndirectPriceQuoteWithoutCode.operations.cancelFolio'
		case IndirectPriceQuoteSupplierStatus.QUOTED:
			return 'loadIndirectPriceQuoteWithoutCode.operations.quoted'
		default:
			return ''
	}
}
/**
 * Get message to show in dialog
 * @returns
 */
export function onMessageStatusDialog(status) {
	switch (status) {
		case IndirectPriceQuoteSupplierStatus.CANCELLED:
			return 'loadIndirectPriceQuoteWithoutCode.operations.messageCancelFolio'
		case IndirectPriceQuoteSupplierStatus.QUOTED:
			return 'loadIndirectPriceQuoteWithoutCode.operations.messageSendQuoteFolio'
		default:
			return 'loadIndirectPriceQuoteWithoutCode.operations.noOperations'
	}
}

/**
 * Get on Get Request Type Description
 * @returns
 */
export function onGetRequestTypeDescription(requestType) {
	switch (requestType) {
		case "1":
			return 'Materiales/Refacciones'
		case "2":
			return 'Servicio NO especializado'
		case "3":
			return 'Servicio especializado REPSE'
		default:
			return 'Tipo de solicitud desconocida'
	}
}

export function getCurrencyDescription(value){

    switch (value) {
        case "1":
            return "UM";
        case "2":
            return "BOL";
        case "3":
            return "CJ";
        case "4":
            return "CUB";
        case "5":
            return "TAM";
        case "6":
            return "GR";
        case "7":
            return "GAR";
        case "8":
            return "GAL";
        case "9":
            return "JGO";
        case "10":
            return "KG";
        case "11":
            return "KM";
        case "12":
            return "L";
        case "13":
            return "LB";
        case "14":
            return "SRV";
        case "15":
            return "M";
        case "16":
            return "M2";
        case "17":
            return "M3";
        case "18":
            return "OZ";
        case "19":
            return "PAA";
        case "20":
            return "PZA";
        case "21":
            return "HRA";
        case "22":
            return "DÍA";
        case "23":
            return "TO";
        case "24":
            return "TOT";
        case "25":
            return "TRA";
        default:
            return "Desconocido";
    }
}

export function canBeEditSupplierInformation(indirectPriceQuoteSupplier, userProfile) {
	if (userProfile === null || userProfile === undefined) return false
	if (indirectPriceQuoteSupplier === null || indirectPriceQuoteSupplier === undefined) return false
	// if (loadAlert.status !== LoadAlertStatus.DRAFT) return false
	if (!indirectPriceQuoteSupplier) return false
	if (userProfile.profile.company_code !== indirectPriceQuoteSupplier.Code) return false
	if(indirectPriceQuoteSupplier.Status !==IndirectPriceQuoteSupplierStatus.SEND_TO_SUPPLIER) return false
	let supplierAdmin = IsUserSupplierAdministrator(userProfile)
	return supplierAdmin
}
