import {
	COLOR_STATUS_INFO,
	COLOR_STATUS_SUCCESS,
	COLOR_STATUS_WARNING,
} from './StatusColorConstants'
export const ExportRequestStatus = {
	Undefined: 0,
	Draft: 1,
    SentToReview:2,
    Complete: 3
}


export const ExportRequestItemType = {
	Undefined: 0,
    NationalProvider:1, //proveedor transporte nacional
    MxProvider:2, // Agente aduanal mexicano
    ForeignTransportProvider:3, //proveedor transporte extranjero
    UsaProvider:4, //usa
}

export function canBeEditExportRequestInformation(exportRequestItem, userProfile)
{
    if(!exportRequestItem) return false
    if(exportRequestItem.Status === ExportRequestStatus.Complete) return false
    if (!userProfile) return false
    return userProfile.profile.company_code === exportRequestItem.SupplierCode
}

/**
 * Get title to chage status
 * @returns
 */
export function onGetStatusDescriptionChip(status) {
	switch (status) {
		case ExportRequestStatus.Draft:
			return 'common.exportRequestStatus.draft'
		case ExportRequestStatus.SentToReview:
			return 'common.exportRequestStatus.review'
		case ExportRequestStatus.Complete:
			return 'common.exportRequestStatus.finalized'
        case ExportRequestStatus.Undefined:
            return 'common.exportRequestStatus.undefined'
		default:
			return ''
	}
}

/**
 * Get title to chage status
 * @returns
 */
export function onGetBackgroundColorChip(status) {
	switch (status) {
		case ExportRequestStatus.Draft:
			return COLOR_STATUS_INFO
		case ExportRequestStatus.SentToReview:
			return COLOR_STATUS_WARNING
		case ExportRequestStatus.Complete:
			return COLOR_STATUS_SUCCESS
		default:
			return ''
	}
}