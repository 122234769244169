import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import queryString from 'query-string'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Chip,
	CircularProgress,
	IconButton,
	Typography,
} from '@material-ui/core'
import { Add, Cancel, CheckCircle, Delete, DoneAll, CloudDownload } from '@material-ui/icons'

import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
import CreateLateralPanel from './components/CreateLateralPanel'

import { converListToArray } from '../../store/helpers/ListHelper'
import * as selectors from '../../store/certifications/certificationsSelector'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import {
	createCertification,
	deleteCertification,
	getCertificationPDF,
	getDownlaodReportCertification,
	loadCertificationsPaginated,
} from '../../store/certifications/certificationsActions'
import { FolderName } from '../../store/helpers/SecurityCoursesHelper'

import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import DownloadLateralPanel from './components/DownloadLateralPanel'
import { IsUserInRole, ROLE_SUPPLIER_QUALITYNGINEER, ROLE_SUPPLIERADMINISTRATOR } from '../../store/helpers/RolesHelper'

const Index = (props) => {
	const { classes } = props
	const dispatch = useDispatch()
	let location = useLocation()
	const history = useHistory()
	const queryParams = queryString.parse(location.search)
	const urlParams = useParams()
	const [openCreatePanel, setOpenCreatePanel] = useState(false)
	const [openDownloadPanel, setOpenDownloadPanel] = useState(false)
	const [certificationInformation, setCertificationInformation] = useState(CertificationInformation)
	const [filtersCertificationReport, setFiltersCertificationReport] = useState(FiltersCertificationReport)

	const certificationsPaginated = useSelector((state) => selectors.getCertificationsSelector(state))
	const isLoading = useSelector((state) => selectors.getIsLoadingSaving(state))
	const isLoadingDonwlaod = useSelector((state) => selectors.getIsLoadingDownload(state))

	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')
	const canBeCreateCertifications =
		!isInternalUser() &&
		(IsUserInRole(ROLE_SUPPLIER_QUALITYNGINEER, props.userRolesByCompany) ||
			IsUserInRole(ROLE_SUPPLIERADMINISTRATOR, props.userRolesByCompany))
	/**
	 * Get Certifications Paginated
	 * @returns certifications paginated
	 */
	const onGetCertificationsPaginated = () =>
		dispatch(
			loadCertificationsPaginated({
				query: queryParams.query,
				pageNumber: queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0,
				rowsPerPage: queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20,
				sortBy: queryParams.sortBy ? queryParams.sortBy : '',
				sortDirection: queryParams.sortDirection ? queryParams.sortDirection : '',
				supplierCode: isInternalUser() && urlParams.code ? urlParams.code : null,
			})
		)
	const onDeletecertification = (item, event) => {
		event.stopPropagation()
		if (item)
			dispatch(deleteCertification(item.id, item.document, FolderName.CERTIFICATION)).then(() => {
				onGetCertificationsPaginated()
			})
	}
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if (parseInt(queryParams.rowsPerPage) !== parseInt(rowsPerPage)) {
			queryParams.pageNumber = 1
			queryParams.rowsPerPage = rowsPerPage
		} else {
			queryParams.pageNumber = page + 1
		}
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangeQuery = (query) => {
		queryParams.query = query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onCreateCertification = () => {
		dispatch(createCertification(certificationInformation)).then(() => {
			setOpenCreatePanel(false)
			setCertificationInformation(CertificationInformation)
		})
	}
	const onOpenViewFile = (item) => {
		dispatch(getCertificationPDF(item.id, item.document, FolderName.CERTIFICATION))
	}
	const onDownlaodReportCertification = () => {
		dispatch(
			getDownlaodReportCertification({
				supplierCode: isInternalUser() && urlParams.code ? urlParams.code : null,
				filters: filtersCertificationReport,
			})
		).then(() => {
			setOpenDownloadPanel(false)
			setFiltersCertificationReport(FiltersCertificationReport)
		})
	}
	useEffect(() => {
		onGetCertificationsPaginated()
	}, [dispatch, location.search])

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'CertificationName')
			return (
				<Typography variant='caption' style={{ maxWidth: '200px' }}>
					{item.certificationName}
				</Typography>
			)
		if (dataSource === 'Notes')
			return (
				<Typography variant='caption' style={{ maxWidth: '350px', maxHeight: '200px', overflow: 'auto' }}>
					{item.notes}
				</Typography>
			)
		if (dataSource === 'StartDateCertification')
			return <Typography variant='caption'>{moment(item.startDateCertification).format('DD/MM/YYYY')}</Typography>

		if (dataSource === 'ValidityCertification') {
			if (item.validityCertification) {
				return (
					<Chip
						className={classes.currentCertification}
						icon={<CheckCircle className={classes.textCertificationValidate} />}
						label={
							<Typography className={classes.textCertificationValidate}>
								{moment(item.endDateCertification).format('DD/MM/YYYY')}
							</Typography>
						}
					/>
				)
			}
			return (
				<Chip
					className={classes.expiredCertification}
					icon={<Cancel className={classes.textCertificationValidate} />}
					label={
						<Typography className={classes.textCertificationValidate}>
							{moment(item.endDateCertification).format('DD/MM/YYYY')}
						</Typography>
					}
				/>
			)
		}
		if (dataSource === 'Document') {
			var documentName = item.document
			return <Link onClick={() => onOpenViewFile(item)}>{documentName}</Link>
		}
		if (dataSource === 'options')
			return (
				<div>
					{canBeCreateCertifications && (
						<Delete
							color='primary'
							style={{ cursor: 'pointer' }}
							onClick={(event) => onDeletecertification(item, event)}
						/>
					)}
				</div>
			)
	}
	console.log({ props })
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar className={classes.avatar}>
						<DoneAll />
					</Avatar>
				}
				action={
					<div className={classes.actionsToolbar}>
						<SearchInput
							value={!!queryParams && queryParams.query ? queryParams.query : ''}
							onChangeSearchValue={onChangeQuery}
						/>
						{isInternalUser() &&
							(isLoadingDonwlaod ? (
								<CircularProgress />
							) : (
								<IconButton onClick={() => setOpenDownloadPanel(true)}>
									<CloudDownload color='primary' />
								</IconButton>
							))}
						{canBeCreateCertifications && (
							<IconButton className={classes.addButton} onClick={() => setOpenCreatePanel(true)}>
								<Add fontSize='small' />
							</IconButton>
						)}
					</div>
				}
				title={
					<Typography>
						<Translate id={'certifications.index.title'} />
					</Typography>
				}
			/>
			<CardContent>
				<div className={classes.cardContent}>
					<DataTable
						isLoading={isLoading}
						data={
							certificationsPaginated && certificationsPaginated.items
								? certificationsPaginated.items
								: []
						}
						configuration={TableConfiguration}
						onRenderCellItem={onRenderCellItem}
						isIndex
						totalRows={
							certificationsPaginated && certificationsPaginated.total ? certificationsPaginated.total : 0
						}
						page={
							certificationsPaginated && certificationsPaginated.pageNumber
								? certificationsPaginated.pageNumber - 1
								: 0
						}
						rowsPerPage={
							certificationsPaginated && certificationsPaginated.pageSize
								? certificationsPaginated.pageSize
								: 20
						}
						sortBy={
							certificationsPaginated.sort && certificationsPaginated.sort.by
								? certificationsPaginated.sort.by
								: 'CertificationName'
						}
						sortAscending={
							certificationsPaginated.sort && certificationsPaginated.sort.direction
								? certificationsPaginated.sort.direction === 'desc'
									? false
									: true
								: false
						}
						onChangeSortCriteria={onChangeSortCriteria}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</div>
			</CardContent>
			<CreateLateralPanel
				open={openCreatePanel}
				onClose={() => setOpenCreatePanel(false)}
				certificationInformation={certificationInformation}
				setCertificationInformation={setCertificationInformation}
				onSubmit={onCreateCertification}
				isLoading={isLoading}
			/>
			<DownloadLateralPanel
				open={openDownloadPanel}
				onClose={() => setOpenDownloadPanel(false)}
				certificationInformation={certificationInformation}
				setCertificationInformation={setCertificationInformation}
				filtersCertificationReport={filtersCertificationReport}
				setFiltersCertificationReport={setFiltersCertificationReport}
				onSubmit={onDownlaodReportCertification}
				isLoading={isLoadingDonwlaod}
			/>
		</Card>
	)
}
const CertificationInformation = {
	CertificationName: '',
	StartDateCertification: '',
	EndDateCertification: '',
	Notes: '',
	Document: [],
}
const FiltersCertificationReport = {
	StartDate: '',
	EndDate: '',
}
const TableConfiguration = {
	columns: [
		{
			header: <Translate id='certifications.tableConfig.name' />,
			dataSource: 'CertificationName',
			isSortable: true,
		},
		{
			header: <Translate id='certifications.tableConfig.startDate' />,
			dataSource: 'StartDateCertification',
			isSortable: true,
		},
		{
			header: <Translate id='certifications.tableConfig.notes' />,
			dataSource: 'Notes',
			isSortable: true,
		},
		{
			header: <Translate id='certifications.tableConfig.validityCertification' />,
			dataSource: 'ValidityCertification',
			isSortable: true,
		},
		{
			header: <Translate id='certifications.tableConfig.document' />,
			dataSource: 'Document',
			isSortable: true,
		},
		{
			header: '',
			dataSource: 'options',
		},
	],
}
const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
		alignItems: 'center',
	},
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	cardContent: {
		height: 'calc(100vh - 260px)',
	},
	currentCertification: {
		backgroundColor: theme.palette.primary.main,
	},
	expiredCertification: {
		backgroundColor: theme.palette.error.light,
	},
	textCertificationValidate: {
		color: theme.palette.common.white,
	},
})

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Index)))
)
