//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI imports section */
import { Divider, Grid, Typography, withStyles } from '@material-ui/core'
//import common components

import LayoutHeaderWorkFlow from '../../../common/LayoutHeaderWorkFlow'
import { Translate } from 'react-localize-redux'

const HeaderComponent = (props) => {
	//object
	const { exportRequest } = props

	return (
		<>
			<LayoutHeaderWorkFlow
				{...exportRequest}
				workFlow={{
					folio: exportRequest.SITFolio,
					origin: 'SIT',
					creation_date_long: exportRequest.creation_date,
					create_by: '-',
					full_supplier: '-',
					class_status: '-',
					actual_operation_description: 'Borrador',
				}}
				company={{
					logo: '',
					name: 'IMBERA SA DE CV',
					rfc: 'VME840820RT9',
					zip_code: 76803,
					address: 'Carr. México - Tequisquiapan Km 3.2 Zona Ind. Valle de Oro San Juan del Río, Querétaro',
				}}
				title={'Solicitud de exportación'}
				additionalData={exportRequest.CreationDate}
				itemStatus={exportRequest.Status}
			/>
			<Divider />

			<Grid container style={{ padding: '10px' }}>
				<Grid item xs={12}>
					<Typography variant='subtitle1'>
						<strong>Información general de la solicitud de exportación</strong>
					</Typography>
				</Grid>
				{/* Fila 1 */}
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								<Translate id='common.folioSIT' />
							</strong>
						</Typography>
						<Typography>{exportRequest.SITFolio}</Typography>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								<Translate id='common.transportType' />
							</strong>
						</Typography>
						<Typography>{exportRequest.TransportType}</Typography>
					</div>
				</Grid>
				{/* Fila 2 */}
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								<Translate id='common.orderType' />
							</strong>
						</Typography>
						<Typography>{exportRequest.OrderType}</Typography>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								<Translate id='common.incoterm' />
							</strong>
						</Typography>
						<Typography>{exportRequest.Incoterm}</Typography>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								<Translate id='common.incotermPlace' />
							</strong>
						</Typography>
						<Typography>{exportRequest.IncotermPlace}</Typography>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant='subtitle1' color='primary'>
							<strong>
								{/* <Translate id='common.incotermPlace' /> */}
								{'Región'}
							</strong>
						</Typography>
						<Typography>{exportRequest.Region ? exportRequest.Region : '-'}</Typography>
					</div>
				</Grid>
			</Grid>
			<Divider />
		</>
	)
}

/** Resources import section */
const useStyle = (theme) => ({})

export default withRouter(withStyles(useStyle, { withTheme: true })(HeaderComponent))
