import React, { PureComponent } from 'react'
import autoBind from 'auto-bind'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import yourlogohere from '../../resources/images/yourlogohere.png'
import { Chip, Grid } from '@material-ui/core'

import logotypeQuestionnaire from '../../resources/images/logo_sustantibility.png'
import logotypeSGC from '../../resources/images/logo_siste_gestion_calidad.jpg'
import { Translate } from 'react-localize-redux'
import { onGetBackgroundColorChip, onGetStatusDescriptionChip } from '../../store/helpers/ExportRequestHelper'

/**
 * Component to get header from format workflow
 */
class LayoutHeaderWorkFlow extends PureComponent {
	/**
	 *Creates an instance of LayoutHeaderWorkFlow.
	 * @param {*} props
	 * @memberof LayoutHeaderWorkFlow
	 */
	constructor(props) {
		super(props)
		autoBind(this)
	}

	/**
	 * Render header
	 */
	render() {
		if (this.props.title && this.props.workFlow && this.props.supplier && this.props.company) {
			const { title, workFlow, supplier, company, classes, isQuote, itemStatus } = this.props
			let supplierLogo = supplier ? supplier.logo : ''
			let urlImageSupplier = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplierLogo}`

			let companyLogo = company ? company.logo : ''
			let urlImageCompany = `${process.env.REACT_APP_SIP_URL_LOGOS_ADMINISTRATION}/${companyLogo}`
			let addLogCompany = !isQuote ? (
				<img
					src={companyLogo ? urlImageCompany : yourlogohere}
					alt='companyLogo'
					className={classes.logoCompany}
				/>
			) : (
				''
			)

			return (
				<Card>
					<CardHeader
						className={classes.withoutPaddingTopBottom}
						avatar={addLogCompany}
						action={
							<span>
								{this.props.isSustentability && (
									<img
										src={logotypeQuestionnaire}
										alt='logoSupplier'
										className={classes.logoSupplier}
									/>
								)}
								{this.props.isQuestionnaireSGC && (
									<img src={logotypeSGC} alt='logoSupplier' className={classes.logoSupplier} />
								)}
								<img
									src={supplierLogo ? urlImageSupplier : yourlogohere}
									alt='logoSupplier'
									className={classes.logoSupplier}
								/>
							</span>
						}
						title={
							<Typography gutterBottom variant='h6'>
								<strong>
									{!isQuote && workFlow.folio}
									{isQuote && workFlow.origin}
								</strong>
								{': '}
								{title}
							</Typography>
						}
						subheader={
							<p>
								{workFlow.creation_date_long} <br /> {workFlow.create_by}{' '}
							</p>
						}
					/>
					{itemStatus !== null ? (
						<div>
					
						<Chip
						label={<Translate id={onGetStatusDescriptionChip(itemStatus)} />}
						style={{ borderRadius: '5px', width: '200px', height: '25px', backgroundColor:onGetBackgroundColorChip(itemStatus), marginLeft: '1rem', marginBottom: '.5rem' }}
						color='inherit'
						/>
						</div>

					) : null}
					

					<CardContent className={classes.withoutPaddingTopBottom ? classes.withoutPaddingTopBottom : ''}>
						<Divider />
						<Grid container spacing={24}>
							<Grid item xs={12} sm={9} md={9} lg={9} xl={10}>
								<Typography variant='caption' display='block'>
									{<strong>{company.name}</strong>} <br />
									{'RFC: '} {company.rfc} <br />
									{company.address}
									<br />
									{'CP: '} {company.zip_code}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
								<Typography variant='caption' display='block'>
									{<strong>{workFlow.full_supplier}</strong>} <br />
									{'RFC: '} {supplier.rfc}
									<br />
									{supplier.address} <br />
									{supplier.city} {supplier.country}
									<br />
									<strong
										style={{ fontSize: '110%', whiteSpace: 'normal', color: 'white' }}
										className={workFlow.class_status}
									>
										{workFlow.actual_operation_description &&
											workFlow.actual_operation_description.toUpperCase()}
									</strong>
									{this.props.additionalData && (
										<>
											<br />
											<strong
												style={{ fontSize: '100%', whiteSpace: 'normal' }}
												className={workFlow.class_status}
											>
												{this.props.additionalData.toUpperCase()}
											</strong>
										</>
									)}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			)
		} else {
			return ''
		}
	}
}

LayoutHeaderWorkFlow.propTypes = {
	title: PropTypes.object.isRequired,
	workFlow: PropTypes.shape({
		folio: PropTypes.string.isRequired,
		origin: PropTypes.string,
		creation_date_long: PropTypes.string.isRequired,
		create_by: PropTypes.string.isRequired,
		full_supplier: PropTypes.string,
		class_status: PropTypes.string.isRequired,
		actual_operation_description: PropTypes.string.isRequired,
	}),
	supplier: PropTypes.shape({
		logo: PropTypes.string,
		full_supplier: PropTypes.string,
		rfc: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
	}),
	company: PropTypes.shape({
		logo: PropTypes.string,
		name: PropTypes.string.isRequired,
		rfc: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		zip_code: PropTypes.number,
	}),
	additionalData: PropTypes.string,
	classes: PropTypes.object,
	isQuote: PropTypes.bool,
	itemStatus: PropTypes.number,
}

LayoutHeaderWorkFlow.defaultProps = {
	title: '',
	supplier: PropTypes.shape({
		logo: '',
		full_supplier: '',
		rfc: '',
		address: '',
		city: '',
		country: '',
	}),
	company: PropTypes.shape({
		logo: '',
		name: '',
		rfc: '',
		address: '',
	}),
	itemStatus: null,
}

const styles = (theme) => ({
	logoCompany: {
		maxHeight: 90,
	},
	logoSupplier: {
		marginLeft: 80,
		marginRigth: 8,
		marginTop: 8,
		maxHeight: 90,
	},
	withoutPaddingTopBottom: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
	},
	actionsToolbar: {},
	grow: {},
	styleTab: {},
	margin: {},
	container: {},
	inline: {},
	tableHead: {},
	textField: {},
	tableContainer: {},
	parnertPadding: {},
	extendedIcon: {},
	stickyDiv: {},
})

export default withStyles(styles, { withTheme: true })(LayoutHeaderWorkFlow)
