//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI imports section */
import {
	Paper,
	Typography,
	withStyles,
} from '@material-ui/core'

import LoadingPage from '../../../common/LoadingPage'
import SupplierTable from './SupplierTable'
import LayoutHeaderWorkFlow from '../../../common/LayoutHeaderWorkFlow'
import HeaderComponent from './HeaderComponent'

const DesktopView = (props) => {
	//Bool props ection
	const { isLoading } = props
	//objects props ection
	const { indirectPriceQuoteWithoutCode, selectedCompany } = props
	//functions
	const { onRedirectToDetail, onGeneratedNewPDFQuoteWithOutPrice } = props
	if (isLoading) return <LoadingPage />
	if (!indirectPriceQuoteWithoutCode) return <LoadingPage />
	return (
		<Paper style={{ padding: '10px' }}>
			<LayoutHeaderWorkFlow
				workFlow={{
					folio: indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.FolioSIT : '-',
					origin: '-',
					create_by: indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.CreateBy : '-',
					full_supplier: indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.CreatorUserName : '-',
					class_status: 'badge badge-success',
					actual_operation_description: 'En proceso',
				}}
				company={selectedCompany}
				title={'Cotización de indirectos sin código'}
			/>
			<HeaderComponent indirectPriceQuoteWithoutCode={indirectPriceQuoteWithoutCode} />
			<Typography color='primary'>Información general</Typography>
			<SupplierTable
				indirectPriceQuoteWithoutCode={indirectPriceQuoteWithoutCode}
				onRedirectToDetail={onRedirectToDetail}
				onGeneratedNewPDFQuoteWithOutPrice={onGeneratedNewPDFQuoteWithOutPrice}
			/>
		</Paper>
	)
}

/** Resources import section */
const useStyle = (theme) => ({})

export default withRouter(withStyles(useStyle, { withTheme: true })(DesktopView))
