import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import propTypes from 'prop-types'

/** Material UI import section */
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	Fab,
	ListSubheader,
} from '@material-ui/core'
import DevolutionIcon from '@material-ui/icons/Reply'
import AddtIcon from '@material-ui/icons/AddTwoTone'
import DeleteIcon from '@material-ui/icons/Delete'

/** Custom component import section */
import DataTable from '../../common/DataTable'
import AddMaterialLateralPanel from './AddMaterialLateralPanel'
/**Import components section */
import { COLOR_CART_PORT } from '../../../store/helpers/StatusColorConstants'
import { AcknowledgmentsReceiptFilesVersions, ConsignementNoteStatus } from '../../../store/helpers/AppConstants'
import { getDeliveriesByEntry } from '../../../store/consignmentNote/consignmentNoteSelectors'
import AcknowledgmentReceipt from './AcknowledgmentReceipt'
import UploadEvidenceMerchandise from './UploadEvidenceMerchandise'

const MaterialsTable = ({
	delivery,
	consignmentNote,
	canbeUpdatePlant,
	canBeCreateDevolution,
	onDialogCreateDevolution,
	classes,
	isSaving,
	onOpenCloseDeleteMaterialDialog,
	canBeAddAcknowledgmentFile,
	onAddAcknowledgmentFile,
	onAddedEvidenceMerchandiseDialog,
	acknowledgmentFiles,
	onRemoveAcknowledgmentFiles,
	onDownloadAcknowledgmentFiles,
	onSeeGetAcknowledgmentFileByName,
	downloadingAcknowledgmentFiles,
	onRemoveEvidenceMerchandiseFiles,
	onDownloadEvidenceMerchandiseFiles,
	onDownloadEvidenceMerchandise,
	onDeleteEvidenceMerchansedise
}) => {
	// materials by entries
	let deliveresList = getDeliveriesByEntry(delivery.recipient, consignmentNote)
	const [openLateralPanel, setOpenLateralPanel] = useState(false)

	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'code') {
			return `${item.code} | ${item.name}`
		}
		if (dataSource === 'productCode') {
			if (item.productCode) return `${item.productCode}`
			else return '-'
		}
		if (dataSource === 'hazardousMaterialCode') {
			if (item.hazardousMaterialCode) return `${item.hazardousMaterialCode}`
			else return '-'
		}
		if (dataSource === 'packagingCode') {
			if (item.packagingCode)
				return `${item.packagingCode} | ${item.packagingDescription ? item.packagingDescription : ''}`
			else return '-'
		}
		if (dataSource === 'options') {
			if (!item.devolutionCode && consignmentNote.status !== ConsignementNoteStatus.DRAFT) {
				return (
					<Tooltip title={'Crear devolución'}>
						<DevolutionIcon color='primary' onClick={() => onDialogCreateDevolution(delivery, item)} />
					</Tooltip>
				)
			} else {
				return item.devolutionCode
			}
		}
		if (dataSource === 'delete') {
			if (!item.devolutionCode && consignmentNote.status === ConsignementNoteStatus.DRAFT) {
				return (
					<Tooltip title={'Eliminar material'}>
						<DeleteIcon
							className={classes.deleteIcon}
							onClick={() => onOpenCloseDeleteMaterialDialog(delivery, item)}
						/>
					</Tooltip>
				)
			}
		}
	}

	/**
	 * Function to open side panel
	 */
	const onOpenLateralPanel = () => {
		setOpenLateralPanel(!openLateralPanel)
	}
	let isNewVersion =
		consignmentNote && consignmentNote.acknowledgmentsReceiptFilesVersion === AcknowledgmentsReceiptFilesVersions.V2
	return (
		<>
			{' '}
			{deliveresList.map((entry) => {
				return (
					<div key={entry.id}>
						<List
							subheader={
								isNewVersion && (
									<ListSubheader className={classes.deliveryContainer}>
										<Typography
											// component='span'
											// variant='body2'
											// color='textPrimary'
											className={classes.deliveryTitle}
										>
											<strong>
												{' '}
												{<Translate id='consignmentNote.show.delivered' />} {': '} {entry.code}
											</strong>
										</Typography>
									</ListSubheader>
								)
							}
							style={isNewVersion ? { padding: '30px' } : null}
						>
							{isNewVersion && (
								<AcknowledgmentReceipt
									canBeAdd={canBeAddAcknowledgmentFile}
									onAddingButtonClick={onAddAcknowledgmentFile}
									files={entry.attachments}
									onRemoveFiles={onRemoveAcknowledgmentFiles}
									onDownloadFiles={onDownloadAcknowledgmentFiles}
									onSeeGetAcknowledgmentFileByName={onSeeGetAcknowledgmentFileByName}
									downloadingFiles={downloadingAcknowledgmentFiles}
									deliveryCode={entry.code}
									isSaving={isSaving}
								/>
							)}

							{isNewVersion && (
								<UploadEvidenceMerchandise
									canBeAdd={canBeAddAcknowledgmentFile}
									onAddingButtonClick={onAddedEvidenceMerchandiseDialog}
									files={entry.attachmentsDeliveries}
									onRemoveFiles={onRemoveEvidenceMerchandiseFiles}
									onDownloadFiles={onDownloadEvidenceMerchandiseFiles}
									onSeeGetAcknowledgmentFileByName={onSeeGetAcknowledgmentFileByName}
									downloadingFiles={downloadingAcknowledgmentFiles}
									deliveryCode={entry.code}
									isSaving={isSaving}
									onDownloadEvidenceMerchandise={onDownloadEvidenceMerchandise}
									onDeleteEvidenceMerchansedise={onDeleteEvidenceMerchansedise}
								/>
							)}

							<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
								<ListItemText
									secondary={
										<Typography component='span' variant='body2' color='textPrimary'>
											<strong>
												{' '}
												{<Translate id='consignmentNote.show.materils' />} {': '} {entry.code}
											</strong>
										</Typography>
									}
								/>
								<ListItemSecondaryAction>
									<div className={classes.containerAddMaterial}>
										<Typography
											component='span'
											variant='body2'
											color='textPrimary'
											className={classes.totalText}
										>
											<strong>
												{' '}
												{<Translate id='consignmentNote.show.totalNumber' />}
												{': '} {entry.materials.length}
											</strong>
										</Typography>
										{consignmentNote &&
											canbeUpdatePlant &&
											consignmentNote.status === ConsignementNoteStatus.DRAFT && (
												<Tooltip
													title={
														<Translate id='consignmentNote.createMaterial.addMaterial' />
													}
												>
													<Fab
														className={classes.marginIcon}
														size='small'
														color='primary'
														onClick={() => {
															onOpenLateralPanel()
														}}
													>
														<AddtIcon />
													</Fab>
												</Tooltip>
											)}
									</div>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
						<div style={isNewVersion ? { paddingLeft: '30px', paddingRight: '30px' } : null}>
							<DataTable
								data={entry.materials}
								configuration={TableConfiguration(canbeUpdatePlant, canBeCreateDevolution)}
								onRenderCellItem={onRenderCellItem}
							/>
						</div>

						{openLateralPanel && (
							<AddMaterialLateralPanel
								delivery={delivery}
								onCloseButtonClick={onOpenLateralPanel}
								consignmentNote={consignmentNote}
								isSaving={isSaving}
							/>
						)}
					</div>
				)
			})}
		</>
	)
}

MaterialsTable.propTypes = {
	materials: propTypes.array,
}

MaterialsTable.defaultProps = {
	materials: [],
}

function TableConfiguration(canbeUpdatePlant, canBeCreateDevolution) {
	let columns = [
		{
			header: <Translate id='consignmentNote.table.requestCode' />,
			dataSource: 'requestCode',
		},
		{
			header: <Translate id='consignmentNote.table.material' />,
			dataSource: 'code',
		},
		{
			header: <Translate id='consignmentNote.table.umBase' />,
			dataSource: 'baseMeasurementUnit',
		},
		{
			header: <Translate id='consignmentNote.table.position' />,
			dataSource: 'position',
		},
		{
			header: <Translate id='consignmentNote.table.grossWeight' />,
			dataSource: 'grossWeight',
		},
		{
			header: <Translate id='consignmentNote.table.unitWeight' />,
			dataSource: 'weightMeasurementUnit',
		},
		{
			header: <Translate id='consignmentNote.table.keyProduct' />,
			dataSource: 'productCode',
		},
		{
			header: <Translate id='consignmentNote.table.deliveryQuantity' />,
			dataSource: 'quantity',
		},
		{
			header: <Translate id='consignmentNote.table.keyMaterial' />,
			dataSource: 'hazardousMaterialCode',
		},
		//  {
		//     header: <Translate id="consignmentNote.table.codeKeyPacking" />,
		//     dataSource: 'packagingCode',
		// },
		{
			header: <Translate id='consignmentNote.table.totalWeight' />,
			dataSource: 'weightInKg',
		},
	]

	if (canBeCreateDevolution) {
		columns.push({
			header: 'Dev.',
			dataSource: 'options',
		})
	}

	if (canbeUpdatePlant) {
		columns.push({
			header: '',
			dataSource: 'delete',
		})
	}

	return { columns }
}

const styles = (theme) => ({
	containerAddMaterial: {
		display: 'flex',
	},
	totalText: {
		marginTop: '10px',
		marginRight: '5px',
	},
	deleteIcon: {
		color: theme.palette.error.main,
		cursor: 'pointer',
	},
	deliveryContainer: {
		borderLeft: `1rem solid ${theme.palette.primary.main}`,
		backgroundColor: '#DBDBDB',
		marginLeft: '-30px',
		marginRight: '-30px',
		height: '50px',
	},
	deliveryTitle: {
		paddingTop: '13px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(MaterialsTable))
