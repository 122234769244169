//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI imports section */
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, withStyles } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PersonIcon from '@material-ui/icons/Person'

//import componenst section
import LoadingPage from '../../../common/LoadingPage'
import DataTable from '../../../common/DataTable'
import SupplierChipStatus from './SupplierChipStatus'
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { GetCurrencyTypeContract } from '../../../../store/helpers/SelectOptions'
import { IndirectPriceQuoteSupplierStatus } from '../../../../store/helpers/AppConstants'

const SupplierTable = (props) => {
	//Bool props ection
	const { isLoading } = props
	//objects props ection
	const { indirectPriceQuoteWithoutCode, classes } = props
	//functions
	const { onRedirectToDetail, onGeneratedNewPDFQuoteWithOutPrice } = props
	if (isLoading) return <LoadingPage />
	console.log('indirectPriceQuoteWithoutCode', indirectPriceQuoteWithoutCode)

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Status') {
			return <SupplierChipStatus status={item.Status} />
		}
		if (dataSource === 'Currency') {
			if (item.Currency) {
				let currencyOptions = GetCurrencyTypeContract()
				let currencyValue = item.Currency ? parseInt(item.Currency, 10) : null
				let selectedCurrency = currencyOptions.find((x) => x.value === currencyValue)
				return selectedCurrency.label
			}
			return ''
		}

		if (dataSource === 'Options') {
			return (
				<div style={{ display: 'flex' }}>
					<IconButton
						aria-label='view' // Agregar accesibilidad
						color='primary' // Puedes cambiar el color o usar "default"
						onClick={() => onRedirectToDetail(item.token)} // Agrega funcionalidad si lo deseas
					>
						<VisibilityIcon />
					</IconButton>
					{item.Status === IndirectPriceQuoteSupplierStatus.QUOTED && (
						<IconButton
							aria-label='view' // Agregar accesibilidad
							color='primary' // Puedes cambiar el color o usar "default"
							onClick={() => onGeneratedNewPDFQuoteWithOutPrice(item)} // Agrega funcionalidad si lo deseas
						>
							<PictureAsPdfIcon />
						</IconButton>
					)}
				</div>
			)
		}
	}

	return (
		<Grid container spacing={16}>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<PersonIcon />
							</Avatar>
						}
						title={'Proveedores'}
					/>
					<Divider />

					<CardContent>
						<DataTable
							data={indirectPriceQuoteWithoutCode.Suppliers}
							configuration={TableConfiguration()}
							// onRowClick={(item) => {
							// 	history.push(`/indirectPriceQuoteWithoutCode/${item.token}`)
							// }}
							// showFilters
							// isIndex
							// totalRows={allRegisterCount}
							onRenderCellItem={onRenderCellItem}
							// page={page}
							// rowsPerPage={rowsPerPage}
							// onChangePage={(newPage, newRowsPerPage) => {
							// 	onChangePage(newPage, newRowsPerPage)
							// }}
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

function TableConfiguration() {
	let columns = [
		{
			header: <Translate id='common.status' />,
			dataSource: 'Status',
			isSortable: true,
		},
		{
			header: 'Código',
			dataSource: 'Code',
		},
		{
			header: 'Nombre',
			dataSource: 'Name',
			isSortable: true,
		},
		{
			header: 'Correo',
			dataSource: 'Email',
			isSortable: true,
		},
		{
			header: 'Lugar de entrega',
			dataSource: 'DeliveryLocation',
			isSortable: true,
		},
		{
			header: 'Vigencia',
			dataSource: 'ServiceValidityDate',
			isSortable: true,
		},
		{
			header: 'Moneda',
			dataSource: 'Currency',
			isSortable: true,
		},
		{
			header: 'Opciones',
			dataSource: 'Options',
			isSortable: true,
		},
	]

	return { columns }
}

/** Resources import section */
const useStyle = (theme) => ({
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
	cardcontent: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	textTitle: {
		fontWeight: 'bold',
	},
	detailsIcon: {
		transform: 'rotate(180deg)',
	},
})

export default withRouter(withStyles(useStyle, { withTheme: true })(SupplierTable))
