import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card, Grid, Typography, IconButton, CardContent, TextField, InputAdornment, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants'
import { IndirectPriceQuoteWithoutCodeItemStatus } from '../../../../store/helpers/AppConstants'
import { getCurrencyDescription } from '../../../../store/helpers/indirectPriceQuoteWithoutCodeHelper'

const useStyle = (theme) => ({
	card: {
		backgroundColor: '#f0f4f8',
		margin: '16px 0',
		borderLeft: `5px solid ${theme.palette.primary.main}`,
		boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
		transition: '0.3s',
	},
	cardAccepted: {
		borderLeft: `5px solid ${COLOR_STATUS_SUCCESS}`,
		backgroundColor: '#e6ffe6', // Fondo verde suave
	},
	cardRejected: {
		borderLeft: `5px solid ${COLOR_STATUS_DANGER}`,
		backgroundColor: '#ffe6e6', // Fondo rojo suave
	},
	cardDraft: {
		borderLeft: `5px solid ${theme.palette.primary.main}`,
		backgroundColor: '#e6f7ff', // Fondo azul suave
	},
	// Otros estilos
	acceptedItem: {
		color: COLOR_STATUS_SUCCESS,
	},
	rejectedItem: {
		color: COLOR_STATUS_DANGER,
	},
	textTitle: {
		fontWeight: 'bold !important',
		// color: theme.palette.primary.main,
	},
})

const SupplierResponses = (props) => {
	const { classes } = props
	//objects
	const { indirectPriceQuoteSupplier, item } = props
	//Funcions
	const { updateIndirectPriceQuoteItemProperty } = props
	//bools

	const { isSaving, canBeEditSupplier } = props

	// const [expanded, setExpanded] = useState(true)

	// const handleExpandClick = () => {
	// 	setExpanded(!expanded)
	// }

	const onUpdateProperty = (event) => {
		let objectProperty = {}
		if (props.updateIndirectPriceQuoteItemProperty) {
			objectProperty[event.target.name] = event.target.value
			updateIndirectPriceQuoteItemProperty(indirectPriceQuoteSupplier.token, item.token, objectProperty)
		}
	}

	const onAcceptedRejeted = (value) => {
		let objectProperty = {}
		if (props.updateIndirectPriceQuoteItemProperty) {
			objectProperty.Status = value
			updateIndirectPriceQuoteItemProperty(indirectPriceQuoteSupplier.token, item.token, objectProperty)
		}
	}

	let canBeUpdatePropertyFromItem = item.Status === IndirectPriceQuoteWithoutCodeItemStatus.ACCEPTED
	// Asignamos la clase dinámica dependiendo del estado del ítem
	let cardClass =
		item.Status === IndirectPriceQuoteWithoutCodeItemStatus.ACCEPTED
			? classes.cardAccepted
			: item.Status === IndirectPriceQuoteWithoutCodeItemStatus.REJECTED
			? classes.cardRejected
			: classes.cardDraft
	return (
		<Card className={`${classes.card} ${cardClass}`}>
			<CardContent className={classes.cardContent}>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<Typography className={classes.textTitle}>Descripción</Typography>
						<Typography>{item ? item.GeneralDescription : '-'}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography className={classes.textTitle}>Cantidad</Typography>
						<Typography className={classes.textTitle}>{item.Quantity}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography className={classes.textTitle}>U.M</Typography>
						<Typography>{item ? getCurrencyDescription(item.UnitMeasure) : '-'}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography className={classes.textTitle}>Detalle de servicio</Typography>
						<Typography>{item ? item.ServiceDescription : '-'}</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography className={classes.textTitle}>Tiempo de entrega</Typography>
						<TextField
							name='DeliveryTime'
							defaultValue={item.DeliveryTime}
							onBlur={onUpdateProperty}
							disabled={!canBeUpdatePropertyFromItem || isSaving || !canBeEditSupplier}
						/>
					</Grid>
					<Grid item xs={2}>
						<Typography className={classes.textTitle}>Precio unitario</Typography>
						<TextField
							name='Price'
							defaultValue={item.Price}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								style: { textAlign: 'right' }, // Alinea el texto a la derecha
							}}
							type='number'
							onBlur={onUpdateProperty}
							disabled={!canBeUpdatePropertyFromItem || isSaving || !canBeEditSupplier}
						/>
					</Grid>
					<Grid item xs={1}>
						<Typography className={classes.textTitle}>Total</Typography>
						<Typography>
							{item
								? `${item.Total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
										.replace('MXN', '')
										.trim()}`
								: '-'}
						</Typography>
					</Grid>
					<Grid item xs={1}>
						{/* <IconButton
							className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label='show more'
						>
							<ExpandMoreIcon />
						</IconButton> */}
						<Tooltip title={'Aceptar'}>
							<IconButton
								onClick={() => onAcceptedRejeted(IndirectPriceQuoteWithoutCodeItemStatus.ACCEPTED)}
								aria-label='show more'
								className={
									item.Status === IndirectPriceQuoteWithoutCodeItemStatus.ACCEPTED &&
									classes.acceptedItem
								}
								disabled={isSaving || !canBeEditSupplier}
							>
								<ThumbUpIcon
									className={
										item.Status === IndirectPriceQuoteWithoutCodeItemStatus.ACCEPTED &&
										classes.acceptedItem
									}
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title={'Rechazar'}>
							<IconButton
								onClick={() => onAcceptedRejeted(IndirectPriceQuoteWithoutCodeItemStatus.REJECTED)}
								className={
									item.Status === IndirectPriceQuoteWithoutCodeItemStatus.REJECTED &&
									classes.rejectedItem
								}
								aria-label='show more'
								disabled={isSaving || !canBeEditSupplier}
							>
								<ThumbDownIcon
									className={
										item.Status === IndirectPriceQuoteWithoutCodeItemStatus.REJECTED &&
										classes.rejectedItem
									}
								/>
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</CardContent>

			{/* <Collapse in={expanded} timeout='auto' unmountOnExit>
				<Divider className={classes.divider} />
				<CardContent className={classes.cardContentExpander}>
					<Grid container spacing={2}>
						<Grid item xs={3}>
							<Typography className={classes.textTitle}>Precio unitario</Typography>
							<TextField
								defaultValue={formatCurrency(item.Price)}
								InputProps={{
									startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								}}
								type='number'
							/>
						</Grid>
						<Grid item xs={3}>
							<Typography className={classes.textTitle}>Tiempo de entrega</Typography>
							<TextField defaultValue={item.DeliveryTime} />
						</Grid>
					</Grid>
				</CardContent>
			</Collapse> */}
		</Card>
	)
}

export default withRouter(withStyles(useStyle, { withTheme: true })(SupplierResponses))
