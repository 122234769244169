import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { Translate, getTranslate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
//Import components section
import DesktopView from './components/showSupplier/DesktopView'
import {
	loadIndirectPriceQuoteWithoutSupplierDetailFromBackEnd,
	updateIndirectPriceQuoteItem,
	updatePriceQuoteWithOutCodeSupplier,
	changeStatusFromBackEnd,
	generatedNewPDFQuoteWithOutPrice
} from '../../store/indirectPriceQuoteWithoutCode/IndirectPriceQuoteWithoutCodeAction'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
//Import store section
import { toSafeObject } from '../../store/helpers/ListHelper'
import Toaster from '../common/Toaster'
import { loadSupplierCode } from '../../store/suppliers/SupplierActions'
import DialogOperation from '../sustainability2/components/DialogOperation'
import {
	AvailableSupplierOperationsConfiguration,
	canBeEditSupplierInformation,
	onMessageStatusDialog,
	onTitleStatusDialog,
} from '../../store/helpers/indirectPriceQuoteWithoutCodeHelper'
import AddCommonItem from '../common/AddCommonItem'
import { IndirectPriceQuoteSupplierStatus } from '../../store/helpers/AppConstants'
const Show = () => {
	const params = useParams()
	const dispatch = useDispatch()

	const isLoading = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('isLoading')
			? state.indirectPriceQuoteWithoutCode.get('isLoading')
			: false
	)
	const isSaving = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('isSaving')
			? state.indirectPriceQuoteWithoutCode.get('isSaving')
			: false
	)
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const translate = useSelector((state) => getTranslate(state.localize))
	const selectedCompany = useSelector((state) =>
		state.profile.get('selectedCompany') ? toSafeObject(state.profile.get('selectedCompany')) : null
	)
	console.log('selectedCompany', selectedCompany)

	const userProfile = useSelector((state) => state.oidc.user)

	const indirectPriceQuoteSupplier = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteSupplier')
			? state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteSupplier').toJS()
			: null
	)
	console.log('indirectPriceQuoteSupplier', indirectPriceQuoteSupplier)

	/** Get user Information*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	const [openDialogConfirm, setDialogConfirm] = useState({
		open: false,
		status: null,
		comments: '',
	})

	const [openDialogOperation, setDialogOperation] = useState(false)

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const onClosedDialog = () => {
		setDialogConfirm({
			open: false,
			status: null,
			sustainabilityId: null,
			comments: '',
		})
	}

	useEffect(() => {
		return dispatch(loadIndirectPriceQuoteWithoutSupplierDetailFromBackEnd(params.token)).then((data) => {
			dispatch(loadSupplierCode(data.Code))
		})
	}, [dispatch])

	const updateIndirectPriceQuoteItemProperty = (requestToken, itemToken, objectProperties) => {
		return dispatch(updateIndirectPriceQuoteItem(requestToken, itemToken, objectProperties))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}
	const updatePriceQuoteWithOutCodeSupplierProperty = (requestToken, objectProperties) => {
		return dispatch(updatePriceQuoteWithOutCodeSupplier(requestToken, objectProperties))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * Function to chage status
	 * @param {*} status
	 */
	const onChangeStatusFromBackEnd = (comments) => {
		let token = params.token
		return dispatch(changeStatusFromBackEnd(openDialogConfirm.status, token, comments, supplier, selectedCompany))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
				onClosedDialog()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onGeneratedNewPDFQuoteWithOutPrice = () => {
		let token = params.token
		return dispatch(generatedNewPDFQuoteWithOutPrice(token))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onOpenCloseDialogOperation = () => {
		setDialogOperation(!openDialogOperation)
	}
	let options = AvailableSupplierOperationsConfiguration(onOpenConfirmDialog)
	let canBeEditSupplier = canBeEditSupplierInformation(indirectPriceQuoteSupplier, userProfile)
	return (
		<>
			<DesktopView
				// bool
				isLoading={isLoading}
				canBeEditSupplier={canBeEditSupplier}
				//object
				indirectPriceQuoteSupplier={indirectPriceQuoteSupplier}
				selectedCompany={selectedCompany}
				isSaving={isSaving}
				supplier={supplier}
				//Function props section
				updateIndirectPriceQuoteItemProperty={updateIndirectPriceQuoteItemProperty}
				updatePriceQuoteWithOutCodeSupplierProperty={updatePriceQuoteWithOutCodeSupplierProperty}
				onOpenCloseDialogOperation={onOpenCloseDialogOperation}
				onGeneratedNewPDFQuoteWithOutPrice={onGeneratedNewPDFQuoteWithOutPrice}
			/>
			{openDialogConfirm.open && (
				<AddCommonItem
					title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
					message={translate(onMessageStatusDialog(openDialogConfirm.status))}
					open={openDialogConfirm.open}
					onClose={onClosedDialog}
					onSaveItem={onChangeStatusFromBackEnd}
					isSaving={isSaving}
					saveButtonTranslate={'common.confirm'}
					isMessage={openDialogConfirm.status !== IndirectPriceQuoteSupplierStatus.CANCELLED ? true : false}
					isTextArea={openDialogConfirm.status === IndirectPriceQuoteSupplierStatus.CANCELLED ? true : false}
				/>
			)}

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>

			{openDialogOperation && (
				<DialogOperation open={openDialogOperation} onClose={onOpenCloseDialogOperation} options={options} />
			)}
		</>
	)
}

const styles = (theme) => ({})

// export default withauthenticatedLayout(Show)
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
