import React from 'react'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done' // Icono para "Send to Supplier"
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Icono para "Quoted"
import CancelIcon from '@material-ui/icons/Cancel' // Icono para "Canceled"
import HelpIcon from '@material-ui/icons/Help' // Icono para "No Answer"
// Estilos personalizados para el Chip
const styles = {
	chip: {
		margin: 4,
	},
}

// Enumerado en JavaScript
const QuoteStatus = {
	InProcess: 1,
	Completed: 2,
	Cancelled: 3,
}

// Componente que retorna un Chip basado en el estado
const StatusChip = ({ status, classes }) => {
	let label, color, icon

	switch (status) {
		case QuoteStatus.InProcess:
			label = 'En proceso'
			color = 'primary' // Azul
			icon = <DoneIcon />
			break
		case QuoteStatus.Completed:
			label = 'Cotizado'
			color = 'secondary' // Rojo
			icon = <CheckCircleIcon />
			break
		case QuoteStatus.Cancelled:
			label = 'Cancelado'
			color = 'default' // Gris
			icon = <CancelIcon />
			break
		default:
			label = 'Desconocido'
			color = 'default' // Gris
			icon = <HelpIcon />
			break
	}

	return (
		<Chip
			label={label}
			// className={classes.chip}
			color={color}
			icon={icon} // Icono añadido
		/>
	)
}

// Exportar componente con estilos
export default withStyles(styles)(StatusChip)
