export const PartNumberFileExtendType = {
    /// <summary>
    /// File extend (.ai, .jpg, .psd)
    /// </summary>
    RequieredPlotArt: 0,

    /// <summary>
    /// File extend (.stps, .dwg, .igs)
    /// </summary>
    RequieredPlot: 1,
}