import React from 'react'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done' // Icono para "Send to Supplier"
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Icono para "Quoted"
import CancelIcon from '@material-ui/icons/Cancel' // Icono para "Canceled"
import HelpIcon from '@material-ui/icons/Help' // Icono para "No Answer"
import { IndirectPriceQuoteSupplierStatus } from '../../../../store/helpers/AppConstants'
// Estilos personalizados para el Chip
const styles = {
	chip: {
		margin: 4,
	},
}



// Componente que retorna un Chip basado en el estado
const StatusChip = ({ status, classes }) => {
	let label, color, icon

	switch (status) {
		case IndirectPriceQuoteSupplierStatus.SEND_TO_SUPPLIER:
			label = 'Enviado a proveedor'
			color = 'primary' // Azul
			icon = <DoneIcon />
			break
		case IndirectPriceQuoteSupplierStatus.QUOTED:
			label = 'Cotizado'
			color = 'success' // Rojo
			icon = <CheckCircleIcon />
			break
		case IndirectPriceQuoteSupplierStatus.CANCELLED:
			label = 'Cancelado'
			color = 'danger' // Gris
			icon = <CancelIcon />
			break
		case IndirectPriceQuoteSupplierStatus.NO_ANSWER:
			label = 'No respondio'
			color = 'warning' // Gris
			icon = <HelpIcon />
			break
		default:
			label = 'Desconocido'
			color = 'warning' // Gris
			icon = <HelpIcon />
			break
	}

	return (
		<Chip
			label={label}
			// className={classes.chip}
			color={color}
			icon={icon} // Icono añadido
		/>
	)
}

// Exportar componente con estilos
export default withStyles(styles)(StatusChip)
