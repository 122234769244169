//import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//Import material ui section
import { withStyles } from '@material-ui/core/styles'
//import components section
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import dataDetail from './dataDetail'
import ShowDesktopView from './components/show/ShowDesktopView'
import { ExportRequestItemType } from '../../store/helpers/ExportRequestHelper'
import HistoryMessagedLateralPanel from '../loadAlert/componentes/show/HistoryMessagedLateralPanel'
//import store section
import {
	getExportRequestByToken,
	uploadFilesExportRequestItem,
	deleteFilesFromItem,
	onSynchronizeSit,
	SetStatusExportRequestItem,
} from '../../store/exportRequest/ExportRequestAction'

const Show = (props) => {
	const dispatch = useDispatch()
	const userProfile = useSelector((state) => state.oidc.user)
	// this is the token from the url
	const { token } = props.match.params
	useEffect(() => {
		dispatch(getExportRequestByToken(token))
	}, [dispatch, token])

	const [exportRequestHistoriesMessages, setExportRequestHistoriesMessages] = useState({ opened: false, items: [] })
	const onOpenPanelHistoryMesages = (items) => {
		setExportRequestHistoriesMessages({ opened: !exportRequestHistoriesMessages.opened, items: items })
	}

	const onUploadFileInItem = (item, files, folderName) => {
		dispatch(uploadFilesExportRequestItem(token, item.token, folderName, files))
	}

	const onDeleteFilesFromItem = (item, folderName, fileName) => {
		dispatch(deleteFilesFromItem(token, item.token, folderName, fileName))
	}

	const onSynchronizeSitInformation = (exportRequestType) => {
		dispatch(onSynchronizeSit(token,exportRequestType))
	}
	// const [shouldDestroyComponent, setShouldDestroyComponent] = useState(false)
	const onSetStatusExportRequestItem = (exportRequestType, status, comments) => {
		return dispatch(SetStatusExportRequestItem(exportRequest.SITFolio, exportRequestType, status, comments)).then(
			() => {
				onSynchronizeSitInformation(exportRequestType)
				setConfirmPasswordConfigModalFromItem({
					opened: !confirmPasswordConfigModalFromItem.opened,
				})
				// setShouldDestroyComponent(true)
			}
		)
	}
	const exportRequest = useSelector((state) => state.exportRequest.get('exportRequestItem').toJS())
	console.log('exportRequest=>>>>>>>>>>>>>>>>>>>', exportRequest)
	const isSavingFile = useSelector((state) => state.exportRequest.get('isSavingFile'))
	const isLoadingItem = useSelector((state) => state.exportRequest.get('isLoadingItem'))
	const isLoadingItemStatus = useSelector((state) => state.exportRequest.get('isLoadingItemStatus'))
	const nationalProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find(
					(x) => x.ExportRequestType === ExportRequestItemType.NationalProvider
			  )
			: null

	const mxProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.MxProvider)
			: null

	const foreignTransportProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find(
					(x) => x.ExportRequestType === ExportRequestItemType.ForeignTransportProvider
			  )
			: null

	const foreignProviderData =
		exportRequest && exportRequest.ExportRequestItems
			? exportRequest.ExportRequestItems.find((x) => x.ExportRequestType === ExportRequestItemType.UsaProvider)
			: null

	const [confirmPasswordConfigModalFromItem, setConfirmPasswordConfigModalFromItem] = useState({
		opened: false,
		item:null
	})

	return (
		<>
			<ShowDesktopView
				exportRequest={exportRequest}
				userProfile={userProfile}
				dataDetail={dataDetail}
				exportRequestItems={exportRequest.ExportRequestItems}
				//object props section
				nationalProviderData={nationalProviderData}
				mxProviderData={mxProviderData}
				foreignTransportProviderData={foreignTransportProviderData}
				usaForeignProviderData={foreignProviderData}
				onUploadFileInItem={onUploadFileInItem}
				onDeleteFilesFromItem={onDeleteFilesFromItem}
				onSynchronizeSit={onSynchronizeSitInformation}
				onSetStatusItem={onSetStatusExportRequestItem}
				isSavingFile={isSavingFile}
				isLoadingItem={isLoadingItem}
				isLoadingItemStatus={isLoadingItemStatus}
				onOpenPanelHistoryMesages={onOpenPanelHistoryMesages}
				confirmPasswordConfigModalFromItem={confirmPasswordConfigModalFromItem}
				setConfirmPasswordConfigModalFromItem={setConfirmPasswordConfigModalFromItem}
				// shouldDestroyComponent={shouldDestroyComponent}
				// setShouldDestroyComponent={setShouldDestroyComponent}
			/>
			{exportRequestHistoriesMessages.opened && (
				<HistoryMessagedLateralPanel
					onCloseButtonClick={() => {
						onOpenPanelHistoryMesages([])
					}}
					loadAlertHistoriesMessages={exportRequestHistoriesMessages.items}
				/>
			)}
		</>
	)
}
const styles = (theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
