/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
/** Material UI import section */
import { Avatar, Card, Grid, Divider, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import AssignmentIcon from '@material-ui/icons/Assignment'
/**Import section components */
/**Import resources section */
import { COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { onGetRequestTypeDescription } from '../../../../store/helpers/indirectPriceQuoteWithoutCodeHelper'
/** Resources import section */
const useStyle = (theme) => ({
	card: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	cardcontent: {
		padding: 0,
		'&:last-child': {
			padding: 0,
		},
	},
	textTitle: {
		fontWeight: 'bold',
	},
})

const HeaderComponent = (props) => {
	const { classes, indirectPriceQuoteWithoutCode } = props

	return (
		<Grid container spacing={16}>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={<Translate id='factoringAffiliation.show.creatorInformation' />}
					/>
					<Divider />
					<CardContent>
						<Grid container spacing={8}>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.createdBy' />
								</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.CreateBy : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.user' />
								</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode
										? indirectPriceQuoteWithoutCode.CreatorUserName
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.emailCreator' />
								</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.CreateEmail : '-'}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader
						className={classes.card}
						avatar={
							<Avatar aria-label='Recipe' className={classes.avatar}>
								<AssignmentIcon />
							</Avatar>
						}
						title={<Translate id='factoringAffiliation.show.supplierInformation' />}
					/>
					<CardContent>
						<Grid container spacing={8}>
							{/* <Grid item xs={4}>
								<Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.supplierInformation' />
								</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.Code : '-'}
								</Typography> */}
							{/* </Grid> */}
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>Tipo de solicitud</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode
										? onGetRequestTypeDescription(indirectPriceQuoteWithoutCode.RequestType)
										: '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>Fecha de envío</Typography>

								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.DateSend : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography className={classes.textTitle}>Fecha de expiración</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.ExpirationDate : '-'}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								{/* <Typography className={classes.textTitle}>
									<Translate id='factoringAffiliation.show.supplierRFC' />
								</Typography>
								<Typography>
									{indirectPriceQuoteWithoutCode ? indirectPriceQuoteWithoutCode.SupplierInFactoring.RFC : '-'}
								</Typography> */}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(HeaderComponent))
