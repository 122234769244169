///Import react section
import React, { useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate, getTranslate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import { Tooltip } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import RateReviewTwoToneIcon from '@material-ui/icons/RateReviewTwoTone'
import Fab from '@material-ui/core/Fab'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import DescriptionIcon from '@material-ui/icons/Description';


import ChangeViewIcon from '@material-ui/icons/ViewCarousel'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import AddIndirectQualificationComponent from './components/index/AddIndirectQualificationComponent'
import RatingSupplierTable from './components/index/RatingSupplierTable'

/**import helper section */
import { converListToArray } from '../../store/helpers/ListHelper'
import { canBeModuleRatingIndirects } from '../../store/helpers/RolesHelper'
import { setToasterOptions } from '../../store/indirectqualifications/IndirectqualificationsActions'
/**Custom hooks index */
import { useIndirectQualificationCustomHookIndex } from './customHooks/UseCustomHookIndex'
import DonwloadReportDialog from '../common/DonwloadReportDialog'
const IndexView = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)
	const {
		indirectsQualifications,
		onAddIndirectQualification,
		indirectsQualificationsByPeriod,
		onDownloadReportRatings,
		onDownloadReportCumulativeQualifications,
	} = useIndirectQualificationCustomHookIndex()

	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const isLoading = useSelector((state) => state.indirectQualifications.get('isLoading'))
	const isSaving = useSelector((state) => state.indirectQualifications.get('isSaving'))
	const indirectsQualificationsCount = useSelector((state) =>
		state.indirectQualifications.get('indirectsQualificationsCount')
	)
	const ratingCount = useSelector((state) => state.indirectQualifications.get('ratingsCount'))
	const toasterMessageConfig = useSelector((state) => state.indirectQualifications.get('toasterConfiguration').toJS())
	const translate = useSelector((state) => getTranslate(state.localize))

	/**Store local this component */
	const [isChangeModeView, setIsChangeModeView] = useState(false)

	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [dateConfigurationModel, setDateConfigurationModelModel] = useState({
		closingDate: null,
		startDate: null,
	})
	const [openModalReport, setOpenModalReport] = useState({open:false})

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (indirectsQualifications) {
			let totalBiddings = isChangeModeView
				? indirectsQualificationsByPeriod.length
				: indirectsQualifications.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBiddings ? totalBiddings : 0}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>
						{isChangeModeView ? (ratingCount ? ratingCount : 0) : indirectsQualificationsCount}
					</span>
					&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (item, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/indirectqualifications/${item.Id}`)
		}
	}

	const redirectToRatingDetail = (ratingId) => {
		props.history.push(`/indirectqualifications/${ratingId}`)
	}

	const onAddIndirectQualificationInBackEnd = (model) => {
		return onAddIndirectQualification(model).then(() => {
			onOpenAndCloseLateralPanel()
		})
	}

	const PanelLateralChildren = useCallback(
		{
			IndexCreateIndirectQualification: (
				<AddIndirectQualificationComponent
					onSaveButtonClick={onAddIndirectQualificationInBackEnd}
					translate={translate}
				/>
			),
		},
		[identifierPanelLateral]
	)

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else if (indirectsQualifications === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<>
					{isChangeModeView === false && (
						<DataTable
							data={indirectsQualifications}
							configuration={TableConfiguration(canBeModuleRatingIndirects(userRolesByCompany))}
							onRenderCellItem={onRenderCellItem}
							onChangeSortCriteria={onChangeSortCriteria}
							onRowClick={onRowClick}
							isIndex
							showFilters
							totalRows={indirectsQualificationsCount}
							sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
							sortAscending={
								queryParams && queryParams.sortDirection
									? queryParams.sortDirection === 'desc'
										? false
										: true
									: false
							}
							page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
							rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
							onChangePage={(newPage, newRowsPerPage) => {
								onChangePage(newPage, newRowsPerPage)
							}}
						/>
					)}
					{isChangeModeView === true && (
						<RatingSupplierTable
							indirectsQualificationsByPeriod={indirectsQualificationsByPeriod}
							isSaving={isSaving}
							dateConfigurationModel={dateConfigurationModel}
							setDateConfigurationModelModel={setDateConfigurationModelModel}
							redirectToRatingDetail={redirectToRatingDetail}
							translate={translate}
							queryParams={queryParams}
							totalRows={ratingCount}
							onChangePage={onChangePage}
							onChangeSortCriteria={onChangeSortCriteria}
						/>
					)}
				</>
			)
		}
	}
	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Name') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Name}
				</Typography>
			)
		}
	}

	/**Delete all filters */
	const deleteAllFilter = () => {
		let queryParams = queryString.parse(location.search)
		queryParams = {}
		history.push({ search: queryString.stringify(queryParams, { encode: false }) })
	}

	const onChangeModeView = () => {
		setIsChangeModeView(!isChangeModeView)
		deleteAllFilter()
	}

	const handleDownloadReportByDateRange=(startDate, endDate) =>{
		onDownloadReportCumulativeQualifications(
			startDate,
			endDate
		)
		setOpenModalReport({...openModalReport, open:false})
	}
	
	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<RateReviewTwoToneIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						{
							<div style={{ marginRight: '5px' }}>
								<SearchInput
									onChangeSearchValue={onChangeSearchValue}
									value={!!queryParams && queryParams.query ? queryParams.query : ''}
								/>
							</div>
						}

						{!isChangeModeView && canBeModuleRatingIndirects(userRolesByCompany) && (
							<Fab
								size='small'
								color='primary'
								onClick={() => {
									onLateralPanelConfig('IndexCreateIndirectQualification')
								}}
							>
								<AddIcon />
							</Fab>
						)}
						{canBeModuleRatingIndirects(userRolesByCompany) && isChangeModeView && (
							<Tooltip title={<Translate id='common.downloadReport' />}>
								<Fab
									size='small'
									color={'secondary'}
									className={classes.categoryIcon}
									onClick={() =>
										onDownloadReportRatings(
											dateConfigurationModel.startDate,
											dateConfigurationModel.closingDate
										)
									}
									disabled={isSaving || indirectsQualificationsByPeriod.length === 0}
								>
									<DownloadIcon />
								</Fab>
							</Tooltip>
						)}
						{canBeModuleRatingIndirects(userRolesByCompany) && (
							<Tooltip title={<Translate id='indirectqualifications.changeView' />}>
								<Fab
									size='small'
									color={isChangeModeView ? 'primary' : 'secondary'}
									className={classes.categoryIcon}
									onClick={() => onChangeModeView()}
								>
									<ChangeViewIcon />
								</Fab>
							</Tooltip>
						)}
						<Tooltip title={<Translate id='indirectqualifications.modalTitle' />}>
							<Fab
								size='small'
								color={'primary'}
								className={classes.categoryIcon}
								onClick={()=>setOpenModalReport({...openModalReport, open:true})}
								isSaving={isSaving}
								disabled={isSaving}
							>
								<DescriptionIcon />
							</Fab>
						</Tooltip>
						
					</div>
				}
				title={<Typography variant='button'>{<Translate id='indirectqualifications.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>
			<CardContent>{getCardContent()}</CardContent>

			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					dispatch(
						setToasterOptions({
							showToaster: false,
							toasterMessage: '',
							toasterVariant: '',
						})
					)
				}}
			/>
			<DonwloadReportDialog
				title={<Translate id='indirectqualifications.modalTitle' />}
				open={openModalReport.open}
				onClose={()=>setOpenModalReport({...openModalReport, open:false})}
				isSaving={false}
				onDonwloadItems={handleDownloadReportByDateRange}
			/>
			<LateralPanelComponent />
		</Card>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='indirectqualifications.table.status' />,
			dataSource: 'Status',
		},
		{
			header: <Translate id='indirectqualifications.table.folio' />,
			dataSource: 'FormatedFolio',
			isSortable: true,
		},
		{
			header: <Translate id='indirectqualifications.table.creationDate' />,
			dataSource: 'CreationDate',
			isSortable: true,
		},
		{
			header: <Translate id='indirectqualifications.table.evaluationDate' />,
			dataSource: 'EvaluationDateDescription',
			isSortable: true,
		},
		{
			header: <Translate id='indirectqualifications.table.name' />,
			dataSource: 'Name',
			isSortable: true,
		},
		{
			header: <Translate id='indirectqualifications.table.evaluationType' />,
			dataSource: 'EvaluationTypeDescription',
			isSortable: true,
		},
		{
			header: <Translate id='indirectqualifications.table.createdName' />,
			dataSource: 'CreatedName',
			isSortable: true,
		},
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
