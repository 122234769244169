///Import react section
import React, { useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//Import mui section
import { Avatar, Card, CardContent, CardHeader, Typography } from '@material-ui/core'

//import components section
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import SearchInput from '../common/SearchInput/SearchInput'
import DataTable from '../common/DataTable'
/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Notifications } from '@material-ui/icons'
import {
	loadIndirectPriceQuoteWithoutCodeCount,
	loadIndirectPriceQuoteWithoutCodeFromBackEnd,
	SET_INDIRECT_PRICE_QUOTE_PAGINATION,
	SET_INDIRECT_PRICE_QUOTE_QUERY,
} from '../../store/indirectPriceQuoteWithoutCode/IndirectPriceQuoteWithoutCodeAction'
import QuoteChipStatus from './components/index/QuoteChipStatus'
import SupplierChipStatus from './components/show/SupplierChipStatus'
import { onGetRequestTypeDescription } from '../../store/helpers/indirectPriceQuoteWithoutCodeHelper'

const Index = (props) => {
	const dispatch = useDispatch()
	let history = useHistory()
	const indirectPriceQuoteWithoutCodeList = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteWithoutCode')
			? state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteWithoutCode').toJS()
			: []
	)
	const page = useSelector((state) => state.indirectPriceQuoteWithoutCode.get('page'))
	const userProfile = useSelector((state) => state.oidc.user)
	const allRegisterCount = useSelector((state) =>
		state.indirectPriceQuoteWithoutCode.get('indirectPriceQuoteWithoutCodeCount')
	)
	const rowsPerPage = useSelector((state) => state.indirectPriceQuoteWithoutCode.get('rowsPerPage'))

	const sortCriteria = useSelector((state) => state.indirectPriceQuoteWithoutCode.get('sortCriteria').toJS())
	const query = useSelector((state) => state.indirectPriceQuoteWithoutCode.get('query'))
	const onChangeSearchValue = (query) => {
		dispatch({
			type: SET_INDIRECT_PRICE_QUOTE_QUERY,
			query: query,
		})
	}
	let isInternalUser = userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : ''
	useEffect(() => {
		dispatch(loadIndirectPriceQuoteWithoutCodeCount())
		dispatch(
			loadIndirectPriceQuoteWithoutCodeFromBackEnd(
				query,
				sortCriteria.by,
				sortCriteria.ascending,
				page,
				rowsPerPage
			)
		)
	}, [dispatch, query, sortCriteria.by, sortCriteria.ascending, page, rowsPerPage])

	const getCounterMessage = () => {
		if (indirectPriceQuoteWithoutCodeList) {
			let totalshippingOrders = indirectPriceQuoteWithoutCodeList.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span>{totalshippingOrders}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span>{allRegisterCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const onChangePage = (page, rowsPerPage) => {
		dispatch({
			type: SET_INDIRECT_PRICE_QUOTE_PAGINATION,
			page,
			rowsPerPage,
		})
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'statusDescription') {
			return isInternalUser ? (
				<QuoteChipStatus status={item.Status} />
			) : (
				<SupplierChipStatus status={item.Status} />
			)
		}
		if (dataSource === 'RequestType') {
			return onGetRequestTypeDescription(item.RequestType)
		}
	}

	const onRedirectToDetail = (item) => {
		if (isInternalUser) history.push(`/indirectPriceQuoteWithoutCode/${item.token}`)
		else history.push(`/indirectPriceQuoteWithoutCode/${item.SupplierToken}/show`)
	}
	return (
		<Card style={{ marginTop: '20px' }}>
			<CardHeader
				avatar={
					<Avatar>
						<Notifications />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<SearchInput onChangeSearchValue={onChangeSearchValue} value={query} />
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.indirectPriceQuoteWithoutCode' />{' '}
					</Typography>
				}
				subheader={getCounterMessage()}
			/>
			<CardContent>
				{
					<DataTable
						data={indirectPriceQuoteWithoutCodeList}
						configuration={TableConfiguration(isInternalUser)}
						// sortBy={sortCriteria.by}
						sortAscending={sortCriteria.ascending}
						// onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={(item) => onRedirectToDetail(item)}
						showFilters
						isIndex
						totalRows={allRegisterCount}
						onRenderCellItem={onRenderCellItem}
						page={page}
						rowsPerPage={rowsPerPage}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				}
			</CardContent>
		</Card>
	)
}

function TableConfiguration(isInternalUser) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'statusDescription',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'CreationDate',
		},
		{
			header: <Translate id='loadIndirectPriceQuoteWithoutCode.index.folio' />,
			dataSource: 'FolioSIT',
			isSortable: true,
		},
		{
			header: <Translate id='loadIndirectPriceQuoteWithoutCode.index.requestType' />,
			dataSource: 'RequestType',
			isSortable: true,
		},
		{
			header: <Translate id='loadIndirectPriceQuoteWithoutCode.index.expirationDate' />,
			dataSource: 'ExpirationDate',
			isSortable: true,
		},
	]
	if (!isInternalUser) {
		columns.push({
			header: 'Código proveedor',
			dataSource: 'SupplierCode',
			isSortable: true,
		})
		columns.push({
			header: 'Nombre proveedor',
			dataSource: 'SupplierName',
			isSortable: true,
		})
	}

	return { columns }
}
const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
