import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

/**Import MaterialUi section */
import { Grid, Avatar, Typography, Button, TextField, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FileCopy'

/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
import { GetCuponFerryMotiveOptions } from '../../../../store/helpers/SelectOptions'
import {  editCuponFerry } from '../../../../store/consignmentNote/consignmentNoteActions'
import DatePicker from '../../../common/DateTimePicker/DatePicker'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import Toaster from '../../../common/Toaster'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'

const EditFerryCoupons = (props) => {
	const dispatch = useDispatch()

	/**props section */
	const { onClose, isSaving, classes } = props
	//Object props
	const { cuponFerryData } = props
	//bool
	const [isLoading, setIsLoding] = useState(true)

	const [ferryCuponModel, setFerryCuponModel] = useState({
		DriverPhoneNumber: '',
		DriverName: '',
		PlatesBox: '',
		TransportType: null,
		Description: '',
		CrossingPoint: 'Topolobampo',
		DropOffPoint: 'La paz',
		CrossingDate: null,
		CrossingType: null,
		IsRouteChange: false,
	})

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
	})

	const onConfirmPassword = () => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
		})
	}


	const canBeSaved = () => {
		if (IsNullOrEmpty(ferryCuponModel.DriverPhoneNumber)) return false
		if (IsNullOrEmpty(ferryCuponModel.DriverName)) return false
		if (IsNullOrEmpty(ferryCuponModel.Description)) return false
		if (IsNullOrEmpty(ferryCuponModel.CrossingPoint)) return false
		if (IsNullOrEmpty(ferryCuponModel.DropOffPoint)) return false
		if (IsNullOrEmpty(ferryCuponModel.CrossingType)) return false
		if (ferryCuponModel.CrossingType === null) return false
		if (ferryCuponModel.CrossingDate === null) return false
		if (ferryCuponModel.CrossingDate === '') return false
		if (ferryCuponModel.TransportType === null) return false

		// if (attachments.length === 0) return false
		// else return true
		return true
	}

	const onChangeDate = (event) => {
		let objectProperty = {}
		objectProperty['CrossingDate'] = event
		setFerryCuponModel({
			...ferryCuponModel,
			CrossingDate: event,
		})
	}

	useEffect(() => {
		setIsLoding(true)
		if (cuponFerryData) {
			setFerryCuponModel({
				...ferryCuponModel,
				DriverPhoneNumber: cuponFerryData ? cuponFerryData.DriverPhoneNumber : '',
				DriverName: cuponFerryData ? cuponFerryData.DriverName : '',
				PlatesBox: cuponFerryData ? cuponFerryData.PlatesBox : '',
				TransportType: cuponFerryData.TransportType ? cuponFerryData.TransportType : '',
				Description: cuponFerryData.TransportType ? cuponFerryData.TransportType.Description : '',
				CrossingPoint: cuponFerryData ? cuponFerryData.CrossingPoint : '',
				DropOffPoint: cuponFerryData ? cuponFerryData.DropOffPoint : '',
				CrossingDate: cuponFerryData ? cuponFerryData.CrossingDate : '',
				IsRouteChange: cuponFerryData ? cuponFerryData.IsRouteChange : '',
				CrossingType: cuponFerryData.CrossingType,
			})
			setIsLoding(false)
		}
	}, [cuponFerryData])

	const onChangeTexfield = (event) => {
		let { name, value } = event.target
		setFerryCuponModel({
			...ferryCuponModel,
			[name]: value,
		})
	}

	const getErrorInCreateFerryCupon = (response) => {
		if (!IsNullOrEmpty(response.data) && typeof response.data === 'string') {
			if (response.data.includes('invalidTransporCodeExist'))
				return 'consignmentNote.errors.invalidTransporCodeExist'
			else if (response.data.includes('alreadyCuponType')) return 'consignmentNote.errors.alreadyCuponType'
			else if (response.data.includes('notExistCuponOutbound'))
				return 'consignmentNote.errors.notExistCuponOutbound'
			else return 'common.errorToSave'
		} else {
			return 'common.errorToSave'
		}
	}

	const oneditCuponFerry = () => {
		return dispatch(editCuponFerry(ferryCuponModel, cuponFerryData.id))
			.then((_) => {
				onConfirmPassword()
				onClose()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInCreateFerryCupon(response),
					toasterVariant: 'warning',
				})
			})
	}

	const onSelectorPropertyChange = (option) => {
		if (option.value === 1) {
			setFerryCuponModel({
				...ferryCuponModel,
				CrossingType: option.value,
				CrossingPoint: 'Topolobampo',
				DropOffPoint: 'La paz',
			})
		} else {
			setFerryCuponModel({
				...ferryCuponModel,
				CrossingType: option.value,
				CrossingPoint: 'La paz',
				DropOffPoint: 'Topolobampo',
			})
		}
	}

	const onChangeCheckBox = (event) => {
		setFerryCuponModel({
			...ferryCuponModel,
			IsRouteChange: event.target.checked,
		})
	}

	return (
		<LateralPanel onClose={onClose}>
			<PanelHeader
				// titlePanel={props.title}
				titlePanel={'Editar cupones de Ferry'}
				icon={
					<Avatar className={classes.avatar}>
						<FindInPage />
					</Avatar>
				}
				onCloseButtonClick={onClose}
				isIconMaterial={true}
			/>
			<div>
				{isLoading ? (
					'Cargando información:'
				) : (
					<>
						<div className={classes.containerInformation}>
							<Grid container>
								<Grid item xs={12}>
									<Typography color='primary'>Información general</Typography>
								</Grid>

								<Grid item xs={12}>
									<Typography color='primary'>Fecha de cruce</Typography>
									<DatePicker
										// opens={'left'}
										onChangeRange={(event) => onChangeDate(event)}
										// disabled={!canBeEditSupplier}
										startDate={
											ferryCuponModel.CrossingDate ? moment(ferryCuponModel.CrossingDate) : ''
										}
										minDate={moment()}
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography color='primary'>Tipo de cruce</Typography>
									{/* <TextField
								id='outlined-dense'
								label='Motivo de la solicitud'
								//   className={classNames(classes.textField, classes.dense)}
								margin='dense'
								variant='outlined'
								fullWidth
								disabled
							/> */}
									<Select
										placeholder={'Tipo de cruce'}
										options={GetCuponFerryMotiveOptions()}
										fullWidth
										value={GetCuponFerryMotiveOptions().find((x) =>
											x.value === ferryCuponModel.CrossingType
										)}
										onChange={onSelectorPropertyChange}
										// defaultValue={getSelectedValue(GetBooleanOptions(), result)}
										styles={{
											menu: (base) => ({ ...base, zIndex: 99999 }),
											menuList: (base) => ({ ...base, paddingTop: 0 }),
											menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
											control: (base) => ({
												...base,
												height: 44,
												minHeight: 44,
											}),
										}}
										// isDisabled={isSaving || !isDisabled}
										menuPortalTarget={document.parentNode}
										menuPlacement={'bottom'}
									/>
								</Grid>
								<Grid item xs={12}>
									{/* <Typography color='primary'>Nombre del operador</Typography> */}
									<TextField
										id='outlined-dense'
										label='Nombre del operador'
										//   className={classNames(classes.textField, classes.dense)}
										margin='dense'
										variant='outlined'
										fullWidth
										name='DriverName'
										value={ferryCuponModel ? ferryCuponModel.DriverName : ''}
										onChange={onChangeTexfield}
									/>
								</Grid>
								<Grid item xs={12}>
									{/* <Typography color='primary'>Celular del operador</Typography> */}
									<TextField
										id='outlined-dense'
										label='Celular del operador'
										//   className={classNames(classes.textField, classes.dense)}
										margin='dense'
										variant='outlined'
										fullWidth
										name='DriverPhoneNumber'
										value={ferryCuponModel ? ferryCuponModel.DriverPhoneNumber : ''}
										onChange={onChangeTexfield}
									/>
								</Grid>

								<Grid item xs={12}>
									{/* <Typography color='primary'>Tipo de unidad</Typography> */}
									<TextField
										id='outlined-dense'
										label='Tipo de unidad'
										//   className={classNames(classes.textField, classes.dense)}
										margin='dense'
										variant='outlined'
										fullWidth
										name='Description'
										value={ferryCuponModel ? ferryCuponModel.Description : ''}
										disabled
										onChange={onChangeTexfield}
									/>
								</Grid>

								<Grid item xs={12}>
									<FormGroup row>
										<FormControlLabel
											control={
												<Checkbox
													checked={ferryCuponModel.IsRouteChange}
													onChange={onChangeCheckBox}
													// value='checkedA'
													disabled
												/>
											}
											label='Si requieres un cambio de Ruta, selecciona esta opción, pero pasará por un proceso de autorización.'
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									{/* <Typography color='primary'>Ruta</Typography> */}
									<TextField
										id='outlined-dense'
										label='Punto de cruce'
										//   className={classNames(classes.textField, classes.dense)}
										margin='dense'
										variant='outlined'
										fullWidth
										name='CrossingPoint'
										value={ferryCuponModel ? ferryCuponModel.CrossingPoint : ''}
										onChange={onChangeTexfield}
									/>
								</Grid>
								<Grid item xs={12}>
									{/* <Typography color='primary'>Ruta</Typography> */}
									<TextField
										id='outlined-dense'
										label='Punto de desembarque'
										//   className={classNames(classes.textField, classes.dense)}
										margin='dense'
										variant='outlined'
										fullWidth
										name='DropOffPoint'
										value={ferryCuponModel ? ferryCuponModel.DropOffPoint : ''}
										onChange={onChangeTexfield}
									/>
								</Grid>
							</Grid>
						</div>
						<Grid container>
							<Grid item xs={12} className={classes.containerBtn}>
								<Button
									data-cy='btn-save'
									type='button'
									color='primary'
									variant='outlined'
									className={classes.saveButton}
									disabled={!canBeSaved() || isSaving}
									onClick={onConfirmPassword}
								>
									{<Translate id='common.save' />}
								</Button>
								<Button
									data-cy='btn-cancel'
									type='button'
									color='secondary'
									variant='outlined'
									className={classes.cancelButton}
									onClick={onClose}
								>
									{<Translate id='common.cancel' />}
								</Button>
							</Grid>
						</Grid>
					</>
				)}
				<Toaster
					message={<Translate id={toasterMessageConfig.toasterMessage} />}
					open={toasterMessageConfig.showToaster}
					variant={toasterMessageConfig.toasterVariant}
					onClose={() => {
						setToasterMessageConfig({
							showToaster: false,
							toasterMessage: '',
							toasterVariant: '',
						})
					}}
				/>
				{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
					<ConfirmPasswordDialog
						open={confirmPasswordConfigModal.opened}
						title={<Translate id='common.confirmOperation' />}
						message1={
							'Se enviará la información al proveedor de cupones para su seguimiento. Una vez enviada la información ya no se pueden realizar cambios'
						}
						message2={<Translate id='common.enterPassword' />}
						onClickButtonConfirm={oneditCuponFerry}
						onClose={onConfirmPassword}
						isSaving={isSaving}
						isVisibleComments={false}
						commentRequired={false}
					/>
				)}
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	textTitle: {
		textAlign: 'center',
		paddingBottom: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	saveButton: {
		bottom: 0,
		margin: 2,
	},
	cancelButton: {
		color: theme.palette.error.main,
		bottom: 0,
		margin: 2,
	},
	errorsContainer: {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 10,
		marginTop: theme.spacing.unit,
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
	},
})

EditFerryCoupons.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onSave: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
	/**
	 * Validate if template is correctly
	 */
	onClose: propTypes.func,
}

EditFerryCoupons.defaultProps = {
	onSave: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onClose: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(EditFerryCoupons))
