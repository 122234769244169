import { fromJS, Map } from 'immutable'

import * as memoActions from './MemoActions'

/**
 * memoActions initial state
 */
export const memosInitialState = fromJS({
	isLoadingMemos: true,
	memos: null,
	isLoadingMemo: false,
	memo: null,
	sortCriteria: {
		by: 'creation_date',
		ascending: false,
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	memosCount: null,
	isUpdatingPlaneName: false,
	isSaving: false,
	memoInformation: null,
})

export default function memosReducer(state = memosInitialState, action) {
	switch (action.type) {
		case memoActions.START_LOADING_MEMOS: {
			return state.merge({
				isLoadingMemos: action.isLoading,
			})
		}
		case memoActions.SET_MEMOS_LIST: {
			let memosLists = fromJS(action.memos)
			return state.merge({
				memos: memosLists,
				isLoadingMemos: false,
			})
		}
		case memoActions.IS_LOADING_MEMO: {
			return state.merge({
				isLoadingMemo: action.isLoadingMemo,
				memoInformation: null,
				memo: null,
			})
		}
		case memoActions.SET_MEMO: {
			return state.merge({
				isLoadingMemo: false,
				isSaving: false,
				memoInformation: fromJS(action.memo),
			})
		}
		case memoActions.IS_SAVING_MEMO: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case memoActions.SET_MEMO_ITEM_SELECTED: {
			let items = state
				.get('memo')
				.get('details')
				.map((memoItem) => {
					if (memoItem.get('token') === action.memoItemToken) {
						return memoItem.set('is_selected', true)
					} else {
						return memoItem.set('is_selected', false)
					}
				})
			let memo = state.get('memo').set('details', items)
			return state.merge({
				memo,
			})
		}
		case memoActions.CHANGE_MEMOS_SORT_CRITERIA: {
			return state.merge({
				sortCriteria: action.sortCriteria,
			})
		}
		case memoActions.CHANGE_MEMOS_QUERY: {
			return state.merge({
				searchQuery: action.query,
				page: 0,
				rowsPerPage: state.rowsPerPage,
			})
		}
		case memoActions.CHANGE_MEMOS_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case memoActions.SET_MEMOS_COUNT: {
			return state.merge({
				memosCount: action.count,
			})
		}
		case memoActions.GET_INFORMATION_MEMO: {
			return state.merge({
				isLoadingMemo: false,
				memo: fromJS(action.memo),
			})
		}
		case memoActions.IS_UPDATING_PLANE_NAME: {
			return state.merge({
				isUpdatingPlaneName: action.isUploading,
			})
		}
		case memoActions.UPDATE_DETAIL_SCHEMATIC: {
			let items = state
				.get('memo')
				.get('details')
				.map((memoItem) => {
					if (memoItem.get('token') === action.detailPlane.token) {
						return memoItem.set('plot_name', action.detailPlane.plot_name)
					} else {
						return memoItem
					}
				})
			let memo = state.get('memo').set('details', items)
			return state.merge({
				memo,
			})
		}
		case memoActions.IS_DELETE_DETAIL_SCHEMATIC: {
			return state.merge({
				isDeletingPlaneName: action.isDeleting,
			})
		}
		case memoActions.DELETE_DETAIL_SCHEMATIC: {
			return state.updateIn(['memo', 'details'], (allDetails) => {
				let index = allDetails.findIndex((item) => {
					return item.get('token') === action.deletedToken
				})
				let detailToChange = allDetails.filter((detail) => detail.get('token') === action.deletedToken)
				detailToChange.set('plot_name', '')
				return allDetails.setIn([index], detailToChange).set('isDeleting', false)
			})
		}
		case memoActions.REMOVE_SUPPLIER_FROM_MEMO: {
			return state.updateIn(['memo', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.memoItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})
				let updatedQuoteList = detailToChange.get('quotes').delete(quoteIndex)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case memoActions.ADD_SUPPLIER_FROM_MEMO: {
			let quoteToAdd = Map(action.quote)
			return state.updateIn(['memo', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.memoItemToken
				})
				let detailToChange = allDetails.get(index)
				let updatedQuoteList = detailToChange.get('quotes').push(quoteToAdd)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case memoActions.ADD_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO: {
			return state.updateIn(['memo', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.memoItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_sample_requiered', true)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case memoActions.REMOVE_SAMPLE_REQUEST_SUPPLIER_FROM_MEMO: {
			return state.updateIn(['memo', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.memoItemToken
				})
				let detailToChange = allDetails.get(index)
				let quoteIndex = detailToChange.get('quotes').findIndex((quoteMap) => {
					return quoteMap.get('supplier_token') === action.supplierToken
				})

				let updatedQuoteList = detailToChange.get('quotes')
				let updateQuote = updatedQuoteList.get(quoteIndex)
				updateQuote = updateQuote.set('is_sample_requiered', false)

				updatedQuoteList = updatedQuoteList.setIn([quoteIndex], updateQuote)
				let updatedDetail = detailToChange.set('quotes', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case memoActions.SEND_QUOTES_REQUEST_MEMO: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case memoActions.FINALIZE_MEMO: {
			let memmoUpdate = state.get('memoInformation')
			memmoUpdate = memmoUpdate.set('is_closed', true)
			return state.merge({
				isSaving: false,
				memoInformation: memmoUpdate,
			})
		}
		case memoActions.UPDATE_COMMENTS_MEMO: {
			let items = state
				.get('memo')
				.get('details')
				.map((item) => {
					if (item.get('token') === action.partNumberDetail.token) {
						return item
							.set('comments', action.partNumberDetail.comments)
							.set('requiered_plot_art', action.partNumberDetail.requiered_plot_art)
							.set('requiered_plot', action.partNumberDetail.requiered_plot)
					} else {
						return item
					}
				})
			let memo = state.get('memo').set('details', items)
			return state.merge({
				memo,
			})
		}
		case memoActions.ADD_FILE_MEMO: {
			let detailsList = state.getIn(['memo', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['memo', 'details', indexDetail, 'attachments']).toJS()
			let filesToAdd = action.files.filter((newFile) => {
				return !oldList.find((uploadedFile) => {
					return uploadedFile.name === newFile.name
				})
			})
			let newList = [...oldList, ...filesToAdd]
			return state.setIn(['memo', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case memoActions.REMOVE_FILE_MEMO: {
			let detailsList = state.getIn(['memo', 'details']).toJS()
			let indexDetail = detailsList.findIndex((detail) => {
				return detail.token === action.tokenPartNumber
			})
			let oldList = state.getIn(['memo', 'details', indexDetail, 'attachments']).toJS()
			let newList = oldList.filter((file) => {
				return file.name !== action.fileName
			})
			return state.setIn(['memo', 'details', indexDetail, 'attachments'], fromJS(newList))
		}
		case memoActions.MARK_QUOTE_DETAIL_ITEM_AS_BEST_OPTION: {
			return state.updateIn(['memoInformation', 'details'], (allDetails) => {
				let index = allDetails.findIndex((detail) => {
					return detail.get('token') === action.detail.token
				})
				let detailToChange = allDetails.get(index)
				let updatedQuoteList = detailToChange.get('quote_details').map((item) => {
					if (item.get('token') === action.quoteItemToken) {
						return item.set('is_best_option', true)
					} else {
						return item.set('is_best_option', false)
					}
				})
				let updatedDetail = detailToChange.set('quote_details', updatedQuoteList)
				return allDetails.setIn([index], updatedDetail)
			})
		}
		case memoActions.SET_REACTIVE_QUOTE_IN_MEMO: {
			return state.updateIn(['memoInformation', 'suppliers'], (allSuppliers) => {
				let index = allSuppliers.findIndex((supplier) => {
					return supplier.get('supplier_token') === action.quote.supplier_token
				})
				let quoteToChange = allSuppliers.get(index)
				let updatedQuoteList = quoteToChange.get('quotes').map((item) => {
					if (item.get('token') === action.quote.token) {
						return item
							.set('status', action.quote.actual_operation_description)
							.set('actual_operation', action.quote.actual_operation)
					} else {
						return item
					}
				})
				let updatedDetail = quoteToChange.set('quotes', updatedQuoteList)
				return allSuppliers.setIn([index], updatedDetail)
			})
		}
		default:
			return state
	}
}
