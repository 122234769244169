import axios from 'axios'

import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'
import moment from 'moment'

export const IS_LOADING_TRANSPORT_TENDER_INDEX = 'IS_LOADING_TRANSPORT_TENDER_INDEX'
export const SET_TRANSPORT_TENDER_LIST = 'SET_TRANSPORT_TENDER_LIST'
export const IS_SAVING_TRANSPORT_TENDER_LIST = 'IS_SAVING__TENDER_LIST'
export const SET_TRANSPORT_TENDER_DETAIL = ' SET_TRANSPORT_TENDER_DETAIL'
export const SET_TRANSPORT_TENDER_WINNER = ' SET_TRANSPORT_TENDER_WINNER'
export const SET_TRANSPORT_CATALOG = 'SET_TRANSPORT_CATALOG '
export const UPDATE_TRANSPORT_CATALOG = 'UPDATE_TRANSPORT_CATALOG '
export const IS_LOADING_TRANSPORT_CARALOG = 'IS_LOADING_TRANSPORT_CARALOG'
export const SET_TRANSPORT_TENDER_UPDATE = 'SET_TRANSPORT_TENDER_UPDATE'
export const IS_LOADING_REPORT = 'IS_LOADING_REPORT'
//Destinationss
export const SET_DESTINATIONS = 'SET_DESTINATIONS'
export const DELETE_DESTINATION = 'DELETE_DESTINATION'
export const SET_CARRIES = 'SET_CARRIES'
export const SET_DELIVERY = 'SET_DELIVERY'
export const DELETE_DELIVERY = 'DELETE_DELIVERY'
export const SET_MATERIALS = 'SET_MATERIALS'
export const DELETE_MATERIAL = 'DELETE_MATERIAL'
export const SET_STATUS = 'SET_STATUS'
export const UPDATE_CARRIER = 'UPDATE_CARRIER'
export const SET_CARRIER_INFORMATION = 'SET_CARRIER_INFORMATION'
export const ERROR_TRANSPORT_TENDER_FILE = 'ERROR_TRANSPORT_TENDER_FILE'
export const IS_LOADING = 'IS_LOADING'
export const UPDATE_CHOOSE_WINNING = 'UPDATE_CHOOSE_WINNING'
export const SET_MATERIAL_LOADING_SCHEDULE = 'SET_MATERIAL_LOADING_SCHEDULE'
export const SET_TIME_OPTIONS = 'SET_TIME_OPTIONS'
export const IS_LOADING_DOWNLOAD_CARRIER_LOG = 'IS_LOADING_DOWNLOAD_CARRIER_LOG'
export const ERROR_TRANSPORT_ZONE_FILE = 'ERROR_TRANSPORT_ZONE_FILE'
export const SET_TRANSPORT_ZONE_LIST = 'SET_TRANSPORT_ZONE_LIST'
export const ERROR_TRANSPORT_TYPE_FILE = 'ERROR_TRANSPORT_TYPE_FILE'
export const ERROR_TARIFF_SAP_FILE = 'ERROR_TARIFF_SAP_FILE'
export const ERROR_REQUIREMENT_CARRIER_FILE = 'ERROR_REQUIREMENT_CARRIER_FILE'
export const ERROR_WINE_CELLAR_FILE = 'ERROR_WINE_CELLAR_FILE'
export const ERROR_CLIENT_FILE = 'ERROR_CLIENT_FILE'
export const SET_MATERIAL_FROM_SAP = 'SET_DESCRIPTION_FROM_SAP'
export const SET_WINNERS_TRANSPORT_TENDERS = 'SET_WINNERS_TRANSPORT_TENDERS'
export const SET_IS_LOADING_WINNERS = 'SET_IS_LOADING_WINNERS'
export const ERROR_MANEUVERS_FILE = 'ERROR_MANEUVERS_FILE'

function isLoading(isLoadingIndex) {
	return {
		type: IS_LOADING_TRANSPORT_TENDER_INDEX,
		isLoadingIndex,
	}
}

function setTransportTenderList(transportTender) {
	return {
		type: SET_TRANSPORT_TENDER_LIST,
		transportTender,
	}
}

function setMaterialLoadingInSchedule(eventsData) {
	return {
		type: SET_MATERIAL_LOADING_SCHEDULE,
		eventsData,
	}
}

function setTransportTenderDetail(detail) {
	return {
		type: SET_TRANSPORT_TENDER_DETAIL,
		detail,
	}
}

function setTransportTenderWinner(detailWinner) {
	return {
		type: SET_TRANSPORT_TENDER_WINNER,
		detailWinner,
	}
}



function isSavingTransportTender(isSaving) {
	return {
		type: IS_SAVING_TRANSPORT_TENDER_LIST,
		isSaving,
	}
}
function isLoadingReport(isSaving) {
	return {
		type: IS_LOADING_REPORT,
		isSaving,
	}
}
function setCatalogs(catalog, nameCatalog) {
	return {
		type: SET_TRANSPORT_CATALOG,
		catalog,
		nameCatalog,
	}
}
function updateCatalogs(catalog, nameCatalog) {
	return {
		type: UPDATE_TRANSPORT_CATALOG,
		catalog,
		nameCatalog,
	}
}
function isLoadingCatalog(isLoadingIndex) {
	return {
		type: IS_LOADING_TRANSPORT_CARALOG,
		isLoadingIndex,
	}
}

function setCarries(carries) {
	return {
		type: SET_CARRIES,
		carries,
	}
}
function setStatus(trasport) {
	return {
		type: SET_STATUS,
		trasport,
	}
}
function setUpdateTranportTender(trasport) {
	return {
		type: SET_TRANSPORT_TENDER_UPDATE,
		trasport,
	}
}

function setCarrierInformation(information) {
	return {
		type: SET_CARRIER_INFORMATION,
		information,
	}
}
function updateChooseWinning(proposal) {
	return {
		type: UPDATE_CHOOSE_WINNING,
		proposal,
	}
}
export function setErrorsTranportTender(errors) {
	return {
		type: ERROR_TRANSPORT_TENDER_FILE,
		errors,
	}
}
function isLoadingName(nameIsloading, valueIsloading) {
	return {
		type: IS_LOADING,
		nameIsloading,
		valueIsloading,
	}
}
function setTimeOptions(timeOptions) {
	return {
		type: SET_TIME_OPTIONS,
		timeOptions,
	}
}
function isLoadingDonwloadCarrierLog(isLoadingDownloadCarrierLog) {
	return {
		type: IS_LOADING_DOWNLOAD_CARRIER_LOG,
		isLoadingDownloadCarrierLog,
	}
}
export function setTransportZoneList(transportZone) {
	return {
		type: SET_TRANSPORT_ZONE_LIST,
		transportZone,
	}
}
export function setErrorsTranportZone(errors) {
	return {
		type: ERROR_TRANSPORT_ZONE_FILE,
		errors,
	}
}
export function setErrorsTranportType(errors) {
	return {
		type: ERROR_TRANSPORT_TYPE_FILE,
		errors,
	}
}
export function setErrorsTariffSAP(errors) {
	return {
		type: ERROR_TARIFF_SAP_FILE,
		errors,
	}
}
export function setErrorsRequirementsCarrier(errors) {
	return {
		type: ERROR_REQUIREMENT_CARRIER_FILE,
		errors,
	}
}
export function setErrorsWineCellar(errors) {
	return {
		type: ERROR_WINE_CELLAR_FILE,
		errors,
	}
}
export function setErrorsClients(errors) {
	return {
		type: ERROR_CLIENT_FILE,
		errors,
	}
}

export function setErrorsManeuvers(errors) {
	return {
		type: ERROR_MANEUVERS_FILE,
		errors,
	}
}
function setMaterialFromSAP(material) {
	return {
		type: SET_MATERIAL_FROM_SAP,
		material,
	}
}
function setWinnerTransportTender(winners) {
	return {
		type: SET_WINNERS_TRANSPORT_TENDERS,
		winners,
	}
}
function setIsLoadingWinners(isLoading) {
	return {
		type: SET_IS_LOADING_WINNERS,
		isLoading,
	}
}
export function addTransportTender(transportTender) {
	return function (dispatch, getState) {
		dispatch(isSavingTransportTender(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let selectedCompany = getState().profile.get('selectedCompany')

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/add`
		let data = {
			ServiceDescription: transportTender.serviceDescription,
			ShipmentId: transportTender.shipmentId,
			DateServiceRequest: transportTender.dateServiceRequest,
			startTime: transportTender.startTime,
			endTime: transportTender.endTime,
			Status: transportTender.status,
			UserName: transportTender.programmer.user_name,
			Name: transportTender.programmer.name,
			Email: transportTender.programmer.user_email,
			SocietyCode: selectedCompany.get('partnership_code'),
			WineCellarId: transportTender.wineCellar ? transportTender.wineCellar.id : '',
			EndTime: transportTender.endTime,
			StartTime: transportTender.startTime,
			Applicationdate: transportTender.applicationDate,
			TypeShipmentId: transportTender.typeShipments.Id,
			TenderEndTime: transportTender.tenderEndTime,
			ShowBaseRate: transportTender.showBaseRate,
			OpenRateTender: transportTender.openRateTender,
			TenderEndDate: transportTender.tenderEndDate,
			FolioLetter: transportTender.folioLetter,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderList(serverResponse.data))

					dispatch(isSavingTransportTender(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingTransportTender(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function updateTransportTender(transportTenderId, transportTender) {
	return function (dispatch, getState) {
		dispatch(isSavingTransportTender(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let selectedCompany = getState().profile.get('selectedCompany')

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/update`
		let data = {
			ServiceDescription: transportTender.serviceDescription,
			ShipmentId: transportTender.shipmentId,
			DateServiceRequest: transportTender.dateServiceRequest,
			startTime: transportTender.startTime,
			endTime: transportTender.endTime,
			Status: transportTender.status,
			UserName: transportTender.programmer.user_name,
			Name: transportTender.programmer.name,
			Email: transportTender.programmer.user_email,
			SocietyCode: selectedCompany.get('partnership_code'),
			WineCellarId: transportTender.wineCellar ? transportTender.wineCellar.id : '',
			EndTime: transportTender.endTime,
			StartTime: transportTender.startTime,
			Applicationdate: transportTender.applicationDate,
			TypeShipmentId: transportTender.typeShipments.value,
			TenderEndTime: transportTender.tenderEndTime,
			ShowBaseRate: transportTender.showBaseRate,
			OpenRateTender: transportTender.openRateTender,
			TenderEndDate: transportTender.tenderEndDate,
			TransportTenderId: transportTenderId,
			FolioLetter: transportTender.folioLetter,
		}

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setUpdateTranportTender(serverResponse.data))

					dispatch(isSavingTransportTender(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingTransportTender(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function massiveTransportTender(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/massive/error`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsTranportTender(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function massiveTransportTenderAdd(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/massive`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})

		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderList(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))

				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadTransportTenderBackEnd(
	{ pageNumber, sortBy, rowsPerPage, sortDirection, query },
	suppliercode,
	status,
	activeTab
) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender?sortBy=${
			sortBy ? sortBy : 'CreationDate'
		}&sortDirection=${sortDirection ? sortDirection : 'desc'}&pageNumber=${
			pageNumber ? pageNumber - 1 : 0
		}&pageSize=${
			rowsPerPage ? rowsPerPage : 10
		}&query=${query}&suppliercode=${suppliercode}&status=${status}&activeTabFilter=${activeTab}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderList(serverResponse.data))
					dispatch(isLoading(false))
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
			})
	}
}

export function loadTransportTenderDetailBackEnd(id, isLoadingDetail = true) {
	return function (dispatch, getState) {
		if (isLoadingDetail) {
			dispatch(isLoading(true))
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/${id}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderDetail(serverResponse.data))
					dispatch(isLoading(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function addTransportType({ transportTenderId, transportTypeId }) {
	return function (dispatch, getState) {
		dispatch(isSavingTransportTender(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/transporteType/add`
		let data = {
			TtransportTenderId: transportTenderId,
			TransportTypeId: transportTypeId,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setUpdateTranportTender(serverResponse.data))
					dispatch(isSavingTransportTender(false))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingTransportTender(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function addDestinations(information, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/Destination`
		let stringDate =
			moment(information.boardingAppointmentDate, 'YYYY-MM-DD').format('YYYY-MM-DD') +
			' ' +
			information.boardingAppointmentTime +
			':00'
		let boardingAppointmentDate = information.requiresBoardingAppointment
			? moment(stringDate, 'YYYY-MM-DD HH:mm:ss')
			: null
		let data = {
			ClientId: information.client.Id,
			TranspostZoneId: information.zone.Id,
			IsMAneuvers: information.requiresManeuvering,
			TransportTenderId: transportTenderId,
			Street: information.street,
			ExternalNumber: information.externalNumber,
			Internalnumber: information.internalnumber,
			Suburb: information.suburb,
			CountryCode: information.countryCode,
			Town: information.town,
			State: information.state,
			Country: information.country,
			SalesManager: information.salesManager,
			EstimatedDeliveryDate: information.estimatedDeliveryDate,
			EstimatedTimeDelivery: information.estimatedTimeDelivery,
			FolioLetter: information.folioLetter,
			BoardingAppointmentDate:
				boardingAppointmentDate !== null
					? moment(boardingAppointmentDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.000Z')
					: null,
			BoardingAppointmentFolio: information.requiresBoardingAppointment
				? information.boardingAppointmentFolio
				: '',
			SelectManeuverType:
				information.SelectManeuverType && information.requiresManeuvering
					? information.SelectManeuverType.value
					: 0,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(setDestinations(serverResponse.data))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					dispatch(isLoading(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function updateDestinations(information, destinationId, transportTenderId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/Destination`
		let stringDate =
			moment(information.boardingAppointmentDate, 'YYYY-MM-DD').format('YYYY-MM-DD') +
			' ' +
			information.boardingAppointmentTime +
			':00'
		let boardingAppointmentDate = information.requiresBoardingAppointment
			? moment(stringDate, 'YYYY-MM-DD HH:mm:ss')
			: null
		let data = {
			ClientId: information.client.Id,
			TranspostZoneId: information.zone.Id,
			IsMAneuvers: information.requiresManeuvering,
			Street: information.street,
			ExternalNumber: information.externalNumber,
			Internalnumber: information.internalnumber,
			Suburb: information.suburb,
			CountryCode: information.countryCode,
			Town: information.town,
			State: information.state,
			Country: information.country,
			SalesManager: information.salesManager,
			EstimatedDeliveryDate: information.estimatedDeliveryDate,
			EstimatedTimeDelivery: information.estimatedTimeDelivery,
			FolioLetter: information.folioLetter,
			DestinationId: destinationId,
			BoardingAppointmentDate:
				boardingAppointmentDate !== null
					? moment(boardingAppointmentDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.000Z')
					: null,
			BoardingAppointmentFolio: information.requiresBoardingAppointment
				? information.boardingAppointmentFolio
				: '',
			SelectManeuverType:
				information.SelectManeuverType && information.requiresManeuvering
					? information.SelectManeuverType.value
					: 0,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function addCarries(codes, TransportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/carrierBidding/add`
		let data = {
			TransportTenderId: TransportTenderId,
			CarriesCodes: codes && codes.length >= 0 ? codes.map((x) => x.Code) : [],
		}

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarries(serverResponse.data.Carriers))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function changeMultipleTransportTendersStatus(allTransportTenderIds) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		// Crear las promesas para cada ID en el array
		const promises = allTransportTenderIds.map((TransportTenderId) => {
			// Aquí llamamos a ChangeTenderStatus por cada ID
			return ChangeTenderStatus(2, TransportTenderId)(dispatch, getState)
		})

		// Ejecutar todas las promesas en paralelo usando axios.all
		return axios
			.all(promises)
			.then(() => {
				dispatch(isLoadingName('isSaving', false))
				return Promise.resolve()
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error)
			})
	}
}

export function ChangeTenderStatus(status, TransportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/changeStatusTender`
		let data = {
			TransportTenderId: TransportTenderId,
			Status: parseInt(status),
			BitacoraStream: new Uint8Array(0),
		}

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setStatus(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function addDelivery(delivery, dealId, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/delivery`
		let data = {
			DeliveryNumber: delivery && delivery.noDeliveryNumber ? 'N/A' : delivery.deliveryNumber,
			DestinationId: dealId,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function UpdateDelivery(delivery, deliveryId, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/delivery`
		let data = {
			DeliveryNumber: delivery && delivery.noDeliveryNumber ? 'N/A' : delivery.deliveryNumber,
			DeliveryId: deliveryId,
		}

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))

					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function addMaterials(Materials, deliveryId, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/materials`
		let data = {
			Materials: Materials,
			deliveryId: deliveryId,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					// dispatch(setMaterials(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function deleteMaterial(materialId, transportTenderId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/materials/${materialId}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(removeMaterial(serverResponse.data))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function deleteDelivery(delivery, transportTenderId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/delivery/${delivery.Id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(removeDelivery(delivery))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function deleteDestination(destination, transportTenderId) {
	console.log('destination', destination)
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/destination/${transportTenderId}/${destination.Id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(removeDestination(destination))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function addCarrierInformation(proposalInformation, carrierBiddingCode, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal`
		let data = {
			CarrierBiddingCode: carrierBiddingCode,
			TransportationAvailability: proposalInformation.transportationAvailability,
			DriverName: proposalInformation.driverName,
			DriverPhoneNumber: proposalInformation.driverPhoneNumber,
			BearingPlates: proposalInformation.bearingPlates,
			Comments: proposalInformation.comments,
			NewAmount: proposalInformation.newAmount,
			EconomicUnit: proposalInformation.economicUnit,
			PlatesBox: proposalInformation.platesBox,
			EconomicalBox: proposalInformation.economicalBox,
			League: proposalInformation.league,
			TransportTenderId: transportTenderId,
			UserName: proposalInformation.userName ? proposalInformation.userName : '-',
			Password: proposalInformation.password ? proposalInformation.password : '-',
			ExistTrackLink: proposalInformation.existTrackLink,
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function editProposalInformation(proposalInformation, carrierBiddingCode, transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal`
		let data = {
			ProposalId: proposalInformation.id,
			CarrierBiddingCode: carrierBiddingCode,
			TransportationAvailability: proposalInformation.transportationAvailability,
			DriverName: proposalInformation.driverName,
			DriverPhoneNumber: proposalInformation.driverPhoneNumber,
			BearingPlates: proposalInformation.bearingPlates,
			Comments: proposalInformation.comments,
			NewAmount: proposalInformation.newAmount,
			EconomicUnit: proposalInformation.economicUnit,
			PlatesBox: proposalInformation.platesBox,
			EconomicalBox: proposalInformation.economicalBox,
			League: proposalInformation.league,
			TransportTenderId: transportTenderId,
			UserName: proposalInformation.userName,
			Password: proposalInformation.password,
			ExistTrackLink: proposalInformation.existTrackLink,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function getCarrierInformation(carrierBiddingCode, transportTenderId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/${carrierBiddingCode}/proposal/${transportTenderId}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					dispatch(isLoadingCatalog(false))
				}
				if (serverResponse.status === 204) {
					dispatch(setCarrierInformation(null))
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function chooseWinning(proposalId, isWinner, biddingId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isProposal', false))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/setWinner`
		let data = {
			ProposalId: proposalId,
			IsWinner: isWinner,
			BiddingId: biddingId,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateChooseWinning(serverResponse.data))
					dispatch(isLoadingName('isProposal', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isProposal', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function addProposalIncident(comments, proposalId, createdByName, createdByUserName, biddingId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isProposal', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/incident`
		let data = {
			Comments: comments,
			ProposalId: proposalId,
			CreatedByName: createdByName,
			CreatedByUserName: createdByUserName,
			BiddingId: biddingId,
		}
		console.log({ data })
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// dispatch(updateChooseWinning(serverResponse.data))
					dispatch(isLoadingName('isProposal', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isProposal', false))
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function changeCarrierInformationStatus(status, carrierBiddingCode, transportTenderdetailId) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/setStatus`
		let data = {
			CarrierBiddingCode: carrierBiddingCode,
			TransportTenderId: transportTenderdetailId,
			Status: parseInt(status),
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function sendSecondRoundCarrier(transportTenderdetailId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/sendToSecondRound`
		let data = {
			TransportTenderId: transportTenderdetailId,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					dispatch(loadTransportTenderDetailBackEnd(transportTenderdetailId, false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function changeCarrierInformationSecondRound(carrierBiddingId) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/setStatus`
		let data = {
			CarrierBiddingId: carrierBiddingId,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCarrierInformation(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
//*** Get catalog  */
export function loadClientsCatalog() {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'clientsCatalog'))
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
			})
	}
}
export function loadAllTransportZone() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportZone/all`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'transportZone'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function loadTransportZoneCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportZone`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'transportZonePagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadFilledTransportZoneTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportZone/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_zonas_transport.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateTransportZoneTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportZone/template/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadTransportZoneTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportZone/template/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportZoneList(serverResponse.data))
					dispatch(isLoadingName('isSaving', true))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', true))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadWineCellarCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/WineCellars`

		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'wineCellarPagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function loadAllWineCellars() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/WineCellars/all`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'wineCellar'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadFilledWineCellarTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/WineCellars/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_bodegas.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateWineCellarTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/WineCellars/validate/template`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsWineCellar(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add wineCellar', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadWineCellarTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/WineCellars/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCatalog(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
				console.error('Error add wine cellar', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadTransportTypeCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportType`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'transportTypePagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function loadAllTransportTypeCatalog() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportType/all`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'transportType'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadFilledTransportTypeTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportType/template/get`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_tipos_transporte.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateTransportTypeTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportType/template/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsTranportType(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadTransportTypeTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportType/template/update`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCatalog(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadRequirementsCarrierCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/customerRequirements`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'requirementsCarriersPagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function deleteRequirementsCarrier(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/customerRequirements/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadRequirementsCarrierTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)

		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/customerRequirement/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_requisitos_transportistas.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateRequirementsCarrierTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/customerRequirements/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsRequirementsCarrier(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadRequirementsCarrierTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/customerRequirements/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadCarriesCatalog() {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Catalogs/carries`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'carriers'))
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
			})
	}
}

export function loadTypeShipmentCatalog() {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TypeShipments`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'typeShipment'))
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
			})
	}
}
export function addTypeShipment(name) {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TypeShipments`
		let data = {
			Name: name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(updateCatalogs(serverResponse.data, 'typeShipment'))
					dispatch(isLoadingCatalog(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function deleteTypeShipment(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TypeShipments/carrier/group/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'typeShipment'))
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
			})
	}
}
export function addCarrier(supplier, typesShipmentId) {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TypeShipments/carrier/add`
		let data = {
			Name: supplier.supplier_name,
			Code: supplier.code,
			TypesShipmentId: typesShipmentId,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// console.log({ data: serverResponse.dtat })
					// dispatch(setUpdateTranportTender(serverResponse.data))
					dispatch(loadTypeShipmentCatalog())
					dispatch(isLoadingCatalog(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))

				console.error('Error add transportTender', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function deleteCarrier(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TypeShipments/carrier/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setCatalogs(serverResponse.data, 'carriers'))
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
			})
	}
}
export function loadRateCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TariffSAP?`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'ratePagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadFilledTariffSAPTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TariffSAP/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'tarifario.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateTariffSAPTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TariffSAP/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsTariffSAP(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadTariffSAPTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TariffSAP/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCatalog(false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
				console.error('Error add tariff sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadClientsPaginatedCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/paginated?`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pagenumber - 1,
						pageSize: serverResponse.data.pagesize,
					}
					dispatch(setCatalogs(transformResponseData, 'clientsPagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function downloadFilledClientTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'clientes.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}
export function validateClientTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsClients(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function uploadClientsTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Clients/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCatalog(false))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add tariff sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}
export function loadMaterialLoadingScheduleFromBackEnd(suppliercode, wineCellarCode, isInternal) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/calendarlocks`

		let command = {
			SupplierCode: suppliercode ? suppliercode : '',
			WineCellarCode: wineCellarCode ? wineCellarCode : '',
			IsInternal: isInternal ? isInternal : false,
		}
		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setMaterialLoadingInSchedule(serverResponse.data))
					dispatch(isLoading(false))
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
			})
	}
}
export function onGetAllWinners() {
	return function (dispatch, getState) {
		dispatch(setIsLoadingWinners(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/allWinners`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setWinnerTransportTender(serverResponse.data))
					dispatch(setIsLoadingWinners(false))
				}
			})
			.catch((error) => {
				dispatch(setIsLoadingWinners(false))
			})
	}
}
export function sendMassiveBinnacleAndSyncSAP(proposalSyncSAPList) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/proposal/sendMassiveBinnacleSyncSap`
		let data = {
			RequestedBy: 'adimbptm',
			ProposalSyncSAPList: proposalSyncSAPList ? proposalSyncSAPList : [],
		}
		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject()
			})
	}
}
export function downloadIncidents(startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/allIncidents/download?`
		let data = { RequestedBy: 'adimbptm', StartDate: startDate, EndDate: endDate }
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'incidentes.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.log({ error })
			})
	}
}
export function getDescriptionByCodeFromSAP(code) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isLoadingCodeFromSAP', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Saps/${code}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					// const description = serverResponse.data.Description
					dispatch(setMaterialFromSAP(serverResponse.data))
					dispatch(isLoadingName('isLoadingCodeFromSAP', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isLoadingCodeFromSAP', false))
			})
	}
}
export function VerifyDisponibleTimeWineCellar(selectDate, wineCellerName, id) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/checkScheduleIsAvailable`
		let command = {
			RequiredWineCellarDate: selectDate,
			WineCellarCode: wineCellerName,
			BiddingId: id,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTimeOptions(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
				}
				return serverResponse.data
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function AddWineCellarDate(transportTenderId, requiredWineCellarDate, chargingStartTime, chargingEndTime) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoading(false))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/setRequiredChargingDate`
		let command = {
			TransportTenderId: transportTenderId,
			RequiredChargingDate: requiredWineCellarDate,
			ChargingStartTime: chargingStartTime ? chargingStartTime : '',
			ChargingEndTime: chargingEndTime ? chargingEndTime : '',
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log({ data: serverResponse.data })
					dispatch(setTransportTenderDetail(serverResponse.data))
					dispatch(isLoading(false))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject(error.response.data.detail)
			})
	}
}
export function downloadCarrierLogPDF(transportTenderId) {
	return (dispatch, getState) => {
		dispatch(isLoadingDonwloadCarrierLog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/generatePdfLogbook`
		let data = {
			TransportTenderId: transportTenderId,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], { type: 'application/pdf' })
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'bitacoraDeTransportista.pdf'
					a.click()
					dispatch(isLoadingDonwloadCarrierLog(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isLoadingDonwloadCarrierLog(false))
				console.error('Error in download carrier log PDF', error.response)
				return Promise.reject()
			})
	}
}

export function downloadConsignmentNoteInformation(transportTenderId) {
	return (dispatch, getState) => {
		dispatch(isLoadingDonwloadCarrierLog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/generateConsignmentNoteInformation`
		let data = {
			TransportTenderId: transportTenderId,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Información_Carta_Porte.xlsx'
					a.click()
					dispatch(isLoadingDonwloadCarrierLog(false))
					return Promise.resolve('Ok')
				}
			})
			.catch((error) => {
				dispatch(isLoadingDonwloadCarrierLog(false))
				console.error('Error in download carrier log PDF', error.response)
				return Promise.reject()
			})
	}
}

export function downloadTransportsInTransit(startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isLoadingReport(true))
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/trasportRecordReport`
		let data = {
			RequestedBy: 'adimbptm',
			StartDate: moment(startDate).format().toString(),
			EndDate: moment(endDate).format().toString(),
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingReport(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Folios_en_Tránsito.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isLoadingReport(false))
				console.log({ error })
			})
	}
}

export function loadManeuversPaginatedCatalog(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/maneuvers`
		const pageNumber = queryParams && queryParams.pageNumber ? queryParams.pageNumber - 1 : 0
		const pageSize = queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20
		endPoint = endPoint + `?pageNumber=${pageNumber}&pageSize=${pageSize}`
		if (queryParams && queryParams.query) endPoint = endPoint + `&query=${queryParams.query}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					const transformResponseData = {
						...serverResponse.data,
						pageNumber: serverResponse.data.pageNumber - 1,
					}
					dispatch(setCatalogs(transformResponseData, 'maneuversPagination'))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
			})
	}
}

export function validateManeuversTemplate(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Maneuvers/template/validate`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setErrorsManeuvers(serverResponse.data))
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add transportZone', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function importManeuverCatalog(file) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		dispatch(isLoadingCatalog(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Maneuvers/template/import`
		let formData = new FormData()
		file.forEach((file) => {
			formData.append('file', file)
		})
		return axios
			.post(endPoint, formData, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCatalog(false))
					dispatch(isLoadingName('isSaving', false))
				}
			})
			.catch((error) => {
				dispatch(isLoadingCatalog(false))
				dispatch(isLoadingName('isSaving', false))
				console.error('Error add tariff sap', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function downloadManeuversTemplate() {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Maneuvers/template`
		return axios
			.get(endPoint, { responseType: 'arraybuffer', headers: configuration.headers })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Maniobras.xlsx'
					a.click()
				}
				dispatch(isLoadingName('isSaving', false))
			})
			.catch((error) => {
				console.log({ error })
				dispatch(isLoadingName('isSaving', false))
			})
	}
}

export function deleteSupplierManeuver(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingName('isSaving', true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/Maneuvers/${id}`
		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingName('isSaving', false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingName('isSaving', false))
				return Promise.reject()
			})
	}
}

export function UpdateLoadingSchedule(transportTenderId) {
	return function (dispatch, getState) {
		dispatch(isLoadingDonwloadCarrierLog(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/updateLoadingSchedule`
		let command = {
			TransportTenderId: transportTenderId,
			BitacoraStream: new Uint8Array(0),
			CanBeSendEmail: true,
		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderDetail(serverResponse.data))
					dispatch(isLoadingDonwloadCarrierLog(false))

					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingDonwloadCarrierLog(false))
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function getTranportTenderByTransportCode(transportCode) {
	return function (dispatch, getState) {
		dispatch(isLoading(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_CONTROL_TOWER}/TransportTender/${transportCode}/checklist`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setTransportTenderWinner(serverResponse.data))
					dispatch(isLoading(false))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoading(false))
				console.error('Error get transport tender information by transport code', error.response)
				return Promise.reject(error.response.data.Message)
			})
	}
}



