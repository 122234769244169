/** react imports section */
import React from 'react'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
// import EditIcon from '@material-ui/icons/Edit'
// import AddIcon from '@material-ui/icons/AddTwoTone'
// import DeleteIcon from '@material-ui/icons/DeleteForever'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { CardHeader, Typography, Checkbox, Chip } from '@material-ui/core'

// import PopperListsComponent from '../../../biddings/components/PopperListsComponent'
// import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'
import { getManeuverTemplateByNumber, getManeuverTypeByNumber } from '../../../../store/helpers/TransportTenderHelper'
import { Warning } from '@material-ui/icons'

const DealsHeader = (props) => {
	const {
		classes,
		deal,
		// setOpenPanel,
		setOpenCollapese,
		openCollapese,
		// setOpenDealsPanel,
		// onDeleteDestination,
		// transportTenderdetail,
		setOpenWarningClient,
	} = props

	// const popperLists = () => {
	// 	let options = []
	// 	options.push({
	// 		title: <Translate id={'carrierTenders.edit'} />,
	// 		onClick: () => setOpenDealsPanel({ open: true, isEdit: true, deal: deal }),
	// 		icon: <EditIcon className={classes.deleteIcon} />,
	// 		isIconMaterial: true,
	// 		disabled: false,
	// 	})
	// 	options.push({
	// 		title: <Translate id='common.delete' />,
	// 		onClick: () => onDeleteDestination(deal),
	// 		icon: <DeleteIcon className={classes.editcon} />,
	// 		isIconMaterial: true,
	// 		disabled: false,
	// 	})
	// 	return options
	// }
	const haveClientRequirements =
		deal && deal.client && deal.client.CustomerRequirements ? deal.client.CustomerRequirements.length > 0 : false
	return (
		<CardHeader
			className={classes.cardHeader}
			avatar={
				<div onClick={() => setOpenCollapese({ open: !openCollapese.open, dealId: deal.id })}>
					{openCollapese.open ? (
						<ExpandLessIcon className={classes.expandIcon} />
					) : (
						<ExpandMoreIcon className={classes.expandIcon} />
					)}
				</div>
			}
			title={
				<div className={classes.deliveryContainer}>
					<div className={classes.clientContainer}>
						<div className={classes.flexStart}>
							<Typography gutterBottom variant='caption' component='p'>
								<strong>
									{' '}
									Repartos a <Translate id={'carrierTenders.deals.client'} />:
								</strong>
								{deal.client.Name}
							</Typography>
							{haveClientRequirements && (
								<Chip
									icon={<Warning className={classes.colorWarning} fontSize='small' />}
									onClick={() => setOpenWarningClient(deal.client)}
									className={classes.chipWarningContent}
									label={
										<Typography className={classes.colorWarning} variant='caption'>
											<Translate id={'carrierTenders.warningRequirement.subTitle'} />
										</Typography>
									}
								/>
							)}
						</div>
						<div className={classes.maneuvers}>
							{getManeuverTypeByNumber(deal.selectManeuverType).length > 0 ? (
								<div
									style={{
										display: 'flex',
									}}
								>
									<Typography gutterBottom variant='caption' component='p'>
										<strong>
											<Translate id={'carrierTenders.deals.maneuver'} />:
										</strong>
									</Typography>
									<div className={classes.checkbox}>
										<Checkbox checked={deal.isManeuvers} disabled />
									</div>
									{getManeuverTemplateByNumber(
										deal.selectManeuverType,
										<Translate id={getManeuverTypeByNumber(deal.selectManeuverType)} />
									)}
								</div>
							) : (
								''
							)}
						</div>
						{/* {transportTenderdetail.Status === TransportTendeStatus.DRAFT && (
							<div className={classes.flexEnd}>
								<Typography gutterBottom variant='body2' component='p'>
									<Translate id={'carrierTenders.deals.deliveries'} />
								</Typography>

								<AddIcon
									onClick={() =>
										setOpenPanel({ open: true, dealId: deal.id, isEdit: false, delivery: null })
									}
									className={classes.addIcon}
								/>
							</div>
						)}
						{transportTenderdetail.Status === TransportTendeStatus.DRAFT && (
							<div lassName={classes.flexEnd}>
								<PopperListsComponent
									title={<Translate id='common.options' />}
									listItems={popperLists(classes)}
								/>
							</div>
						)} */}
					</div>
					<div className={classes.zoneContainer}>
						<Typography gutterBottom variant='caption' component='p'>
							<strong>
								{' '}
								<Translate id={'carrierTenders.deals.zone'} />:
							</strong>{' '}
							{deal.transportZone.Code} - {deal.transportZone.Direction}
						</Typography>
						<Typography gutterBottom variant='caption' component='p'>
							<strong>
								{' '}
								<Translate id={'carrierTenders.deals.address'} />:
							</strong>{' '}
							{deal.street},{deal.internalnumber}, {deal.externalNumber},{deal.suburb},{deal.countryCode},
							{deal.state}, {deal.country}
						</Typography>
					</div>
					<Typography gutterBottom variant='caption' component='p'>
						<strong>
							<Translate id={'carrierTenders.deals.salesManager'} />:{' '}
						</strong>{' '}
						{deal.salesManager}
					</Typography>
					{deal.boardingAppointmentFolio && (
						<div className={classes.informationContainer}>
							<Typography gutterBottom variant='caption' component='p'>
								<strong>
									<Translate id={'carrierTenders.deals.appointmentFolio'} />:
								</strong>{' '}
								{deal.boardingAppointmentFolio}
							</Typography>
							<Typography gutterBottom variant='caption' component='p'>
								<strong>
									<Translate id={'carrierTenders.deals.appointmentDate'} />:
								</strong>{' '}
								{deal.boardingAppointmentDate
									? moment(deal.boardingAppointmentDate).format('DD/MM/YYYY')
									: '-'}
							</Typography>
							<Typography gutterBottom variant='caption' component='p'>
								<strong>
									<Translate id={'carrierTenders.deals.appointmentTime'} />:
								</strong>{' '}
								{deal.boardingAppointmentDate
									? moment(deal.boardingAppointmentDate).format(' HH:mm A')
									: '-'}
							</Typography>
						</div>
					)}
				</div>
			}
		/>
	)
}

export default DealsHeader
