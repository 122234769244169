/** Redux section imports */
import { localizeReducer } from 'react-localize-redux'
import { combineReducers } from 'redux'

/** Reducers section imports */
import { reducer as oidcReducer } from 'redux-oidc'

/** Actions section imports */
import accreditedWorkshopsReducer from './accreditedWorkshops/accreditedWorkshopsReducer'
import internalUsersRender from './administration/InternalReducer'
import nonConformitiesReducer from './administration/NonConformitiesReducer'
import officialNoticeReducer from './administration/OfficialNoticeReducer'
import warehouseWorkersRender from './administration/WarehouseWorkersReducer'
import alternativeProveduresReducer from './alternativeProvedure/AlternativeProvedureReducer'
import biddingsReducer from './biddings/BiddingsReducer'
import billingsReducer from './billings/BillingsReducer'
import categoriesReducer from './categories/CategoriesReducer'
import companiesReducer from './companies/CompanyReducer'
import consignmentNoteReducer from './consignmentNote/consignmentNoteReducer'
import contactSupplierReducer from './contactsupplier/ContactSupplierReducer'
import employeesReducer from './contractors/EmployeesReducer'
import medicalCheckReducer from './contractors/MedicalCheckReducer'
import servicesProjectsReducer from './contractors/ServicesProjectsReducer'
import socialInsurancePaymentsReducer from './contractors/SocialInsurancePaymentsReducer'
import trainingsReducer from './contractors/TrainingReducer'
import controlCO2Reducer from './controlCO2/controlCO2Reducer'
import sacsReducer from './correctiveActions/SacReducer'
import customerGuaranteeReducer from './customerGuarantee/customerGuaranteReducer'
import deliveryQualificationsReducer from './deliveryQualifications/deliveryQualificationsReducer'
import devolutiosReducer from './devolutions/DevolutionReducer'
import ethicCodeReducer from './ethicCode/ethicCodeReducer'
import expedientSupplierReducer from './expedientSupplier/ExpedientSupplierReducer'
import factoringAffiliationReducer from './factoringAffiliations/FactoringAffiliationReducer'
import failureGuaranteeReducer from './failureGuarantee/failureGuaranteeReducer'
import freightReducer from './freight/FreightReducer'
import guaranteeReportReducer from './guaranteeReport/guaranteeReportReducer'
import indirectQualifications from './indirectqualifications/IndirectqualificationsReducer'
import loadReducer from './loadAlerts/LoadAlertReducer'
import manualsportalReducer from './manuals/ManualPortalReducer'
import maquilaOrdersReducer from './maquilaOrders/MaquilaOrderReducer'
import materialDeliveryReducer from './materialDelivery/MaterialDeliveryReducer'
import memosReducer from './memos/MemoReducer'
import noticeChangeReducer from './noticeChanges/NoticeChangeReducer'
import notificationsReducer from './notifications/NotificationReducer'
import partNumbersReducer from './partNumbers/PartNumberReducer'
import phoneDirectoryReducer from './phoneDirectories/PhoneDirectoryReducer'
import deliveryOrderPlanningReducer from './plannigFersa/DeliveryOrderReducer'
import releaseCalendarLockFersaReducer from './plannigFersa/ReleaseCalendarReducer'
import releasePlanningReducer from './plannigFersa/ReleasePlanningReducer'
import deliveryOrderReducer from './planning/DeliveryOrderReducer'
import releaseCalendarLockReducer from './planning/ReleaseCalendarReducer'
import releasePlanReducer from './planning/ReleasePlanReducer'
import transportdestinations from './planning/TransportDestinationReducer'
import profileReducer from './profile/ProfileReducer'
import prospectsReducer from './prospects/ProspectReducer'
import qualityCertificateReducer from './qualityCertificate/qualityCertificateReducer'
import qualityFailureReportReducer from './qualityFailureReport/QualityFailureReportReducer'
import qualityAuditReducer from './qualityaudit/QualityAuditReducer'
import questionnaireReducer from './questionnaires/QuestionnairesReducer'
import quotesRender from './quotes/QuotesReducer'
import ratingReducer from './ratings/ratingReducer'
import rawMaterialNonConfReducer from './rawMaterialNonConformity/RawMaterialReducer'
import requestPriceChangeReducer from './requestPriceChange/requestPriceChangeReducer'
import reworksReducer from './reworks/ReworkReducer'
import sacsQualityReducer from './sacsQuality/SacsQualityReducer'
import sampleRequestRender from './samplerequests/SampleRequestReducer'
import shippingOrdersReducer from './shippingOrders/ShippingOrderReducer'
import sparePartPriceChange from './sparePartPriceChange/sparePartPriceChangeReducer'
import specificationsReduce from './specifications/specifications.Reducer'
import supplierContractsReducer from './supplierconstracts/SupplierConstractReducer'
import externalUsersRender from './suppliers/ExternalUserReducer'
import suppliersRender from './suppliers/SupplierReducer'
import sustainabilityReducer from './sustainability/SustainabilityReducer'
import sustainabilityV2Reduer from './sustainability2/SustainabilityReducer'
import transportTenderReducer from './transportTender/transportTenderReducer'
import warehouseRawMaterialReducer from './warehouseRawMaterial/WarehouseRawMaterialReducer'
import warehouseWindowsReducer from './warehouseWindows/WarehouseWindowsReducer'
import warrantyReportReducer from './warrantyReportsPtm/warrantyReportPtmReducer'
import workflowReducer from './workflows/WorkflowReducer'
import securityCoursesReducer from './securityCourses/securityCoursesReducer'
import certificationsReducer from './certifications/certificationsReducer'
import exportRequestReducer from './exportRequest/ExportRequestReducer'
import indirectPriceQuoteWithoutCodeReducer from './indirectPriceQuoteWithoutCode/IndirectPriceQuoteWithoutCodeReducer'
export default combineReducers({
	oidc: oidcReducer,
	localize: localizeReducer,
	companies: companiesReducer,
	suppliers: suppliersRender,
	internalUsers: internalUsersRender,
	externalUsers: externalUsersRender,
	warehouseWorkers: warehouseWorkersRender,
	officialNotices: officialNoticeReducer,
	memos: memosReducer,
	noticechanges: noticeChangeReducer,
	materialDelivery: materialDeliveryReducer,
	quotes: quotesRender,
	samplerequests: sampleRequestRender,
	alternativeProvedures: alternativeProveduresReducer,
	requestPriceChange: requestPriceChangeReducer,
	sacs: sacsReducer,
	partNumbers: partNumbersReducer,
	profile: profileReducer,
	ratings: ratingReducer,
	rawMaterialNonConformity: rawMaterialNonConfReducer,
	qualityFailureReport: qualityFailureReportReducer,
	nonConformities: nonConformitiesReducer,
	devolutions: devolutiosReducer,
	reworks: reworksReducer,
	sacsQuality: sacsQualityReducer,
	notifications: notificationsReducer,
	workflow: workflowReducer,
	shippingOrders: shippingOrdersReducer,
	freights: freightReducer,
	customerGuarantee: customerGuaranteeReducer,
	failureGuarantee: failureGuaranteeReducer,
	warrantyReports: warrantyReportReducer,
	guaranteeReport: guaranteeReportReducer,
	warehouseRawMaterials: warehouseRawMaterialReducer,
	contractorEmployees: employeesReducer,
	contractorSocialInsurancePayments: socialInsurancePaymentsReducer,
	contractorServicesProjects: servicesProjectsReducer,
	contractorMedicalcheck: medicalCheckReducer,
	releasePlan: releasePlanReducer,
	deliveryOrders: deliveryOrderReducer,
	releaseCalendarLock: releaseCalendarLockReducer,
	transportdestinations: transportdestinations,
	ethicCodes: ethicCodeReducer,
	sustainability: sustainabilityReducer,
	contactSuppliers: contactSupplierReducer,
	expedientSuppliers: expedientSupplierReducer,
	manualsportal: manualsportalReducer,
	questionnaires: questionnaireReducer,
	contracts: supplierContractsReducer,
	prospects: prospectsReducer,
	accreditedWorkshops: accreditedWorkshopsReducer,
	biddings: biddingsReducer,
	maquilaOrders: maquilaOrdersReducer,
	categories: categoriesReducer,
	sustainabilityV2: sustainabilityV2Reduer,
	indirectQualifications: indirectQualifications,
	billings: billingsReducer,
	warehouseWindows: warehouseWindowsReducer,
	consignmentNote: consignmentNoteReducer,
	releasePlanning: releasePlanningReducer,
	qualityAudit: qualityAuditReducer,
	deliveryOrder: deliveryOrderPlanningReducer,
	releaseCalendarFersaLock: releaseCalendarLockFersaReducer,
	trainings: trainingsReducer,
	deliveryQualifications: deliveryQualificationsReducer,
	controlCO2: controlCO2Reducer,
	qualityCertificate: qualityCertificateReducer,
	phoneDirectories: phoneDirectoryReducer,
	transportTender: transportTenderReducer,
	specifications: specificationsReduce,
	factoringAffiliation: factoringAffiliationReducer,
	sparePartPriceChange: sparePartPriceChange,
	loadAlert: loadReducer,
	securityCourses: securityCoursesReducer,
	certifications:certificationsReducer,
	exportRequest: exportRequestReducer,
	indirectPriceQuoteWithoutCode:indirectPriceQuoteWithoutCodeReducer
})
