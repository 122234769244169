import React, { useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import {
	canBeEditInternalUserInformation,
	canBeEditSupplierInformation,
	loadAlertItemStatus,
} from '../../store/helpers/LoadAlertHelper'
import { getTranslate } from 'react-localize-redux'
import {
	AddPreviousOrigin,
	UpdateLoadAlert,
	UpdateLoadAlertItem,
	changeStatusLoadAlert,
	changeStatusLoadAlertItem,
	deleteFilesLoadAlert,
	loadAlertFromBack,
	sendNotificationToSupplier,
	toSit,
	uploadFilesLoadAlert,
	createTrackingGuide,
} from '../../store/loadAlerts/LoadAlertAction'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import Toaster from '../common/Toaster'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import AddAnnexesDocumentes from './componentes/AddAnnexesDocumentes'
import AddInspectionDocument from './componentes/AddInspectionDocument'
import AddInvoiceDocuments from './componentes/AddInvoiceDocuments'
import AddLoadAlert from './componentes/AddLoadAlert'
import AddMaterial from './componentes/AddMaterial'
import AddPreviousDocument from './componentes/AddPreviousDocument'
import DesktopView from './componentes/show/DesktopView'
import HistoryMessagedLateralPanel from './componentes/show/HistoryMessagedLateralPanel'
import WarningComponent from '../common/WarningComponent'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const Show = () => {
	const params = useParams()
	const dispatch = useDispatch()
	const [tab, setTab] = useState('1')
	const [confirmPasswordConfigModal, setconfirmPasswordConfigModal] = useState(false)
	const [confirmPasswordConfigModalDelete, setconfirmPasswordConfigModalDelete] = useState(false)
	const [dialogOperationModal, setDialogOperationModal] = useState(false)
	const [openDialogConfirm, setDialogConfirm] = useState({
		open: false,
		status: null,
		comments: '',
	})

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	const translate = useSelector((state) => getTranslate(state.localize))
	const closeDialogOperation = () => {
		setDialogOperationModal(!dialogOperationModal)
	}
	const [confirmPasswordConfigModalFromItem, setConfirmPasswordConfigModalFromItem] = useState({
		item: null,
		toStatus: null,
		opened: false,
	})

	const [confirmPasswordConfigModalForCreateTrackingGuide, setConfirmPasswordConfigModalForCreateTrackingGuide] =
		useState({
			item: null,
			opened: false,
		})

	const onConfirmPasswordConfigModalForCreateTrackingGuide = (item) => {
		setConfirmPasswordConfigModalForCreateTrackingGuide({
			item: item,
			opened: !confirmPasswordConfigModalForCreateTrackingGuide.opened,
		})
	}

	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [loadAlertItem, setLoadAlertItem] = useState(null)
	let userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany').toJS())
	const isLoading = useSelector((state) =>
		state.loadAlert.get('isLoading') ? state.loadAlert.get('isLoading') : false
	)
	const isSaving = useSelector((state) => (state.loadAlert.get('isSaving') ? state.loadAlert.get('isSaving') : false))
	const [openAvailableOperationsDialog, setOpenAvailableOperationsDialog] = useState(false)
	const setOpenDialog = (value) => {
		setOpenAvailableOperationsDialog(value)
	}
	const loadAlert = useSelector((state) =>
		state.loadAlert.get('loadAlert') ? state.loadAlert.get('loadAlert').toJS() : []
	)
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const userProfile = useSelector((state) => state.oidc.user)
	const nacionalData = loadAlert && loadAlert.items ? loadAlert.items.find((x) => x.type === 2) : null
	const internacionalData = loadAlert && loadAlert.items ? loadAlert.items.find((x) => x.type === 1) : null
	const previousOriginData = loadAlert && loadAlert.items ? loadAlert.items.find((x) => x.type === 3) : null
	let isInternalUser = userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : ''

	const onModalConfirmLoadAlert = () => {
		setconfirmPasswordConfigModal(!confirmPasswordConfigModal)
	}
	const onModalConfirmLoadAlertDelete = () => {
		setconfirmPasswordConfigModalDelete(!confirmPasswordConfigModalDelete)
	}

	const onModalConfirmLoadAlertFromItem = (item, toStatus, supplierDescription) => {
		setConfirmPasswordConfigModalFromItem({
			item: item,
			toStatus: toStatus,
			opened: !confirmPasswordConfigModalFromItem.opened,
			supplierDescription: supplierDescription,
		})
	}

	const onClosedDialog = () => {
		setDialogConfirm({
			open: false,
			status: null,
			sustainabilityId: null,
			comments: '',
		})
	}

	let canbeUpdateSupplierInternational = canBeEditSupplierInformation(
		loadAlert,
		userRolesByCompany,
		userProfile,
		internacionalData
	)

	let canbeUpdateSupplierNacional = canBeEditSupplierInformation(
		loadAlert,
		userRolesByCompany,
		userProfile,
		nacionalData
	)
	let canbeUpdateSupplierPreviousOrigin = canBeEditSupplierInformation(
		loadAlert,
		userRolesByCompany,
		userProfile,
		previousOriginData
	)

	let canBeEditInternalUserData = canBeEditInternalUserInformation(
		loadAlert,
		userRolesByCompany,
		userProfile,
		isInternalUser
	)

	let supplierLogo = supplier ? supplier.logo : ''
	let urlImageSupplier = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplierLogo}`
	/** Get user Information*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [loadAlertHistoriesMessages, setLoadAlertHistoriesMessages] = useState({ opened: false, items: [] })

	const onOpenPanelHistoryMesages = (items) => {
		setLoadAlertHistoriesMessages({ opened: !loadAlertHistoriesMessages.opened, items: items })
	}
	useEffect(() => {
		dispatch(loadAlertFromBack(params.token)) //sortCriteria.by
	}, [dispatch])

	// const changeLoadAlertStatus = () => {
	// 	if (tab === '1')
	// 		dispatch(
	// 			changeStatusLoadAlert(loadAlert.token, '', loadAlertStatus.ReviewInternationalCarrierInformation)
	// 		).then(() => {
	// 			setOpenDialog(false)
	// 		})
	// 	else if (tab === '2')
	// 		dispatch(changeStatusLoadAlert(loadAlert.token, '', loadAlertStatus.ReviewNacionalCarrierInformation)).then(
	// 			() => {
	// 				setOpenDialog(false)
	// 			}
	// 		)
	// }

	const onChangeLoadAlertStatus = (comments) => {
		return dispatch(changeStatusLoadAlert(loadAlert.token, comments, openDialogConfirm.status))
		.then(() => {
			onClosedDialog()
			setToasterMessageConfig({
				showToaster: true,
				toasterVariant: 'success',
				toasterMessage: 'common.addCorrectly',
			})
		})
		.catch(() => {
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'common.errorToAdd',
				toasterVariant: 'error',
			})
		})
	}
	const addPreviousOriging = () => {
		return dispatch(AddPreviousOrigin(params.token))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToAdd',
					toasterVariant: 'error',
				})
			})
	}
	const onUpdateRequest = (objectProperties) => {
		return dispatch(UpdateLoadAlert(objectProperties))
			.then((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch((error) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: error.data.ExceptionMessage,
					toasterVariant: 'error',
				})
			})
	}

	const onSendNotificationToSupplier = (itemToken) => {
		return dispatch(sendNotificationToSupplier(loadAlert.token, itemToken))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.send',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const updateLoadAlertItemProperty = (objectProperties) => {
		return dispatch(UpdateLoadAlertItem(objectProperties))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.saveCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}

	const onUploadInspectionDocument = (files) => {
		return dispatch(uploadFilesLoadAlert(loadAlertItem.token, files, 'InspectionDocument', 'inspectionDocument'))
	}
	const onUploadInvoiceDocument = (files) => {
		return dispatch(uploadFilesLoadAlert(loadAlertItem.token, files, 'InvoiceDocument', 'invoiceDocument'))
	}
	const onUploadAnnexesDocument = (files) => {
		return dispatch(uploadFilesLoadAlert(loadAlertItem.token, files, 'AnnexesDocument', 'annexesDocument'))
	}
	const onUploadPreviousDocument = (files) => {
		return dispatch(uploadFilesLoadAlert(loadAlertItem.token, files, 'PreviousDocument', 'previousDocument'))
	}
	const deleteAnnexesFiles = (name, item) => {
		return dispatch(deleteFilesLoadAlert(item.token, 'AnnexesDocument', name))
	}
	const deleteInvoiceFiles = (name, item) => {
		return dispatch(deleteFilesLoadAlert(item.token, 'InvoiceDocument', name))
	}
	const deletePreviousFiles = (name, item) => {
		return dispatch(deleteFilesLoadAlert(item.token, 'PreviousDocument', name))
	}
	const deleteInspectionFiles = (name, item) => {
		return dispatch(deleteFilesLoadAlert(item.token, 'InspectionDocument', name))
	}

	const onLateralPanelConfig = (identifier, item) => {
		setIdentifierPanelLateral(identifier)
		setLoadAlertItem(item)
		onOpenAndCloseLateralPanel()
	}

	const PanelLateralChildren = useCallback(
		{
			AddLoadAlert: <AddLoadAlert onCloseModal={() => onOpenAndCloseLateralPanel()} />,
			AddMaterial: <AddMaterial onCloseModal={() => onOpenAndCloseLateralPanel()} />,
			AddInspectionDocument: (
				<AddInspectionDocument
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					onUploadInspectionDocument={onUploadInspectionDocument}
				/>
			),
			AddAnnexesDocumentes: (
				<AddAnnexesDocumentes
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					onUploadAnnexesDocument={onUploadAnnexesDocument}
					deleteAnnexesFiles={deleteAnnexesFiles}
				/>
			),
			AddInvoiceDocuments: (
				<AddInvoiceDocuments
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					onUploadInvoiceDocument={onUploadInvoiceDocument}
					deleteInvoiceFiles={deleteInvoiceFiles}
				/>
			),
			AddPreviousDocument: (
				<AddPreviousDocument
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					onUploadPreviousDocument={onUploadPreviousDocument}
					deletePreviousFiles={deletePreviousFiles}
				/>
			),
			// HistoryMessages: loadAlertHistoriesMessages && loadAlertHistoriesMessages.opened && (
			// 	<HistoryMessages
			// 		onCloseModal={() => {
			// 			setLoadAlertHistoriesMessages([])
			// 			onOpenAndCloseLateralPanel()
			// 		}}
			// 		loadAlertHistoriesMessages={loadAlertHistoriesMessages.items}
			// 	/>
			// ),
		},
		[identifierPanelLateral]
	)
	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const onGetMessage1ModalConfirmFromItem = () => {
		switch (confirmPasswordConfigModalFromItem.toStatus) {
			case loadAlertItemStatus.Draft:
				return (
					<Translate
						id='loadAlerts.sentDataToReviewToSupplier'
						data={{ supplierType: confirmPasswordConfigModalFromItem.supplierDescription }}
					/>
				)
			case loadAlertItemStatus.ReviewInformation:
				return (
					<Translate
						id='loadAlerts.sentDataToReviewToSupplier'
						data={{ supplierType: confirmPasswordConfigModalFromItem.supplierDescription }}
					/>
				)
			case loadAlertItemStatus.AcceptedInformation:
				return (
					<Translate
						id='loadAlerts.acceptedItem'
						data={{ supplierType: confirmPasswordConfigModalFromItem.supplierDescription }}
					/>
				)
			default:
				return 'Not implemented operation'
		}
	}

	const onSyncInformationToSit = () => {
		return dispatch(toSit(params.token))
			.then(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToAdd',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<>
			<DesktopView
				//array props section
				userRolesByCompany={userRolesByCompany}
				//functions props section
				onOpenConfirmDialog={onOpenConfirmDialog}
				onClosedDialog={onClosedDialog}
				// changeLoadAlertStatus={changeLoadAlertStatus}
				onLateralPanelConfig={onLateralPanelConfig}
				onUpdateRequest={onUpdateRequest}
				addPreviousOriging={addPreviousOriging}
				updateLoadAlertItemProperty={updateLoadAlertItemProperty}
				onSendNotificationToSupplier={onSendNotificationToSupplier}
				deleteAnnexesFiles={deleteAnnexesFiles}
				deleteInvoiceFiles={deleteInvoiceFiles}
				deleteInspectionFiles={deleteInspectionFiles}
				deletePreviousFiles={deletePreviousFiles}
				onModalConfirmLoadAlert={onModalConfirmLoadAlert}
				onModalConfirmLoadAlertDelete={onModalConfirmLoadAlertDelete}
				setOpenAvailableOperationsDialog={setOpenAvailableOperationsDialog}
				setOpenDialog={setOpenDialog}
				onModalConfirmLoadAlertFromItem={onModalConfirmLoadAlertFromItem}
				onOpenPanelHistoryMesages={onOpenPanelHistoryMesages}
				onConfirmPasswordConfigModalForCreateTrackingGuide={onConfirmPasswordConfigModalForCreateTrackingGuide}
				onSyncInformationToSit={onSyncInformationToSit}
				closeDialogOperation={closeDialogOperation}
				setDialogConfirm={setDialogConfirm}
				onChangeLoadAlertStatus={onChangeLoadAlertStatus}
				//bool props section
				canbeUpdateSupplierInternational={canbeUpdateSupplierInternational}
				canbeUpdateSupplierNacional={canbeUpdateSupplierNacional}
				canbeUpdateSupplierPreviousOrigin={canbeUpdateSupplierPreviousOrigin}
				isLoading={isLoading}
				canBeEditInternalUserData={canBeEditInternalUserData}
				openAvailableOperationsDialog={openAvailableOperationsDialog}
				isSaving={isSaving}
				dialogOperationModal={dialogOperationModal}
				isInternalUser={isInternalUser}
				//string props section
				supplierLogo={supplierLogo}
				urlImageSupplier={urlImageSupplier}
				tab={tab}
				setTab={setTab}
				identifierPanelLateral={identifierPanelLateral}
				//object
				params={params}
				loadAlert={loadAlert}
				supplier={supplier}
				previousOriginData={previousOriginData}
				userProfile={userProfile}
				nacionalData={nacionalData}
				internacionalData={internacionalData}
				translate={translate}
				openDialogConfirm={openDialogConfirm}
			/>
			<LateralPanelComponent />

			{loadAlertHistoriesMessages.opened && (
				<HistoryMessagedLateralPanel
					onCloseButtonClick={() => {
						onOpenPanelHistoryMesages([])
					}}
					loadAlertHistoriesMessages={loadAlertHistoriesMessages.items}
				/>
			)}
			{/* <ConfirmPasswordDialog
				open={confirmPasswordConfigModal}
				title={<Translate id='common.confirmOperation' />}
				message1={'Se aceptará la alerta de carga.'}
				message2={<Translate id='common.enterPassword' />}
				onClickButtonConfirm={(message) => {
					if (tab === '1')
						dispatch(
							changeStatusLoadAlert(
								loadAlert.token,
								message.comments,
								loadAlertStatus.AcceptedInternationalCarrierInformation
							)
						).then(() => {
							onModalConfirmLoadAlert()
						})
					else if (tab === '2')
						dispatch(
							changeStatusLoadAlert(
								loadAlert.token,
								message.comments,
								loadAlertStatus.AcceptedNacionalCarrierInformation
							)
						).then(() => {
							onModalConfirmLoadAlert()
						})
				}}
				onClose={() => onModalConfirmLoadAlert()}
				// isSaving={isSaving}
				isVisibleComments={true}
				commentRequired={false}
			/>
			<ConfirmPasswordDialog
				open={confirmPasswordConfigModalDelete}
				title={<Translate id='common.confirmOperation' />}
				message1={'Se rechazará la alerta de carga.'}
				message2={<Translate id='common.enterPassword' />}
				onClickButtonConfirm={(message) => {
					if (tab === '1')
						dispatch(
							changeStatusLoadAlert(
								loadAlert.token,
								message.comments,
								loadAlertStatus.RejectedInternationalCarrierInformation
							)
						).then(() => {
							onModalConfirmLoadAlertDelete()
						})
					else if (tab === '2')
						dispatch(
							changeStatusLoadAlert(
								loadAlert.token,
								message.comments,
								loadAlertStatus.RejectedNacionalCarrierInformation
							)
						).then(() => {
							onModalConfirmLoadAlertDelete()
						})
				}}
				onClose={() => onModalConfirmLoadAlertDelete()}
				// isSaving={isSaving}
				isVisibleComments={true}
				commentRequired={false}
			/> */}

			{confirmPasswordConfigModalFromItem && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModalFromItem.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={onGetMessage1ModalConfirmFromItem()}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(message) => {
						return dispatch(
							changeStatusLoadAlertItem(
								loadAlert.token,
								confirmPasswordConfigModalFromItem.item.token,
								message.comments,
								confirmPasswordConfigModalFromItem.toStatus
							)
						).then(() => {
							onModalConfirmLoadAlertFromItem(null, 0, '')
						})
					}}
					onClose={() => onModalConfirmLoadAlertFromItem(null, 0, '')}
					isSaving={isSaving}
					isVisibleComments={true}
					commentRequired={true}
				/>
			)}

			{confirmPasswordConfigModalForCreateTrackingGuide && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModalForCreateTrackingGuide.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						<WarningComponent
							title='Advertencia'
							message={<Translate id='loadAlerts.warningLoadalert' />}
							// warningError='error'
						/>
					}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(message) => {
						return dispatch(
							createTrackingGuide(
								loadAlert.token,
								confirmPasswordConfigModalForCreateTrackingGuide.item.token
							)
						).then(() => {
							onConfirmPasswordConfigModalForCreateTrackingGuide(null)
						})
					}}
					onClose={() => onConfirmPasswordConfigModalForCreateTrackingGuide(null)}
					isSaving={isSaving}
				/>
			)}

			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

// export default withauthenticatedLayout(Show)
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
