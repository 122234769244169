/** Import ReactJS */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'

/** Material UI import section */
import { Card, Grid, Divider, Typography, Button, Tooltip, InputAdornment } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import AssignmentIcon from '@material-ui/icons/Assignment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import FolderIcon from '@material-ui/icons/AttachFile'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MarkunreadIcon from '@material-ui/icons/Markunread'
import MoneyIcon from '@material-ui/icons/Money'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import SendIcon from '@material-ui/icons/Send'
// import components section
import Evidence from '../../../common/Evidence'
/**Import resources section */
import { BACKGROUND_COLOR_GRAY, COLOR_STATUS_ALERT_INFO } from '../../../../store/helpers/StatusColorConstants'
import { GetPaymentConditionsType } from '../../../../store/helpers/SelectOptions'
import { BankType, FactoringStatus, FilesFolderName } from '../../../../store/helpers/AppConstants'
import downloadTemplate from '../../../../resources/files/LayoutCiti.xlsx'
import {
	IsUserInRole,
	ROLE_FACTORING_AFFILIATION_ADMINISTRATOR,
	ROLE_FACTORING_AFFILIATION_CREATOR,
} from '../../../../store/helpers/RolesHelper'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'

const BankInformation = (props) => {
	//Array props section
	const { userRolesByCompany } = props
	//int props section
	const { activeStep } = props
	//Function props section
	const { onUpdateFactoring, onAddAttachmets, onDeleteFactoringsFiles, onDownloadFactoringFile } = props
	const { factoring, classes } = props
	//bool props section
	const { isInternalUser, canBeEditInformation, isSaving } = props

	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		item: null,
	})

	let optionsPayments = GetPaymentConditionsType()
	let existPaymentConditions = optionsPayments.find((x) => x.value.toString() === factoring.PaymentConditions)
	let isUserCreator = IsUserInRole(ROLE_FACTORING_AFFILIATION_CREATOR, userRolesByCompany)
	let isUserAdministrator = IsUserInRole(ROLE_FACTORING_AFFILIATION_ADMINISTRATOR, userRolesByCompany)

	const onConfirmPassword = (item) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			item: item,
		})
	}

	const IsVoBoMising =()=>{
		if(factoring && factoring.bankFiles.length === 0 
			&& (factoring.PaymentConditions === '90' || factoring.PaymentConditions === '120' ) )
			return true;
		return false;
	}

	const IsMissingCitiFiles =()=>{
		if(factoring &&factoring.BankType === BankType.CITI && factoring.citiFiles.length === 0 )
			return true;
		return false;
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Card className={classes.card}>
						<CardHeader
							className={classes.cardHeader}
							avatar={
								<Avatar aria-label='Recipe' className={classes.avatar}>
									<AssignmentIcon />
								</Avatar>
							}
							title={'Información de los datos bancarios para la afilación'}
							action={
								isUserCreator &&
								factoring &&
								factoring.FactoringStatus === FactoringStatus.DRAFT && (
									<Tooltip title={'Enviar a revisión'}>
										<IconButton
											onClick={onConfirmPassword}
											disabled={isSaving || (factoring && !factoring.CanBeSendToReview) || IsVoBoMising() || IsMissingCitiFiles()}
										>
											<SendIcon color='primary' />
										</IconButton>
									</Tooltip>
								)
							}
						/>
						<Divider />
						<CardContent>
							<Grid container direction='row' justify='center' alignItems='flex-start' spacing={8}>
								<Grid item xs={12}>
									<List component='nav' className={classes.root}>
										<ListItem>
											<ListItemIcon>
												<MoneyIcon color='primary' />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														<Translate id='factoringAffiliation.steps.paymentConditions' />
													</Typography>
												}
												secondary={
													<>
														<Select
															className={classes.documentTypeSelect}
															options={GetPaymentConditionsType()}
															onChange={(option) =>
																onUpdateFactoring(
																	activeStep,
																	factoring.Currencies,
																	`${option.value}`,
																	factoring ? factoring.AnnualPurchase : '_',
																	factoring ? factoring.AceptedBySupplier : '-',
																	factoring ? factoring.CommentRejectedSupplier : '-'
																)
															}
															value={existPaymentConditions}
															styles={{
																menu: (base) => ({
																	...base,
																	zIndex: 10,
																	maxHeight: 200,
																}),
																menuList: (base) => ({
																	...base,
																	maxHeight: 200,
																	paddingTop: 0,
																}),
																menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
															}}
															menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
															menuPlacement={'auto'}
															isDisabled={												
																!isInternalUser ||
																!canBeEditInformation ||
																isSaving ||
																!isUserCreator
															}
														/>
														{(factoring.PaymentConditions === '90' || factoring.PaymentConditions === '120') && (
															<>
																{canBeEditInformation && isUserCreator && (
																	<>
																		<Typography>
																			Por favor ingresa el archivo del visto bueno
																			por parte del administrador
																		</Typography>

																		<Evidence
																			files={[]}
																			maxFiles={1}
																			uploadFiles={(files) =>
																				onAddAttachmets(
																					files,
																					FilesFolderName.BankFiles
																				)
																			}
																		/>
																	</>
																)}

																<Grid item xs={12}>
																	<List>
																		{factoring &&
																			factoring.bankFiles.map((file) => {
																				return (
																					<>
																						<ListItem
																							className={classes.fileItem}
																						>
																							<ListItemAvatar>
																								<Avatar>
																									<FolderIcon />
																								</Avatar>
																							</ListItemAvatar>
																							<ListItemText
																								primary={file.name}
																							/>
																							<ListItemSecondaryAction>
																								{(isUserAdministrator ||
																									isUserCreator) && (
																									<IconButton
																										aria-label='download'
																										onClick={() =>
																											onDownloadFactoringFile(
																												file.name,
																												FilesFolderName.BankFiles
																											)
																										}
																										disabled={
																											isSaving
																										}
																									>
																										<CloudDownloadIcon />
																									</IconButton>
																								)}
																								{canBeEditInformation &&
																									isUserCreator && (
																										<IconButton
																											aria-label='Delete'
																											onClick={() =>
																												onDeleteFactoringsFiles(
																													file.name,
																													FilesFolderName.BankFiles
																												)
																											}
																											disabled={
																												isSaving ||
																												!isUserCreator
																											}
																										>
																											<DeleteIcon />
																										</IconButton>
																									)}
																							</ListItemSecondaryAction>
																						</ListItem>
																						<Divider />
																					</>
																				)
																			})}
																		{factoring &&
																			factoring.bankFiles.length === 0 && (
																				<Typography>
																					No existen archivos cargados hasta
																					el momento
																				</Typography>
																			)}
																	</List>
																</Grid>
															</>
														)}
													</>
												}
											/>
										</ListItem>
										<Divider />
										<ListItem button>
											<ListItemIcon>
												<MarkunreadIcon color='primary' />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														<Translate id='factoringAffiliation.steps.bank' />
													</Typography>
												}
												secondary={
													factoring && factoring.BankType === BankType.BBVA ? 'BBVA' : 'CITI'
												}
											/>
										</ListItem>
										<Divider />

										<ListItem button>
											<ListItemIcon>
												<AttachMoneyIcon color='primary' />
											</ListItemIcon>
											<ListItemText
												inset
												primary={
													<Typography className={classes.textTitle}>
														<Translate id='factoringAffiliation.steps.anualPurchase' />
													</Typography>
												}
												secondary={
													<div>
														<TextField
															type='number'
															defaultValue={factoring ? factoring.AnnualPurchase : 0}
															onBlur={(event) =>
																onUpdateFactoring(
																	activeStep,
																	factoring.Currencies,
																	factoring ? factoring.PaymentConditions : '-',
																	event.target.value,
																	factoring ? factoring.AceptedBySupplier : '-',
																	factoring ? factoring.CommentRejectedSupplier : '-'
																)
															}
															disabled={!isInternalUser || !canBeEditInformation || isSaving}
															InputProps={{
																endAdornment :
																<InputAdornment position="end">
																	{(factoring && factoring.Currencies.length > 0 ) ?
																		<Typography className={classes.textTitle}>
																			{factoring.Currencies[0]}
																		</Typography> : '' }
															  	</InputAdornment>
															}}
														/>
													</div>
												}
											/>
										</ListItem>
									</List>
									<Divider />
									{factoring && factoring.BankType === BankType.CITI && (
										<>
											<Grid item xs={12}>
												<List component='nav' className={classes.root}>
													<ListItem>
														<ListItemIcon>
															<MoneyIcon color='primary' />
														</ListItemIcon>
														<ListItemText
															inset
															primary={
																isInternalUser && 
																	<Typography className={classes.textTitle}>
																	Por favor ingresa elarchivo layout para la
																	afiliación con CITI, asegurate de que el archivo
																	tenga toda la información necesaria para la
																	afilación.
																</Typography>
															}
															secondary={
																<>
																	<a href={downloadTemplate}>
																		<Button
																			data-tut='reactour-save'
																			data-cy='btn-save'
																			type='button'
																			color='primary'
																			variant='outlined'
																			className={classes.btnCreate}
																		>
																			<CloudDownloadIcon />
																			Layout Citi
																		</Button>
																	</a>
																	{canBeEditInformation && isUserCreator && (
																		<Evidence
																			files={[]}
																			maxFiles={1}
																			uploadFiles={(files) =>
																				onAddAttachmets(
																					files,
																					FilesFolderName.CitiFiles
																				)
																			}
																		/>
																	)}
																	<List>
																		{factoring &&
																			factoring.citiFiles.map((file) => {
																				return (
																					<>
																						<ListItem
																							className={classes.fileItem}
																						>
																							<ListItemAvatar>
																								<Avatar>
																									<FolderIcon />
																								</Avatar>
																							</ListItemAvatar>
																							<ListItemText
																								primary={file.name}
																								// secondary={secondary ? 'Secondary text' : null}
																							/>
																							<ListItemSecondaryAction>
																								<IconButton
																									aria-label='download'
																									onClick={() =>
																										onDownloadFactoringFile(
																											file.name,
																											FilesFolderName.CitiFiles
																										)
																									}
																									disabled={isSaving}
																								>
																									<CloudDownloadIcon />
																								</IconButton>
																								{canBeEditInformation && (
																									<IconButton
																										aria-label='Delete'
																										onClick={() =>
																											onDeleteFactoringsFiles(
																												file.name,
																												FilesFolderName.CitiFiles
																											)
																										}
																										disabled={
																											isSaving
																										}
																									>
																										<DeleteIcon />
																									</IconButton>
																								)}
																							</ListItemSecondaryAction>
																						</ListItem>
																						<Divider />
																					</>
																				)
																			})}
																		{factoring &&
																			factoring.citiFiles.length === 0 && (
																				<Typography>
																					No existen archivos cargados hasta
																					el momento
																				</Typography>
																			)}
																	</List>
																</>
															}
														/>
													</ListItem>
												</List>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						'Se enviara la información al administrador para su seguimiento. Una vez enviada la información ya no se pueden realizar cambios'
					}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(comment) =>
						onUpdateFactoring(
							2,
							factoring.Currencies,
							factoring ? factoring.PaymentConditions : '-',
							factoring ? factoring.AnnualPurchase : '_',
							false,
							factoring ? factoring.Comments : '_'
						).then((_) => {
							onConfirmPassword(null)
						})
					}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({
	card: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	cardHeader: {
		backgroundColor: COLOR_STATUS_ALERT_INFO,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(BankInformation))
