import React, { useState } from 'react'
import { Button, IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { AddBox, AddCircle, CloudDownload, Delete } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import DocumentToAddModal from './DocumentToAddModal'
import AttachmentIcon from '@material-ui/icons/Attachment'

const useStyle = (theme) => ({})

const ListDocumentComponentAdd = (props) => {
	const {
		documents,
		title,
		// panel,
		onDeleteFilesFromItem,
		item,
		editable,
		folderPathIdentifier,
		isSavingFile
	} = props

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleOpenModal = () => {
		setIsModalOpen(true)
	}

	const handleCloseModal = () => {
		setIsModalOpen(false)
	}

	const onUploadFileInItem =(files)=>{
		if(props.onUploadFileInItem){
			props.onUploadFileInItem(item,files,folderPathIdentifier)
		}
	}

	return (
		<List>
			<ListItem>
				<ListItemText
					primary={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', gap: '15px' }}>
								{editable ? <AddBox /> : <AttachmentIcon />}
								<Typography
									component='span'
									variant='body2'
									color='textSecondary'
									style={{ whiteSpace: 'nowrap' }}
								>
									<strong>
										{' '}
										<Translate id={title} />
									</strong>
								</Typography>
							</div>
							{editable && (
								<Button onClick={handleOpenModal}>
									<Typography component='span' variant='body2' style={{ marginRight: '8px' }}>
										Agregar documento
									</Typography>
									<AddCircle style={{ color: '#4377C4' }} />
								</Button>
							)}
						</div>
					}
				/>
			</ListItem>
			{documents && documents.length > 0 && (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 250px))',
						gap: '15px 30px',
					}}
				>
					{documents.map((document, index) => (
						<Paper
							style={{
								display: 'flex',
								backgroundColor: '#F0F4F8',
								padding: '0rem 1rem 0rem 1rem',
								alignItems: 'center',
								height: '3.5rem',
							}}
						>
							<ListItemText
								primary={
									<Typography
										title={document.name}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											maxWidth: '100%',
										}}
									>
										{document.name}
									</Typography>
								}
							/>
							<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
								<IconButton
									target='_blank'
									style={{ padding: '5px' }}
									href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${document.relativePath}`}
								>
									<CloudDownload style={{ color: '#4377C4', fontSize: '20px' }} />
								</IconButton>
								{editable && (
									<IconButton
									    disabled={isSavingFile}
										style={{ padding: '5px' }}
										onClick={() => onDeleteFilesFromItem(item, folderPathIdentifier, document.name)}
									>
										<Delete style={{ color: '#4377C4', fontSize: '20px' }} />
									</IconButton>
								)}
							</div>
						</Paper>
					))}
				</div>
			)}
			<DocumentToAddModal open={isModalOpen} onClose={handleCloseModal} handleSave={onUploadFileInItem} isSavingFileBtn={isSavingFile} />
		</List>
	)
}
ListDocumentComponentAdd.defaultProps = {
	editable: false,
}

export default withRouter(withStyles(useStyle, { withTheme: true })(ListDocumentComponentAdd))
