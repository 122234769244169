import { Button, Drawer, Icon, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Dropzone from './Dropzone'
import { isSavingFile } from '../../store/exportRequest/ExportRequestAction'
import { useDispatch, useSelector } from 'react-redux'

const DocumentToAddModal = ({ open, onClose, handleSave, isSavingFileBtn }) => {
	const [files, setFiles] = React.useState([]);
	const dispatch = useDispatch()

	const isSavingFiles = useSelector((state) => state.exportRequest.get('isSavingFile'));

	const handleFileChange = async (newFiles) => {
		dispatch(isSavingFile(true));
		await new Promise((resolve) => {
			setFiles([...files, ...newFiles]);
			resolve();
			
		});
		dispatch(isSavingFile(false));
		return 'Ok'
	}
	
	const onRemoveFile = async (fileName) => {
		dispatch(isSavingFile(true));

		await new Promise((resolve) => {
			setFiles(files.filter(file => file.name !== fileName));
			resolve();
		});
		dispatch(isSavingFile(false));
		return 'Ok'
	}

	const handleSaveBtn = (files) => {
		dispatch(isSavingFile(true));
		onClose();
		handleSave(files);
		setFiles([]);
		dispatch(isSavingFile(false));
	}
	return (
		<Drawer anchor='right' open={open} onClose={onClose} style={{ width: '100%', height: '100%' }}>
			<div style={{ backgroundColor: 'white', width: '30vw', height: '100%' }}>
				<IconButton style={{ position: 'absolute', right: 0 }} onClick={onClose}>
					<CancelIcon />
				</IconButton>
				<div style={{ width: '100%', textAlign: 'center' }}>
					<Icon style={{ color: '#86EDCD' }}>
						<NotificationsIcon />
					</Icon>
					<Typography variant='h6' style={{ color: '#757575' }}>
						<Translate id='common.addDocument' />
					</Typography>
				</div>
				<div style={{ margin: '2rem 1rem 1rem 1rem' }}>
					<Typography variant='subtitle2'>
						<Translate id='common.addDocument' />
					</Typography>
				</div>
				<div style={{ margin: '2rem' }}>
					{!isSavingFiles &&
					<Dropzone 
						files={files}
						onChange={handleFileChange} 
						onDeleteFile={onRemoveFile}
					/>}
					<Button
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						disabled={isSavingFileBtn || files.length === 0}
						style={{ width: '100%', marginTop: '5rem' }}
						onClick={() => handleSaveBtn(files)}
						>
						{<Translate id='common.save' />}
					</Button>
				</div>
			</div>
		</Drawer>
	)
}

export default DocumentToAddModal
