import { fromJS } from 'immutable'
import * as transportTender from './transportTenderAction'

export const transportTenderInitialState = fromJS({
	isLoadingTransportTender: false,
	isSaving: false,
	transportTender: null,
	detail: null,
	detailWinner:null,
	detailTransportType: {
		code: null,
		description: null,
	},
	sortCriteria: {
		sortBy: 'CreationDate',
		sortDirection: 'asc',
	},
	searchQuery: '',
	page: 0,
	rowsPerPage: 20,
	transportTenderCount: 0,
	isLoadingCatalog: false,
	transportZone: [],
	clientsCatalog: [],
	wineCellar: [],
	transportType: [],
	typeShipment: [],
	carriers: [],
	carrierInformation: null,
	errorTransportTenderFile: [],
	errorIsLoadingFile: false,
	isProposal: false,
	wineCellarPagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	ratePagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	transportZonePagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	transportTypePagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	requirementsCarriersPagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	clientsPagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	maneuversPagination: {
		items: [],
		pageNumber: 0,
		pageSize: 20,
		total: 0,
		sort: null,
	},
	eventsSchedule: [],
	timeOptions: [],
	isLoadingDownloadCarrierLog: false,
	errorTransportZoneFile: [],
	errorTransportTypeFile: [],
	errorManeuversFile: [],
	errorTariffSAPFile: [],
	errorRequirementsCarrierFile: [],
	errorWineCellarFile: [],
	errorClientsFile: [],
	isLoadingCodeFromSAP: false,
	materialSap: '',
	winnersTransportTenders: [],
	isLoadingWinners: false,
})

export default function moduleTransportTender(state = transportTenderInitialState, action) {
	switch (action.type) {
		case transportTender.IS_LOADING_TRANSPORT_TENDER_INDEX: {
			return state.merge({
				isLoadingTransportTender: action.isLoadingIndex,
			})
		}
		case transportTender.IS_LOADING: {
			return state.merge({
				[action.nameIsloading]: action.valueIsloading,
			})
		}
		case transportTender.SET_TRANSPORT_TENDER_LIST: {
			return state.merge({
				transportTender: fromJS(action.transportTender.items),
				transportTenderCount: action.transportTender.total,
				page: action.transportTender.pageNumber,
				rowsPerPage: action.transportTender.pageSize,
				sortCriteria: {
					sortBy: 'CreationDate',
					sortDirection: action.transportTender.sortDirection,
				},
				isLoadingTransportTender: false,
			})
		}
		case transportTender.IS_SAVING_TRANSPORT_TENDER_LIST: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case transportTender.IS_LOADING_REPORT: {
			return state.merge({
				isSaving: action.isSaving,
			})
		}
		case transportTender.SET_MATERIAL_LOADING_SCHEDULE: {
			return state.merge({
				eventsSchedule: action.eventsData,
			})
		}
		case transportTender.SET_TRANSPORT_TENDER_DETAIL: {
			return state.merge({
				detail: fromJS(action.detail),
				detailTransportType: {
					code: action.detail.TransportTypeCode,
					description: action.detail.TransportTypeDescription,
				},
			})
		}
		case transportTender.SET_TRANSPORT_TENDER_WINNER: {
			return state.merge({
				detailWinner: fromJS(action.detailWinner),
			})
		}
		case transportTender.ERROR_TRANSPORT_TENDER_FILE: {
			return state.merge({
				errorTransportTenderFile: fromJS(action.errors),
			})
		}
		case transportTender.SET_TRANSPORT_CATALOG: {
			return state.merge({
				[action.nameCatalog]: fromJS(action.catalog),
			})
		}
		case transportTender.UPDATE_TRANSPORT_CATALOG: {
			const catalog = fromJS(action.catalog)
			return state.updateIn([action.nameCatalog], (allCatalog) => {
				let index = allCatalog.findIndex((d) => d.get('Id') === catalog.get('Id'))
				if (index >= 0) {
					return allCatalog.setIn([index], catalog)
				}
				return allCatalog.push(catalog)
			})
		}
		case transportTender.IS_LOADING_TRANSPORT_CARALOG: {
			return state.merge({
				isLoadingCatalog: action.isLoadingIndex,
			})
		}
		case transportTender.SET_DESTINATIONS: {
			const destination = fromJS(action.destinations)
			return state.updateIn(['detail', 'Destinations'], (allDestinations) =>
				addDestinations(allDestinations, destination)
			)
		}
		case transportTender.SET_CARRIES: {
			return state.updateIn(['detail', 'Carriers'], (allCarries) => action.carries)
		}
		case transportTender.SET_STATUS: {
			return state.updateIn(['detail'], (allCarries) => action.trasport)
		}
		case transportTender.UPDATE_CARRIER: {
			const carrier = fromJS(action.carrier)
			const typeShipmentIndex = state
				.getIn(['typeShipment'])
				.findIndex((d) => d.get('Id') === carrier.get('TypeShipment'))

			return state.updateIn(['typeShipment', typeShipmentIndex, 'Carriers'], (Carriers) => {
				return Carriers.push(carrier)
			})
		}
		case transportTender.SET_DELIVERY: {
			const delivery = fromJS(action.delivery)

			const destinationIndex = state
				.getIn(['detail', 'Destinations'])
				.findIndex((d) => d.get('Id') === delivery.get('DestinationId'))
			const deliveryIndex = state
				.getIn(['detail', 'Destinations', destinationIndex, 'Deliveries'])
				.findIndex((d) => d.get('Id') === delivery.get('Id'))
			if (deliveryIndex >= 0)
				return state.setIn(['detail', 'Destinations', destinationIndex, 'Deliveries', deliveryIndex], delivery)
			else {
				return state.updateIn(['detail', 'Destinations', destinationIndex, 'Deliveries'], (allDestination) => {
					return allDestination.push(delivery)
				})
			}
		}
		case transportTender.SET_MATERIALS: {
			const materials = fromJS(action.materials)
			const destinationIndex = state
				.getIn(['detail', 'Destinations'])
				.findIndex((d) => d.get('Id') === materials.get('DestinationId'))

			if (materials.toJS().length > 0) {
				const deliveryIndex = state
					.getIn(['detail', 'Destinations', destinationIndex, 'Deliveries'])
					.findIndex((d) => d.get('Id') === materials.toJS()[0].DeliveriesId)
				if (deliveryIndex >= 0)
					return state.updateIn(
						['detail', 'Destinations', destinationIndex, 'Deliveries', deliveryIndex, 'Materials'],
						(allMaterials) => {
							return allMaterials.push(...materials)
						}
					)
			}
			return state
		}
		case transportTender.SET_TRANSPORT_TENDER_UPDATE: {
			return state.updateIn(['detail'], () => action.trasport)
		}
		case transportTender.DELETE_MATERIAL: {
			const material = fromJS(action.material)
			const destinationIndex = state
				.getIn(['detail', 'Destinations'])
				.findIndex((d) => d.get('Id') === material.get('DestinationId'))
			const deliveryIndex = state
				.getIn(['detail', 'Destinations', destinationIndex, 'Deliveries'])
				.findIndex((d) => d.get('Id') === material.get('DeliveriesId'))
			if (deliveryIndex >= 0)
				return state.updateIn(
					['detail', 'Destinations', destinationIndex, 'Deliveries', deliveryIndex, 'Materials'],
					(allMaterials) => {
						return allMaterials.filter((m) => m.get('Id') !== material.get('Id'))
					}
				)

			return state
		}
		case transportTender.DELETE_DELIVERY: {
			const delivery = fromJS(action.delivery)
			const destinationIndex = state
				.getIn(['detail', 'Destinations'])
				.findIndex((d) => d.get('Id') === delivery.get('DestinationId'))

			return state.updateIn(['detail', 'Destinations', destinationIndex, 'Deliveries'], (allDestination) => {
				return allDestination.filter((d) => d.get('Id') !== delivery.get('Id'))
			})
		}
		case transportTender.DELETE_DESTINATION: {
			const destination = fromJS(action.destination)
			return state.updateIn(['detail', 'Destinations'], (allDestination) => {
				return allDestination.filter((d) => d.get('Id') !== destination.get('Id'))
			})
		}
		case transportTender.SET_CARRIER_INFORMATION: {
			const information = fromJS(action.information)
			return state.merge({
				carrierInformation: information,
			})
		}
		case transportTender.UPDATE_CHOOSE_WINNING: {
			return state.updateIn(['detail', 'Proposals'], () => action.proposal)
		}
		case transportTender.SET_TIME_OPTIONS: {
			return state.merge({
				timeOptions: action.timeOptions,
			})
		}
		case transportTender.IS_LOADING_DOWNLOAD_CARRIER_LOG: {
			return state.merge({
				isLoadingDownloadCarrierLog: action.isLoadingDownloadCarrierLog,
			})
		}
		case transportTender.SET_TRANSPORT_ZONE_LIST: {
			return state.merge({
				transportZone: fromJS(action.transportZone),
			})
		}
		case transportTender.ERROR_TRANSPORT_ZONE_FILE: {
			return state.merge({
				errorTransportZoneFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_TRANSPORT_TYPE_FILE: {
			return state.merge({
				errorTransportTypeFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_MANEUVERS_FILE: {
			return state.merge({
				errorManeuversFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_TARIFF_SAP_FILE: {
			return state.merge({
				errorTariffSAPFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_REQUIREMENT_CARRIER_FILE: {
			return state.merge({
				errorRequirementsCarrierFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_WINE_CELLAR_FILE: {
			return state.merge({
				errorWineCellarFile: fromJS(action.errors),
			})
		}
		case transportTender.ERROR_CLIENT_FILE: {
			return state.merge({
				errorClientsFile: fromJS(action.errors),
			})
		}
		case transportTender.SET_MATERIAL_FROM_SAP: {
			return state.merge({
				materialSap: fromJS(action.material),
			})
		}
		case transportTender.SET_WINNERS_TRANSPORT_TENDERS: {
			return state.merge({
				winnersTransportTenders: fromJS(action.winners),
			})
		}
		case transportTender.SET_IS_LOADING_WINNERS: {
			return state.merge({
				isLoadingWinners: fromJS(action.isLoading),
			})
		}
		default:
			return state
	}
}

const addDestinations = (allDestinations, destination) => {
	let indexOfBidding = allDestinations.findIndex((d) => d.get('Id') === destination.get('Id'))

	if (indexOfBidding >= 0) {
		return allDestinations.setIn([indexOfBidding], destination)
	}
	return allDestinations.push(destination)
}
