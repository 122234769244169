///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import Avatar from '@material-ui/core/Avatar'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'

/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import FactoringAffiliationTable from './components/Index/FactoringAffiliationTable'
import { toSafeObject } from '../../store/helpers/ListHelper'
import LoadingPage from '../common/LoadingPage'
import SearchInput from '../common/SearchInput/SearchInput'
import CreateFactoringAffiliationPanel from './components/Index/CreateFactoringAffiliationPanel'

//import action section
import { loadFactoringAffiliationPaginatedFromBackEnd } from '../../store/factoringAffiliations/FactoringAffiliationActions'

const FactoringAffiliationIndex = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	/**Redux store information section */
	const isSaving = useSelector((state) => state.factoringAffiliation.get('isSaving'))
	const isLoading = useSelector((state) => state.factoringAffiliation.get('isLoading'))
	const factoringAffiliationPaginated = useSelector((state) =>
		state.factoringAffiliation.get('factoringAffiliationPaginated')
			? toSafeObject(state.factoringAffiliation.get('factoringAffiliationPaginated'))
			: null
	)
	/** Get user Information*/
	const userProfile = useSelector((state) => state.oidc.user)
	const isInternalUser = () => (userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : '')

	const administratorSupplier = useSelector((state) =>
		state.externalUsers.get('administratorSupplier')
			? toSafeObject(state.externalUsers.get('administratorSupplier'))
			: null
	)

	/**Use this section for set use state */
	const [openCreateFactoringLateralPanel, setOpenCreateFactoringLateralPanel] = useState(false)

	/**Use effect section */
	useEffect(() => {
		let queryParams = queryString.parse(location.search)
		if (!isInternalUser()) {
			console.log('isInternalUser()', isInternalUser())
			console.log('userProfile.profile ()', userProfile.profile)
			queryParams.SupplierCode = userProfile && userProfile.profile ? userProfile.profile.company_code : '-'
			console.log('queryParams()', queryParams)
		}
		dispatch(loadFactoringAffiliationPaginatedFromBackEnd(queryParams))
	}, [dispatch, location.search])

	const onOpenCreateFactoringAffiliationPanel = () => {
		setOpenCreateFactoringLateralPanel(!openCreateFactoringLateralPanel)
	}
	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	/**
	 * onRowClick
	 * @param {*} calendar
	 * @param {*} event
	 */
	const onRowClick = (itemRow, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/factoringaffiliation/${itemRow.id}`)
		}
	}

	const onRedirectToDetail = (id) => [props.history.push(`/factoringaffiliation/${id}`)]

	const getCounterMessage = () => {
		if (factoringAffiliationPaginated && factoringAffiliationPaginated.items) {
			let total = factoringAffiliationPaginated.items.length
			let factoringCount = factoringAffiliationPaginated.total
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{' '}{total}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{' '}{factoringCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	const onGetCardContent = () => {
		if (isLoading) {
			return <LoadingPage />
		} else {
			return (
				<FactoringAffiliationTable
					factoringAffiliationPaginated={factoringAffiliationPaginated}
					queryParams={queryParams}
					onChangePage={(newPage, newRowsPerPage) => {
						onChangePage(newPage, newRowsPerPage)
					}}
					onRedirectToDetail={onRowClick}
					onChangeSortCriteria={onChangeSortCriteria}
				/>
			)
		}
	}

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<ContactPhoneIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

							<Tooltip title={<Translate id='factoringAffiliation.create' />}>
								<Fab
									size='small'
									color='primary'
									className={classes.categoryIcon}
									disabled={isSaving}
									onClick={onOpenCreateFactoringAffiliationPanel}
								>
									<AddIcon />
								</Fab>
							</Tooltip>

					</div>
				}
				title={<Typography variant='button'>{<Translate id='factoringAffiliation.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>
			<CardContent>{onGetCardContent()}</CardContent>

			{openCreateFactoringLateralPanel && (
				<CreateFactoringAffiliationPanel
					//Objects props section
					administratorSupplier={administratorSupplier}
					//Functions props section
					onCloseButtonClick={onOpenCreateFactoringAffiliationPanel}
					onRedirectToDetail={onRedirectToDetail}
				/>
			)}
		</Card>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(FactoringAffiliationIndex)))
