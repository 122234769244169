import React from 'react'
import { Typography, Divider } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { TransportTendeStatus } from '../../../../store/helpers/AppConstants'

const CarrierTable = (props) => {
	const { setOpenPanel, carrierBidding, transportTenderdetail } = props

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 15px 15px 15px' }}>
				<Typography gutterBottom variant='body2' component='p'>
					Transportista
				</Typography>
				{(transportTenderdetail.Status === TransportTendeStatus.DRAFT ||
					transportTenderdetail.Status === TransportTendeStatus.SENT_TO_CARRIER ||
					transportTenderdetail.Status === TransportTendeStatus.IN_EVALUATION ||
					transportTenderdetail.Status === TransportTendeStatus.SENT_LONG_TO_WINNING_CARRIER
				) && (
					<div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setOpenPanel(true)}>
						<Typography gutterBottom variant='body2' component='p'>
							Editar listado de transportistas
						</Typography>
						<EditIcon color='primary' />
					</div>
				)}
			</div>
			<Divider />
			{carrierBidding &&
				carrierBidding.map((carrier) => {
					return (
						<div key={carrier.Id}>
							<div style={{ display: 'flex', alignItems: 'center', margin: '7px 0px 7px 15px' }}>
								<Typography gutterBottom variant='body2' component='p'>
									{carrier.Code} | {carrier.Name}
								</Typography>
							</div>
							<Divider />
						</div>
					)
				})}
		</div>
	)
}

export default CarrierTable
