//Import react section
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
//Import Mui section
import AddIcon from '@material-ui/icons/AddTwoTone'
import { Group } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Card, CardHeader, Fab, IconButton, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

//Impoprt components section
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import DataTable from '../common/DataTable'
//Import store section
// import { getLoggedUserSelector } from '../../store/profile/ProfileSelectors'
import { canBeModuleConsignmentNote } from '../../store/helpers/RolesHelper'
import {
	loadFerryCuponManagerPaginatedCatalog,
	validateFerryCuponManagerTemplate,
	importFerryCuponManagerCatalog,
	downloadFerryCuponManagerTemplate,
	deleteFerryCuponManager,
	SET_CUPON_FERRY,
} from '../../store/consignmentNote/consignmentNoteActions'
import { converListToArray } from '../../store/helpers/ListHelper'
import SearchInput from '../common/SearchInput/SearchInput'
import DialogUploadMassiveFerryCuponManager from './components/indexFerryCuponManager/DialogUploadMassiveFerryCuponManager.js'
const Maneuver = (props) => {
	const { classes } = props
	let history = useHistory()
	let location = useLocation()
	const dispatch = useDispatch()

	const [openDialog, setOpenDialog] = useState(false)
	const [file, setfile] = useState([])

	const queryParams = queryString.parse(location.search)
	// const loggetUser = useSelector((state) => getLoggedUserSelector(state))
	const identifier = useSelector((state) => state.consignmentNote.get('identifier'))
	const isSaving = useSelector((state) => state.consignmentNote.get('isSaving'))
	const cuponsFerryManagerData = useSelector((state) =>
		state.consignmentNote.get('cuponsFerryManager')
			? converListToArray(state.consignmentNote.get('cuponsFerryManager'))
			: []
	)
	const cuponsFerryManagerCount = useSelector((state) => state.consignmentNote.get('cuponsFerryManagerCount'))
	const page = useSelector((state) => state.consignmentNote.get('page'))
	// const sortCriteria = useSelector((state) => state.consignmentNote.get('sortCriteria'))
	const rowsPerPage = useSelector((state) => state.consignmentNote.get('rowsPerPage'))

	const ErrorsManeuversFile = useSelector((state) =>
		state.consignmentNote.get('errorsCuponsFerryManager')
			? converListToArray(state.consignmentNote.get('errorsCuponsFerryManager'))
			: []
	)
	/** Connect with redux */
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	// const userRoles = loggetUser && loggetUser.grantedRoles ? loggetUser.grantedRoles : []
	const isTransportTenderAdminitsrator = canBeModuleConsignmentNote(userRolesByCompany)

	const onChangeQuery = (query) => {
		if (query) queryParams.query = query
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}
	const onChangePage = (page, rowsPerPage) => {
		queryParams.pageNumber = page + 1
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}
	useEffect(() => {
		dispatch(loadFerryCuponManagerPaginatedCatalog(queryParams))
	}, [dispatch, location.search])

	useEffect(() => {
		if (file.length > 0) {
			dispatch(validateFerryCuponManagerTemplate(file))
		}
	}, [file])

	const onDownloadTemplateFilled = () => {
		return dispatch(downloadFerryCuponManagerTemplate())
	}
	const canBeSaved = () => {
		if (ErrorsManeuversFile.length > 0) return false
		if (file.length === 0) return false
		return true
	}
	const onSave = () => {
		dispatch(importFerryCuponManagerCatalog(file))
			.then(() => {
				setOpenDialog(false)
				dispatch(loadFerryCuponManagerPaginatedCatalog(queryParams))
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}

	const onDeleteFerryCupon = (item) => {
		return dispatch(deleteFerryCuponManager(item.id))
			.then(() => {
				return Promise.resolve('Ok')
			})
			.catch(() => {
				return Promise.reject()
			})
	}
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'cCEmails') return item.ccEmails.length !== 0 ? item.ccEmails.join() : ''
		if (dataSource === 'options')
			return (
				<div>
					<IconButton disabled={isSaving} onClick={() => onDeleteFerryCupon(item)}>
						<Delete fontSize='small' color='primary' />
					</IconButton>
				</div>
			)
	}

	const getCounterMessage = () => {
		if (cuponsFerryManagerData) {
			let totalRegisterByPage = cuponsFerryManagerData.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalRegisterByPage}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{cuponsFerryManagerCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar>
						<Group />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeQuery}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

						{isTransportTenderAdminitsrator && (
							<Fab size='small' color='primary' onClick={() => setOpenDialog(true)}>
								<AddIcon />
							</Fab>
						)}
					</div>
				}
				title={<Typography variant='button'>{'Administración cupones ferries'}</Typography>}
				subheader={getCounterMessage()}
			/>

			{(isSaving && identifier === SET_CUPON_FERRY) ? (
				<LoadingPage />
			) : (
				<DataTable
					// data={cuponsFerryManagerData.items}
					data={cuponsFerryManagerData}
					configuration={TableConfiguration()}
					onRenderCellItem={onRenderCellItem}
					showFilters
					isIndex
					page={page}
					rowsPerPage={rowsPerPage}
					totalRows={cuponsFerryManagerCount}
					onChangePage={onChangePage}
				/>
			)}

			{openDialog && (
				<DialogUploadMassiveFerryCuponManager
					title={'Importación masiva de administración de cupones de ferry'}
					typeLoadTranslate={'massiveManeuvers'}
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					file={file}
					setfile={setfile}
					onDownloadTemplateFilled={onDownloadTemplateFilled}
					isLoading={isSaving}
					canBeSaved={canBeSaved()}
					errorList={ErrorsManeuversFile}
					onSave={onSave}
				/>
			)}
		</Card>
	)
}
function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id={'carrierTenders.maneuvers.table.transportZone'} />,
			dataSource: 'transportZoneCode',
		},
		{
			header: 'Punto de cruce',
			dataSource: 'crossingPoint',
		},
		{
			header: 'Punto de desembarque',
			dataSource: 'dropOffPoint',
		},
		{
			header: 'Contacto proveedor',
			dataSource: 'contactSupplier',
		},
		{
			header: 'Proveedor de cupón',
			dataSource: 'supplierName',
		},
		{
			header: 'CC emails encargados',
			dataSource: 'ccEmails',
		},
		{
			header: <Translate id={'carrierTenders.deals.requirementsCarriers.table.options'} />,
			dataSource: 'options',
		},
	]
	return { columns }
}

const styles = (theme) => ({
	containerTitle: {
		margin: '15px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Maneuver)))
