/** Helpers import section */
import axios from 'axios'

import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'
import { COMPANY_PTM } from '../helpers/ResourcesHelper'

export const STAR_LOADING_SUPPLIERS = 'STAR_LOADING_SUPPLIERS'
export const SET_SUPPLIERS_LIST = 'SET_SUPPLIERS_LIST'
export const IS_LOADING_SUPPLIER = 'IS_LOADING_SUPPLIER'
export const SET_SUPPLIER = 'SET_SUPPLIER'
export const SET_SUPPLIER_BY_QUERY = 'SET_SUPPLIER_BY_QUERY'
export const STAR_LOADING_INVOICES = 'STAR_LOADING_INVOICES'
export const SET_INVOICES_LIST = 'SET_INVOICES_LIST'
export const START_LOADING_STOCKS = 'START_LOADING_STOCKS'
export const SET_STOCKS_LIST = 'SET_STOCKS_LIST'
export const START_LOADING_INVENTORIES = 'START_LOADING_INVENTORIES'
export const SET_INVENTORIES_LIST = 'SET_INVENTORIES_LIST'
export const START_LOADING_CONSIGNMENTS = 'START_LOADING_CONSIGNMENTS'
export const SET_CONSIGNMENTS_LIST = 'SET_CONSIGNMENTS_LIST'
export const START_LOADING_PURCHASE_ORDERS = 'START_LOADING_PURCHASE_ORDERS'
export const SET_PURCHASE_ORDERS_LIST = 'SET_PURCHASE_ORDERS_LIST'
export const SET_SELETECT_SUPPLIER = 'SET_SELETECT_SUPPLIER'
export const CHANGE_SUPPLIERS_SORT_CRITERIA = 'CHANGE_SUPPLIERS_SORT_CRITERIA'
export const CHANGE_SUPPLIERS_QUERY = 'CHANGE_SUPPLIERS_QUERY'
export const CHANGE_SUPPLIERS_PAGINATION = 'CHANGE_SUPPLIERS_PAGINATION'
export const SET_SUPPLIERS_COUNT = 'SET_SUPPLIERS_COUNT'
export const SET_ADVANCE_OPTIONS_SUPPLIER = 'SET_ADVANCE_OPTIONS_SUPPLIER'
export const IS_SAVING = 'IS_SAVING'
export const SET_PARTNERSHIP_SUPPLIER = 'SET_PARTNERSHIP_SUPPLIER'
export const START_BANCKS_LIST_SUPPLIER = 'START_BANCKS_LIST_SUPPLIER'
export const SET_BANCKS_LIST_SUPPLIER = 'SET_BANCKS_LIST_SUPPLIER'
export const SET_SPECIFICATIONS = 'SET_SPECIFICATIONS'
export const SET_ENABLE_SUPPLIER = 'SET_ENABLE_SUPPLIER'
export const START_LOADING_INVENTORY_MAQUILAS = 'START_LOADING_INVENTORY_MAQUILAS'
export const SET_INVENTORY_MAQUILAS = 'SET_INVENTORY_MAQUILAS'
export const IS_DOWNLOAD_QUERIES = 'IS_DOWNLOAD_QUERIES'
export const SET_SALES_CONTACT = 'SET_SALES_CONTACT'
export const IS_LOADING_CONTACT = 'IS_LOADING_CONTACT'

export const SET_FOLLOWED_SUPPLIERS = 'SET_FOLLOWED_SUPPLIERS'
export const ADD_FOLLOWED_SUPPLIER_TO_USER = 'ADD_FOLLOWED_SUPPLIER_TO_USER'
export const REMOVE_FOLLOWED_SUPPLIER_TO_USER = 'REMOVE_FOLLOWED_SUPPLIER_TO_USER'
export const SET_SUPPLIER_TYPES = 'SET_SUPPLIER_TYPES'
export const IS_DOWNLOADING_REPORT_EMPLOYEES = 'IS_DOWNLOADING_REPORT_EMPLOYEES'
export const SET_SUPPLIERS_CARRIERS = 'GET_SUPPLIERS_CARRIERS'
export const IS_LOADING_SUPPLIERS_CARRIERS = 'GET_SUPPLIERS_CARRIERS'
export const IS_LOADING_SAP_SUPPLIER = 'IS_LOADING_SAP_SUPPLIER'

function isDonwloadingReportEmployees(isLoading) {
	return {
		type: IS_DOWNLOADING_REPORT_EMPLOYEES,
		isLoading,
	}
}

/**
 * Action start loading suppliers
 * @param {*} isLoading
 */
function isLoadingSuppliers(isLoading) {
	return {
		type: STAR_LOADING_SUPPLIERS,
		isLoading: isLoading,
	}
}

/**
 * Action to set suppliers list
 * @param {*} suppliers
 */
function setSuppliersList(suppliers) {
	return {
		type: SET_SUPPLIERS_LIST,
		suppliers: suppliers,
	}
}

/**
 * Action to set suppliers list
 * @param {*} suppliers
 */
function setSuppliersTypesList(suppliers) {
	return {
		type: SET_SUPPLIER_TYPES,
		suppliers: suppliers,
	}
}

/**
 * Action to start load supplier
 */
function startLoadSupplier(isLoading) {
	return {
		type: IS_LOADING_SUPPLIER,
		isLoadingSupplier: isLoading,
	}
}

/**
 * Action to set supplier
 * @param {*} supplier
 */
function setSupplier(supplier) {
	return {
		type: SET_SUPPLIER,
		supplier: supplier,
	}
}

/**
 * Action to set suppliers by query
 * @param {*} suppliers
 */
function setSuppliersByQuery(suppliers) {
	return {
		type: SET_SUPPLIER_BY_QUERY,
		suppliers: suppliers,
	}
}

/**
 * Action reducer to set the supplier's counter
 *
 * @param {*} count
 * @returns
 */
function setSuppliersCount(count) {
	return {
		type: SET_SUPPLIERS_COUNT,
		count,
	}
}

/**
 * Action to set lock and unlock supplier
 * @param {*} enabled
 */
function setEnabledSupplier(enabled) {
	return {
		type: SET_ENABLE_SUPPLIER,
		enabled: enabled,
	}
}

/**
 * Set external user roles
 *
 * @param {*} userId
 * @param {*} roles
 * @returns
 */
function setFollowedSuppliersInUser(followedSuppliers) {
	return {
		type: SET_FOLLOWED_SUPPLIERS,
		followedSuppliers,
	}
}

/**
 * Action to is donwload queries
 * @param {*} isDonwload
 */
function isDownloadQueries(isDonwload) {
	return {
		type: IS_DOWNLOAD_QUERIES,
		isDonwload: isDonwload,
	}
}

/**
 * Set supplier contact list of sales and collect
 * @param {*} contactList
 * @returns
 */
function setSalesContact(contactList) {
	return {
		type: SET_SALES_CONTACT,
		contactList: contactList,
	}
}

/**
 * Action to, indicate that contact list of sales and collect is loading
 * @param {*} isLoading
 * @returns
 */
function isLoadingContact(isLoading) {
	return {
		type: IS_LOADING_CONTACT,
		isLoading: isLoading,
	}
}

/**
 * Action to get profile the company by user logger
 */
export function getCompanyByUser() {
	return function (dispatch, getState) {
		dispatch(startLoadSupplier(true))

		if (getState().oidc.user) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/company`
			return axios
				.get(endPoint, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setSupplier(serverResponse.data))
					}
				})
				.catch((error) => {
					console.error('Error to load supplier', error.response)
					dispatch(startLoadSupplier(false))
					return Promise.reject()
				})
		}
	}
}

/**
 * Action to get all supplier from backend
 */
export function loadSuppliersFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingSuppliers(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/suppliers`
		let data = {
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSuppliersList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingSuppliers(false))
				console.error('Error getting the suppliers list', error.response)
			})
	}
}

/**
 * Get supplier's count
 *
 * @export
 * @returns
 */
export function getSuppliersCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().suppliers.get('suppliersCount')
		if (actualCounter) {
			return
		}

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/suppliers/count`
		let data = {}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSuppliersCount(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error("Error getting the supplier's count", error.response)
			})
	}
}

/**
 * Get and set the supplier in the estate from read from backend
 * @param {*} token
 */
export function loadSupplier(token) {
	return function (dispatch, getState) {
		dispatch(startLoadSupplier(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/suppliers/${token}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplier(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load supplier', error.response)
				dispatch(startLoadSupplier(false))
				return Promise.reject()
			})
	}
}

/**
 * Get and set the supplier in the estate from read from backend
 * @param {*} token
 */
export function loadSupplierCode(companyCode) {
	return function (dispatch, getState) {
		dispatch(startLoadSupplier(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/company/${companyCode}`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSupplier(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load supplier', error.response)
				dispatch(startLoadSupplier(false))
				return Promise.reject()
			})
	}
}

/**
 * Get advances options suppliers
 */
function setAdvanceOptions(supplier) {
	return {
		type: SET_ADVANCE_OPTIONS_SUPPLIER,
		supplier: supplier,
	}
}

/**
 * Action is saving
 */
function isSavingSupplier(isSaving) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
	}
}

/**
 * Get and set the supplier in the estate from read from backend
 * @param {*} token
 */
export function loadAdvanceOptionsSupplier(code) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/code`
		let data = {
			SupplierCode: code,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setAdvanceOptions(serverResponse.data))
				}
			})
			.catch((error) => {
				console.error('Error to load supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Get partnership options suppliers
 */
function setPartnership(partnership) {
	return {
		type: SET_PARTNERSHIP_SUPPLIER,
		partnership: partnership,
	}
}

/**
 * Get and set the supplier in the estate from read from backend
 * @param {*} token
 */
export function loadPartnership(supplierCode, companyToken) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/partnership`
		let data = {
			SupplierNumber: supplierCode,
			CompanyToken: companyToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPartnership(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				console.error('Error to load supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Function to is loading bancks
 * @param {*} isLoadingBanck
 */
function isLoadingBancks(isLoadingBanck) {
	return {
		type: START_BANCKS_LIST_SUPPLIER,
		isLoadingBanck: isLoadingBanck,
	}
}

/**
 * Get bancks suppliers
 */
function setBancks(bancks) {
	return {
		type: SET_BANCKS_LIST_SUPPLIER,
		bancks: bancks,
	}
}

/**
 * Get and set the supplier in the estate from read from backend
 * @param {*} token
 */
export function loadBankAccountsSupplier(supplierCode, companyToken) {
	return function (dispatch, getState) {
		dispatch(isLoadingBancks(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/bankaccount`
		let data = {
			SupplierNumber: supplierCode,
			CompanyToken: companyToken,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setBancks(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingBancks(false))
				console.error('Error to load bancks supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to update supplier data
 * @param {*} provider
 */
export function updateAdvanceOptionsSupplier(supplier, code) {
	return function (dispatch, getState) {
		dispatch(isSavingSupplier(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/${code}`
		let data = {
			ReleaseLevel: supplier.release_level,
			Sorter: supplier.sorter,
			Scales: supplier.scales,
			IsOnlineProductsSupplier: supplier.is_online_products_supplier,
			IsContractorSupplier: supplier.is_contractor_supplier,
			IsCustomBrokerSupplier: supplier.is_custom_broker_supplier,
			AdministratorToken: supplier.administrator_token,
			IsSupplierToEvaluate: supplier.is_supplier_to_evaluate,
			IsMaquilaSupplier: supplier.is_maquila_supplier,
			RatingType: supplier.rating_type,
			IsActiveModulePlanningFersa: supplier.is_active_module_planning_fersa,
			AccessPatioStorageWindows: supplier.access_patio_storage_windows,
			IsContracOurOrder: supplier.is_contrac_our_order,
		}
		return axios
			.put(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setAdvanceOptions(response.data))
					dispatch(isSavingSupplier(false))
				} else {
					dispatch(isSavingSupplier(false))
				}
			})
			.catch((error) => {
				console.error('Error update advances ooptions', error.response)
				dispatch(isSavingSupplier(false))
				return Promise.reject()
			})
	}
}

/**
 *  Action to update partnership supplier data
 */
export function updatePartnershipSupplier(partnership, supplierCode, companyId) {
	return function (dispatch, getState) {
		dispatch(isSavingSupplier(true))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/supplier/partnership`
		let data = {
			SupplierNumber: supplierCode,
			CompanyId: companyId,
			IsInspectionFree: partnership.is_inspection_free,
			DaysRequiredForDeliveryMaterial: partnership.days_required_for_delivery_material,
			TimeHoursRequiredForDeliveryMaterial: partnership.time_hours_required_for_delivery_material,
			IsAutomotiveSupplier: partnership.is_automotive_supplier,
			NumberPpmsBySupplier: partnership.number_ppms_by_supplier,
			NumberRamps: partnership.number_ramps,
			IsRequieredQualityCertificate: partnership.is_requiered_quality_certificate,
			IsRequieredCerificateByTemplate: partnership.is_requiered_cerificate_by_template,
			IsSupplierToAddCarbonFootprint: partnership.is_supplier_to_add_carbon_foot_print,
			SupplierType: partnership.supplier_type,
			MonthConfirm: partnership.month_confirm ? parseInt(partnership.month_confirm) : null,
			IsPlantAccessBlocked: partnership.is_plant_access_blocked,
			BlockingReason:
				partnership.blockingReason !== null && partnership.blockingReason !== ''
					? partnership.blockingReason
					: null,
			Research: partnership.research !== null ? partnership.research : 0,
			IsCarrier: partnership.is_carrier,
			IsContract: partnership.is_contract,
			IsOrder: partnership.is_order,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setPartnership(response.data))
					dispatch(isSavingSupplier(false))
				} else {
					dispatch(isSavingSupplier(false))
				}
			})
			.catch((error) => {
				console.error('Error update advance options', error.response)
				dispatch(isSavingSupplier(false))
				return Promise.reject()
			})
	}
}
/**
 * Action to serch suppliers by Query
 * @param {*} query
 */
export function searchBySuppliersByQury(query) {
	return function (dispatch, getState) {
		dispatch(isLoadingSuppliers(true))

		let moduleName = 'moduleSIP'

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/modules/${moduleName}/companies?query=${query}`
		return axios
			.get(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setSuppliersByQuery(response.data))
					return Promise.resolve(response.data)
				}
			})
			.catch((error) => {
				console.error(error)
				dispatch(isLoadingSuppliers(false))
			})
	}
}

/**
 * Action to search supplier by query and by company
 * @param {*} query
 */
export function searchBySuppliersFromCompanyByQuery(query) {
	return function (dispatch, getState) {
		dispatch(isLoadingSuppliers(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/${selectedCompany.get(
			'id'
		)}/company?query=${query}`
		let allSuppliers
		return axios
			.get(endPoint, configuration)
			.then((suppliersResponse) => {
				allSuppliers = suppliersResponse.data
				return axios.all(
					allSuppliers.map((supplier) =>
						axios.get(`${process.env.REACT_APP_IDENTITY_SERVER}/api/company/${supplier.code}`)
					)
				)
			})
			.then((identitySupplierResponses) => {
				let allIdentitySuppliers = identitySupplierResponses.map((response) => response.data)
				let mappedSuppliers = allSuppliers.map((supplier) => {
					let identitySupplier = allIdentitySuppliers.find((s) => s.code === supplier.code)
					return identitySupplier
				})
				dispatch(setSuppliersByQuery(mappedSuppliers))
				return Promise.resolve(mappedSuppliers)
			})
			.catch((error) => {
				dispatch(isLoadingSuppliers(false))
				console.error('Error loading all suppliers', error)
				return Promise.reject()
			})
	}
}

/**
 * Action to serch suppliers by Query
 * @param {*} query
 */
export function searchShareCompanies(query) {
	return function (dispatch, getState) {
		dispatch(isLoadingSuppliers(true))
		let moduleName = 'moduleSIP'
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/modules/${moduleName}/companies?query=${query}`
		let result
		return axios
			.get(endPoint, configuration)
			.then((responseSuppliers) => {
				if (responseSuppliers.status === 200) {
					result = responseSuppliers.data
					dispatch(setSuppliersByQuery(result))
					let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/officialnotice/${selectedCompany.get(
						'administrator_token'
					)}/search?query=${''}`
					return axios
						.get(endPoint, configuration)
						.then((responseCompanies) => {
							if (responseCompanies.status === 200) {
								result = responseCompanies.data.concat(result)
								dispatch(setSuppliersByQuery(result))
								return Promise.resolve(result)
							}
						})
						.catch((error) => {
							console.error(error)
							return Promise.resolve(result)
						})
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}
}

/**
 * Action start loading invoices
 * @param {*} isLoading
 */
function isLoadingInvoices(isLoading) {
	return {
		type: STAR_LOADING_INVOICES,
		isLoading: isLoading,
	}
}

/**
 * Action to set invoices list
 * @param {*} invoices
 */
function setInvoicesList(invoices) {
	return {
		type: SET_INVOICES_LIST,
		invoices: invoices,
	}
}

/**
 * Action to get invoices by supplier from SAP from backend
 */
export function getInvoicesFromSAP(supplierCode, startDate, endDate, dateType) {
	return function (dispatch, getState) {
		dispatch(isLoadingInvoices(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/invoices`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			DateType: dateType,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setInvoicesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingInvoices(false))
				console.error('Error getting invoices by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload invoices by supplier from SAP from backend
 */
export function donwlodInvoicesFromSAP(supplierCode, startDate, endDate, dateType) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let invoices = getState().suppliers.get('invoices') ? getState().suppliers.get('invoices').toJS() : null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodInvoices`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			DateType: dateType,
			ListQueries: invoices,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Facturas_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload invoices by supplier list', error.response)
			})
	}
}

/**
 * Action start loading inventories maquilas
 * @param {*} isLoading
 */
function isLoadingInventoriesMaquilas(isLoading) {
	return {
		type: START_LOADING_INVENTORY_MAQUILAS,
		isLoading: isLoading,
	}
}

/**
 * Action to set inventories list
 * @param {*} stocks
 */
function setInventoriesMaquilasList(inventoriesMaquilas) {
	return {
		type: SET_INVENTORY_MAQUILAS,
		inventoriesMaquilas,
	}
}

/**
 * Action to get inventories by supplier from SAP from backend
 */
export function getInventoryMaquilaFromSAP(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingInventoriesMaquilas(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/supplier/inventorymaquila`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setInventoriesMaquilasList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingInventoriesMaquilas(false))
				console.error('Error getting inventories by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload InventoryMaquila by supplier from SAP from backend
 */
export function donwlodInventoryMaquilaFromSAP(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let inventoryMaquila = getState().suppliers.get('inventoryMaquilas')
			? getState().suppliers.get('inventoryMaquilas').toJS()
			: null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodInventorymaquila`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			ListQueries: inventoryMaquila,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Inventarios_de_maquila_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload InventoryMaquila by supplier list', error.response)
			})
	}
}

/**
 * Action start loading inventories
 * @param {*} isLoading
 */
function isLoadingInventories(isLoading) {
	return {
		type: START_LOADING_INVENTORIES,
		isLoading: isLoading,
	}
}

/**
 * Action to set inventories list
 * @param {*} stocks
 */
function setInventoriesList(inventories) {
	return {
		type: SET_INVENTORIES_LIST,
		inventories: inventories,
	}
}

/**
 * Action to get inventories by supplier from SAP from backend
 */
export function getInventoriesFromSAP(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingInventories(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/inventories`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setInventoriesList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingInventories(false))
				console.error('Error getting inventories by supplier list', error.response)
			})
	}
}

/**
 * Action on donwload Inventories by supplier
 * @param {*} supplierCode
 */
export function donwlodInventoriesFromSAP(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let inventories = getState().suppliers.get('inventories')
			? getState().suppliers.get('inventories').toJS()
			: null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodInventories`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			ListQueries: inventories,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Inventarios_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload inventories by supplier list', error.response)
			})
	}
}

/**
 * Action start loading purchase orders
 * @param {*} isLoading
 */
function isLoadingPurchaseOrders(isLoading) {
	return {
		type: START_LOADING_PURCHASE_ORDERS,
		isLoading: isLoading,
	}
}

/**
 * Action to set purchase Orders list
 * @param {*} purchase
 */
function setPurchaseOrdersList(purchaseOrders) {
	return {
		type: SET_PURCHASE_ORDERS_LIST,
		purchaseOrders: purchaseOrders,
	}
}

/**
 * Action to get purchase orders by supplier from SAP from backend
 */
export function getPurchaseOrdersFromSAP(supplierCode, startDate, endDate, dateType, companyCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingPurchaseOrders(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/purchaseOrders`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			DateType: dateType,
			CompanyIdentifier: companyCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPurchaseOrdersList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingPurchaseOrders(false))
				console.error('Error getting purchase orders by supplier list', error.response)
			})
	}
}

/**
 * Action to get purchase orders by supplier from SAP from backend
 */
export function getPurchaseOrdersFromSAPByPeriod(supplierCode, dateType, period) {
	return function (dispatch, getState) {
		dispatch(isLoadingPurchaseOrders(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/purchaseOrders`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			DateType: dateType,
			Period: period,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setPurchaseOrdersList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingPurchaseOrders(false))
				console.error('Error getting purchase orders by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload PurchaseOrders by supplier from SAP from backend
 */
export function donwlodPurchaseOrdersFromSAP(supplierCode, startDate, endDate, dateType) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let purchaseOrders = getState().suppliers.get('purchaseOrders')
			? getState().suppliers.get('purchaseOrders').toJS()
			: null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodPurchaseOrders`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			DateType: dateType,
			ListQueries: purchaseOrders,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Ordenes_de_Compra_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload purchase orders by supplier list', error.response)
			})
	}
}

/**
 * Action start loading consignments
 * @param {*} isLoading
 */
function isLoadingConsignments(isLoading) {
	return {
		type: START_LOADING_CONSIGNMENTS,
		isLoading: isLoading,
	}
}

/**
 * Action to set consignments list
 * @param {*} consignments
 */
function setConsignmentsList(consignments) {
	return {
		type: SET_CONSIGNMENTS_LIST,
		consignments: consignments,
	}
}

/**
 * Action to get consignments by supplier from SAP from backend
 */
export function getConsignmentsFromSAP(supplierCode, startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isLoadingConsignments(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/consignments`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setConsignmentsList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingConsignments(false))
				console.error('Error getting consignments by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload consignments by supplier from SAP from backend
 */
export function donwlodConsignmentsFromSAP(supplierCode, startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let consgiments = getState().suppliers.get('consignments')
			? getState().suppliers.get('consignments').toJS()
			: null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodConsignments`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			ListQueries: consgiments,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Consignacion_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload consignments by supplier list', error.response)
			})
	}
}

/**
 * Action start loading stocks
 * @param {*} isLoading
 */
function isLoadingStocks(isLoading) {
	return {
		type: START_LOADING_STOCKS,
		isLoading: isLoading,
	}
}

/**
 * Action to set stocks list
 * @param {*} stocks
 */
function setStocksList(stocks) {
	return {
		type: SET_STOCKS_LIST,
		stocks: stocks,
	}
}

/**
 * Action to get stocks by supplier from SAP from backend
 */
export function getStocksFromSAP(supplierCode, startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isLoadingStocks(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/stocks`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setStocksList(serverResponse.data))
				}
			})
			.catch((error) => {
				dispatch(isLoadingStocks(false))
				console.error('Error getting stocks by supplier list', error.response)
			})
	}
}

/**
 * Action to donwload stocks by supplier from SAP from backend
 */
export function donwlodStocksFromSAP(supplierCode, startDate, endDate) {
	return function (dispatch, getState) {
		dispatch(isDownloadQueries(true))
		let selectedCompany = getState().profile.get('selectedCompany')
		let stocks = getState().suppliers.get('stocks') ? getState().suppliers.get('stocks').toJS() : null
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/donwlodStocks`
		let data = {
			SupplierCode: supplierCode,
			CompanyToken: selectedCompany.get('token'),
			StartDate: startDate,
			EndDate: endDate,
			ListQueries: stocks,
		}
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isDownloadQueries(false))
					var blob = new Blob([serverResponse.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = `Entradas_de_almacen_${supplierCode}.xlsx`
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDownloadQueries(false))
				console.error('Error donwload stocks by supplier list', error.response)
			})
	}
}

/**
 * Action to get Specification Plane by Purchase order from SAP
 * @param {*} specPlano
 * @param {*} revision
 */
export function getSpecificationPlaneByPurchaseOrder(specPlano, revision) {
	return function (dispatch, getState) {
		dispatch(isSavingSupplier(true))

		let selectedCompany = getState().profile.get('selectedCompany')
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${
			process.env.REACT_APP_SIP_SUPPLIERS
		}/partnumbers?specPlano=${specPlano}&revision=${revision}&tokenCompany=${selectedCompany.get('token')}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					let items = []

					/// Get informaiton from list json (new implementation portal plot)
					if (selectedCompany.get('get_company_identifier') === COMPANY_PTM) {
						items = serverResponse.data
					} else {
						let result = document.createElement('html')
						result.innerHTML = serverResponse.data

						var values = result.getElementsByTagName('option')
						for (var i = 0; i < values.length; i++) {
							items.push(values[i].value)
						}
					}
					dispatch({
						type: SET_SPECIFICATIONS,
						specPlanos: items,
					})
					return Promise.resolve(items)
				} else {
					dispatch(isSavingSupplier(false))
				}
			})
			.catch((error) => {
				dispatch(isSavingSupplier(false))
				console.error('Error getting gets specificatiosn in purcnase order list', error)
				return Promise.reject(error)
			})
	}
}

/**
 * Upload logo company
 *
 * @export
 * @param {*} user
 * @param {*} fileBase64
 * @returns
 */
export function uploadLogoSupplier(supplierToken, fileBase64) {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/companies/${supplierToken}/savealogo`
		let data = { fileBase64 }

		return axios
			.put(endPoint, data, configuration)
			.then((response) => {
				dispatch(setSupplier(response.data))
				return Promise.resolve()
			})
			.catch((err) => {
				console.error('Error uploading supplier logo', err.response)
				return Promise.reject()
			})
	}
}

/**
 * Action to set lockout enable user
 * @param {*} userId
 * @param {*} enabled
 */
export function lockOnUnlockSupplier(supplierCode, enabled, reason) {
	return function (dispatch, getState) {
		dispatch(isSavingSupplier(true))

		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/supplier/lockonunlock`
		let data = {
			SupplierCode: supplierCode,
			LockOnUnLock: enabled,
			Reason: reason,
			UserLock: user.profile.name,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setEnabledSupplier(enabled))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSupplier(true))
				console.error(error)
			})
	}
}

/**
 * Load user's Followed Suppliers
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadUserFollowedSuppliers() {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${user.profile.sub}/followedsuppliers`
		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setFollowedSuppliersInUser(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error("Error loading user's Followed Suppliers", error.response)
				return Promise.reject()
			})
	}
}

/**
 * Toggle the Followed of a user in a Supplier
 * AddUserToFollowedSupplier
 * @export
 * @param {*} assigned
 * @param {*} company
 * @param {*} role
 */
export function toggleAddSupplierFollowedToUser(supplier) {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${user.profile.sub}/followedsuppliers`

		let data = {
			SupplierToken: supplier.token,
			SupplierName: supplier.name,
			SupplierCode: supplier.code,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: ADD_FOLLOWED_SUPPLIER_TO_USER,
						item: serverResponse.data,
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error adding a user into a followed supplier', error.response)
				return Promise.reject()
			})
	}
}

/**
 * Toggle the Followed of a user in a Supplier
 * RemoveUserToFollowedSupplier
 * @export
 * @param {*} assigned
 * @param {*} company
 * @param {*} role
 */
export function toggleRemoveSupplierFollowedToUser(supplierToken) {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${user.profile.sub}/followedsuppliers/${supplierToken}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch({
						type: REMOVE_FOLLOWED_SUPPLIER_TO_USER,
						supplierToken: supplierToken,
					})
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error removing the user from a followed supplier', error.response)
				return Promise.reject()
			})
	}
}

export function loadAllSupplierTypes() {
	return function (dispatch, getState) {
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/expedientclassification/company`

		let data = {
			CompanyCode: selectedCompany.get('company_code'),
		}

		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setSuppliersTypesList(serverResponse.data))
				}
				return Promise.resolve(serverResponse.data)
			})
			.catch((error) => {
				console.error("Error getting the supplier's types", error.response)
				return Promise.reject()
			})
	}
}

/**
 *Download report of employees insurance payments
 *
 * @export
 * @returns
 */
export function downloadReportEmployeeInsurancePayment() {
	return (dispatch, getState) => {
		let selectedCompany = getState().profile.get('selectedCompany')
		dispatch(isDonwloadingReportEmployees(true))
		let data = {
			CompanyId: selectedCompany.get('id'),
			// CompanyId: selectedCompany('token')
		}
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/contractoraccess/report/insurancePayment`
		return axios
			.post(endPoint, data, { responseType: 'arraybuffer' })
			.then((response) => {
				if (response.status === 200) {
					dispatch(isDonwloadingReportEmployees(false))
					var blob = new Blob([response.data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.href = url
					a.download = 'Reporte_de_accesos_contratistas.xlsx'
					a.click()
				}
			})
			.catch((error) => {
				dispatch(isDonwloadingReportEmployees(false))
				console.error('Error downloading report employees insurance payments', error.response)
				return Promise.reject()
			})
	}
}

export function synchronizeSupplierAutomaticallyByCode(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isSavingSupplier(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/synchronizesupplierssap/${supplierCode}`
		return axios
			.post(endPoint, {}, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingSupplier(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingSupplier(true))
				console.error(error)
			})
	}
}

/**
 * Get sales and collect contacts by supplier code and company id from SAP
 * @param {*} supplierCode
 * @returns
 */
export function getSalesCollectContact(supplierCode) {
	return function (dispatch, getState) {
		dispatch(isLoadingContact(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let selectedCompany = getState().profile.get('selectedCompany')
		let companyId = selectedCompany.get('id')
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/salescollectcontact/${supplierCode}/${companyId}`
		return axios
			.get(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setSalesContact(response.data))
					dispatch(isLoadingContact(false))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to get contact', error.response)
				dispatch(isLoadingContact(false))
				return Promise.reject()
			})
	}
}
/**
 * Get Suppliers who are carriers
 */
function setSuppliersAreCarriers(suppliers) {
	return {
		type: SET_SUPPLIERS_CARRIERS,
		suppliers: suppliers,
	}
}
function setIsLoadingSupplierCarriers(isloadingCarries) {
	return {
		type: IS_LOADING_SUPPLIERS_CARRIERS,
		isloadingCarries: isloadingCarries,
	}
}

export function getSuppliersAreCarriers() {
	return function (dispatch, getState) {
		dispatch(setIsLoadingSupplierCarriers(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/carriers`
		return axios
			.get(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setSuppliersAreCarriers(response.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error('Error to get suppliers are carriers', error.response)
				dispatch(setIsLoadingSupplierCarriers(false))
				return Promise.reject()
			})
	}
}

function setIsLoadingSapSupplier(isLoadingSapSupplier) {
	return {
		type: IS_LOADING_SAP_SUPPLIER,
		isLoadingSapSupplier: isLoadingSapSupplier,
	}
}

export function SincronizeSapSupplier(supplierNumber) {
	return function (dispatch, getState) {
		dispatch(setIsLoadingSapSupplier(true))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/companies/synchronizesupplierssap/${supplierNumber}`
		return axios
			.post(endPoint, configuration)
			.then((response) => {
				if (response.status === 200) {
					let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
					let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/suppliers/synchronizesupplierssap/${supplierNumber}`
					return axios
						.post(endPoint, configuration)
						.then((response) => {
							if (response.status === 200) {
								return Promise.resolve()
							}
						})
						.catch((error) => {
							console.error('Error to synchronize suppliers', error.response)
							return Promise.reject()
						})
				}
			})
			.catch((error) => {
				console.error('Error to synchronize suppliers', error.response)
				return Promise.reject()
			})
			.finally(() => {
				dispatch(setIsLoadingSapSupplier(false))
			})
	}
}
