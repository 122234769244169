import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translate, getTranslate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'

/** Import component section */
import withauthenticatedLayout from '../../layouts/withauthenticatedLayout'
import SearchInput from '../../common/SearchInput/SearchInput'

/** Material UI imports section */
import { withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, Avatar, Fab, Typography, CardContent, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'
import ServicesProjectsIcon from '@material-ui/icons/Build'
import OptionsMenu from '../../common/OptionsMenu'
import ArchiveDownloadIcon from '@material-ui/icons/Archive'
import DonwloadReportDialog from '../../common/DonwloadReportDialog'
import { DeleteForever } from '@material-ui/icons'

/** Actions and helpers imports section */
import LoadingPage from '../../common/LoadingPage'
import DataTable from '../../common/DataTable'
import {
	CHANGE_SERVICES_PROJECTS_SORT_CRITERIA,
	CHANGE_SERVICES_PROJECTS_QUERY,
	CHANGE_SERVICES_PROJECTS_PAGINATION,
	getServicesProjectsCount,
	addServiceProject,
	loadServicesProjectsFromBackEnd,
	downloadServiceReportByRangeDate,
	removeServicesBycontractors,
	loadServicesProjectsDeletedFromBackEnd,
	getServicesProjectsDeletedCount,
	loadPurchaseOrder,
	loadLegalFolio,
} from '../../../store/contractors/ServicesProjectsAction'
import AddServiceProjectDialog from './components/AddServiceProjectDialog'
import Toaster from '../../common/Toaster'
import DialogConfirmationRemove from './components/DialogConfirmation'

import { loadSupplier, loadSupplierCode } from '../../../store/suppliers/SupplierActions'
import { converListToArray } from '../../../store/helpers/ListHelper'
import { IsCurrentNotActiveDirectoryUser, IsCurrentActiveDirectoryUser } from '../../../store/helpers/SessionHelper'
import {
	canCloseRegistersAccessControl,
	ROLE_ACCESSCONTROL_INDUSTRIALSECURITY,
} from '../../../store/helpers/RolesHelper'
import { COMPANY_IMBERA, COMPANY_FERSA } from '../../../store/helpers/ResourcesHelper'
import { changeTypeServiceToIdTranslate } from '../../../store/helpers/ServicesListTypes'
import { COLOR_QUESTIONNAIRE_CHERRY } from '../../../store/helpers/StatusColorConstants'

/**
 * Class container to show services / projects index
 *
 * @class Index
 * @extends {Component}
 */
class Index extends Component {
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAddServiceProjectModal: false,
			itemToken: null,
			showMenu: true,
			openModalByMonthReport: false,
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			openOptionsModal: false,
			openConfirmationDialog: false,
			deleteServices: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		if (!this.props.isLoadingUser) {
			this.props.loadServicesProjects(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.loadServicesProjectsDeleted(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
			this.props.getServicesProjectsCount()
			this.props.getServicesProjectsDeletedCount()
			this.props.loadSupplierByCode(this.props.user.profile.company_code)
		}
	}

	/**
	 * componentDidUpdate
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof Index
	 */
	componentDidUpdate(prevProps, prevState) {
		let prevCompanyToken = prevProps.selectedCompany ? prevProps.selectedCompany.token : ''
		if (prevProps.isLoadingEmployees !== this.props.isLoadingEmployees && this.props.supplier === null) {
			this.props.loadSupplier(this.props.user.profile.company_token)
		}
		if (this.props.selectedCompany && prevCompanyToken !== this.props.selectedCompany.token) {
			this.props.loadServicesProjects()
			this.props.loadServicesProjectsDeleted()
			this.props.getServicesProjectsCount()
			this.props.getServicesProjectsDeletedCount()
		} else if (
			prevProps.sortCriteria.by !== this.props.sortCriteria.by ||
			prevProps.sortCriteria.ascending !== this.props.sortCriteria.ascending ||
			prevProps.searchQuery !== this.props.searchQuery ||
			prevProps.page !== this.props.page ||
			prevProps.rowsPerPage !== this.props.rowsPerPage
		) {
			this.props.loadServicesProjects(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPag
			)
			this.props.loadServicesProjectsDeleted(
				this.props.searchQuery,
				this.props.sortCriteria.by,
				this.props.sortCriteria.ascending,
				this.props.page,
				this.props.rowsPerPage
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	onChangeSortCriteria(sortBy, sortAscending) {
		this.props.changeSortCriteria(sortBy, sortAscending)
	}

	/**
	 * On change search value
	 *
	 * @param {*} searchQuery
	 * @memberof Index
	 */
	onChangeSearchValue(searchQuery) {
		this.props.changeSearchQuery(searchQuery)
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "saveCorrectly"
	 *
	 * @param {string} [idTranslation="common.saveCorrectly"]
	 * @memberof Show
	 */
	showSuccessToaster(idTranslation = 'common.saveCorrectly') {
		this.setState({
			showToaster: true,
			toasterVariant: 'success',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * Method to show succes toaster
	 * with custom message or default "errorToSave"
	 *
	 * @param {string} [idTranslation="common.errorToSave"]
	 * @memberof Show
	 */
	showErrorToaster(idTranslation = 'common.errorToSave') {
		this.setState({
			showToaster: true,
			toasterVariant: 'error',
			toasterMessage: <Translate id={idTranslation} />,
		})
	}

	/**
	 * HAndle state to show or hide add service/project modal
	 *
	 * @memberof Index
	 */
	handleShowAddServiceProjectDialog() {
		this.setState({
			openAddServiceProjectModal: !this.state.openAddServiceProjectModal,
		})
	}

	/**
	 * Handle event to call action and add service/project
	 *
	 * @param {*} serviceProject
	 * @returns
	 * @memberof Index
	 */
	handleOnAddServiceProject(serviceProject) {
		return this.props
			.onAddServiceProject(serviceProject, this.props.supplier ? this.props.supplier.full_name : '')
			.then((tokenService) => {
				this.showSuccessToaster()
				this.props.history.push(`/servicesprojects/${tokenService}`)
				return Promise.resolve('Ok')
			})
			.catch(() => {
				this.showErrorToaster()
				return Promise.reject()
			})
	}

	onRenderCellItem(dataSource, item) {
		const { classes } = this.props
		if (dataSource === 'is_active') {
			return (
				<div className={classes.titleItem}>
					<ServicesProjectsIcon
						className={
							this.state.deleteServices
								? classes.inactive
								: item.is_active_service
								? classes.active
								: classes.inactive
						}
					/>
					<Translate
						id={
							this.state.deleteServices
								? 'common.deleteItem'
								: item.is_active_service
								? 'common.active'
								: 'common.inactive'
						}
					/>
				</div>
			)
		}
		if (dataSource === 'description') {
			return <div style={{ width: '300px' }}>{item.description}</div>
		}
		if (dataSource === 'supplier_name') {
			return <div style={{ width: '280px' }}>{item.supplier_name} </div>
		}
		if (dataSource === 'service_project_type') {
			return <Translate id={changeTypeServiceToIdTranslate(item.service_project_type)} />
		}
		if (dataSource === '') {
			return this.renderOptionsMenu(item)
		}
	}

	/**
	 * Remove document from index
	 * @param {*} token
	 */
	onRemoveDocument(event, token, description) {
		event.stopPropagation()
		this.setState({
			openConfirmationDialog: true,
			fileToken: token,
			itemDescription: description,
		})
	}

	/**
	 * Method to render OptionsMenu
	 *
	 * @param {*} item
	 * @returns
	 * @memberof AnnotationsRMI
	 */
	renderOptionsMenu(item) {
		var options = []
		var role = this.props.userRolesByCompany.find((x) => x.name === ROLE_ACCESSCONTROL_INDUSTRIALSECURITY)
		if (role && role.name === ROLE_ACCESSCONTROL_INDUSTRIALSECURITY) {
			options.push({
				itemClick: (e) => this.onRemoveDocument(e, item.token, item.supplier_name),
				tooltipTranslation: <Translate id='noticeChanges.deactivate' />,
				menuItemIcon: <DeleteForever style={{ color: COLOR_QUESTIONNAIRE_CHERRY }} />,
			})
		}

		return (
			<React.Fragment>
				<OptionsMenu
					itemToken={item.token}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === item.token}
					options={options}
					xsSize
				/>
				<DialogConfirmationRemove
					title={'Confirmacion para desactivar el servicio'}
					opened={this.state.openConfirmationDialog}
					onClose={() => {
						this.setState({ openConfirmationDialog: false })
					}}
					message={
						<Typography>
							{'¿Desea '}
							<Translate id='contractorsModule.moveProjectService' />
							{this.state.itemDescription}
							{'?'}
						</Typography>
					}
					action={() => {
						this.props.moveServiceProyectToTrash(this.state.fileToken).finally(() => {
							this.props.loadServicesProjects(
								this.props.searchQuery,
								this.props.sortCriteria.by,
								this.props.sortCriteria.ascending,
								this.props.page,
								this.props.rowsPerPage
							)
							this.props.loadServicesProjectsDeleted(
								this.props.searchQuery,
								this.props.sortCriteria.by,
								this.props.sortCriteria.ascending,
								this.props.page,
								this.props.rowsPerPage
							)
							this.props.getServicesProjectsCount()
							this.props.getServicesProjectsDeletedCount()
						})
					}}
				/>
			</React.Fragment>
		)
	}

	/**
	 * Handle click of options menu component to set item Token in state nd open menu
	 *
	 * @param {*} token
	 * @memberof Index
	 */
	handleClickOptionsMenu(token) {
		this.setState({
			itemToken: token,
		})
	}

	/**
	 * Handle on close optons menu to set void item token in state
	 *
	 * @memberof Index
	 */
	handleCloseOptionsMenu() {
		if (!this.state.openModalByMonthReport) {
			this.setState(
				{
					itemToken: '',
					showMenu: false,
				},
				() => {
					this.setState({ showMenu: true })
				}
			)
		}
	}

	/**
	 * Open modal for set report by mont
	 *
	 * @memberof Index
	 */
	handleOpenModal() {
		this.setState({
			openModalByMonthReport: true,
		})
	}

	/**
	 * Close modal to report by month
	 *
	 * @memberof Index
	 */
	handleCloseModal() {
		this.setState(
			{
				openModalByMonthReport: false,
			},
			() => this.handleCloseOptionsMenu()
		)
		this.setState({ startDate: '', endDate: '' })
	}

	/**
	 *Download report of from contacts
	 *
	 * @memberof Index
	 */
	handleDownloadReportByDateRange(startDate, endDate) {
		this.props
			.downloadServiceReportByRangeDate(startDate, endDate)
			.then(() => {
				this.handleCloseModal()
				this.showSuccessToaster()
			})
			.catch((error) => {
				this.showErrorToaster()
			})
	}

	onGetOrders(data) {
		return this.props.loadPurchaseOrder(this.props.supplier.code, data.purchase_order)
	}
	onGetFolios(data) {
		return this.props.loadLegalFolio(this.props.supplier.code, data.purchase_order)
	}

	/**
	 *Render action button in card download expedient report
	 *
	 * @memberof Index
	 */
	renderAction() {
		var options = []
		options.push({
			itemClick: () => this.handleOpenModal(),
			tooltipTranslation: <Translate id='contractorsModule.downoloadReportService' />,
			menuItemIcon: <ArchiveDownloadIcon color='secondary' />,
			isLoading: this.props.isSavingServiceProject,
		})
		if (this.state.showMenu)
			return (
				<OptionsMenu
					itemToken={'item.token'}
					handleClickOptions={this.handleClickOptionsMenu}
					open={this.state.itemToken === 'item.token'}
					handleClickAway={this.handleCloseOptionsMenu}
					options={options}
				/>
			)
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	getCardContent() {
		const { sortCriteria } = this.props
		if (this.props.isLoadingServicesProjects === true) {
			return <LoadingPage />
		} else if (this.props.servicesProjects === null) {
			return (
				<div className='loader-container'>
					<Typography variant='subtitle1' gutterBottom>
						{' '}
						<Translate id='common.errorToGetInformation' />
					</Typography>
				</div>
			)
		} else {
			return (
				<DataTable
					data={this.state.deleteServices ? this.props.servicesProjectsDelete : this.props.servicesProjects}
					configuration={TableConfiguration(
						IsCurrentActiveDirectoryUser(this.props.user),
						this.props.selectedCompany.company_code === COMPANY_IMBERA ||
							this.props.selectedCompany.company_code === COMPANY_FERSA
					)}
					sortBy={sortCriteria.by}
					sortAscending={sortCriteria.ascending}
					onChangeSortCriteria={this.onChangeSortCriteria}
					onRenderCellItem={this.onRenderCellItem}
					onRowClick={(serviceProject, event) => {
						if (event.target.tagName !== 'SPAN' && event.target.tagName !== 'BUTTON')
							this.props.history.push(`/servicesprojects/${serviceProject.token}`)
					}}
					showFilters
					isIndex
					totalRows={
						this.state.deleteServices
							? this.props.servicesProjectsDeletedCount
							: this.props.servicesProjectsCount
					}
					page={this.props.page}
					rowsPerPage={this.props.rowsPerPage}
					onChangePage={(newPage, newRowsPerPage) => {
						this.props.changePage(newPage, newRowsPerPage)
					}}
				/>
			)
		}
	}

	/**
	 * Get material deliverie's counter
	 *
	 * @returns
	 * @memberof Index
	 */
	getCounterMessage() {
		if (this.props.servicesProjects) {
			let totalservicesProjects = this.props.servicesProjects.length
			return (
				<div>
					{this.props.translate('common.showing')}&nbsp;
					<span>{totalservicesProjects}</span>&nbsp;
					{this.props.translate('common.of')}&nbsp;
					<span>
						{this.state.deleteServices
							? this.props.servicesProjectsDeletedCount
							: this.props.servicesProjectsCount}
					</span>
					&nbsp;
					{this.props.translate('common.records')}
				</div>
			)
		}
	}

	render() {
		const { classes, isLoadingPurchaseOrders, purchaseOrder, partnership } = this.props
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar>
							<ServicesProjectsIcon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							<SearchInput
								className={classes.searchInput}
								onChangeSearchValue={this.onChangeSearchValue}
								value={this.props.searchQuery}
							/>
							{IsCurrentNotActiveDirectoryUser(this.props.user) && (
								<Tooltip title={<Translate id='contractorsModule.addProjectService' />}>
									<Fab
										size='small'
										color='primary'
										onClick={() => {
											this.setState({ openAddServiceProjectModal: true })
										}}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							)}

							{canCloseRegistersAccessControl(this.props.userRolesByCompany) && this.renderAction()}
						</div>
					}
					title={
						<Typography variant='button'>
							<Translate id='menu.servicesProjects' />
						</Typography>
					}
					subheader={this.getCounterMessage()}
				/>
				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={this.state.toasterMessage}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				<AddServiceProjectDialog
					open={this.state.openAddServiceProjectModal}
					isSaving={this.props.isSavingServiceProject}
					addServiceProject={this.handleOnAddServiceProject}
					onClose={this.handleShowAddServiceProjectDialog}
					selectedCompany={this.props.selectedCompany}
					onGetOrders={this.onGetOrders}
					onGetFolios={this.onGetFolios}
					purchaseOrder={purchaseOrder}
					isLoadingPurchaseOrders={isLoadingPurchaseOrders}
					partnership={partnership}
				/>

				{/** Dialog to download report */}
				<DonwloadReportDialog
					title={<Translate id='expedientSupplier.downloadReportByMonth' />}
					open={this.state.openModalByMonthReport}
					onClose={this.handleCloseModal}
					isSaving={this.props.isSavingServiceProject}
					onDonwloadItems={this.handleDownloadReportByDateRange}
				/>
			</Card>
		)
	}
}

function TableConfiguration(isUserInternal, isImberaUnity) {
	let columns = [
		{
			header: <Translate id='common.status' />,
			dataSource: 'is_active',
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creation_date',
			isSortable: true,
		},
		{
			header: <Translate id='contractorsModule.startDate' />,
			dataSource: 'start_date',
		},
		{
			header: <Translate id='contractorsModule.endDate' />,
			dataSource: 'end_date',
		},
		{
			header: <Translate id='contractorsModule.purchaseOrder' />,
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='common.description' />,
			dataSource: 'description',
		},
	]

	if (isImberaUnity) {
		columns.push({
			header: <Translate id='contractorsModule.types' />,
			dataSource: 'service_project_type',
		})
	}

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.supplier' />,
			dataSource: 'supplier_name',
			isSortable: true,
		})
	}

	if (isUserInternal) {
		columns.push({
			header: <Translate id='common.options' />,
			dataSource: '',
		})
	}

	return { columns }
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let selectedCompany = state.profile.get('selectedCompany')
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		isLoadingUser: state.oidc.isLoadingUser,
		user: user,
		selectedCompany: selectedCompany ? selectedCompany.toJS() : null,
		isLoadingServicesProjects: state.contractorServicesProjects.get('isLoadingServicesProjects'),
		isSavingServiceProject: state.contractorServicesProjects.get('isSavingServiceProject'),
		servicesProjects: state.contractorServicesProjects.get('servicesProjects')
			? state.contractorServicesProjects.get('servicesProjects').toJS()
			: null,
		servicesProjectsDelete: state.contractorServicesProjects.get('servicesProjectsDeleted')
			? state.contractorServicesProjects.get('servicesProjectsDeleted').toJS()
			: null,
		servicesProjectsCount: state.contractorServicesProjects.get('servicesProjectsCount'),
		servicesProjectsDeletedCount: state.contractorServicesProjects.get('servicesProjectsDeletedCount'),
		sortCriteria: state.contractorServicesProjects.get('sortCriteria')
			? state.contractorServicesProjects.get('sortCriteria').toJS()
			: null,
		searchQuery: state.contractorServicesProjects.get('searchQuery'),
		page: state.contractorServicesProjects.get('page'),
		rowsPerPage: state.contractorServicesProjects.get('rowsPerPage'),
		translate: getTranslate(state.localize),
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isLoadingPurchaseOrders: state.contractorServicesProjects.get('isLoadingPurchaseOrders'),
		purchaseOrder: state.contractorServicesProjects.get('purchaseOrders')
			? state.contractorServicesProjects.get('purchaseOrders').toJS()
			: null,
		partnership: state.suppliers.get('partnership') ? state.suppliers.get('partnership').toJS() : null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadSupplier: (token) => {
			return dispatch(loadSupplier(token))
		},
		loadServicesProjects: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadServicesProjectsFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		loadServicesProjectsDeleted: (query, orderBy, sortAscending, page, rowsPerPage) => {
			dispatch(loadServicesProjectsDeletedFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage))
		},
		getServicesProjectsCount: () => {
			dispatch(getServicesProjectsCount())
		},
		getServicesProjectsDeletedCount: () => {
			dispatch(getServicesProjectsDeletedCount())
		},
		onAddServiceProject: (service, supplierName) => {
			return dispatch(addServiceProject(service, supplierName))
		},
		loadSupplierByCode: (supplierCode) => {
			dispatch(loadSupplierCode(supplierCode))
		},
		changeSortCriteria: (sortBy, sortAscending) => {
			dispatch({
				type: CHANGE_SERVICES_PROJECTS_SORT_CRITERIA,
				sortCriteria: {
					by: sortBy,
					ascending: sortAscending,
				},
			})
		},
		changeSearchQuery: (query) => {
			dispatch({
				type: CHANGE_SERVICES_PROJECTS_QUERY,
				query: query,
			})
		},
		changePage: (page, rowsPerPage) => {
			dispatch({
				type: CHANGE_SERVICES_PROJECTS_PAGINATION,
				page,
				rowsPerPage,
			})
		},
		downloadServiceReportByRangeDate: (startDate, endDate) => {
			return dispatch(downloadServiceReportByRangeDate(startDate, endDate))
		},
		moveServiceProyectToTrash: (token) => {
			return dispatch(removeServicesBycontractors(token))
		},
		loadPurchaseOrder: (supplierCode, purchaseOrder) => {
			return dispatch(loadPurchaseOrder(supplierCode, purchaseOrder))
		},
		loadLegalFolio: (supplierCode, purchaseOrder) => {
			return dispatch(loadLegalFolio(supplierCode, purchaseOrder))
		},
	}
}

const styles = (theme) => ({
	active: {
		color: '#28A745',
		margin: '5px',
	},
	inactive: {
		color: '#DC3545',
		margin: '5px',
	},
	titleItem: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 0,
		width: '95px',
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '5px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Index)))
)
