import { Button, LinearProgress, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import propTypes from 'prop-types'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Dropzone from '../../common/Dropzone'
/**Import components section */

const ViewComponent = (props) => {
	const {
		item,
		onEditRequest,
		onUploadAtitionalDocuments,
		onRemoveFile,
		onRemovePaidRequestCustoms,
		onUploadPaidRequestCustoms,
		onRemoveCustomsAgentExpenseAccount,
		onUploadCustomsAgentExpenseAccount,
		onRemoveCustomsAgentAdvancePaymentRequest,
		onUploadCustomsAgentAdvancePaymentRequest,
	} = props
	const shippingOrder = useSelector((state) =>
		state.shippingOrders.get('shippingOrder') ? state.shippingOrders.get('shippingOrder').toJS() : []
	)
	const isSaving = useSelector((state) => state.shippingOrders.get('isSaving'))

	const onUpdateRequestControl = () => {
		onEditRequest({
			token: item.token,
			advanceAgentCustomsData: item.advance_agent_customs_payment,
			advanceAgentCustoms: item.advance_agent_customs_reference,
			observations: item.observations,
		})
	}
	console.log('shippingOrder.aditional_documents', shippingOrder.aditional_documents)
	return (
		<div>
			<List
				dense
				style={{
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'auto',
					maxHeight: 'calc(100vh - 160px)',
				}}
			>
				<ListItem style={{ width: '100%' }}>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.additionalDocuments' />
									</strong>
								</Typography>
							</div>
						}
					/>
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					{!isSaving && (
						<Dropzone
							baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
							onChange={onUploadAtitionalDocuments}
							onDeleteFile={onRemoveFile}
							files={shippingOrder.aditional_documents}
							acceptedFiles={['xml', 'pdf', 'excel', 'images', 'XLSX','OtherFiles']}
						/>
					)}
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.addPaidRequestCustoms' />
									</strong>
								</Typography>
							</div>
						}
					/>
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					{!isSaving && (
						<Dropzone
							baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
							onChange={onUploadPaidRequestCustoms}
							onDeleteFile={onRemovePaidRequestCustoms}
							files={shippingOrder.paid_request_customs}
							acceptedFiles={['xml','pdf']}
							// maxFiles={1}
						/>
					)}
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.addCustomsAgentExpenseAccount' />
									</strong>
								</Typography>
							</div>
						}
					/>
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					{!isSaving && (
						<Dropzone
							baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
							onChange={onUploadCustomsAgentExpenseAccount}
							onDeleteFile={onRemoveCustomsAgentExpenseAccount}
							files={shippingOrder.customs_agent_expense_account}
							acceptedFiles={['xml', 'pdf', 'excel', 'images', 'XLSX','OtherFiles']}
						/>
					)}
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.addCustomsAgentAdvancePaymentRequest' />
									</strong>
								</Typography>
							</div>
						}
					/>
				</ListItem>
				<ListItem style={{ width: '100%' }}>
					{!isSaving && (
						<Dropzone
							baseApi={process.env.REACT_APP_SIP_URL_FILES_PLANNING}
							onChange={onUploadCustomsAgentAdvancePaymentRequest}
							onDeleteFile={onRemoveCustomsAgentAdvancePaymentRequest}
							files={shippingOrder.customs_agent_advance_payment_request}
							acceptedFiles={['xml', 'pdf', 'excel', 'images', 'XLSX','OtherFiles']}
						/>
					)}
				</ListItem>
				<br />
			</List>
			<List>
			{isSaving && <LinearProgress color='primary'/>}
				<ListItem>
					<Button
						onClick={() => onUpdateRequestControl()}
						data-cy='btn-save'
						type='button'
						color='primary'
						variant='outlined'
						fullWidth
						disabled={isSaving}
					>
						<Translate id='importRequests.sinchronizeWithSit' />
					</Button>
				</ListItem>
			</List>
		</div>
	)
}

const styles = (theme) => ({
	containerInformation: {
		maxHeight: `calc(100vh - ${165}px)`,
		minHeight: `calc(100vh - ${165}px)`,
		overflowY: 'auto',
		padding: '10px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	widthBtnSaved: {
		minWidth: '200px',
	},
	divider: {
		marginRight: '10px',
	},
	bgRed: {
		backgroundColor: 'red',
	},
})

ViewComponent.propTypes = {
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Selected item data
	 */
	item: propTypes.object,
}

ViewComponent.defaultProps = {
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
	item: {},
}

export default withRouter(withStyles(styles, { withTheme: true })(ViewComponent))
