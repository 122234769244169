/** Helpers import section */
import axios from 'axios'

import { getCompaniesGrantedToUser } from '../companies/CompanyActions'

export const SET_LOGGED_USER_GRANTED_COMPANIES = 'SET_LOGGED_USER_GRANTED_COMPANIES'
export const IS_LOADING_USER_PROFILE = 'IS_LOADING_USER_PROFILE'
export const SET_LOGGED_USER_ROLES = 'SET_LOGGED_USER_ROLES'
export const SET_IS_OPEN_LATERAL_MENU = 'SET_IS_OPEN_LATERAL_MENU'

export function setIsOpenClosedLateralMenu(isOpen) {
	return {
		type: SET_IS_OPEN_LATERAL_MENU,
		isOpen: isOpen,
	}
}

/**
 * Action to set the logged user companies
 *
 * @param {*} companies
 */
function setLoggedUserGrantedCompanies(companies) {
	return {
		type: SET_LOGGED_USER_GRANTED_COMPANIES,
		companies: companies,
	}
}

/**
 * Action to notify about loading the user profile
 *
 * @returns
 */
function isLoadingUserProfile(isLoading) {
	return {
		type: IS_LOADING_USER_PROFILE,
		isLoading,
	}
}

/**
 * Action to set logged user roles
 * @param {*} roles
 */
function setLoggedUserRoles(roles) {
	return {
		type: SET_LOGGED_USER_ROLES,
		roles: roles,
	}
}

/**
 * Load the logged in user profile
 *
 * @export
 * @returns
 */
export function loadLoggedUserProfile() {
	return function (dispatch, getState) {
		let companiesGranted = getState().profile.get('grantedCompanies')
		if (companiesGranted.size > 0) {
			// The profile has been already loaded... return
			return Promise.resolve()
		}

		// Load the profile
		dispatch(isLoadingUserProfile(true))
		let loggedUser = getState().oidc.user
		if (loggedUser) {
			return dispatch(getCompaniesGrantedToUser(loggedUser.profile.sub)).then((companiesResponse) => {
				if(companiesResponse && companiesResponse.data)
					dispatch(setLoggedUserGrantedCompanies(companiesResponse.data))
				dispatch(isLoadingUserProfile(false))
				return Promise.resolve()
			})
		} else {
			console.warn('Error getting the logged user profile. No oidc information in the store')
			return Promise.reject()
		}
	}
}

/**
 * Load user's role
 *
 * @export
 * @param {*} userId
 * @returns
 */
export function loadUserProfileRoles() {
	return function (dispatch, getState) {
		let userRoles = getState().profile.get('grantedRoles')
		if (userRoles.size > 0) {
			//console.log("userRoles", userRoles)
			// The profile has been already loaded... return
			return Promise.resolve()
		}

		dispatch(isLoadingUserProfile(true))
		let loggedUser = getState().oidc.user
		if (loggedUser) {
			let endPoint = `${process.env.REACT_APP_SIP_SUPPLIERS}/users/${loggedUser.profile.sub}/roles`
			return axios
				.get(endPoint)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setLoggedUserRoles(serverResponse.data))
						dispatch(isLoadingUserProfile(false))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isLoadingUserProfile(false))
					console.error("Error loading user's roles", error.response)
					return Promise.reject()
				})
		}
	}
}

export const confirmUserPassword = (password) => {
	return function (dispatch, getState) {
		let user = getState().oidc.user
		let command = {
			UserId: user ? user.profile.sub : '',
			UserPassword: password,
		}
		let endPoint = `${process.env.REACT_APP_IDENTITY_SERVER}api/user/credentialsconfirm`

		return axios
			.post(endPoint, command)
			.then((response) => {
				return Promise.resolve(response.data)
			})
			.catch((error) => {
				return Promise.reject()
			})
	}
}
