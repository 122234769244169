import moment from 'moment'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { CriteriaType, FileType, NumberFormatType, ScopeType } from './AppConstants'
import { isCategoryRawMaterials, isCategoryService } from './BiddingHelper'
import { COLOR_STATUS_ALERT_INFO, COLOR_STATUS_WARNING } from './StatusColorConstants'

/**
 * Main Function
 * Get selected Value of a Selector
 *
 * @export
 * @param {*} selectArray
 * @param {*} value
 * @returns
 */
export function getSelectedValue(selectArray, value) {
	return selectArray.find((option) => {
		return option.value === value || option.value === String(value)
	})
}

/**
 * Method to get all types PartnershipCode to selected
 */
export function GetPartnerships() {
	let types = []
	types.push({ value: '', name: 'Seleccione una opción' })
	types.push({ value: '0107', name: '0107' })
	types.push({ value: '0124', name: '0124' })
	types.push({ value: '0167', name: '0167' })
	types.push({ value: '0578', name: '0578' })
	types.push({ value: '0432', name: '0432' })
	types.push({ value: '0487', name: '0487' })
	types.push({ value: '0536', name: '0536' })
	types.push({ value: '0924', name: '0924' })
	types.push({ value: '1623', name: '1623' })
	types.push({ value: '1619', name: '1619' })
	types.push({ value: '1621', name: '1621' })
	types.push({ value: '0506', name: '0506' })
	types.push({ value: '1618', name: '1618' })
	types.push({ value: '1624', name: '1624' })
	types.push({ value: '0199', name: '0199' })
	types.push({ value: '0542', name: '0542' })
	return types
}

/**
 * Method to get all list Center for Companies to selected
 */
export function GetCentersToCompany() {
	let types = []
	types.push({ value: '', name: 'Seleccione una opción' })
	types.push({ value: '1000', name: '1000' })
	types.push({ value: '1100', name: '1100' })
	types.push({ value: '1200', name: '1200' })
	types.push({ value: '2500', name: '2500' })
	types.push({ value: '3000', name: '3000' })
	types.push({ value: '4000', name: '4000' })
	types.push({ value: '4001', name: '4001' })
	types.push({ value: '4100', name: '4100' })
	types.push({ value: '4500', name: '4500' })
	types.push({ value: '6000', name: '6000' })
	types.push({ value: '7000', name: '7000' })
	types.push({ value: '7100', name: '7100' })
	types.push({ value: '7001', name: '7001' })
	types.push({ value: '7499', name: '7499' })
	types.push({ value: '7500', name: '7500' })
	types.push({ value: '8000', name: '8000' })
	types.push({ value: '8001', name: '8001' })
	return types
}

/**
 * Method to get all list of Division Companies to selected
 */
export function GetAllDivisionToCompany() {
	let types = []
	types.push({ value: '', name: 'Seleccione una opción' })
	types.push({ value: 'V010', name: 'V010' })
	types.push({ value: 'V020', name: 'V020' })
	types.push({ value: 'P020', name: 'P020' })
	types.push({ value: 'P010', name: 'P010' })
	types.push({ value: 'M010', name: 'M010' })
	types.push({ value: 'M020', name: 'M020' })
	types.push({ value: 'V030', name: 'V030' })
	types.push({ value: 'T100', name: 'T100' })
	types.push({ value: 'T101', name: 'T101' })
	types.push({ value: 'T120', name: 'T120' })
	types.push({ value: 'T200', name: 'T200' })
	types.push({ value: 'T300', name: 'T300' })
	types.push({ value: 'T250', name: 'T250' })
	types.push({ value: 'T750', name: 'T750' })
	return types
}

/**
 * Method to get all list of Categories to selected
 */
export function GetAllCategoriesNotices() {
	let types = []
	types.push({ value: '', name: 'Seleccione una opción' })
	types.push({ value: 'Tutoriales', name: 'Tutoriales' })
	types.push({ value: 'Avisos', name: 'Avisos' })
	types.push({ value: 'Calidad', name: 'Calidad' })
	types.push({ value: 'Formatos', name: 'Formatos' })
	types.push({ value: 'Sostenibilidad', name: 'Sostenibilidad' })
	types.push({ value: 'Avisos de privacidad', name: 'Avisos de privacidad' })
	return types
}

/**
 * Method to get all notice change types
 */
export function GetRatingType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Proveedor Material prima' })
	types.push({ value: '2', label: 'Proveedor Indirecto' })
	return types
}

/**
 * Method to get all relase level
 */
export function GetReleaseLevel() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Nivel 1' })
	types.push({ value: '2', label: 'Nivel 2' })
	types.push({ value: '3', label: 'Nivel 3' })
	return types
}

/**
 * Method to get all notice change types
 */
export function GetChangeNoticeType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Liberación' })
	types.push({ value: '2', label: 'Cambio de revisión' })
	return types
}

/**
 * Method to get type quote
 */
export function GetTypeQuote(isPTM) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Normal' })
	if (isPTM === 'PTM') types.push({ value: '2', label: 'Maquila' })
	else types.push({ value: '2', label: 'Adquisición de insumos' })
	types.push({ value: '3', label: 'Consignación' })
	types.push({ value: '4', label: 'Escala' })
	return types
}

/**
 * Method to get all measurement unit list
 */
export function GetMeasurementUnits() {
	let units = []
	units.push({ value: '', label: 'Seleccione una opción' })
	units.push({ value: 1, label: 'Piezas' })
	units.push({ value: 2, label: 'Metros' })
	units.push({ value: 3, label: 'Kilogramos' })
	units.push({ value: 4, label: 'Toneladas' })
	units.push({ value: 5, label: 'Litros' })
	units.push({ value: 6, label: 'Miles' })
	units.push({ value: 7, label: 'Cientos' })
	units.push({ value: 8, label: 'Gramos' })
	units.push({ value: 9, label: 'Libras' })
	units.push({ value: 10, label: 'Galones' })
	units.push({ value: 11, label: 'Tramos' })
	units.push({ value: 12, label: 'Pallet' })
	units.push({ value: 13, label: 'Pie' })
	units.push({ value: 14, label: 'Pares' })
	units.push({ value: 15, label: 'Contenedores' })
	units.push({ value: 16, label: 'Cajas' })
	units.push({ value: 17, label: 'Cubetas' })
	units.push({ value: 18, label: 'Hoja PTM' })
	units.push({ value: 19, label: 'Juegos' })
	units.push({ value: 20, label: 'Papeles' })
	units.push({ value: 21, label: 'Bolsas' })
	units.push({ value: 22, label: 'Rollo' })
	units.push({ value: 23, label: 'Hoja' })
	units.push({ value: 24, label: 'Servicios' })
	return units
}

/**
 * Method to get type quote
 */
export function GetTypeInvestmentTooling() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Amortización 100% Proveedor' })
	types.push({ value: 2, label: 'Inversión 100% Planta' })
	types.push({ value: 3, label: 'Inversión 50% Planta / Amortización 50% Proveedor' })
	types.push({ value: 4, label: 'Inversión 50% Planta / 50% Proveedor' })
	types.push({ value: 5, label: 'Inversión 100% Proveedor' })
	types.push({ value: 6, label: 'Otro' })
	return types
}

/**
 * Method to get time unit
 */
export function GetTimeUnit() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Dias' })
	types.push({ value: '2', label: 'Semanas' })
	types.push({ value: '3', label: 'Meses' })
	types.push({ value: '4', label: 'Años' })
	return types
}

/**
 * Method to get delivery place
 */
export function GetDeliveryPlace() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Planta San Juan del Río' })
	types.push({ value: '2', label: 'Imbera planta SJR' })
	types.push({ value: '3', label: 'Imbera servicios (CEDIS) SJR' })
	return types
}

/**
 * Method to get type from proveduria
 */
export function GetTypeProveduria(companyCode) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Materia prima' })
	types.push({ value: '2', label: 'Refacciones' })
	if (companyCode === 'PTM') types.push({ value: '3', label: 'Maquilas' })
	else types.push({ value: '3', label: 'Adquisición de insumos' })
	types.push({ value: '4', label: 'Servicios' })
	if (companyCode === 'CEDIS') types.push({ value: '5', label: 'Indirectos' })
	return types
}

/**
 * function to get currency type
 */
export function GetCurrencyType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'ARS' })
	types.push({ value: 2, label: 'BRL' })
	types.push({ value: 3, label: 'COP' })
	types.push({ value: 4, label: 'EUR' })
	types.push({ value: 5, label: 'GBP' })
	types.push({ value: 6, label: 'MXP' })
	types.push({ value: 7, label: 'RMB' })
	types.push({ value: 8, label: 'USD' })
	types.push({ value: 9, label: 'VEB' })
	return types
}

/**
 * Method to get container type
 */
export function GetContainerType() {
	let types = []
	types.push({ value: '', label: 'Seleccionar una opción' })
	types.push({ value: '1', label: 'Conte 20' })
	types.push({ value: '2', label: 'Conte 30' })
	types.push({ value: '3', label: 'Conte 40' })
	types.push({ value: '4', label: 'Conte 40 HC' })
	types.push({ value: '5', label: 'Consolidado' })
	return types
}

/**
 * Method to get all container color
 */
export function GetContainmentColorType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 'Rosa', label: 'Rosa' })
	types.push({ value: 'Azul', label: 'Azul' })
	types.push({ value: 'Café', label: 'Café' })
	types.push({ value: 'Amarillo', label: 'Amarillo' })
	types.push({ value: 'Otra', label: 'Otra' })
	return types
}

/**
 * Method to get all container utilize
 */
export function GetContainmentUtilizeType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 'Marcado de cera', label: 'Marcado de cera' })
	types.push({ value: 'Etiqueta de color', label: 'Etiqueta de color' })
	types.push({ value: 'Marcador indeleble', label: 'Marcador indeleble' })
	return types
}

/**
 * Method to get all container Location
 */
export function GetContainmentLocationType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 'Zona que no es vista', label: 'Zona que no es vista' })
	types.push({ value: 'Parte lateral', label: 'Parte lateral' })
	types.push({ value: 'Al centro', label: 'Al centro' })
	types.push({ value: 'Zona visible', label: 'Zona visible' })
	types.push({ value: 'Otra', label: 'Otra' })
	return types
}

/**
 * Method to get all container IdentifyBy
 */
export function GetContainmentIdentifyByType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 'Pieza', label: 'Pieza' })
	types.push({ value: 'Caja', label: 'Caja' })
	types.push({ value: 'Tarima', label: 'Tarima' })
	types.push({ value: 'Bolsa', label: 'Bolsa' })
	types.push({ value: 'Paquete', label: 'Paquete' })
	types.push({ value: 'Piramide', label: 'Piramide' })
	types.push({ value: 'Contenedor', label: 'Contenedor' })
	return types
}

/**
 * Method to get areas
 */
export function GetAreas() {
	let types = []
	types.push({ value: '', label: 'Seleccionar una opción' })
	types.push({ value: 1, label: 'Desarrollo de Proveedores' })
	types.push({ value: 2, label: 'Manufactura' })
	types.push({ value: 3, label: 'Ingeniería de Producto' })
	types.push({ value: 4, label: 'Ingeniería Industrial' })
	types.push({ value: 5, label: 'Ingeniería de Refrigeración' })
	types.push({ value: 6, label: 'Planeación y Control de la Producción' })
	return types
}

/**
 * Method to get areas
 */
export function GetCecos() {
	let types = []
	types.push({ value: '', label: 'Seleccionar una opción' })
	types.push({ value: 204, label: '204  LOGISTICA EMPLEADOS IMBERA' })
	types.push({ value: 205, label: '205  ALMACEN PRODUCCIÓN TERMINADO' })
	types.push({ value: 206, label: '206  ALMACEN MATERIA PRIMA' })
	types.push({ value: 207, label: '207  ALMACEN PRODUCTO TERMINADO MEXICO' })
	types.push({ value: 208, label: '208  ALMACEN MTY' })
	types.push({ value: 501, label: '501  INGENIERIA DE PRODUCTO' })
	types.push({ value: 502, label: '502  INGENIERIA DE PROCESO' })
	types.push({ value: 503, label: '503  LABORATORIO' })
	types.push({ value: 504, label: '504  INGENIERIA DE REFRIGERACION' })
	types.push({ value: 505, label: '505  INVESTIGACION Y DESARROLLO' })
	types.push({ value: 512, label: '512  INGENIERIA DE PROYECTOS' })
	return types
}

/**
 * Method to get of Content Brand list
 */
export function GetContentBrandsList() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'PUNTO VERDE', label: 'PUNTO VERDE' })
	types.push({ value: 'PUNTO ROJO', label: 'PUNTO ROJO' })
	types.push({ value: 'PUNTO NARANJA', label: 'PUNTO NARANJA' })
	types.push({ value: 'PUNTO CON MARCADOR VERDE', label: 'PUNTO CON MARCADOR VERDE' })
	types.push({ value: 'PUNTO CON MARCADOR ROJO', label: 'PUNTO CON MARCADOR ROJO' })
	return types
}

/**
 * Get all GetAllTimeToEntryDeliveryOrder
 */
export function GetAllTimeToEntryDeliveryOrder() {
	let types = []
	types.push({ value: 0, label: 'No Selected' })
	types.push({ value: 30, label: '30 Minutos' })
	types.push({ value: 1, label: '1 Hora' })
	types.push({ value: 2, label: '2 Horas' })
	types.push({ value: 3, label: '3 Horas' })
	return types
}

/**
 * Get types official notice
 */
export function GetOfficialNoticeType(showVideoOption = false) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Avisos' })
	types.push({ value: 2, label: 'Comunicados' })
	if (showVideoOption) types.push({ value: 3, label: 'Video' })
	return types
}

/**
 * Method to get boolean options Yes/No and optionally add Not apply option
 */
export function GetBooleanOptions(withNotApply = false) {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Si' })
	types.push({ value: '2', label: 'No' })
	if (withNotApply) types.push({ value: '3', label: 'N/a' })
	return types
}

/**
 * Method to get action types
 */
export function GetActionTypes() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'Correctiva' })
	types.push({ value: '2', label: 'Mejora' })
	types.push({ value: '3', label: 'Preventiva' })

	return types
}

/**
 * Metod to get quote cancel type
 */
export function GetCancelFolio() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'Error de captura', label: 'Error de captura' })
	types.push({ value: 'Error de análisis orden de compra', label: 'Error de análisis orden de compra' })
	types.push({
		value: 'Error de análisis especificación de material',
		label: 'Error de análisis especificación de material',
	})
	types.push({ value: 'otro', label: 'otro' })

	return types
}

/**
 *Get report type in report guarantee
 *
 * @export
 */
export function GetReportType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'Garantía de planta', label: 'Garantía de planta' })
	types.push({ value: 'Garantía de componente', label: 'Garantía de componente' })
	return types
}

/**
 *Get report type in report guarantee
 *
 * @export
 */
export function GetAnalysisReportType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'Imputable al proveedor', label: 'Imputable al proveedor' })
	types.push({ value: 'Mal ensamble', label: 'Mal ensamble' })
	types.push({ value: 'Mal uso del componente', label: 'Mal uso del componente' })
	types.push({ value: 'Diseño', label: 'Diseño' })
	return types
}

export function GetMonths() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Enero' })
	types.push({ value: 2, label: 'Febrero' })
	types.push({ value: 3, label: 'Marzo' })
	types.push({ value: 4, label: 'Abril' })
	types.push({ value: 5, label: 'Mayo' })
	types.push({ value: 6, label: 'Junio' })
	types.push({ value: 7, label: 'Julio' })
	types.push({ value: 8, label: 'Agosto' })
	types.push({ value: 9, label: 'Septiembre' })
	types.push({ value: 10, label: 'Octubre' })
	types.push({ value: 11, label: 'Noviembre' })
	types.push({ value: 12, label: 'Diciembre' })

	return types
}

/**
 * MEthod to get years
 */
export function GetYears() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	let currentYear = moment().year()
	for (let year = currentYear; year >= currentYear - 5; year--) {
		types.push({ value: year, label: String(year) })
	}
	return types
}

/**
 * Get list of possible arrival hours
 *
 * @export
 * @returns
 */
export function GetArrivalHours(occupedHours) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })

	let items = []
	new Array(23).fill().forEach((_acc, index) => {
		if (index >= 7) {
			items.push(moment({ hour: index }).format('HH:mm'))
			if (index < 22) items.push(moment({ hour: index, minute: 30 }).format('HH:mm'))
		}
	})
	items.forEach((hour) => {
		let foundHour = foundOccupedHour(hour, occupedHours)
		let optionHour = {}
		optionHour.value = hour
		optionHour.label = hour
		if (foundHour !== undefined) {
			let colorlock =
				foundHour.message_lock === 'deliveryOrderModule.error.lockByRequiredDeliveryTime'
					? COLOR_STATUS_ALERT_INFO
					: COLOR_STATUS_WARNING
			optionHour.color = colorlock
			optionHour.isDisabled = true
			optionHour.messageLock = foundHour.message_lock
		}
		types.push(optionHour)
	})
	return types
}
export function GetArrivalHoursFersa(occupedHours, starTtime, endTime) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })

	let items = []
	new Array(15).fill().forEach((_acc, index) => {
		if (index >= starTtime) {
			items.push(moment({ hour: index }).format('HH:mm'))
			if (index < endTime) items.push(moment({ hour: index, minute: 30 }).format('HH:mm'))
		}
	})
	items.forEach((hour) => {
		let foundHour = foundOccupedHour(hour, occupedHours)
		let optionHour = {}
		optionHour.value = hour
		optionHour.label = hour
		if (foundHour !== undefined) {
			let colorlock =
				foundHour.message_lock === 'deliveryOrderModule.error.lockByRequiredDeliveryTime'
					? COLOR_STATUS_ALERT_INFO
					: COLOR_STATUS_WARNING
			optionHour.color = colorlock
			optionHour.isDisabled = true
			optionHour.messageLock = foundHour.message_lock
		}
		types.push(optionHour)
	})
	return types
}
export function GetArrivalHoursRamp3(occupedHours) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })

	let items = []
	// let hoursRamp3 = ;
	;[7, 9, 11, 13, 15, 17, 19].forEach((value) => {
		items.push(moment({ hour: value }).format('HH:mm'))
	})
	items.forEach((hour) => {
		let foundHour = foundOccupedHour(hour, occupedHours)
		let optionHour = {}
		optionHour.value = hour
		optionHour.label = hour
		if (foundHour !== undefined) {
			optionHour.color =
				foundHour.message_lock === 'deliveryOrderModule.error.lockByRequiredDeliveryTime'
					? COLOR_STATUS_ALERT_INFO
					: COLOR_STATUS_WARNING
			optionHour.isDisabled = true
			optionHour.messageLock = foundHour.message_lock
		}
		types.push(optionHour)
	})
	return types
}

/**
 * Get list of possible arrival hours
 *
 * @export
 * @returns
 */
export function GetArrivalHoursRamp4(occupedHours) {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })

	let items = []
	new Array(20).fill().forEach((_acc, index) => {
		if (index >= 8) {
			items.push(moment({ hour: index }).format('HH:mm'))
			if (index < 19) items.push(moment({ hour: index, minute: 30 }).format('HH:mm'))
		}
	})
	items.forEach((hour) => {
		let foundHour = foundOccupedHour(hour, occupedHours)
		let optionHour = {}
		optionHour.value = hour
		optionHour.label = hour
		if (foundHour !== undefined) {
			let colorlock =
				foundHour.message_lock === 'deliveryOrderModule.error.lockByRequiredDeliveryTime'
					? COLOR_STATUS_ALERT_INFO
					: COLOR_STATUS_WARNING
			optionHour.color = colorlock
			optionHour.isDisabled = true
			optionHour.messageLock = foundHour.message_lock
		}
		types.push(optionHour)
	})
	return types
}

function foundOccupedHour(hourToSearch, occupedHours) {
	return occupedHours.find((occupedHour) => {
		return (
			occupedHour.start_time === hourToSearch ||
			moment(hourToSearch, 'HH:mm').isBetween(
				moment(occupedHour.start_time, 'HH:mm'),
				moment(occupedHour.end_time, 'HH:mm')
			)
		)
	})
}

/**
 * Function to ger number ramps from release plan
 */
export function GetRampsFromRelease() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: '1 Rampa' })
	types.push({ value: 2, label: '2 Rampa' })
	types.push({ value: 3, label: '3 Rampa' })
	types.push({ value: 4, label: '4 Rampa' })
	types.push({ value: 5, label: 'Todas las rampas' })
	return types
}
/**
 * Function to ger number ramps from release planning Fersa
 */
export function GetRampsFersaFromRelease() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Nave A' })
	types.push({ value: 2, label: 'Nave B' })
	types.push({ value: 3, label: 'Cedis' })
	types.push({ value: 5, label: 'Todas las rampas' })
	return types
}
/**
 * returns the ramp of fersa
 */
export function GetRampsFersa(ramp) {
	if (ramp === 1) return 'Nave A'
	else if (ramp === 2) return 'Nave B'
	else if (ramp === 3) return 'Cedis'
	else return ''
}
export function GetRampsOptions() {
	let optionsRamp = [
		{ value: 0, label: 'Todas' },
		{ value: 1, label: 'Rampa 1' },
		{ value: 2, label: 'Rampa 2' },
		{ value: 3, label: 'Rampa 3' },
		{ value: 4, label: 'Rampa 4' },
	]
	return optionsRamp
}
/**
 * Fuction to ger number month of the confirm planning Fersa
 */
export function GetConfirmMonthFromRelease() {
	let types = []
	types.push({ value: 0, label: 'Seleciona una opción' })
	types.push({ value: 1, label: '1 mes' })
	types.push({ value: 2, label: '2 mes' })
	types.push({ value: 3, label: '3 mes' })
	types.push({ value: 4, label: '4 mes' })
	types.push({ value: 5, label: '5 mes' })
	types.push({ value: 6, label: '6 mes' })
	types.push({ value: 7, label: '7 mes' })
	types.push({ value: 8, label: '8 mes' })
	types.push({ value: 9, label: '9 mes' })
	types.push({ value: 10, label: '10 mes' })
	types.push({ value: 11, label: '11 mes' })
	types.push({ value: 12, label: '12 mes' })
	return types
}
/**
 * Function to get time to lock calendar
 */
export function GetTimeToLockCalendar() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '07:00', label: '7:00' })
	types.push({ value: '07:30', label: '7:30' })
	types.push({ value: '08:00', label: '8:00' })
	types.push({ value: '08:30', label: '8:30' })
	types.push({ value: '09:00', label: '9:00' })
	types.push({ value: '09:30', label: '9:30' })
	types.push({ value: '10:00', label: '10:00' })
	types.push({ value: '10:30', label: '10:30' })
	types.push({ value: '11:00', label: '11:00' })
	types.push({ value: '11:30', label: '11:30' })
	types.push({ value: '12:00', label: '12:00' })
	types.push({ value: '12:30', label: '12:30' })
	types.push({ value: '13:00', label: '13:00' })
	types.push({ value: '13:30', label: '13:30' })
	types.push({ value: '14:00', label: '14:00' })
	types.push({ value: '14:30', label: '14:30' })
	types.push({ value: '15:00', label: '15:00' })
	types.push({ value: '15:30', label: '15:30' })
	types.push({ value: '16:00', label: '16:00' })
	types.push({ value: '16:30', label: '16:30' })
	types.push({ value: '17:00', label: '17:00' })
	types.push({ value: '17:30', label: '17:30' })
	types.push({ value: '18:00', label: '18:00' })
	types.push({ value: '18:30', label: '18:30' })
	types.push({ value: '19:00', label: '19:00' })
	types.push({ value: '19:30', label: '19:30' })
	types.push({ value: '20:00', label: '20:00' })
	types.push({ value: '20:30', label: '20:30' })
	types.push({ value: '21:00', label: '21:00' })
	types.push({ value: '21:30', label: '21:30' })
	types.push({ value: '22:00', label: '22:00' })
	types.push({ value: '22:30', label: '22:30' })
	types.push({ value: '23:00', label: '23:00' })
	return types
}

/**
 * Get list of warehouses
 *
 * @export
 * @returns
 */
export function GetWarehouses() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '0010', label: '0010' })
	types.push({ value: '0011', label: '0011' })
	types.push({ value: '0012', label: '0012' })
	types.push({ value: '0016', label: '0016' })
	types.push({ value: '0017', label: '0017' })
	return types
}

/**
 * Get list of warehouses
 *
 * @export
 * @returns
 */
export function GetWarehousesFersa() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '4000', label: 'RECIBOS FERSA' })
	types.push({ value: '4001', label: 'RECIBOS PACTO' })
	types.push({ value: '4101', label: 'MATERIA PRIMA A' })
	types.push({ value: '4102', label: 'MATERIA PRIMA B' })
	types.push({ value: '4104', label: 'MP MAQUILA' })
	types.push({ value: '4110', label: 'INDIRECTO A' })
	types.push({ value: '4111', label: 'INDIRECTO B' })
	types.push({ value: '3000', label: 'RECIBOS FABATSA' })
	types.push({ value: '3100', label: 'MATERIA PRIMA FABATSA' })
	types.push({ value: '3101', label: 'INDIRECTOS' })
	types.push({ value: '3500', label: 'MAQUILA FABATSA' })
	return types
}

/**
 * Get list of warehouses
 *
 * @export
 * @returns
 */
export function GetWorkCentersFersa() {
	let types = []
	types.push({ value: '3000', label: '3000' })
	types.push({ value: '7500', label: '7500' })
	types.push({ value: '7510', label: '7510' })
	types.push({ value: '7520', label: '7520' })
	return types
}

/**
 * Get ramps in delivery order item
 */
export function GetRamps() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'Rampa 1', label: 'Rampa 1' })
	types.push({ value: 'Rampa 2', label: 'Rampa 2' })
	types.push({ value: 'Rampa 3', label: 'Rampa 3' })
	types.push({ value: 'Rampa 4', label: 'Rampa 4' })
	return types
}

/**
 * Method to get number of ramps by partnership
 *
 * @export
 * @returns
 */
export function GetRampsBySupplier(partnership) {
	let numberRamps = []
	numberRamps.push({ value: 0, label: 'Seleccione una opción' })
	/// Si no existe number_ramps por proveedor agregar 2 rampas
	if (partnership) {
		for (var ramp = 0; ramp < partnership.number_ramps; ramp++) {
			if (partnership.number_ramps !== 4) {
				numberRamps.push({
					value: ramp + 1,
					label: `Rampa ${ramp + 1}`,
				})
			}
			if (partnership.number_ramps === 4) {
				if (ramp === 0 || ramp === 1) {
					numberRamps.push({
						value: ramp + 1,
						label: `Rampa ${ramp + 1}`,
					})
				}
				if (ramp === 3) {
					numberRamps.push({
						value: 4,
						label: `Rampa ${4}`,
					})
				}
			}
		}
	} else {
		numberRamps.push({ value: 1, label: 'Rampa 1' })
		numberRamps.push({ value: 2, label: 'Rampa 2' })
	}
	return numberRamps
}

export function GetRampsBySupplierFersa(partnership) {
	let numberRamps = []
	numberRamps.push({ value: 0, label: 'Seleccione una opción' })
	/// Si no existe number_ramps por proveedor agregar 2 rampas
	let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	if (partnership) {
		for (var ramp = 0; ramp < partnership.number_ramps; ramp++) {
			if (ramp === 2) {
				numberRamps.push({
					value: ramp + 1,
					label: `Cedis`,
				})
			} else {
				numberRamps.push({
					value: ramp + 1,
					label: `Nave ${alphabet[ramp]}`,
				})
			}
		}
	} else {
		numberRamps.push({ value: 1, label: 'Nave A' })
		numberRamps.push({ value: 2, label: 'Nave B' })
	}
	return numberRamps
}

/**
 * Method to get transport type
 */
export function GetTransportType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Camión de carga (Torton)' })
	types.push({ value: 2, label: 'Trailer' })
	types.push({ value: 3, label: 'Camioneta' })
	return types
}

/**
 * Methos to get liste the options question
 */
export function GetQuestionType() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Abierta' })
	types.push({ value: 2, label: 'Multiple' })
	return types
}

export function GetQuestionTypeSustainability() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 0, label: 'Multiple' })
	types.push({ value: 1, label: 'Abierta' })
	return types
}

export function GetOpertationType() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Planta/Manufactura' })
	types.push({ value: 2, label: 'CEDIS' })
	types.push({ value: 3, label: 'Oficina Administrativa' })
	types.push({ value: 4, label: 'Taller de Servicio' })
	types.push({ value: 5, label: 'Punto de Venta' })
	types.push({ value: 6, label: 'Otros' })
	return types
}

export function GetContactsType() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Plantilla' })
	types.push({ value: 2, label: 'Compartir/Excluir' })
	return types
}

/**
 * Method to get questionnaires type
 */
export function GetQuestionnairesType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Sostenibilidad' })
	types.push({ value: 2, label: 'Sistema de Gestión de Calidad' })
	types.push({ value: 3, label: 'Certificaciones' })
	types.push({ value: 4, label: 'Código de Ética' })
	types.push({ value: 5, label: 'Capacitaciones' })
	return types
}

/**
 * Method to get questionnaires type
 */
export function GetClassificationType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Critica' })
	types.push({ value: 2, label: 'Área de oportunidad' })
	return types
}

/**
 * Methos to get liste the options question
 */
export function GetSeccionType() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Preguntas multiples o abiertas' })
	types.push({ value: 2, label: 'Preguntas de selección' })
	return types
}

/**
 * Method to add color in section questionaire
 */
export function GetColorInSectionType() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: '#941D3B', label: 'Guinda' })
	types.push({ value: '#e28f4f', label: 'Naranja' })
	types.push({ value: '#cd74db', label: 'Morado' })
	types.push({ value: '#3AC47D', label: 'Verde' })
	types.push({ value: '#3B86FF', label: 'Azul' })
	types.push({ value: '#28A745', label: 'Verde prueba' })
	return types
}

/**
 * Get type question from section
 */
export function GetQuestionBySection(sectionOpen = false) {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	if (sectionOpen) {
		types.push({ value: 1, label: 'Abierta' })
		types.push({ value: 2, label: 'Multiple' })
	} else {
		types.push({ value: 3, label: 'Catalogo Si/No' })
		types.push({ value: 4, label: 'Catalogo Si/No/Na' })
	}
	return types
}

export function GetBusinessTurnType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Industrial' })
	types.push({ value: 2, label: 'Comercial' })
	types.push({ value: 3, label: 'Servicio' })
	return types
}

export function GetBusinessType() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Micro' })
	types.push({ value: 2, label: 'Pequeña' })
	types.push({ value: 3, label: 'Mediana' })
	types.push({ value: 4, label: 'Grande' })
	return types
}

/**
 * Get ramps in delivery order item
 */
export function GetMaterialsTypeServices() {
	let types = []
	types.push({ value: '', label: 'Seleccione una opción' })
	types.push({ value: 'Material', label: 'Material' })
	types.push({ value: 'Hoja de seguridad', label: 'Hoja de seguridad' })
	return types
}

export function GetProspectTypes() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '6', label: 'Prospecto nacional P.M' })
	types.push({ value: '7', label: 'Prospecto nacional P.F' })
	types.push({ value: '5', label: 'Prospecto Extranjero ' })
	types.push({ value: '3', label: 'Prospecto Filial ' })

	return types
}

/**
 * Get type audit quality
 */
export function GetTypeAudit() {
	let types = []
	types.push({ value: 0, label: 'Seleccione la auditoría' })
	types.push({ value: 1, label: 'Inicial' })
	types.push({ value: 2, label: 'Seguimiento' })
	return types
}
export const getOptions = (option) => ({
	value: option.Id,
	label: `${option.Name}`,
})

/**
 * Get supplier status from audit quality
 */
export function getStatePlan() {
	let options = []
	options.push({ value: 0, label: <Translate id='qualityAudit.selectState' /> })
	options.push({ value: 1, label: <Translate id='common.accepted' /> })
	options.push({ value: 2, label: <Translate id='qualityAudit.conditionedState' /> })
	options.push({ value: 3, label: <Translate id='common.rejected' /> })
	return options
}

/**Get options for categories bindding*/
export const getOptionsCategoriesReactSelect = (data) => {
	return data.map((option) => getOptions(option))
}

/**
 * Get bidding criteria type
 */
export function GetBiddingEvaluationCriteriaType() {
	let types = []
	types.push({ value: CriteriaType.BOOLEAN, label: <Translate id='biddingsModule.services.criteriaType.bolean' /> })
	types.push({ value: CriteriaType.NUMERIC, label: <Translate id='biddingsModule.services.criteriaType.numeric' /> })
	types.push({ value: CriteriaType.TEXT, label: <Translate id='biddingsModule.services.criteriaType.text' /> })
	types.push({ value: CriteriaType.MONEY, label: <Translate id='biddingsModule.services.criteriaType.currency' /> })
	return types
}

/**
 * Get bidding evaluation criteria type
 */
export function GetBiddingEvaluationType() {
	let types = []
	types.push({ value: true, label: <Translate id='biddingsModule.services.automatic' /> })
	types.push({ value: false, label: <Translate id='biddingsModule.services.manual' /> })
	return types
}

/**
 * Get bidding evaluation rule type
 */
export function GetBiddingEvaluationRuleType() {
	let types = []
	types.push({ value: 0, label: <Translate id='biddingsModule.services.automaticEvaluationRule.none' /> })
	types.push({ value: 1, label: <Translate id='biddingsModule.services.automaticEvaluationRule.lowestIsTheBest' /> })
	types.push({ value: 2, label: <Translate id='biddingsModule.services.automaticEvaluationRule.highestIsTheBest' /> })
	return types
}

/**
 * Get number format criteria type
 */
export function GetNumberFormatsOptions() {
	let types = []
	// types.push({ value: NumberFormatType.CURRENCY , label: <Translate id="biddingsModule.numberFormat.currency" /> })
	types.push({ value: NumberFormatType.DECIMAL, label: <Translate id='biddingsModule.numberFormat.decimal' /> })
	types.push({ value: NumberFormatType.NUMBER, label: <Translate id='biddingsModule.numberFormat.number' /> })
	types.push({ value: NumberFormatType.PERCENT, label: <Translate id='biddingsModule.numberFormat.percent' /> })
	return types
}

export const getOptionsGroup = (option) => ({
	value: option.Id,
	label: `${option.Group}`,
})

/**Get options for group select*/
export const getOptionsGroupReactSelect = (data) => {
	return data.map((option) => getOptionsGroup(option))
}

/**
 * Get bidding evaluation rule type
 */
export function GetBiddingBoolEvaluationRuleType() {
	let types = []
	types.push({ value: 0, label: <Translate id='biddingsModule.services.automaticEvaluationRule.none' /> })
	types.push({ value: 4, label: <Translate id='biddingsModule.services.automaticEvaluationRuleBool.noIsTheBest' /> })
	types.push({ value: 3, label: <Translate id='biddingsModule.services.automaticEvaluationRuleBool.yesIsTheBest' /> })
	return types
}

export const getMeasurementsOptionsReactSelect = (option) => ({
	value: option,
	label: `${option}`,
})

/**Get options for group select*/
export const getMeasurementsOptions = (data) => {
	return data.map((option) => getMeasurementsOptionsReactSelect(option))
}

export const getIncotermsOptionsReactSelect = (option) => ({
	value: option.Term,
	label: `${option.Term} | ${option.Name}`,
})

/**Get options for incoterms select*/
export const getIncotermsOptions = (data) => {
	return data.map((option) => getIncotermsOptionsReactSelect(option))
}

export function GetIncotermsQuotes() {
	let types = []
	types.push({ value: 'EXW', label: 'EXW' })
	types.push({ value: 'FCA', label: 'FCA' })
	types.push({ value: 'FAS', label: 'FAS' })
	types.push({ value: 'FOB', label: 'FOB' })
	types.push({ value: 'CPT', label: 'CPT' })
	types.push({ value: 'CIP', label: 'CIP' })
	types.push({ value: 'CFR', label: 'CFR' })
	types.push({ value: 'CIF', label: 'CIF' })
	types.push({ value: 'DAT', label: 'DAT' })
	types.push({ value: 'DAP', label: 'DAP' })
	types.push({ value: 'DDP', label: 'DDP' })
	return types
}
export const getDivisasOptionsReactSelect = (option) => ({
	value: option.Code,
	label: `${option.Code} | ${option.Name}`,
})

/**Get options for divisas select*/
export const getDivisasOptions = (data) => {
	return data.map((option) => getDivisasOptionsReactSelect(option))
}

/**
 * Get bidding criteria options
 */
export function GetCriteriaOptionsType(bidding, isModeEdited) {
	let types = []
	if (isCategoryService(bidding)) {
		types.push({ value: ScopeType.ITEM, label: 'Criterio por item' })
	}
	if (isCategoryRawMaterials(bidding) && isModeEdited) {
		types.push({ value: ScopeType.ITEM, label: 'Criterio por item', isOptionDisabled: true })
	}
	types.push({ value: ScopeType.GENERAL, label: 'Criterio general' })
	return types
}

/**
 * Get evaluation quality type
 */
export function GetIndirectQualificationEvaluationType() {
	let types = []
	types.push({ value: 1, label: <Translate id='indirectqualifications.create.service' /> })
	types.push({ value: 2, label: <Translate id='indirectqualifications.create.sparePart' /> })
	return types
}

/**
 * Get folio type billings
 */
export function GetFolioType() {
	let types = []
	types.push({ value: 1, label: <Translate id='billings.create.import' /> })
	types.push({ value: 2, label: <Translate id='billings.create.export' /> })
	return types
}

export const getSuppliersTypesOptionsReactSelect = (option) => ({
	value: option.ClassificationCode,
	label: `${option.Name}`,
})

/**Get options for divisas select*/
export const getSupplierTypesOptions = (data) => {
	return data.map((option) => getSuppliersTypesOptionsReactSelect(option))
}

/**
 * Get bidding evaluation rule type
 */
export function GetFileTypeOptions() {
	let types = []
	types.push({ value: FileType.PDF, label: <Translate id='expedientSupplier.fileType.pdf' /> })
	types.push({ value: FileType.EXCEL, label: <Translate id='expedientSupplier.fileType.excel' /> })
	types.push({ value: FileType.ZIP, label: <Translate id='expedientSupplier.fileType.zip' /> })
	types.push({ value: FileType.WORD, label: <Translate id='expedientSupplier.fileType.work' /> })
	types.push({ value: FileType.IMAGEN, label: <Translate id='expedientSupplier.fileType.imagen' /> })
	types.push({ value: FileType.PDF_O_IMAGEN, label: <Translate id='expedientSupplier.fileType.PdfImagen' /> })
	return types
}

export const mappedCatalogOptionFromReactSelect = (option) => ({
	value: option.user_name,
	label: `${option.user_name} | ${option.name}`,
	email: option.user_email,
	name: `${option.user_name} | ${option.name}`,
	full_name: `${option.name}`,
})

export function GetSyncSapType() {
	let types = []
	types.push({ value: 1, label: 'Sincronización de 5 días' })
	types.push({ value: 2, label: 'Sincronizar por número de orden' })
	return types
}

/**Get options for divisas select*/
export const mappedSuppliersExternalOptions = (data) => {
	return data.map((option) => mappedCatalogOptionFromReactSelect(option))
}

export function ContractorsProjectServiceOptions() {
	let options = []
	options.push({ value: 0, label: <Translate id='common.selectedOption' /> })
	options.push({ value: 1, label: <Translate id='contractorsModule.purchaseOrder' /> })
	options.push({ value: 2, label: <Translate id='contractorsModule.contract' /> })
	options.push({ value: 3, label: <Translate id='contractorsModule.surveyQuotation' /> })
	return options
}

export function ContractorsProjectServiceOptionsImbera(active) {
	let options = []
	options.push({ value: 0, label: <Translate id='common.selectedOption' /> })
	if (active ? active['is_order'] : true)
		options.push({ value: 1, label: <Translate id='contractorsModule.purchaseOrder' /> })
	if (active ? active['is_contract'] : true)
		options.push({ value: 2, label: <Translate id='contractorsModule.contract' /> })
	options.push({ value: 3, label: <Translate id='contractorsModule.surveyQuotation' /> })
	return options
}

export function GetResearchColorOptions() {
	let option = []
	option.push({ value: 0, label: <Translate id='common.selectedOption' /> })
	option.push({ value: 1, label: <Translate id='suppliers.researchGreen' /> })
	option.push({ value: 2, label: <Translate id='suppliers.researchRed' /> })
	option.push({ value: 3, label: <Translate id='suppliers.researchYellow' /> })
	return option
}

export const getOptionsTypesProspects = (option) => ({
	value: option.ClassificationCode,
	label: `${option.ClassificationCode}`,
})

/**Get options for group select Types Prospects*/
export const getProspectsOptions = (data) => {
	return data.map((option) => getOptionsTypesProspects(option))
}

export function GetLanguageOptions() {
	let option = []
	option.push({ value: 0, label: <Translate id='common.spanish' /> })
	option.push({ value: 1, label: <Translate id='common.english' /> })
	option.push({ value: 2, label: <Translate id='common.portugues' /> })
	return option
}

export function GetTypeDealContract() {
	let optionsTypeDeal = [
		{ value: 1, label: 'Arrendamiento' },
		{ value: 2, label: 'Aviso de Terminación' },
		{ value: 3, label: 'Formato' },
		{ value: 4, label: 'Comodato' },
		{ value: 5, label: 'Compraventa' },
		{ value: 6, label: 'Convenio de Confidencialidad' },
		{ value: 7, label: 'Convenio Modificatorio' },
		{ value: 8, label: 'Prestación de Servicios' },
		{ value: 9, label: 'Maquila' },
		{ value: 10, label: 'Suministro' },
		{ value: 11, label: 'Otro' },
	]
	return optionsTypeDeal
}

export function GetCurrencyTypeContract() {
	let optionsTypeOfCurrency = [
		{ value: 0, label: 'NA' },
		{ value: 1, label: 'MXP' },
		{ value: 2, label: 'USD' },
		{ value: 3, label: 'EUR' },
	]
	return optionsTypeOfCurrency
}

export function GetSignatureConstract() {
	let optionsSignature = [
		{ value: 'Electrónica', label: 'Electrónica' },
		{ value: 'Física', label: 'Física' },
	]
	return optionsSignature
}

export function GetLegaContract() {
	let legalOptions = [
		{ value: 'Nacional', label: 'Nacional' },
		{ value: 'Internacional', label: 'Internacional' },
	]
	return legalOptions
}

export function GetLanguageContract() {
	let languageOptions = [
		{ value: 'Español', label: 'Español' },
		{ value: 'Ingles', label: 'Ingles' },
	]
	return languageOptions
}

export function GetOptionResolved() {
	let optionsResolved = [
		{ value: true, label: 'SI' },
		{ value: false, label: 'NO' },
	]
	return optionsResolved
}

export function GetConditionsContract() {
	let optionsConditions = [
		{ value: 0, label: 'Garantias' },
		{ value: 1, label: 'Seguros' },
		{ value: 2, label: 'Fianzas' },
		{ value: 3, label: 'Penalizaciones' },
		{ value: 4, label: 'Rebate' },
		{ value: 5, label: 'Otra' },
		{ value: 6, label: 'NA' },
	]
	return optionsConditions
}

export function GetTrackingContractType() {
	let optionsContractTypes = [
		{ value: 0, label: '-seleccione una opción-' },
		{ value: 1, label: 'Renovación vigencia' },
		{ value: 2, label: 'Otros clausulados' },
		{ value: 3, label: 'Aviso de terminación' },
	]
	return optionsContractTypes
}

export function GetSupplierNationalityType() {
	let options = [
		{ value: 1, label: <Translate id='factoringAffiliation.national' /> },
		{ value: 2, label: <Translate id='factoringAffiliation.foreigner' /> },
	]
	return options
}

export function GetPaymentConditionsType() {
	let options = [
		{ value: 90, label: <Translate id='factoringAffiliation.90Days' /> },
		{ value: 120, label: <Translate id='factoringAffiliation.120Days' /> },
		{ value: 150, label: <Translate id='factoringAffiliation.150Days' /> },
		{ value: 180, label: <Translate id='factoringAffiliation.180Days' /> },
	]
	return options
}

export function GetPaymentConditionsCurrencyType() {
	let options = [
		{ value: 'MXP', label: 'MXP' },
		{ value: 'USD', label: 'USD' },
	]
	return options
}

/**
 * Method to get all list Center for Companies to selected
 */
export function GetCentersFactoring() {
	let types = []
	types.push({ value: '4000', label: '4000' })
	types.push({ value: '4001', label: '4001' })
	types.push({ value: '4100', label: '4100' })
	types.push({ value: '4500', label: '4500' })
	types.push({ value: '6000', label: '6000' })
	types.push({ value: '7000', label: '7000' })
	types.push({ value: '7100', label: '7100' })
	types.push({ value: '7001', label: '7001' })
	types.push({ value: '7499', label: '7499' })
	types.push({ value: '7500', label: '7500' })
	return types
}

export function getCenterProspects() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: '4500 - METALICOS' })
	types.push({ value: '2', label: '5100 - PLÁSTICOS TÉCNICOS MEXICANOS TOTAL SOCIEDAD' })
	types.push({ value: '3', label: '7000 - IMBERA' })
	types.push({ value: '4', label: '7000 | 4500 - IMBERA | METALICOS ' })

	return types
}

export function getNoticeOfPrivacyIMBERA() {
	let types = []
	types.push({ value: '0', label: 'Seleccione una opción' })
	types.push({ value: '1', label: 'METALICOS' })
	types.push({ value: '2', label: 'PLÁSTICOS TÉCNICOS MEXICANOS TOTAL' })
	types.push({ value: '3', label: 'IMBERA' })
	return types
}

export const containerOptions = [
	{ value: 'Container20DC', label: '20DC' },
	{ value: 'Container40DC', label: '40DC' },
	{ value: 'Container40HC', label: '40HC' },
	{ value: 'ContainerConsolidated', label: 'Consolidado' },
	{ value: 'ContainerBox53', label: 'Caja 53' },
	{ value: 'ContainerRabon', label: 'Rabón' },
	{ value: 'ContainerAereo', label: 'Aéreo' },
	{ value: 'ContainerDHL', label: 'DHL' },
	{ value: 'ContainerFedex', label: 'FEDEX' },
	{ value: 'ContainerUPS', label: 'UPS' },
]

export const operationTyperOptions = [
	{ value: 'Aereo', label: 'Aéreo' },
	{ value: 'Paqueteria', label: 'Paquetería' },
	{ value: 'Ferreo', label: 'Férreo' },
	{ value: 'Maritimo', label: 'Marítimo' },
	{ value: 'Terrestre', label: 'Terrestre' },
	{ value: 'Consolidado', label: 'Consolidado' },
]

export const trackWithOptions = [
	{ value: 'Container number', label: 'Número de contenedor' },
	{ value: 'MBL / Booking Number', label: 'MBL / Número de reserva' },
]

// export const carrierOptions = [
// 	{ value: 'maersk', label: 'Maersk'},
// 	{ value: 'overseas-logistics', label: 'Overseas Logistics'},
// 	{ value: 'overseas-territory-fr-ems', label: 'Overseas Territory FR EMS'},
// 	{ value: 'overseas-territory-us-post', label: 'Overseas Territory FR EMS'},
// 	{ value: 'fedex', label: 'FedEx'},
// 	{ value: 'dhl', label: 'DHL'},
// 	{ value: 'ups', label: 'UPS'},
// 	{ value: 'cosco', label: 'COSCO eGlobal'},
// ]
export const carrierOptions = [
	{ value: 'ACL', label: 'ACL' },
	{ value: 'ADMIRAL LINE', label: 'ADMIRAL LINE' },
	{ value: 'AKKON LINES', label: 'AKKON LINES' },
	{ value: 'ALADIN EXPRESS', label: 'ALADIN EXPRESS' },
	{ value: 'ALIANCA', label: 'ALIANCA' },
	{ value: 'ANL', label: 'ANL' },
	{ value: 'ANTILLEAN MARINE', label: 'ANTILLEAN MARINE' },
	{ value: 'APL', label: 'APL' },
	{ value: 'ARKAS LINE', label: 'ARKAS LINE' },
	{ value: 'ASIA EXPRESS LINE', label: 'ASIA EXPRESS LINE' },
	{ value: 'AVANA LOGISTEK ( BALAJI )', label: 'AVANA LOGISTEK ( BALAJI )' },
	{ value: 'BAL LINE', label: 'BAL LINE' },
	{ value: 'BERMUDA CONTAINER LINE (BCL)', label: 'BERMUDA CONTAINER LINE (BCL)' },
	{ value: 'BG FREIGHT LINE', label: 'BG FREIGHT LINE' },
	{ value: 'BLPL SINGAPORE', label: 'BLPL SINGAPORE' },
	{ value: 'BLUE WATER LINES', label: 'BLUE WATER LINES' },
	{ value: 'BMC LINES', label: 'BMC LINES' },
	{ value: 'BOLUDA', label: 'BOLUDA' },
	{ value: 'CK LINE', label: 'CK LINE' },
	{ value: 'CMA CGM', label: 'CMA CGM' },
	{ value: 'CNC LINE', label: 'CNC LINE' },
	{ value: 'CONTAINERSHIPS (CONTAZ)', label: 'CONTAINERSHIPS (CONTAZ)' },
	{ value: 'CORDELIA SHIPPING LINE', label: 'CORDELIA SHIPPING LINE' },
	{ value: 'COSCO', label: 'COSCO' },
	{ value: 'COSIARMA', label: 'COSIARMA' },
	{ value: 'CROWLEY', label: 'CROWLEY' },
	{ value: 'CU LINES', label: 'CU LINES' },
	{ value: 'DONG YOUNG SHIPPING', label: 'DONG YOUNG SHIPPING' },
	{ value: 'DONGJIN SHIPPING', label: 'DONGJIN SHIPPING' },
	{ value: 'ECON SHIPPING', label: 'ECON SHIPPING' },
	{ value: 'ECU WORLDWIDE', label: 'ECU WORLDWIDE' },
	{ value: 'ELLERMAN', label: 'ELLERMAN' },
	{ value: 'EMIRATES LINE', label: 'EMIRATES LINE' },
	{ value: 'EMKAY LINE', label: 'EMKAY LINE' },
	{ value: 'EVERGREEN', label: 'EVERGREEN' },
	{ value: 'FESCO', label: 'FESCO' },
	{ value: 'G2 OCEAN', label: 'G2 OCEAN' },
	{ value: 'GEEST LINE', label: 'GEEST LINE' },
	{ value: 'GOLD STAR LINE', label: 'GOLD STAR LINE' },
	{ value: 'GOODRICH', label: 'GOODRICH' },
	{ value: 'GRIMALDI', label: 'GRIMALDI' },
	{ value: 'HAMBURG SUD', label: 'HAMBURG SUD' },
	{ value: 'HAPAG LLOYD', label: 'HAPAG LLOYD' },
	{ value: 'HEDE SHIPPING', label: 'HEDE SHIPPING' },
	{ value: 'HEUNG A', label: 'HEUNG A' },
	{ value: 'HOEGH AUTOLINERS', label: 'HOEGH AUTOLINERS' },
	{ value: 'HS LINE (HANSUNG)', label: 'HS LINE (HANSUNG)' },
	{ value: 'HYUNDAI MM', label: 'HYUNDAI MM' },
	{ value: 'INTERASIA', label: 'INTERASIA' },
	{ value: 'JJ SHIPPING', label: 'JJ SHIPPING' },
	{ value: 'JSV', label: 'JSV' },
	{ value: 'K LINE (RO-RO)', label: 'K LINE (RO-RO)' },
	{ value: 'KALYPSO', label: 'KALYPSO' },
	{ value: 'KAMBARA KISEN', label: 'KAMBARA KISEN' },
	{ value: 'KMTC', label: 'KMTC' },
	{ value: 'LESCHACO', label: 'LESCHACO' },
	{ value: 'MACS', label: 'MACS' },
	{ value: 'MAERSK LINE', label: 'MAERSK LINE' },
	{ value: 'MARFRET LINES', label: 'MARFRET LINES' },
	{ value: 'MARGUISA', label: 'MARGUISA' },
	{ value: 'MARIANA EXPRESS LINES (MELL)', label: 'MARIANA EXPRESS LINES (MELL)' },
	{ value: 'MASTER CONSOL LINE', label: 'MASTER CONSOL LINE' },
	{ value: 'MATSON', label: 'MATSON' },
	{ value: 'MAXICON', label: 'MAXICON' },
	{ value: 'MBF CARPENTERS', label: 'MBF CARPENTERS' },
	{ value: 'MEDKON', label: 'MEDKON' },
	{ value: 'MERCOSUL LINE', label: 'MERCOSUL LINE' },
	{ value: 'MESSINA LINE', label: 'MESSINA LINE' },
	{ value: 'MILAHA', label: 'MILAHA' },
	{ value: 'MSC', label: 'MSC' },
	{ value: 'NAMSUNG SHIPPING', label: 'NAMSUNG SHIPPING' },
	{ value: 'NATIONAL SHIPPING AMERICA (NSA)', label: 'NATIONAL SHIPPING AMERICA (NSA)' },
	{ value: 'NAUKA LINES', label: 'NAUKA LINES' },
	{ value: 'NIRINT SHIPPING', label: 'NIRINT SHIPPING' },
	{ value: 'ONE LINE', label: 'ONE LINE' },
	{ value: 'OOCL', label: 'OOCL' },
	{ value: 'OTHERS', label: 'OTHERS' },
	{ value: 'OVP SHIPPING', label: 'OVP SHIPPING' },
	{ value: 'PAN OCEAN', label: 'PAN OCEAN' },
	{ value: 'PANCON', label: 'PANCON' },
	{ value: 'PANTOS SHIPPING', label: 'PANTOS SHIPPING' },
	{ value: 'PASHA HAWAII', label: 'PASHA HAWAII' },
	{ value: 'PIL', label: 'PIL' },
	{ value: 'PSL NAVEGACAO', label: 'PSL NAVEGACAO' },
	{ value: 'RCL', label: 'RCL' },
	{ value: 'SAFETRANS LINE', label: 'SAFETRANS LINE' },
	{ value: 'SAMUDERA', label: 'SAMUDERA' },
	{ value: 'SARJAK CONTAINER LINE', label: 'SARJAK CONTAINER LINE' },
	{ value: 'SAVANA LINE', label: 'SAVANA LINE' },
	{ value: 'SCI', label: 'SCI' },
	{ value: 'SEA LEAD SHIPPING', label: 'SEA LEAD SHIPPING' },
	{ value: 'SEA LEGEND SHIPPING', label: 'SEA LEGEND SHIPPING' },
	{ value: 'SEALAND', label: 'SEALAND' },
	{ value: 'SEATRADE', label: 'SEATRADE' },
	{ value: 'SHIPCO TRANSPORT', label: 'SHIPCO TRANSPORT' },
	{ value: 'SIDRA LINE', label: 'SIDRA LINE' },
	{ value: 'SINOKOR', label: 'SINOKOR' },
	{ value: 'SINOTRANS', label: 'SINOTRANS' },
	{ value: 'SITC LINE', label: 'SITC LINE' },
	{ value: 'SM LINE', label: 'SM LINE' },
	{ value: 'STOLT TANK CONTAINERS', label: 'STOLT TANK CONTAINERS' },
	{ value: 'STREAMLINE SHIPPERS', label: 'STREAMLINE SHIPPERS' },
	{ value: 'SUNMARINE LINE', label: 'SUNMARINE LINE' },
	{ value: 'SWIRE SHIPPING', label: 'SWIRE SHIPPING' },
	{ value: 'TAILWIND', label: 'TAILWIND' },
	{ value: 'TARROS', label: 'TARROS' },
	{ value: 'TOTE MARITIME', label: 'TOTE MARITIME' },
	{ value: 'TRAILER BRIDGE', label: 'TRAILER BRIDGE' },
	{ value: 'TRANS ASIA LINE', label: 'TRANS ASIA LINE' },
	{ value: 'TRANSFAR SHIPPING', label: 'TRANSFAR SHIPPING' },
	{ value: 'TRANSIT FRUITS EAGLE', label: 'TRANSIT FRUITS EAGLE' },
	{ value: 'TRANSVISION SHIPPING', label: 'TRANSVISION SHIPPING' },
	{ value: 'TROY CONTAINER LINE', label: 'TROY CONTAINER LINE' },
	{ value: 'TS LINES', label: 'TS LINES' },
	{ value: 'TURKON LINE', label: 'TURKON LINE' },
	{ value: 'UGL SHIPPING', label: 'UGL SHIPPING' },
	{ value: 'UNIFEEDER', label: 'UNIFEEDER' },
	{ value: 'UWL', label: 'UWL' },
	{ value: 'VERTRACO SHIPPING', label: 'VERTRACO SHIPPING' },
	{ value: 'VOLTA SHIPPING', label: 'VOLTA SHIPPING' },
	{ value: 'W SHIPS LINES', label: 'W SHIPS LINES' },
	{ value: 'WALLENIUS LINES', label: 'WALLENIUS LINES' },
	{ value: 'WAN HAI LINES', label: 'WAN HAI LINES' },
	{ value: 'WEC LINES', label: 'WEC LINES' },
	{ value: 'YANG MING', label: 'YANG MING' },
	{ value: 'ZIM LINE', label: 'ZIM LINE' },
]
export const incotermOptions = [
	{ value: 'CFR', label: 'CFR' },
	{ value: 'CIF', label: 'CIF' },
	{ value: 'CIP', label: 'CIP' },
	{ value: 'CPT', label: 'CPT' },
	{ value: 'DAF', label: 'DAF' },
	{ value: 'DAP', label: 'DAP' },
	{ value: 'DAT', label: 'DAT' },
	{ value: 'DDP', label: 'DDP' },
	{ value: 'DDU', label: 'DDU' },
	{ value: 'DEQ', label: 'DEQ' },
	{ value: 'DES', label: 'DES' },
	{ value: 'EXW', label: 'EXW' },
	{ value: 'FAS', label: 'FAS' },
	{ value: 'FCA', label: 'FCA' },
	{ value: 'FOB', label: 'FOB' },
]

export function GetCurrencyPTM() {
	let types = []
	types.push({ value: 'MXP', label: 'MXP' })
	types.push({ value: 'USD', label: 'USD' })
	types.push({ value: 'EUR', label: 'EUR' })
	types.push({ value: 'GBP', label: 'GBP' })
	types.push({ value: 'RMB', label: 'RMB' })
	types.push({ value: 'VEB', label: 'VEB' })
	return types
}

export function GetMeasurementPTM() {
	let types = []
	types.push({ value: 'CA', label: 'CA' })
	types.push({ value: 'CIE', label: 'CIE' })
	types.push({ value: 'CJ', label: 'CJ' })
	types.push({ value: 'CM', label: 'CM' })
	types.push({ value: 'CUB', label: 'CUB' })
	types.push({ value: 'DOC', label: 'DOC' })
	types.push({ value: 'GAL', label: 'GAL' })
	types.push({ value: 'GAR', label: 'GAR' })
	types.push({ value: 'GR', label: 'GR' })
	types.push({ value: 'JGO', label: 'JGO' })
	types.push({ value: 'KG', label: 'KG' })
	types.push({ value: 'L', label: 'L' })
	types.push({ value: 'M', label: 'M' })
	types.push({ value: 'M2', label: 'M2' })
	types.push({ value: 'M3', label: 'M3' })
	types.push({ value: 'ML', label: 'ML' })
	types.push({ value: 'MLL', label: 'MLL' })
	types.push({ value: 'PAA', label: 'PAA' })
	types.push({ value: 'PZA', label: 'PZA' })
	types.push({ value: 'ROL', label: 'ROL' })
	types.push({ value: 'SER', label: 'SER' })
	types.push({ value: 'TAM', label: 'TAM' })
	types.push({ value: 'TO', label: 'TO' })
	types.push({ value: 'TOT', label: 'TOT' })
	types.push({ value: 'TAM', label: 'TS' })
	return types
}

export function GetPositionPTM() {
	let types = []
	types.push({ value: 'Administrativo', label: 'Administrativo' })
	types.push({ value: 'Profesional', label: 'Profesional' })
	types.push({ value: 'Técnico', label: 'Técnico' })
	types.push({ value: 'Operativo', label: 'Operativo' })
	return types
}

export const mappedCatalogOption = (option) => ({
	value: option.Code,
	label: `${option.Description}`,
})

/**Get options for divisas select*/
export const mappedCatologOptions = (data) => {
	return data.map((option) => mappedCatalogOption(option))
}


/**
 * Method to get boolean options Yes/No and optionally add Not apply option
 */
export function GetCuponFerryMotiveOptions() {
	let types = []
	types.push({ value: 0, label: 'Seleccione una opción' })
	types.push({ value: 1, label: 'Ida' })
	types.push({ value: 2, label: 'Vuelta' })
	return types
}
