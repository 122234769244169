import React from 'react'
import { Translate } from 'react-localize-redux'
import { Typography, withStyles, CardHeader, Card, IconButton, Tooltip } from '@material-ui/core'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { Edit } from '@material-ui/icons'
import WarningIcon from '@material-ui/icons/Warning'
import { Info } from '@material-ui/icons'
const CarrierInformation = (props) => {
	const { carrierInformation, classes, transportTenderdetail, isTypeTransportTrailer, openEditProposalInformation, isEditable, onOpenCalculateTarif } = props
	const link = carrierInformation && carrierInformation.League ? carrierInformation.League : ''

	const cleanUrl = link ? link.trim() : ''
	// Verificar si la URL comienza con "http" o "https"
	const finalUrl = cleanUrl.startsWith('http') ? cleanUrl : `http://${cleanUrl}`
	return (
		<Card>
			<CardHeader
				className={classes.cardContent}
				avatar={<AssignmentIndIcon color='secondary' fontSize='large' />}
				title={
					<div className={classes.informationContent}>
						<div className={classes.driverInformation}>
							<Typography gutterBottom variant='body2' component='p'>
								<strong>
									<Translate id='carrierTenders.carrierInformation.driverName' />:{' '}
								</strong>
								{carrierInformation.DriverName}
							</Typography>
							<Typography gutterBottom variant='body2' component='p'>
								<strong>
									<Translate id='carrierTenders.carrierInformation.driverPhoneNumber.title' />:{' '}
								</strong>
								{carrierInformation.DriverPhoneNumber}
							</Typography>
							<Typography gutterBottom variant='body2' component='p'>
								<strong>
									<Translate id='carrierTenders.carrierInformation.bearingPlates' />:{' '}
								</strong>
								{carrierInformation.BearingPlates}
							</Typography>
						</div>
						{isTypeTransportTrailer && (
							<div className={classes.transportationInformation}>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.economicUnit' />:{' '}
									</strong>
									{carrierInformation.EconomicUnit}
								</Typography>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.platesBox' />:{' '}
									</strong>
									{carrierInformation.PlatesBox}
								</Typography>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.economicalBox' />:
									</strong>
									{carrierInformation.EconomicalBox}
								</Typography>
							</div>
						)}
						<div className={classes.mapLink}>
							<Typography gutterBottom variant='body2' component='p'>
								<strong>
									<Translate id='carrierTenders.carrierInformation.link' />:
								</strong>
								<a href={finalUrl} target='_blank' rel='noopener noreferrer'>
									{carrierInformation.League
										? 'Ver ubicación de transporte en mapa'
										: 'Link no compartido'}
								</a>
							</Typography>
							<div>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.comments' />:{' '}
									</strong>
									{carrierInformation &&
									carrierInformation.Comments &&
									carrierInformation.Comments.length > 0
										? carrierInformation.Comments
										: 'Sin comentarios'}
								</Typography>
							</div>
							<div>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.user' />:{' '}
									</strong>
									{carrierInformation && carrierInformation.UserName
										? carrierInformation.UserName
										: '-'}
								</Typography>
							</div>
							<div>
								<Typography gutterBottom variant='body2' component='p'>
									<strong>
										<Translate id='carrierTenders.carrierInformation.password' />:{' '}
									</strong>
									{carrierInformation && carrierInformation.Password
										? carrierInformation.Password
										: '-'}
								</Typography>
							</div>
						</div>
						<div className={classes.mapLinkDescription}>
							<Typography gutterBottom style={{ wordBreak: 'break-word', whiteSpace: 'normal',maxWidth: '800px',  }} variant='body2' component='p'>
								<strong>
									<Translate id='carrierTenders.carrierInformation.linkDescription' />:
								</strong>

								{carrierInformation.League ? carrierInformation.League : ' Link no compartido'}
							</Typography>
						</div>
						{transportTenderdetail&&transportTenderdetail.ShowBaseRate && <div >
							<Typography gutterBottom variant='body2' component='p' >
								<strong>
									<Translate id='carrierTenders.resultsTender.tariffAmount' /> :
								</strong>{' '}
								{carrierInformation.TariffAmount
									? carrierInformation.TariffAmount.toLocaleString('es-MX', {
											style: 'currency',
											currency: 'MXN',
									  })
									: '$0 MXN'}
								<Tooltip
									title={
										carrierInformation.TariffAmount === 0 ? (
											<Translate id='carrierTenders.resultsTender.missinTariffAmount' />
										) : (
											<Translate id='carrierTenders.resultsTender.seeTariffAmount' />
										)
									}
								>
									<IconButton
										onClick={() => onOpenCalculateTarif(true, carrierInformation.TariffAmount)}
										fontSize={'small'}
									>
										{carrierInformation.TariffAmount === 0 ? (
											<WarningIcon className={classes.colorIconWarning} fontSize='small' />
										) : (
											<Info className={classes.colorIcon} fontSize='small' />
										)}
									</IconButton>
								</Tooltip>
							</Typography>
						</div>}
						<div className={classes.editActionContent}>
							{isEditable && (
								<div className={classes.editContent}>
									<IconButton onClick={() => openEditProposalInformation(true)}>
										<Edit fontSize='small' />
									</IconButton>
								</div>
							)}
						</div>
					</div>
				}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	cardContent: {
		boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)',
		borderRadius: '8px',
		backgroundColor: '#F9F9F9',
		borderBottom: '2px solid #e0e0e0',
		borderRight: '2px solid #e0e0e0',
	},
	informationContent: {
		display: 'grid',
	},
	driverInformation: {
		gridColumn: '1/2',
		gridRow: '1/2',
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 2fr 1fr',
	},
	transportationInformation: {
		gridColumn: '1/2',
		gridRow: '2/3',
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 2fr 1fr',
		gridAutoRows: '2fr',
	},
	mapLink: {
		gridColumn: '1/2',
		gridRow: '3/4',
		display: 'grid',
		gridTemplateColumns: '2fr 2fr 2fr 1fr',
	},
	titleProps: {},
	editActionContent: {
		gridRow: '1/4',
		gridColumn: '2/3',
	},
	editContent: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	mapLinkDescription: {
		gridRow: '4/5',
		display: 'grid',
	},
})
export default withStyles(styles, { withTheme: true })(CarrierInformation)
