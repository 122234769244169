/**BIDDING SECTION */
export const BiddingStatus = {
	DRAFT: 0,
	PUBLISHED: 1,
	CLOSED: 3,
	RESOLVED: 4,
	FINISHED: 5,
}

export const BiddingSuppliers = {
	INTERNAL: 0,
	EXTERNAL: 1,
}

export const BiddingMaterialOrServices = {
	MATERIAL: 0,
	SERVICE: 1,
}

export const CriteriaType = {
	NUMERIC: 'Numeric',
	TEXT: 'Text',
	LIST: 'List',
	BOOLEAN: 'Boolean',
	MONEY: 'Money',
}
export const ScopeType = {
	ITEM: 'ItemScope',
	GENERAL: 'GlobalScope',
}

export const ItemProposalStatus = {
	DRAFT: 0,
	COMPLETED: 1,
	DECLINED: 2,
	CANCELLED: 3,
}

export const sparePartPriceChangeRequestStatus = {
	DRAFT: 1,
	IN_PROCESS: 2,
	IS_ACCEPTED: 3,
	IS_REJECTED: 4,
	CLOSED: 5,
	CANCELLED: 6,
	PRICES_SAP: 7,
	DELETED: 8,
	UPDATE_SAP: 9,
	ERROR_SAP: 10,
	SEND_SAP: 11,
}

export const CategoryType = {
	SERVICE: 'Servicio',
	MATERIAL: 'Materias Primas',
}

export const NumberFormatType = {
	CURRENCY: 'C',
	DECIMAL: 'D',
	NUMBER: 'N',
	PERCENT: 'P',
}

export const ActionsStatus = {
	ACTION_PUBLISH: 'ACTION_PUBLISH',
	ACTION_CLOSED: 'ACTION_CLOSED',
	ACTION_CALCULATE: 'CALCULATE',
	ACTION_DECLINED_PROPOSAL: 'ACTION_DECLINED_PROPOSAL',
	ACTION_SEND_PROPOSAL: 'ACTION_SEND_PROPOSAL',
}

/**SUSTAINABILITY V2 SECTION */

export const SustainabilityStatus = {
	DRAFT: 0,
	SEND_SUPPLIER: 1,
	RESPONSE_SUPPLIER: 2,
	PUBLISH_RESULTS: 3,
	INITIAL_ACTIONS_PLAN: 4,
	IN_PROCEES_ACTIONS_PLAN: 5,
	RESPONSE_ACTIONS_SUPPLIER: 6,
	REEVALUATE_QUETIOONAIRE: 7,
	PROGRAM_AUDIT: 8,
	AUDIT_RESPONSE: 9,
	GENERATED_FINDINGS: 10,
	IN_PROCESS_FINFINGS: 11,
	FIDDINGS_RESPONSE: 12,
	REEVALUATE_AUDIT: 13,
	COMPLETED: 14,
	CANCELLED: 15,
	AUDIT_IN_PROCESS: 16,
	REMOVE: 17,
	REACTIVATE_FINDING: 18,
}

export const QualityAuditStatus = {
	DRAFT: 1,
	SEND_SUPPLIER: 3001,
	ANSWERED_SUPPLIER: 3002,
	RESEND_TO_SUPPLIER: 3004,
	CLOSED: 20,
	CANCELLED: 3003,
	ACCEPTED: 3005,
	EVIDENCE_SENT: 3006,
	REQUEST_EVIDENCE: 3007,
}

export const ClassificationType = {
	CRITICAL: 1,
	OPPORTUNITYAREA: 2,
}

export const SustainabilityActionStatus = {
	PENDIENT: 1,
	OPEN: 2,
	PROCESS: 3,
	CLOSE: 4,
	ACCEPTED: 5,
}

export const QuestionStatusType = {
	UNDEFINED: 0,
	COMPLIES: 1,
	FAILS: 2,
}
export const IndirectQualificationsStatus = {
	UNDEFINED: 0,
	DRAFT: 1,
	SEND_TO_EVALUATE: 2,
	CANCEL: 3,
	GENERATE_RATING: 4,
	PUBLISH_RATINGS: 5,
	REMOVE: 6,
}

export const ItemType = {
	//Version 1
	DELIVERY: 0,
	SPECIFICATION_COMPLIANCE: 1,
	PRICE: 2,
	DELIVERY_DOCUMENTATION: 3,
	CONFIRMATION: 4,
	RESPONSE_TIMES: 5, // V2
	RELIABILITY: 6,
	CONTROL_PROCESS: 7,
	CHANGES: 8,
	EMERGENCIES: 9,
	UNITS: 10, // V2
	NOTIFICATION_TRAVEL: 11, // V2
	ROUTE_CHANGE: 12, // V2
	NOTIFICATION_INCIDENCE: 13, // V2
	CARRIERS: 14,
	REGULATIONS: 15,
	DOCUMENTS_ADMINISTRATION: 16,
	LOADING_PUNCTUALITY: 17, // V2
	PUNTUALITY_DELIVERY: 18, // V2
	TARIFF_COST: 19,
	QUALITY_SERCICE: 20,
	CARRIER_DAMAGE: 21,
	DEVOLUTIONS: 22, // V2
	SINISTER: 23, // V2
	CRITICAL: 24,
	OTHERS: 25,
	CLEAN: 26,
	CONDITIONS: 27,
	REQUESTED_EQUIPMENT: 28,
	INFORMATION_AVAILABILITY: 29,

	//Version 2
	UNITS_CANCELLATION: 30,
	REJECTED: 31,
	MECHANICAL_FAILURE: 32,
	UNITS_STATUS: 33,
	GPS: 34,
	EXPENSE_LETTER: 35,
	DELIVERY_ACKNOWLEDGMENT: 36,
	UPDATED_STATEMENTS: 37,
	PHYSICAL_STATE: 38,
	AIR_SUSPENSION: 39,
	FASTENING_EQUIPMENT: 40,
	CIRCULATION_CARD: 41,
	LICENSE: 42,
}

export const SectionType = {
	//Version 1
	PRODUCT: 0,
	SERVICE: 1,
	EXTRA: 2,
	FLEXIBILITY: 3,
	AVAILABILITY: 4, // V2
	EFFECTIVE_COMUNICATION: 5, // V2
	COMPLIANCE_PROCESSES: 6, // V2
	SERVICE_TRANSPORT: 7, // V2
	INCIDENCTS: 8,
	UNITS: 9, // V2

	//Version 2
	COMPLIANCE_ADMINISTRATIVE: 10,
	DOCUMENTATION: 11,
}

export const SupplierType = {
	SERVICE: 0,
	PRODUCT: 1,
	CARRIER: 2,
}

export const IndirectQualificationType = {
	SERVICE: 1,
	CARRIER: 2,
}
export const FolioType = {
	IMPORT: 1,
	EXPORT: 2,
}

export const BillingStatus = {
	DRAFT: 1,
	SEND_TO_SUPPLIERS: 2,
	CANCEL: 3,
}

export const IndirectQualificationsSupplierStatus = {
	UNDEFINED: 0,
	DRAFT: 1,
	GENERATE_RATING: 2,
	CANCEL: 4,
	SEND_EVALUATE: 5,
	EVALUATE_RESPONSIBLE: 6,
	PUBLISH_RATING: 7,
	GENERATE_ACTION_PLANS: 8,
	SEND_ACTION_PLANT_TO_SUPPLIER: 9,
	ACTION_PLANT_RESPONSE: 10,
	CLOSE: 11,
}

export const ActionPlantStatus = {
	PENDIENT: 1,
	OPEN: 2,
	PROCESS: 3,
	AUTHORIZER: 4,
	CLOSE: 5,
}

export const ActionPlantQuestionnaireStatus = {
	PENDIENT: 1,
	OPEN: 2,
	PROCESS: 3,
	AUTHORIZER: 4,
	CLOSE: 5,
}

export const Evaluationclassification = {
	RELIABILITY_SUPPLIER: 'ReliableSupplier',
	RELIABILITY_WHITH_OPPORTUNITY: 'ReliableWithOpportunity',
	REGURAR_WHITH_OPPORTUNITY: 'RegularWithOpportunity',
	UNREALIABLE_SUPPLIER: 'UnreliableSupplier',
}

export const FileType = {
	PDF: 1,
	EXCEL: 2,
	ZIP: 3,
	WORD: 4,
	IMAGEN: 5,
	PDF_O_IMAGEN: 6,
}

export const ExpedientSupplierStatus = {
	UNDEFINED: 0,
	DRAFT: 49,
	SEND_SUPPLIERS: 50,
	ANSWERED_SUPPLIER: 51,
	ACCEPTED_IN_PLANT: 52,
	CLOSED_IN_PLANT: 53,
	CANCEL_IN_PLANT: 54,
	RESEND_TO_SUPPLIER: 55,
}
export const OfficialNoticeType = {
	NOTICE: 0,
	COMMINIQUE: 1,
	VIDEO: 3,
}

/**WAREHOUSE WINDOWS SECTION */
export const WarehouseWindowsStatus = {
	IN_PROCESS: 4,
	CLOSED: 20,
	CANCEL: 21,
}

export const HistorialConsultationMaquilaOrderType = {
	CONSULTATION_MAQUILA_ORDER: 1,
}

/** V2 SECTION */

export const ConsignementNoteStatus = {
	DRAFT: 0,
	SENT_TO_CARRIER: 1,
	IN_PROCESS: 2,
	COMPLETED: 3,
	CANCELLED: 4,
	PENDING_ACKNOWLEDGMENT: 5,
	RESENT_TO_CARRIERT: 6,
	PENDING_ADDITIONAL_EXPENSES: 7,
}

export const CertificateStatus = {
	DRAFT: 0,
	SENT_TO_VALIDATE: 1,
	COMPLETED: 2,
	FORWAEDED: 3,
	CANCELLED: 4,
	REMOVE: 5,
}

export const AcknowledgmentsReceiptFilesVersions = {
	V1: 'V1',
	V2: 'V2',
}

export const ConsingmentType = {
	SAP: 'SAP',
	HANDMADE: 'HANDMADE',
	DEVOLUTION: 'DEVOLUTION',
}

export const ConsingmentNoteFilesType = {
	CONSIGNMENT_NOTE: 'CONSIGNMENT_NOTE',
	ACKNOWLEDGMENT_RECEIPTS: 'ACKNOWLEDGMENT_RECEIPTS',
	ADDITIONAL_EXPENSES: 'ADDITIONAL_EXPENSES',
	EVIDENCE_DELIVERY: 'EVIDENCE_DELIVERY',
}

export const TransportTendeStatus = {
	UNDEFINED: 0,
	DRAFT: 1,
	SENT_TO_CARRIER: 2,
	SENT_LONG_TO_WINNING_CARRIER: 3,
	IN_EVALUATION: 4,
	CANCEL: 5,
}
export const CarrierStatus = {
	UNDEFINED: 0,
	DRAFT: 1,
	EVALUATION: 2,
	DECLINE: 3,
	EXPIRED: 4,
	CANCEL: 5,
}
export const ManeuverTypeEnum = {
	PayForImbera: 1,
	ContratedForTransport: 2,
	PaidCarrierToCustomer: 3,
}

export const FactoringAfiliationStatus = {
	DRAFT: 1,
}
export const FactoringStatus = {
	DRAFT: 1,
	IN_PROCESS: 2,
	VALIDATION_SUPPLIER: 3,
	REJECTED_BY_SUPPLIER: 4,
	PAYMENT_SETUP_COMPLETATION: 5,
	SIGNATURES_WITH_BANK: 6,
	CLOSED: 7,
}
export const FilesFolderName = {
	FactoringAffiliationFiles: 'FactoringAffiliationFiles',
	BankFiles: 'BankFiles',
	CitiFiles: 'CitiFiles',
}

export const BankType = {
	BBVA: 1,
	CITI: 2,
}

export const LoadAlertStatus = {
	DRAFT: 1,
}


export const DownloadType = {
	BY_SUPPLIER: 'BySuppliers',
	BY_DESTINATION_CLIENT: 'ByDestinationClient',
	BY_RFC: 'ByRFC',
	ALL: 'All'
}


export const IndirectPriceQuoteWithoutCodeItemStatus ={
	DRAFT: 1,
	ACCEPTED:2,
	REJECTED:3
}

// Enumerado en JavaScript
export const IndirectPriceQuoteSupplierStatus = {
    SEND_TO_SUPPLIER: 1,
    QUOTED: 2,
    CANCELLED: 3,
    NO_ANSWER: 4,
}

export const FerryCuponStatus ={
	DRAFT: 1,
	ACCEPTED:2,
	REJECTED:3
}