import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import autoBind from 'auto-bind'
import CreatableSelect from 'react-select/creatable'
/** Material-UI imports section */
import { Translate } from 'react-localize-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import OptionsIcon from '@material-ui/icons/LineStyle'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import GroupIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import TextField from '@material-ui/core/TextField'

import LoadingPage from '../../common/LoadingPage'
import Toaster from '../../common/Toaster'

/** Import actions */
import {
	COMPANY_PTM,
	COMPANY_REPARE,
	COMPANY_IMBERA,
	COMPANY_FERSA,
	COMPANY_METALICOS,
	COMPANY_MAQUINAS_EOS,
} from '../../../store/helpers/ResourcesHelper'
import {
	GetReleaseLevel,
	GetAllTimeToEntryDeliveryOrder,
	GetRampsFromRelease,
	GetRatingType,
	GetRampsFersaFromRelease,
	GetConfirmMonthFromRelease,
	GetResearchColorOptions,
} from '../../../store/helpers/SelectOptions'
import {
	loadAdvanceOptionsSupplier,
	updateAdvanceOptionsSupplier,
	loadPartnership,
	updatePartnershipSupplier,
	loadAllSupplierTypes,
} from '../../../store/suppliers/SupplierActions'
import { getSupplierTypesOptions } from '../../../store/helpers/SelectOptions'
import { canBeModuleExpeditByCompany } from '../../../store/helpers/RolesHelper'
import { converListToArray } from '../../../store/helpers/ListHelper'
import AdvancedOptionsPTM from '../common/AdvancedOptionsPTM'

/**
 * Component to show general information supplier
 */
class AdvancedOptions extends Component {
	/**
	 * Creates an instance of View.
	 * @param {*} props
	 * @memberof View
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			showToaster: false,
			toasterMessage: null,
			toasterVariant: '',
			supplierTypes: [],
			supplier: {
				release_level: '0',
				sorter: false,
				scales: false,
				is_online_products_supplier: false,
				is_contractor_supplier: false,
				is_custom_broker_supplier: false,
				is_supplier_to_evaluate: false,
				is_maquila_supplier: false,
				rating_type: '0',
				is_active_module_planning_fersa: false,
				access_patio_storage_windows: false,
				is_contrac_our_order: true,
			},
			partnership: {
				is_inspection_free: false,
				is_carrier: false,
				off_set_delivery_days: 0,
				days_required_for_delivery_material: 0,
				time_hours_required_for_delivery_material: 0,
				is_automotive_supplier: false,
				number_ppms_by_supplier: 0,
				number_ramps: 2,
				is_requiered_quality_certificate: false,
				is_requiered_cerificate_by_template: false,
				is_supplier_to_add_carbon_foot_print: false,
				month_confirm: 0,
				is_plant_access_blocked: false,
				blockingReason: '',
				research: 0,
				is_contract: true,
				is_order: true,
			},
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let supplierCode = this.props.supplierCode
		this.props.loadAdvanceOptionsSupplier(supplierCode).then(() => {
			this.setState({
				supplier: { ...this.props.advanceOptionsSupplier },
			})
		})
		/// loadPartnership
		let companyToken = this.props.selectedCompany.token
		this.props.loadPartnership(supplierCode, companyToken).then(() => {
			this.setState({
				partnership: { ...this.props.partnership },
			})
		})
		this.props.loadAllSupplierTypes().then((resultSuppliers) => {
			this.setState({
				supplierTypes: getSupplierTypesOptions(resultSuppliers),
			})
		})
	}

	/**
	 * On change document type
	 *
	 * @param {*} option
	 * @memberof DocumentTypeSelect
	 */
	onChangeDocumentType(option) {
		let updatedSupplier = { ...this.state.supplier }
		updatedSupplier['release_level'] = option.value
		this.setState({
			supplier: updatedSupplier,
		})
	}

	/**
	 * Update the property
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updateProperty(property, value) {
		let updatedSupplier = { ...this.state.supplier }
		updatedSupplier[property] = value
		this.setState({
			supplier: updatedSupplier,
		})
	}

	/**
	 * Action to save supplier
	 */
	onSaveAdvanceOption() {
		let supplierCode = this.props.supplierCode
		this.props
			.updateAdvanceOptionsSupplier(this.state.supplier, supplierCode)
			.then(() => {
				this.setState({
					showToView: true,
					showToaster: true,
					toasterMessage: <Translate id='common.saveCorrectly' />,
					toasterVariant: 'success',
				})
			})
			.catch(() => {
				this.setState({
					showToaster: true,
					toasterMessage: <Translate id='common.errorToSave' />,
					toasterVariant: 'error',
				})
			})
	}

	/**
	 * On change TimeToEntryDeliveryOrder
	 *
	 * @param {*} option
	 * @memberof TimeToEntryDeliveryOrder
	 */
	onChangeTimeToEntryDeliveryOrder(option) {
		let updatedPartnership = { ...this.state.partnership }
		updatedPartnership['time_hours_required_for_delivery_material'] = option.value
		this.setState({
			partnership: updatedPartnership,
		})
	}

	/**
	 * on change researche option
	 * @param {*} option
	 */
	onChangeResearchOption(option) {
		let updatePartnership = { ...this.state.partnership }
		updatePartnership['research'] = option.value
		this.setState({
			partnership: updatePartnership,
		})
	}

	/**
	 * On change TimeToEntryDeliveryOrder
	 *
	 * @param {*} option
	 * @memberof TimeToEntryDeliveryOrder
	 */
	onChangeSupplierType(option) {
		let updatedPartnership = { ...this.state.partnership }
		updatedPartnership['supplier_type'] = option.value
		this.setState({
			partnership: updatedPartnership,
		})
	}

	/**
	 * On change number_ramps
	 *
	 * @param {*} option
	 * @memberof number_ramps
	 */
	onChangeNumberRampsDeliveryOrder(option) {
		let updatedPartnership = { ...this.state.partnership }
		updatedPartnership['number_ramps'] = option.value
		this.setState({
			partnership: updatedPartnership,
		})
	}

	onChangeMonthConfirmDeliveryOrder(option) {
		let updatedMonthConfirm = { ...this.state.partnership }
		updatedMonthConfirm['month_confirm'] = option.value
		this.setState({
			partnership: updatedMonthConfirm,
		})
	}

	onChangeRatingType(option) {
		let updatedSupplier = { ...this.state.supplier }
		updatedSupplier['rating_type'] = option.value
		this.setState({
			supplier: updatedSupplier,
		})
	}

	/**
	 * Update partnership the property
	 *
	 * @param {*} user
	 * @param {*} value
	 * @memberof View
	 */
	updatePartnershipProperty(property, value) {
		let updatedPartnership = { ...this.state.partnership }
		updatedPartnership[property] = value
		this.setState({
			partnership: updatedPartnership,
		})
	}

	/**
	 * Action to save Partnership data
	 */
	onSavePartnershipData() {
		let supplierCode = this.props.supplierCode
		if (!this.canBeSaveBlockAccesPlant())
			this.setState({
				showToaster: true,
				toasterMessage: <Translate id='suppliers.messageBlock' />,
				toasterVariant: 'error',
			})
		else {
			this.props
				.updatePartnershipSupplier(this.state.partnership, supplierCode, this.props.selectedCompany.id)
				.then(() => {
					this.setState({
						showToView: true,
						showToaster: true,
						toasterMessage: <Translate id='common.saveCorrectly' />,
						toasterVariant: 'success',
					})
				})
				.catch(() => {
					this.setState({
						showToaster: true,
						toasterMessage: <Translate id='common.errorToSave' />,
						toasterVariant: 'error',
					})
				})
		}
	}

	canBeSaveBlockAccesPlant() {
		if (this.state.partnership.is_plant_access_blocked) {
			if (this.state.partnership.blockingReason !== '' && this.state.partnership.blockingReason !== null)
				return true
			else return false
		} else return true
	}

	/**
	 * Render
	 *
	 * @returns
	 * @memberof AdvancedOptions
	 */
	render() {
		const { classes } = this.props
		let options = GetReleaseLevel()
		let timeDeliveryMateril = GetAllTimeToEntryDeliveryOrder()
		let numberRamps = GetRampsFromRelease()
		let numberRampsFersa = GetRampsFersaFromRelease()
		let monthConfirm = GetConfirmMonthFromRelease()
		let ratingTypeOptions = GetRatingType()
		let researchOptions = GetResearchColorOptions()
		if (
			this.props.advanceOptionsSupplier === null &&
			this.state.supplier === null &&
			this.props.partnership === null
		) {
			return <LoadingPage />
		} else {
			let supplier = this.state.supplier
			let partnership = this.state.partnership
			let existSupplierType = this.state.supplierTypes.find((x) => x.value === partnership.supplier_type)
			return (
				<Grid container spacing={24} justify='space-between'>
					<Grid item xs={4}>
						<form noValidate autoComplete='off'>
							<List className={classes.root}>
								<ListItem alignItems='flex-start'>
									<OptionsIcon />
									<ListItemText secondary={<Translate id='suppliers.options' />} />
								</ListItem>
								<ListItem alignItems='flex-start'>
									<ListItemText
										primary={<Translate id='suppliers.releaseLevel' />}
										secondary={
											<Select
												value={options[supplier.release_level]}
												className={classes.documentTypeSelect}
												options={options}
												onChange={this.onChangeDocumentType}
											/>
										}
									/>
								</ListItem>
								{this.props.selectedCompany.company_code === COMPANY_IMBERA && (
									<ListItem alignItems='flex-start'>
										<ListItemText
											primary={<Translate id='suppliers.ratingType' />}
											secondary={
												<Select
													value={ratingTypeOptions[supplier.rating_type]}
													className={classes.documentTypeSelect}
													options={ratingTypeOptions}
													onChange={this.onChangeRatingType}
												/>
											}
										/>
									</ListItem>
								)}
								<ListItem alignItems='flex-start'>
									<ListItemText
										primary={
											<React.Fragment>
												<FormControlLabel
													control={
														<Checkbox
															onChange={(event) => {
																this.updateProperty('sorter', event.target.checked)
															}}
															color='primary'
															value='status'
															checked={supplier.sorter}
														/>
													}
													label={<Translate id='suppliers.isSupplierSorter' />}
												/>
												<FormControlLabel
													control={
														<Checkbox
															onChange={(event) => {
																this.updateProperty('scales', event.target.checked)
															}}
															color='primary'
															value='status'
															checked={supplier.scales}
														/>
													}
													label={<Translate id='suppliers.enableScales' />}
												/>
												<FormControlLabel
													control={
														<Checkbox
															onChange={(event) => {
																this.updateProperty(
																	'is_online_products_supplier',
																	event.target.checked
																)
															}}
															color='primary'
															value='status'
															checked={supplier.is_online_products_supplier}
														/>
													}
													label={<Translate id='suppliers.rmiInternal' />}
												/>
												{this.props.selectedCompany &&
													this.props.selectedCompany.company_code === COMPANY_PTM && (
														<FormControlLabel
															control={
																<Checkbox
																	onChange={(event) => {
																		this.updateProperty(
																			'is_supplier_to_evaluate',
																			event.target.checked
																		)
																	}}
																	color='primary'
																	value='status'
																	checked={supplier.is_supplier_to_evaluate}
																/>
															}
															label={<Translate id='suppliers.isSupplierToEvaluate' />}
														/>
													)}
												<FormControlLabel
													control={
														<Checkbox
															onChange={(event) => {
																this.updateProperty(
																	'is_contractor_supplier',
																	event.target.checked
																)
															}}
															color='primary'
															value='status'
															checked={supplier.is_contractor_supplier}
														/>
													}
													label={<Translate id='suppliers.isContractorSupplier' />}
												/>
												<FormControlLabel
													control={
														<Checkbox
															onChange={(event) => {
																this.updateProperty(
																	'access_patio_storage_windows',
																	event.target.checked
																)
															}}
															color='primary'
															value='status'
															checked={supplier.access_patio_storage_windows}
														/>
													}
													label={<Translate id='suppliers.hasAccessToPatioStorageWindows' />}
												/>

												{this.props.selectedCompany.company_code === COMPANY_IMBERA && (
													<FormControlLabel
														control={
															<Checkbox
																onChange={(event) => {
																	this.updateProperty(
																		'is_contrac_our_order',
																		event.target.checked
																	)
																}}
																color='primary'
																value='status'
																checked={supplier.is_contrac_our_order}
															/>
														}
														label={<Translate id='suppliers.isContracOurOrder' />}
													/>
												)}

												{this.props.selectedCompany.company_code === COMPANY_FERSA && (
													<FormControlLabel
														control={
															<Checkbox
																onChange={(event) => {
																	this.updateProperty(
																		'is_active_module_planning_fersa',
																		event.target.checked
																	)
																}}
																color='primary'
																value='status'
																checked={supplier.is_active_module_planning_fersa}
															/>
														}
														label={<Translate id='suppliers.isActiveModuleFersa' />}
													/>
												)}
											</React.Fragment>
										}
									/>
								</ListItem>
							</List>
							<Button
								style={{ marginLeft: '16px' }}
								data-cy='btn-saves'
								type='button'
								variant='outlined'
								color='secondary'
								onClick={() => {
									this.onSaveAdvanceOption()
								}}
								disabled={this.props.isSaving}
							>
								{<Translate id='common.save' />}
							</Button>
							<br />
							<br />
						</form>
					</Grid>
					{this.props.selectedCompany.company_code !== COMPANY_FERSA && (
						<Grid item xs={8}>
							<form noValidate autoComplete='off'>
								{this.props.selectedCompany &&
									this.props.selectedCompany.company_code !== COMPANY_PTM && (
										<List className={classes.root}>
											{(this.props.selectedCompany.company_code === COMPANY_REPARE ||
												this.props.selectedCompany.company_code === COMPANY_IMBERA ||
												this.props.selectedCompany.company_code === COMPANY_METALICOS ||
												this.props.selectedCompany.company_code === COMPANY_MAQUINAS_EOS) &&
												canBeModuleExpeditByCompany(this.props.userRolesByCompany) && (
													<>
														<ListItem alignItems='flex-start'>
															<GroupIcon />
															<ListItemText
																secondary={
																	<React.Fragment>
																		{
																			<Translate id='suppliers.optionsClasiffication' />
																		}
																	</React.Fragment>
																}
															/>

															<ListItemText
																secondary={
																	<React.Fragment>
																		{<Translate id='suppliers.deliveryPlan' />}
																	</React.Fragment>
																}
															/>
														</ListItem>

														<ListItem alignItems='flex-start'>
															<ListItemText
																primary={
																	<Translate id='suppliers.supplierType.title' />
																}
																secondary={
																	<div style={{ width: '310px' }}>
																		<CreatableSelect
																			placeholder={
																				<Translate id='suppliers.supplierType.title' />
																			}
																			options={this.state.supplierTypes}
																			onChange={this.onChangeSupplierType}
																			value={existSupplierType}
																			styles={{
																				menu: (base) => ({
																					...base,
																					zIndex: 9999,
																				}),
																				menuList: (base) => ({
																					...base,
																					paddingTop: 0,
																				}),
																				menuPortal: (base) => ({
																					...base,
																					zIndex: 9999,
																				}), /// THIS IS TO SHOW MENU OVER MODAL
																				control: (base) => ({
																					...base,
																					height: 40,
																					minHeight: 40,
																				}),
																			}}
																			menuPortalTarget={document.body}
																			menuPlacement={'bottom'}
																		/>
																	</div>
																}
															/>
														</ListItem>
													</>
												)}
											<ListItem alignItems='flex-start'>
												<GroupIcon />
												<ListItemText
													secondary={
														<React.Fragment>
															{<Translate id='suppliers.optionsQuality' />}
														</React.Fragment>
													}
												/>
											</ListItem>

											<ListItem alignItems='flex-start'>
												<ListItemText
													primary={
														<React.Fragment>
															<FormControlLabel
																control={
																	<Checkbox
																		onChange={(event) => {
																			this.updatePartnershipProperty(
																				'is_inspection_free',
																				event.target.checked
																			)
																		}}
																		color='primary'
																		value='status'
																		checked={partnership.is_inspection_free}
																	/>
																}
																label={<Translate id='suppliers.freeInspection' />}
															/>
															{supplier.is_contractor_supplier &&
																this.props.selectedCompany.company_code ===
																	COMPANY_IMBERA && (
																	<React.Fragment>
																		<br />
																		<FormControlLabel
																			control={
																				<Checkbox
																					onChange={(event) => {
																						this.updatePartnershipProperty(
																							'is_plant_access_blocked',
																							event.target.checked
																						)
																					}}
																					color='primary'
																					value='status'
																					checked={
																						partnership.is_plant_access_blocked
																					}
																				/>
																			}
																			label={
																				<Translate id='suppliers.blockAccesPlant' />
																			}
																		/>
																	</React.Fragment>
																)}
															{supplier.is_contrac_our_order &&
																this.props.selectedCompany.company_code ===
																	COMPANY_IMBERA && (
																	<React.Fragment>
																		<br />
																		<FormControlLabel
																			control={
																				<Checkbox
																					onChange={(event) => {
																						this.updatePartnershipProperty(
																							'is_order',
																							event.target.checked
																						)
																					}}
																					color='primary'
																					value='status'
																					checked={partnership.is_order}
																				/>
																			}
																			label={<Translate id='suppliers.isOrder' />}
																		/>
																	</React.Fragment>
																)}
															{supplier.is_contrac_our_order &&
																this.props.selectedCompany.company_code ===
																	COMPANY_IMBERA && (
																	<React.Fragment>
																		<br />
																		<FormControlLabel
																			control={
																				<Checkbox
																					onChange={(event) => {
																						this.updatePartnershipProperty(
																							'is_contract',
																							event.target.checked
																						)
																					}}
																					color='primary'
																					value='status'
																					checked={partnership.is_contract}
																				/>
																			}
																			label={
																				<Translate id='suppliers.isContract' />
																			}
																		/>
																	</React.Fragment>
																)}
															{supplier.is_contractor_supplier &&
																partnership.is_plant_access_blocked &&
																this.props.selectedCompany.company_code ===
																	COMPANY_IMBERA && (
																	<React.Fragment>
																		<br />
																		{<Translate id='suppliers.reasonBlocking' />}
																		<br />
																		<Grid item xs={6}>
																			<TextField
																				fullWidth
																				className={classes.textField}
																				name='blockingReason'
																				label={
																					<Translate id='suppliers.messageBlock' />
																				}
																				value={partnership.blockingReason}
																				multiline
																				margin='normal'
																				rows={4}
																				variant='outlined'
																				onChange={(event) => {
																					this.updatePartnershipProperty(
																						'blockingReason',
																						event.target.value
																					)
																				}}
																			/>
																		</Grid>
																	</React.Fragment>
																)}
														</React.Fragment>
													}
												/>
											</ListItem>
											<ListItem alignItems='flex-start'>
												<GroupIcon />
												<ListItemText
													secondary={
														<React.Fragment>
															{<Translate id='suppliers.transportationOptions' />}
														</React.Fragment>
													}
												/>
											</ListItem>

											<ListItem alignItems='flex-start'>
												<ListItemText
													primary={
														<React.Fragment>
															<FormControlLabel
																control={
																	<Checkbox
																		onChange={(event) => {
																			this.updatePartnershipProperty(
																				'is_carrier',
																				event.target.checked
																			)
																		}}
																		color='primary'
																		value='status'
																		checked={partnership.is_carrier}
																	/>
																}
																label={
																	<Translate id='suppliers.youAreCarrierProvider' />
																}
															/>
														</React.Fragment>
													}
												/>
											</ListItem>

											{this.props.selectedCompany &&
												this.props.selectedCompany.company_code === COMPANY_IMBERA && (
													<ListItem alignItems='flex-start'>
														<GroupIcon />
														<ListItemText
															secondary={
																<React.Fragment>
																	{<Translate id='suppliers.importationOptions' />}
																</React.Fragment>
															}
														/>
													</ListItem>
												)}

											{this.props.selectedCompany &&
												this.props.selectedCompany.company_code === COMPANY_IMBERA && (
													<ListItem alignItems='flex-start'>
														<ListItemText
															primary={
																<React.Fragment>
																	<FormControlLabel
																		control={
																			<Checkbox
																				onChange={(event) => {
																					this.updateProperty(
																						'is_custom_broker_supplier',
																						event.target.checked
																					)
																				}}
																				color='primary'
																				value='status'
																				checked={
																					supplier.is_custom_broker_supplier
																				}
																			/>
																		}
																		label={
																			<Translate id='suppliers.isCustomBrokerSupplier' />
																		}
																	/>
																</React.Fragment>
															}
														/>
													</ListItem>
												)}
										</List>
									)}
								{this.props.selectedCompany &&
									this.props.selectedCompany.company_code === COMPANY_PTM && (
										<AdvancedOptionsPTM
											classes={this.classes}
											canBeModuleExpeditByCompany={canBeModuleExpeditByCompany}
											onChangeSupplierType={this.onChangeSupplierType}
											existSupplierType={existSupplierType}
											researchOptions={researchOptions}
											onChangeTimeToEntryDeliveryOrder={this.onChangeTimeToEntryDeliveryOrder}
											partnership={partnership}
											updatePartnershipProperty={this.updatePartnershipProperty}
											onChangeNumberRampsDeliveryOrder={this.onChangeNumberRampsDeliveryOrder}
											numberRamps={numberRamps}
											timeDeliveryMateril={timeDeliveryMateril}
											supplierTypes={this.state.supplierTypes}
											userRolesByCompany={this.props.userRolesByCompany}
											onChangeResearchOption={this.onChangeResearchOption}
										/>
									)}

								<br />
								<Button
									style={{ marginLeft: '16px' }}
									data-cy='btn-save'
									type='button'
									variant='outlined'
									color='secondary'
									onClick={() => {
										this.onSavePartnershipData()
										this.onSaveAdvanceOption()
									}}
									disabled={this.props.isSaving}
								>
									{<Translate id='common.save' />}
								</Button>
							</form>
							<br />
							<br />
						</Grid>
					)}
					{this.props.selectedCompany.company_code === COMPANY_FERSA && (
						// <AdvacedOptionsPlanningFersa
						// 	onSavePartnershipData={this.onSavePartnershipData()}
						// 	isSaving={this.props.isSaving}
						// />
						<Grid item xs={8}>
							<form noValidate autoComplete='off'>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<div>
											<div className={classes.flexEnd}>
												<GroupIcon />
												<ListItemText
													secondary={
														<React.Fragment>
															{<Translate id='suppliers.optionsClasiffication' />}
														</React.Fragment>
													}
												/>
											</div>
											<div className={classes.margin}>
												<ListItemText
													primary={<Translate id='suppliers.supplierType.title' />}
													secondary={
														<div style={{ width: '310px' }}>
															<CreatableSelect
																placeholder={
																	<Translate id='suppliers.supplierType.title' />
																}
																options={this.state.supplierTypes}
																onChange={this.onChangeSupplierType}
																value={existSupplierType}
																styles={{
																	menu: (base) => ({ ...base, zIndex: 9999 }),
																	menuList: (base) => ({
																		...base,
																		paddingTop: 0,
																	}),
																	menuPortal: (base) => ({
																		...base,
																		zIndex: 9999,
																	}), /// THIS IS TO SHOW MENU OVER MODAL
																	control: (base) => ({
																		...base,
																		height: 40,
																		minHeight: 40,
																	}),
																}}
																menuPortalTarget={document.body}
																menuPlacement={'bottom'}
															/>
														</div>
													}
												/>
											</div>

											<div className={classes.flexEnd}>
												<GroupIcon />
												<ListItemText
													secondary={
														<React.Fragment>
															{<Translate id='suppliers.optionsQuality' />}
														</React.Fragment>
													}
												/>
											</div>
											<div className={classes.margin}>
												<ListItemText
													primary={
														<React.Fragment>
															<FormControlLabel
																control={
																	<Checkbox
																		onChange={(event) => {
																			this.updatePartnershipProperty(
																				'is_inspection_free',
																				event.target.checked
																			)
																		}}
																		color='primary'
																		value='status'
																		checked={partnership.is_inspection_free}
																	/>
																}
																label={<Translate id='suppliers.freeInspection' />}
															/>
														</React.Fragment>
													}
												/>{' '}
											</div>
											<div>
												<ListItemText
													primary={'Mes de confirmación'}
													secondary={
														<div style={{ width: '310px' }}>
															<Select
																value={monthConfirm[partnership.month_confirm]}
																className={classes.documentTypeSelect}
																options={monthConfirm}
																onChange={this.onChangeMonthConfirmDeliveryOrder}
																styles={{
																	menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
																	menuList: (base) => ({
																		...base,
																		maxHeight: 100,
																		paddingTop: 0,
																	}),
																	clearIndicator: (base) => ({
																		...base,
																		color: '#ff0000',
																		padding: 0,
																	}),
																	dropdownIndicator: (base) => ({
																		...base,
																		padding: 0,
																	}),
																	indicatorSeparator: () => null,
																}}
															/>
														</div>
													}
												/>
											</div>
										</div>
									</Grid>
									<Grid item xs={6}>
										<div>
											<div className={classes.margin}>
												<ListItemText
													secondary={
														<React.Fragment>
															{<Translate id='suppliers.deliveryPlan' />}
														</React.Fragment>
													}
												/>
											</div>
											<div className={classes.margin}>
												<ListItemText
													primary={<Translate id='suppliers.timeHoursforDeliveryMaterial' />}
													secondary={
														<div style={{ width: '310px' }}>
															<Select
																value={
																	timeDeliveryMateril[
																		partnership
																			.time_hours_required_for_delivery_material
																	]
																}
																className={classes.documentTypeSelect}
																options={timeDeliveryMateril}
																onChange={this.onChangeTimeToEntryDeliveryOrder}
															/>
														</div>
													}
												/>
											</div>
											<div className={classes.margin}>
												<ListItemText
													primary={<Translate id='suppliers.numberPampsPTM' />}
													secondary={
														<div style={{ width: '310px' }}>
															<Select
																value={numberRampsFersa[partnership.number_ramps]}
																className={classes.documentTypeSelect}
																options={numberRampsFersa}
																onChange={this.onChangeNumberRampsDeliveryOrder}
																styles={{
																	menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER ANY ELEMENT
																	menuList: (base) => ({
																		...base,
																		maxHeight: 150,
																		paddingTop: 0,
																	}),
																	clearIndicator: (base) => ({
																		...base,
																		color: '#ff0000',
																		padding: 0,
																	}),
																	dropdownIndicator: (base) => ({
																		...base,
																		padding: 0,
																	}),
																	indicatorSeparator: () => null,
																}}
															/>
														</div>
													}
												/>
											</div>
											<div className={classes.margin}>
												{' '}
												<ListItemText
													secondary={
														<div style={{ width: '310px' }}>
															<FormControlLabel
																control={
																	<Checkbox
																		onChange={(event) => {
																			this.updatePartnershipProperty(
																				'is_requiered_cerificate_by_template',
																				event.target.checked
																			)
																		}}
																		color='primary'
																		value='status'
																		checked={
																			partnership.is_requiered_cerificate_by_template
																		}
																	/>
																}
																label={
																	<Translate id='suppliers.isRequieredQualityCertificate' />
																}
															/>
														</div>
													}
												/>
											</div>
											<div className={classes.margin}>
												{' '}
												<TextField
													style={{ width: '290px' }}
													id='standard-read-only-input'
													label={<Translate id='suppliers.daysRequiredForDeliveryMaterial' />}
													value={partnership.days_required_for_delivery_material}
													onChange={(event) => {
														this.updatePartnershipProperty(
															'days_required_for_delivery_material',
															event.target.value
														)
													}}
												/>
											</div>
										</div>
									</Grid>
								</Grid>
								<br />
								<div className={classes.color}>
									<Button
										style={{ marginRight: '56px' }}
										data-cy='btn-save'
										type='button'
										variant='outlined'
										color='secondary'
										onClick={() => {
											this.onSavePartnershipData()
										}}
										disabled={this.props.isSaving}
									>
										{<Translate id='common.save' />}
									</Button>
								</div>
							</form>
						</Grid>
					)}

					<Toaster
						message={this.state.toasterMessage}
						open={this.state.showToaster}
						variant={this.state.toasterVariant}
						onClose={() => {
							this.setState({ showToaster: false })
						}}
					/>
				</Grid>
			)
		}
	}
}

/**
 * Map state to props mapStateToProps
 * @param {*} state
 */
function mapStateToProps(state) {
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	return {
		supplier: state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null,
		supplierTypes: state.suppliers.get('supplierTypes') ? state.suppliers.get('supplierTypes').toJS() : null,
		advanceOptionsSupplier: state.suppliers.get('advanceOptionsSupplier')
			? state.suppliers.get('advanceOptionsSupplier').toJS()
			: null,
		isSaving: state.suppliers.get('isSaving'),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
	}
}

/**
 * mapDispatchToProps
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadAdvanceOptionsSupplier: (token) => {
			return dispatch(loadAdvanceOptionsSupplier(token))
		},
		updateAdvanceOptionsSupplier: (supplier, code) => {
			return dispatch(updateAdvanceOptionsSupplier(supplier, code))
		},
		loadPartnership: (supplierCode, companyToken) => {
			return dispatch(loadPartnership(supplierCode, companyToken))
		},
		updatePartnershipSupplier: (partnership, supplierCode, companyId) => {
			return dispatch(updatePartnershipSupplier(partnership, supplierCode, companyId))
		},
		loadAllSupplierTypes: () => {
			return dispatch(loadAllSupplierTypes())
		},
	}
}

const viewStyles = (theme) => ({
	edit: {
		marginRight: 30,
		backgroundColor: theme.palette.text.hint,
	},
	root: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	imagenSize: {
		margin: 50,
		width: 1090,
		height: 420,
		marginLeft: 80,
	},
	formControl: {},
	toolbar: {},
	searchInput: {},
	button: {},
	actionsToolbar: {},
	showingUsersCounter: {},
	grow: {},
	supplierBadge: {},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: '100px',
		paddingRight: '150px',
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		marginLeft: 10,
		margin: 10,
		width: 60,
		height: 60,
	},
	logoFileInput: {
		display: 'none',
	},
	flexEnd: {
		display: 'flex',
		marginTop: '7px',
	},
	margin: {
		marginTop: '15px',
	},
	color: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})

export default withRouter(
	withStyles(viewStyles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AdvancedOptions))
)
