import { fromJS } from 'immutable'

import * as consignmentNoteActions from './consignmentNoteActions'

/**
 * contraActions initial state
 */
export const consignmentNoteInitialState = fromJS({
	isLoading: true,
	identifier: '',
	isSaving: false,
	consignmentNoteList: [],
	consignmentNoteCount: 0,
	consignmentNote: null,
	isDonwloading: false,
	deliveriesList: [],
	cuponsFerry: [],
	cuponsFerryManager: null,
	errorsCuponsFerryManager: []
})

export default function companiesReducer(state = consignmentNoteInitialState, action) {
	switch (action.type) {
		case consignmentNoteActions.START_LOADING_CONSIGNMENTNOTE_LIST: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case consignmentNoteActions.SET_CONSIGNMENTNOTE_LIST: {
			return state.merge({
				consignmentNoteList: fromJS(action.dataList),
				isLoading: false,
				identifier: '',
			})
		}
		case consignmentNoteActions.SET_CONSIGNMENTNOTE_COUNT: {
			return state.merge({
				consignmentNoteCount: action.count,
			})
		}
		case consignmentNoteActions.SET_DELIVERIES: {
			return state.merge({
				deliveriesList: fromJS(action.dataList),
			})
		}
		case consignmentNoteActions.IS_SAVING_CONSIGNMENTNOTE: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case consignmentNoteActions.SET_CONSIGNMENTNOTE: {
			return state.merge({
				isLoading: false,
				identifier: '',
				consignmentNote: action.consignmentNote,
			})
		}
		case consignmentNoteActions.IS_DOWNLOADING_CONSIGNMENTNOTE: {
			return state.merge({
				isDonwloading: action.isDonwloading,
			})
		}
		case consignmentNoteActions.ADD_CONSIGNMENTNOTE_MATERIAL: {
			return state.updateIn(['consignmentNote', 'deliveries'], (allDeliveries) =>
				addMaterial(allDeliveries, action.deliverieId, action.item)
			)
		}
		case consignmentNoteActions.SET_CUPONS_FERRY: {
			return state.merge({
				isLoading: false,
				identifier: '',
				cuponsFerry: action.cuponsFerry,
			})
		}
		case consignmentNoteActions.SET_CUPON_FERRY:{
			return state.updateIn(['cuponsFerry'], allCupons => addFerryCupon(allCupons, fromJS(action.cuponFerry))).set('isSaving', false);
		}
		case consignmentNoteActions.SET_FERRY_CUPONS_PAGINATED:{
			return state.merge({
				cuponsFerryManager: fromJS(action.cuponsFerryManager.items),
				cuponsFerryManagerCount: action.cuponsFerryManager.total,
				page: action.cuponsFerryManager.pageNumber,
				rowsPerPage: action.cuponsFerryManager.pageSize,
				sortCriteria: {
					sortBy: 'CreationDate',
					sortDirection: action.cuponsFerryManager.sortDirection,
				},
				isLoadingCuponsFerryManager: false,
			})
		}
		case consignmentNoteActions.SET_FERRY_CUPONS_MANAGERS_ERRORS: {
			return state.merge({
				errorsCuponsFerryManager: fromJS(action.errors),
			})
		}
		case consignmentNoteActions.DELETE_FERRY_CUPONS_MANAGERS:{
            return state.updateIn(['cuponsFerryManager'], allFerryCupons => allFerryCupons.filter(item => item.get('id') !== action.id)).set('isSaving', false);

		}
		case consignmentNoteActions.DELETE_FERRY_CUPON:{
            return state.updateIn(['cuponsFerry'], allFerryCupons => allFerryCupons.filter(item => item.get('id') !== action.id)).set('isSaving', false);

		}
		
		default:
			return state
	}
}

const addMaterial = (allDeliveries, deliverieId, item) => {
	let indexOfDeliverie = allDeliveries.findIndex((d) => d.get('Id') === deliverieId)
	if (indexOfDeliverie >= 0) {
		let materials = allDeliveries.get(indexOfDeliverie).toJS().materials
		materials.push(item)
		return allDeliveries.setIn([indexOfDeliverie, 'materials'], materials)
	}
	return allDeliveries
}

const addFerryCupon = (allCupons, cupon) => {
    let indexCupon = allCupons.findIndex(d => d.get('id') === cupon.get('id'));
    if (indexCupon >= 0) {
        return allCupons.setIn([indexCupon], cupon);
    }
    return allCupons.push(cupon)
}

