import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

import SampleIcon from '@material-ui/icons/LocalShipping'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import {
	Grid,
	TextField,
	Tooltip,
	Typography,
	IconButton,
	ListItemText,
	ListItemSecondaryAction,
	ListItem,
	List,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
	Switch,
} from '@material-ui/core'

import PlotSelector from '../../common/PlotSelector'
import SuppliersSelector from '../../common/SupplierSelector'
import SupplierByCompnaySelector from '../../common/SupplierByCompnaySelector'
import { PartNumberFileExtendType } from '../../../store/helpers/memosHelper'

/**
 * Memo item lateral options panels
 *
 * @class MemoItemDialog
 * @extends {Component}
 */
class MemoItemDialog extends Component {
	/**
	 *Creates an instance of MemoItemDialog.
	 * @param {*} props
	 * @memberof MemoItemDialog
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			comments: this.props.item ? this.props.item.comments : null,
			requiered_plot_art: this.props.item ? this.props.item.requiered_plot_art : null,
			requiered_plot: this.props.item ? this.props.item.requiered_plot : null,
		}
	}

	/**
	 * React life-cycle method
	 * Update status if props has change
	 *
	 * @param {*} prevProps
	 * @param {*} prevState
	 * @memberof MemoItemDialog
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.item !== prevProps.item) {
			this.setState({ comments: this.props.item.comments })
		}
	}

	/**
	 * On remove supplier
	 *
	 * @memberof MemoItemDialog
	 */
	onRemoveSupplierClick(item, quote) {
		if (this.props.onRemoveSupplier) {
			this.props.onRemoveSupplier(item, quote.supplier_token)
		}
	}

	/**
	 * On add sample request to supplier
	 */
	onAddSampleRequestSupplierClick(item, quote) {
		if (this.props.onAddSampleRequestSupplier) {
			this.props.onAddSampleRequestSupplier(item, quote)
		}
	}

	/**
	 * On remove sample request to supplier
	 */
	onRemoveSampleRequestSupplierClick(item, quote) {
		if (this.props.onRemoveSampleRequestSupplier) {
			this.props.onRemoveSampleRequestSupplier(item, quote.supplier_token)
		}
	}

	/**
	 * Update property in state
	 *
	 * @param {*} property
	 * @param {*} value
	 * @memberof MemoItemDialog
	 */
	updateProperty(property, value) {
		this.setState({
			[property]: value,
		})
		if (property === 'requiered_plot_art')
			if (this.props.onAddComment) this.props.onAddComment(this.props.item, this.state.comments, value, PartNumberFileExtendType.RequieredPlotArt)
		if (property === 'requiered_plot')
			if (this.props.onAddComment) this.props.onAddComment(this.props.item, this.state.comments, value, PartNumberFileExtendType.RequieredPlot)
	}

	/**
	 * on Add comment to item
	 *
	 * @param {*} item
	 * @memberof MemoItemDialog
	 */
	onAddComment(item) {
		if (this.props.onAddComment) this.props.onAddComment(item, this.state.comments, this.state.requiered_plot_art)
	}

	/**
	 *
	 *
	 * @memberof MemoItemDialog
	 */
	getSelectedSuppliers() {
		const { classes, item } = this.props
		return item.quotes.map((quote) => {
			return (
				<ListItem key={quote.quote_token} divider dense>
					<ListItemText
						primary={
							<React.Fragment>
								{quote.supplier_code} | {quote.supplier_name}{' '}
							</React.Fragment>
						}
					/>
					<ListItemSecondaryAction>
						<Tooltip
							title={
								<Translate
									id={
										quote.is_sample_requiered === true
											? 'memos.removeRequestSampleTooltipMessage'
											: 'memos.requestSampleTooltipMessage'
									}
								/>
							}
						>
							<IconButton
								className={classes.sampleButton}
								color={quote.is_sample_requiered === true ? 'primary' : 'default'}
								onClick={() => {
									quote.is_sample_requiered === true
										? this.onRemoveSampleRequestSupplierClick(item, quote)
										: this.onAddSampleRequestSupplierClick(item, quote)
								}}
							>
								<SampleIcon />
							</IconButton>
						</Tooltip>
						{quote.is_draft && (
							<Tooltip title={<Translate id='memos.removeSupplierRequestTooltipMessage' />}>
								<IconButton
									className={classes.deleteButton}
									onClick={() => {
										this.onRemoveSupplierClick(item, quote)
									}}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						)}
					</ListItemSecondaryAction>
				</ListItem>
			)
		})
	}

	/**
	 *
	 *
	 * @returns
	 * @memberof MemoItemDialog
	 */
	render() {
		const { item, companyImbera, classes } = this.props
		return (
			<Dialog open={this.props.show}>
				<DialogTitle id='form-dialog-title'>
					<Translate id='deliveryOrderModule.materialsTable.plot' />: {item.part_number} Rev. {item.review}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<PlotSelector
								specPlano={item.plano_number}
								revision={item.review}
								selectedPlotName={item.plot_name}
								formatype='Memos'
								kind='1'
								onPlotSelected={(plotSelectd) => {
									this.props.onPlotSelected(item, plotSelectd)
								}}
							/>
						</Grid>
						{companyImbera && (
							<div>
								<Grid item xs={12} className={classes.itemsDetail}>
									<Typography color='primary'>
										<Translate id='plotSelector.addPlotArt' />
									</Typography>
									<Switch
										checked={this.state.requiered_plot_art}
										onChange={(event) => {
											this.updateProperty('requiered_plot_art', event.target.checked)
										}}
									/>
								</Grid>
								<Grid item xs={12} className={classes.itemsDetail}>
									<Typography color='primary'>
										<Translate id='plotSelector.addPlot' />
									</Typography>
									<Switch
										checked={this.state.requiered_plot}
										onChange={(event) => {
											this.updateProperty('requiered_plot', event.target.checked)
										}}
									/>
								</Grid>
							</div>
						)}
						<Grid item xs={12}>
							<Typography>
								<Translate id='plotSelector.addComments' />
							</Typography>
							<TextField
								key={'comments'}
								fullWidth
								variant='outlined'
								multiline
								rowsMax='3'
								value={this.state['comments'] ? this.state['comments'] : ''}
								onChange={(event) => {
									this.updateProperty('comments', event.target.value)
								}}
								onBlur={() => this.onAddComment(item)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption' gutterBottom>
								<Translate id='memos.addSupplierToMemoMessage' />
							</Typography>
							{!companyImbera && (
								<SuppliersSelector
									onSupplierSelected={(supplier) => {
										this.props.onSupplierSelected(item, supplier)
									}}
								/>
							)}
							{companyImbera && (
								<SupplierByCompnaySelector
									onSupplierSelected={(supplier) => {
										this.props.onSupplierSelected(item, supplier)
									}}
								/>
							)}
							<List dense style={{ maxHeight: '20vh', overflowY: 'auto' }}>
								{this.getSelectedSuppliers()}
							</List>
							<br />
							<br />
							<br />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.onClose} color='primary'>
						<Translate id='common.close' />
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	//SSM-0558-19
	deleteButton: {
		color: 'red',
		maxWidth: 35,
		maxHeight: 35,
		paddingTop: 6,
		paddingLeft: 14,
	},
	sampleButton: {
		maxWidth: 35,
		maxHeight: 35,
		paddingTop: 6,
		paddingLeft: 14,
	},
	itemsDetail: {
		display: 'flex',
		alignItems: 'center',
	},
})

export default withStyles(styles, { withTheme: true })(MemoItemDialog)
