import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import autoBind from 'auto-bind'
import { withStyles } from '@material-ui/core/styles'
import { Translate, getTranslate } from 'react-localize-redux'
import { Link } from 'react-router-dom'

/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { ListItemIcon, CardHeader, ListItemText, ListItem, List, Tooltip, IconButton, Button } from '@material-ui/core'

import FolioIcon from '@material-ui/icons/Filter9Outlined'
import ProspectSupplierIncon from '@material-ui/icons/LocalShipping'
import Avatar from '@material-ui/core/Avatar'
import NotesIcon from '@material-ui/icons/NotesSharp'
import UserIcon from '@material-ui/icons/Person'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import AcceptIcon from '@material-ui/icons/ThumbUp'
import RejectIcon from '@material-ui/icons/ThumbDown'
import CancelIcon from '@material-ui/icons/NotInterested'
import SendIcon from '@material-ui/icons/Send'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'
import PrivacyIcon from '@material-ui/icons/ChromeReaderMode'
import AddIcon from '@material-ui/icons/Add'
import BusinessIcon from '@material-ui/icons/Business'

import Reorder from '@material-ui/icons/Reorder'
import { AppBar, Tabs, Tab } from '@material-ui/core'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import LoadingPage from '../common/LoadingPage'
import HtmlEditor from '../common/HtmlEditor'
import Toaster from '../common/Toaster'
import AddCommonItem from '../common/AddCommonItem'
import DocumentsProspect from './Components/DocumentsProspect'
import Evidence from '../common/Evidence'

import {
	COMPANY_IDENTIFIER_IMBERA_MX,
	COMPANY_IDENTIFIER_IMBERA_REPARE,
	COMPANY_IDENTIFIER_PTMT,
	COMPANY_IDENTIFIER__METALLIC,
	COMPANY_IMBERA,
	ENVIROMENT_TYPE_PRODUCTION,
} from '../../store/helpers/ResourcesHelper'
/** Import action section */
import {
	canBeModuleProspectsSupplier,
	ROLE_PROSPECTS_SUPPLIERS,
	ROLE_COMPANYADMINISTRATOR,
	IsUserInRole,
	ROLE_SUPPLIERDEVELOPMENT,
	ROLE_SUPPLIER_DEVELOPERS_EVALUATION_QUESTIONNAIRE_FILE,
} from '../../store/helpers/RolesHelper'
import { converListToArray } from '../../store/helpers/ListHelper'
import {
	loadProspect,
	acceptedByClient,
	rejectedByClient,
	sendDocumentsToProspect,
	sendEmailReminderProspect,
	updateStatusFileInPlant,
	updatePropertyProspect,
	canceledByClient,
	removeProspectByClient,
	loadProspectHistory,
	processToSapByClient,
	updateProspect,
	uploadDocumentsSendProspect,
	removeFilesSendProspect,
	registration,
	removeDocumentByProspect,
	saveDocumentToAddProspect,
	uploadDocumentsProspect,
	removeFilesEvidence,
} from '../../store/prospects/ProspectAction'
import { showErrorToaster, showSuccessToaster } from '../../store/helpers/ToasterHelpers'
import { IsNullOrEmpty } from '../../store/helpers/StringHelper'
import { checkPrivacyNotice } from '../../store/helpers/NoticeOfPrivacy'
import { backgroundColorSelect } from '../../store/helpers/ProspectHelper.js'
import ProspectStatusHistory from './Components/ProspectStatusHistory'
import TextField from '@material-ui/core/TextField/TextField'
import RegistrationSupplier from './Components/RegistrationSupplier'
import ShowPanelToAddFiles from '../expedientSupplier/components/show/ShowPanelToAddFiles.js'

/**
 * Container show prospect
 */
class Show extends Component {
	/**
	 * Create an instance prospect
	 * @param {*} props
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = {
			openAcepted: false,
			openRejected: false,
			openCancel: false,
			openSendDocuments: false,
			openSendMail: false,
			openRemove: false,
			openProcessToSap: false,
			openRegistrationProvider: false,
			activeTab: 0,
			showDocuments: false,
		}
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		let prospectToken = this.props.match.params.token
		this.props.loadProspect(prospectToken)
		this.props.loadProspectHistory(prospectToken)
	}

	updateValueProspect(event) {
		const { prospect } = this.props
		const { id, value, name } = event.target
		const { key } = event
		if (key === 'Enter') {
			if (name === 'contact_email') {
				let update = prospect ? prospect.contact_email.replace(id, value) : ''
				this.props
					.updateProspect(this.props.match.params.token, update, name)
					.then(() => {
						this.setState(showSuccessToaster('common.saveCorrectly'))
					})
					.catch((error) => {
						this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
					})
			} else {
				this.props
					.updateProspect(this.props.match.params.token, value, name)
					.then(() => {
						this.setState(showSuccessToaster('common.saveCorrectly'))
					})
					.catch((error) => {
						this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
					})
			}
		}
	}

	/**
	 * Render item in list of prospect
	 *
	 * @param {*} idTranslation
	 * @param {*} value
	 * @param {*} icon
	 * @param {*} isLink
	 * @param {*} isButton
	 * @returns
	 * @memberof Show
	 */
	renderItemList(idTranslation, value, icon) {
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText
					secondary={
						<>
							<Typography
								component='span'
								variant='body2'
								className={this.props.classes.inline}
								color='textPrimary'
							>
								<Translate id={idTranslation} />
							</Typography>
							{': ' + value}
						</>
					}
				/>
			</ListItem>
		)
	}

	renderItemListTextField(idTranslation, value, icon, name, disabled = false) {
		const { classes } = this.props
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<div className={classes.titleStyles}>
					<Typography
						component='span'
						variant='body2'
						className={this.props.classes.inline}
						color='textPrimary'
					>
						<Translate id={idTranslation} />
					</Typography>
					{': '}
				</div>
				<TextField
					className={classes.textFieldStyles}
					onKeyPress={this.updateValueProspect}
					id='filled-margin-dens'
					name={name}
					label={''}
					disabled={disabled}
					defaultValue={value}
				/>
			</ListItem>
		)
	}

	renderItemListTextFieldEmail(idTranslation, value, icon, name, disabled = false) {
		const { classes } = this.props
		const listEmail = value.split(',')
		return (
			<ListItem divider key={idTranslation}>
				<ListItemIcon>{icon}</ListItemIcon>
				<div className={classes.titleStyles}>
					<Typography
						component='span'
						variant='body2'
						className={this.props.classes.inline}
						color='textPrimary'
					>
						<Translate id={idTranslation} />
					</Typography>
					{': '}
				</div>
				{listEmail.map((email) => {
					return (
						<TextField
							key={email}
							className={classes.textFieldStyles}
							onKeyPress={this.updateValueProspect}
							id={email}
							name={name}
							label={''}
							defaultValue={email}
							disabled={disabled}
						/>
					)
				})}
			</ListItem>
		)
	}

	/**
	 * onClickCancelExpedient
	 */
	onClickAcepted() {
		this.setState({ openAcepted: true })
	}
	onProcessToSap() {
		this.setState({ openProcessToSap: true })
	}

	onClickRejected() {
		this.setState({ openRejected: true })
	}

	onClickCancel() {
		this.setState({ openCancel: true })
	}

	onClickSendDocuments() {
		this.setState({ openSendDocuments: true })
	}

	onClickSendMail() {
		this.setState({ openSendMail: true })
	}

	onClickRemove() {
		this.setState({ openRemove: true })
	}
	onClickRegistrationProvider() {
		this.setState({ openRegistrationProvider: true })
	}

	onCloseRegistrationProvider = () => {
		this.setState({
			openRegistrationProvider: !this.state.openRegistrationProvider,
		})
	}
	/**
	 * onAcceptedByClient
	 * @param {*} comments
	 */
	onAcceptedByClient(comments) {
		this.props
			.acceptedByClient(this.props.match.params.token, comments)
			.then(() => {
				this.setState({ openAcepted: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	onProcessToSapByClient() {
		this.props
			.processToSapByClient(this.props.match.params.token)
			.then(() => {
				this.setState({ openProcessToSap: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}
	/**
	 * onRejectedByClient
	 * @param {*} comments
	 */
	onRejectedByClient(comments) {
		this.props
			.rejectedByClient(this.props.match.params.token, comments)
			.then(() => {
				this.setState({ openRejected: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onCancelByClient
	 * @param {*} comments
	 */
	onCancelByClient(comments) {
		this.props
			.canceledByClient(this.props.match.params.token, comments)
			.then(() => {
				this.setState({ openCancel: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onSendDocumentsProspect
	 * @param {*} comments
	 */
	onSendDocumentsProspect(comments) {
		this.props
			.sendDocumentsToProspect(this.props.match.params.token, comments)
			.then(() => {
				this.setState({ openSendDocuments: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onSendEmailRecordProspect
	 * @param {*} comments
	 */
	onSendEmailRecordProspect(comments) {
		this.props
			.sendEmailReminderProspect(this.props.match.params.token, comments)
			.then(() => {
				this.setState({ openSendMail: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * onRejectedByClient
	 * @param {*} comments
	 */
	onRemoveProspect() {
		this.props
			.removeProspectByClient(this.props.match.params.token)
			.then(() => {
				this.setState({ openRemove: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
				this.props.history.push(`/prospects`)
			})
			.catch((error) => {
				this.setState(showErrorToaster(!IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}

	/**
	 * getProspectType
	 * @param {*} prospect_type
	 * @returns {string} Description of prospect type
	 */
	getProspectType(prospect_type) {
		if (prospect_type === 0) return ''
		else if (prospect_type === 1) return 'Materia prima'
		else if (prospect_type === 2) return 'Indirecto'
		else if (prospect_type === 3) return 'Filial'
		else if (prospect_type === 4) return 'Gubernamental / Paraestatales / Agentes Aduanales'
		else if (prospect_type === 5) return 'Extranjero'
		else if (prospect_type === 6) return 'Proveedor nacional P.F'
		else if (prospect_type === 7) return 'Proveedor nacional P.F Indirectos'
		else if (prospect_type === 8) return 'Proveedor nacional P.F Materia prima'
		else if (prospect_type === 9) return 'Proveedor nacional P.M'
		else if (prospect_type === 10) return 'Proveedor nacional P.M Indirectos'
		else if (prospect_type === 11) return 'Proveedor nacional P.M Materia prima'
		else if (prospect_type === 12) return 'Servicio Especializado PF Indirecto'
		else if (prospect_type === 13) return 'Servicio Especializado PF Materia prima'
		else if (prospect_type === 14) return 'Servicio Especializado PM Indirecto'
		else if (prospect_type === 15) return 'Servicio Especializado PM Materia prima'
		else return ''
	}

	/**
	 * Handle state to set token of item to show options menu list
	 *
	 * @param {*} itemToken
	 * @memberof ShowExpedientBySupplier
	 */
	handleClickOptionsMenu(itemToken) {
		this.setState({
			itemToken,
		})
	}

	/**
	 * Change status docunet in on update status file
	 * @param {*} token
	 * @param {*} status
	 */
	onUpdateStatusFile(token, status) {
		this.props.updateStatusFileInPlant(token, status).then(() => {
			this.handleCloseOptionsMenu()
		})
	}

	/**
	 * Function to donwload documentations in ZIP
	 */
	downloadDocumentsInZIP() {
		let prospectToken = this.props.match.params.token
		let urlPlot = `${process.env.REACT_APP_SIP_SUPPLIERS}/prospect/${prospectToken}/downloadZip`
		window.open(urlPlot, '_blank')
	}

	onClickToActiveTab = (event, value) => {
		this.setState({ activeTab: value })
	}

	uploadFiles(fileToken, files) {
		this.props.uploadDocumentsSendProspect(fileToken, files)
		return Promise.resolve('Ok')
	}

	removeFiles(fileName) {
		this.props.removeProspectFile(fileName)
		return Promise.resolve('Ok')
	}

	onSave(token, value) {
		this.props
			.registrationProspect(token, value.feedback, value.emails)
			.then(() => {
				this.onCloseRegistrationProvider()
				Promise.resolve('Ok')
			})
			.catch(() => {
				this.setState(showErrorToaster('common.error'))
			})
		return Promise.resolve('Ok')
	}

	onOpenAddDocumentsDialog() {
		this.setState({
			showDocuments: true,
		})
	}
	onSaveFiles(addDocument) {
		this.props
			.saveDocumentToAddProspect(this.props.match.params.token, addDocument.label)
			.then(() => {
				this.setState({ showDocuments: false })
				this.setState(showSuccessToaster('common.saveCorrectly'))
			})
			.catch((error) => {
				this.setState(showErrorToaster(IsNullOrEmpty(error) ? error : 'common.errorToSave'))
			})
	}
	onCloseAddDocmentsDialog() {
		this.setState({
			showDocuments: false,
		})
	}
	/**
	 * Get the card content
	 *
	 * @memberof Show
	 */
	onChangePrivacyType(center) {
		if (center === null) return COMPANY_IMBERA
		else if (center.includes('4500') && center.includes('7000')) return COMPANY_IMBERA
		else if (center.includes('7000')) return COMPANY_IMBERA
		else if (center.includes('5100')) return COMPANY_IDENTIFIER_PTMT
		else if (center.includes('4500')) return COMPANY_IDENTIFIER__METALLIC
		else return COMPANY_IMBERA
	}

	getCardContent() {
		if (this.props.isLoading === true) {
			return <LoadingPage />
		} else if (this.props.prospect !== null) {
			const { prospect, prospectHistory, selectedCompanyIdentifier } = this.props
			let isDisabledObsPlant = prospect.status !== 50
			return (
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Paper>
							<List>
								{this.renderItemList(
									'common.folio',
									`${prospect.folio} - Tipo prospecto: ${this.getProspectType(
										prospect.prospect_type
									)}`,
									<FolioIcon />
								)}
								{this.renderItemList(
									'prospects.prospectName',
									prospect.prospect_name,
									<ProspectSupplierIncon />
								)}
								{this.renderItemListTextField(
									'prospects.contactName',
									`${prospect.contact_name} `,
									<UserIcon />,
									'contactName',
									prospect.status === 3 ? true : false
								)}
								{this.renderItemListTextFieldEmail(
									'prospects.viewContactEmail',
									`${prospect.contact_email}`,
									<EmailIcon />,
									'contact_email',
									prospect.status === 3 ? true : false
								)}

								{this.renderItemList('common.createBy', prospect.creator_name, <UserIcon />)}
								{prospect.folio_sit &&
									this.renderItemList(
										'prospects.folioSit',
										prospect.folio_sit,
										<ProspectSupplierIncon />
									)}

								{prospect.center_sit &&
									selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX &&
									this.renderItemList('prospects.centerSit', prospect.center_sit, <BusinessIcon />)}

								{prospect.attachmentsToSupplier && prospect.attachmentsToSupplier.length !== 0 && (
									<ListItem divider>
										<ListItemText
											secondary={
												<>
													<Typography component='span' variant='body2' color='textPrimary'>
														<Translate id='prospects.AdditionalVendorFiles' />
													</Typography>
													<Evidence
														isDisabled={true}
														baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
														removeFiles={this.props.removeFiles}
														uploadFiles={this.props.uploadFiles}
														files={prospect.attachmentsToSupplier}
													/>
												</>
											}
										/>
									</ListItem>
								)}
								{process.env.PUBLIC_URL !== ENVIROMENT_TYPE_PRODUCTION && (
									<ListItem divider>
										<ListItemIcon>
											<Reorder style={{ marginTop: '-7px' }} />
										</ListItemIcon>
										<ListItemText
											secondary={
												<Typography variant='subtitle2'>
													<Translate id='prospects.prospectusCode' />
													{': ' + prospect.code + ' '}
													<a
														href={`http://localhost:3000/prospect/register/${prospect.token}`}
														target='_blank'
														rel='noopener noreferrer'
													>
														click
													</a>
												</Typography>
											}
										/>
									</ListItem>
								)}
								{selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_MX ? (
									<ListItem divider>
										<ListItemIcon>
											<PrivacyIcon style={{ marginTop: '-7px' }} />
										</ListItemIcon>
										<ListItemText
											secondary={
												<Typography variant='subtitle2'>
													<a
														href={checkPrivacyNotice(
															this.onChangePrivacyType(prospect.center_sit),
															localStorage,
															this.props.activeprivacyNotice
														)}
														target='_blank'
														rel='noopener noreferrer'
													>
														<Translate id='common.privacyNoticePTM' />
													</a>
												</Typography>
											}
										/>
									</ListItem>
								) : (
									<ListItem divider>
										<ListItemIcon>
											<PrivacyIcon style={{ marginTop: '-7px' }} />
										</ListItemIcon>
										<ListItemText
											secondary={
												<Typography variant='subtitle2'>
													<a
														href={checkPrivacyNotice(
															this.props.selectedCompanyIdentifier,
															localStorage,
															this.props.activeprivacyNotice
														)}
														target='_blank'
														rel='noopener noreferrer'
													>
														<Translate id='common.privacyNoticePTM' />
													</a>
												</Typography>
											}
										/>
									</ListItem>
								)}
								{this.renderItemList('common.comments', prospect.notes, <NotesIcon />)}
								{prospect.comments_rejected &&
									this.renderItemList(
										'prospects.cancelRequest',
										prospect.comments_rejected,
										<NotesIcon color='error' />
									)}
								{prospect &&
									prospect.status !== 1 &&
									prospect.status !== 2 &&
									prospect.status !== 4 &&
									prospect.status !== 5 &&
									prospect.status !== 6 &&
									this.renderItemListTextField(
										'prospects.vendorCode',
										prospect.supplier_code,
										<NotesIcon color='primary' />,
										'supplier_code',
										false
									)}
								{prospect.supplier_code &&
									prospect.date_supplier_Code &&
									this.renderItemList(
										'prospects.dateSupplierCode',
										prospect.date_supplier_Code,
										<NotesIcon color='primary' />
									)}
								{prospect.status !== 1 && (
									<ListItem key={'downloadExpedientZip'} divider>
										<ListItemText
											secondary={
												<Link
													color='primary'
													onClick={() => this.downloadDocumentsInZIP()}
													to='#'
												>
													<DownloadIcon style={{ margin: '5px' }} />
													<Translate id='prospects.downloadProspectus' />
												</Link>
											}
										/>
									</ListItem>
								)}
							</List>
						</Paper>
					</Grid>
					<Grid item xs={11}>
						<Typography component='span' variant='body2' color='textPrimary'>
							<strong>{<Translate id='prospects.packageLeafletreviews' />}</strong>
						</Typography>
						<HtmlEditor
							id='observations'
							label={''}
							labelVariant={'body2'}
							isDisabled={true}
							initialValue={prospect.observations}
						/>
					</Grid>
					<Grid item xs={1}>
						{!prospect.is_closed &&
							selectedCompanyIdentifier === COMPANY_IDENTIFIER_IMBERA_REPARE &&
							(IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) ||
								IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany)) &&
							prospect.status === 1 && (
								<Button
									variant='contained'
									color='primary'
									style={{ marginTop: '30px' }}
									onClick={() => this.onOpenAddDocumentsDialog()}
								>
									<AddIcon style={{ margin: '0px' }} />
								</Button>
							)}
						<ShowPanelToAddFiles
							show={this.state.showDocuments}
							onCloseModal={this.onCloseAddDocmentsDialog}
							addFileOnExpedient={this.onSaveFiles}
							onClose={this.onCloseAddDocmentsDialog}
							isSaving={this.props.isSaving}
							documentsNotRequired={prospect ? prospect.documents_not_used : []}
						/>
					</Grid>
					{prospect.attachments.length !== 0 && (
						<Grid item xs={12}>
							<Typography component='span' variant='body2' color='textPrimary'>
								<strong>
									<Translate id='prospects.documentsSentProspectus' />
								</strong>
							</Typography>
							<Evidence
								isDisabled={true}
								baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
								files={prospect.attachments}
							/>
						</Grid>
					)}
					{this.props.isLoading !== true && (
						<>
							<AppBar position='static' color='default'>
								<Tabs
									indicatorColor='primary'
									textColor='primary'
									value={this.state.activeTab}
									onChange={this.onClickToActiveTab}
								>
									<Tab label={<Translate id='expedientSupplier.documents' />} />
									<Tab label={'Historico'} />
								</Tabs>
							</AppBar>

							{this.state.activeTab === 0 && (
								<>
									{prospect.file_prospects.length !== 0 && (
										<DocumentsProspect
											prospect={prospect}
											isDisabledObsPlant={isDisabledObsPlant}
											is_active_prospect={false}
											isDisableOptions={canBeModuleProspectsSupplier(
												this.props.userRolesByCompany
											)}
											canBeAuthorizer={IsUserInRole(
												ROLE_PROSPECTS_SUPPLIERS,
												this.props.userRolesByCompany
											)}
											isSupplierDevelopAdministrator={IsUserInRole(
												ROLE_SUPPLIERDEVELOPMENT,
												this.props.userRolesByCompany
											)}
											updateStatusFileInPlant={this.props.updateStatusFileInPlant}
											updatePropertyProspect={this.props.updatePropertyProspect}
											removeDocument={this.props.removeDocumentByProspect}
											removeFiles={this.props.removeFiles}
											// translate={this.props.translate}
											uploadDocumentsProspect={this.props.uploadDocumentsProspect}
											isSupplierDevelopersEvaluationQuestionnaireFile={IsUserInRole(
												ROLE_SUPPLIER_DEVELOPERS_EVALUATION_QUESTIONNAIRE_FILE,
												this.props.userRolesByCompany
											)}
											isViewSupplier ={false}
										/>
									)}
								</>
							)}
							{this.state.activeTab === 1 && (
								<>
									<ProspectStatusHistory prospect={prospectHistory} />
								</>
							)}
						</>
					)}
				</Grid>
			)
		}
	}

	render() {
		const { prospect, classes } = this.props
		const prospectus_download = prospect ? prospect.prospectus_download_files : []
		const status = prospectus_download ? prospectus_download.map((x) => x.file_status) : []
		return (
			<Card>
				<CardHeader
					avatar={
						<Avatar style={{ backgroundColor: backgroundColorSelect(prospect && prospect.status) }}>
							<ProspectSupplierIncon />
						</Avatar>
					}
					action={
						<div className={classes.actionsToolbar}>
							{
								<span>
									{prospect &&
										prospect.supplier_code !== null &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip title={'Notificación de Alta provedor'}>
												<IconButton color='primary' onClick={this.onClickRegistrationProvider}>
													<SendIcon style={{ color: '#30B1FC' }} />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status === 7 &&
										prospect.file_prospects.length === 0 &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip
												title={<Translate id='prospects.submitApplicationRequiredDocuments' />}
											>
												<IconButton color='primary' onClick={this.onClickSendDocuments}>
													<SendIcon style={{ color: '#F7B924' }} />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status === 2 &&
										status.includes(4) === false &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip title={<Translate id='prospects.processToSap' />}>
												<IconButton color='primary' onClick={this.onProcessToSap}>
													<AcceptIcon style={{ color: '#F7B924' }} />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status === 7 &&
										prospect.supplier_code !== null &&
										prospect.status === 7 &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip title={<Translate id='prospects.finalize' />}>
												<IconButton color='primary' onClick={this.onClickAcepted}>
													<AcceptIcon style={{ color: '#3AC47D' }} />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status === 2 &&
										status.includes(4) === true &&
										(IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) ||
											IsUserInRole(ROLE_SUPPLIERDEVELOPMENT, this.props.userRolesByCompany)) && (
											<Tooltip title={'Rechazar y reenviar'}>
												<IconButton color='primary' onClick={this.onClickRejected}>
													<RejectIcon color='error' />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status !== 3 &&
										prospect.status !== 5 &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip title={<Translate id='common.cancel' />}>
												<IconButton color='primary' onClick={this.onClickCancel}>
													<CancelIcon color='error' />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										(prospect.status === 1 || prospect.status === 6 || prospect.status === 4) &&
										IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany) && (
											<Tooltip title={'Enviar recordatorio de solicitud'}>
												<IconButton color='primary' onClick={this.onClickSendMail}>
													<EmailIcon style={{ color: '#30B1FC' }} />
												</IconButton>
											</Tooltip>
										)}
									{prospect &&
										prospect.status === 5 &&
										(IsUserInRole(ROLE_COMPANYADMINISTRATOR, this.props.userRolesByCompany) ||
											IsUserInRole(ROLE_PROSPECTS_SUPPLIERS, this.props.userRolesByCompany)) && (
											<Tooltip title={<Translate id='common.delete' />}>
												<IconButton color='primary' onClick={this.onClickRemove}>
													<DeleteIcon color='primary' />
												</IconButton>
											</Tooltip>
										)}
								</span>
							}
						</div>
					}
					title={
						<Typography variant='button'>
							{<Translate id='prospects.prospectName' />} {':  '} {prospect && prospect.prospect_name}
						</Typography>
					}
					subheader={
						<p>
							<Translate id='common.creationDate' />: {prospect && prospect.creation_date}
							<br /> {'Folio'}: {prospect && prospect.folio} | {'Estatus'}:{' '}
							{prospect && prospect.description_status}
						</p>
					}
				/>

				<CardContent>{this.getCardContent()}</CardContent>

				<Toaster
					message={<Translate id={this.state.toasterMessage} />}
					open={this.state.showToaster}
					variant={this.state.toasterVariant}
					onClose={() => {
						this.setState({ showToaster: false })
					}}
				/>

				{/** Dialog to rejected request */}
				<AddCommonItem
					title={<Translate id='prospects.rejectRequestProvider' />}
					message={<Translate id='prospects.reasonRejection' />}
					open={this.state.openRejected}
					onClose={() => {
						this.setState({ openRejected: false })
					}}
					onSaveItem={this.onRejectedByClient}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to acepted request */}
				<AddCommonItem
					title={'Finalizar solicitud'}
					message={'Se finaliza la solicitud agregar nota de cierre.'}
					open={this.state.openAcepted}
					onClose={() => {
						this.setState({ openAcepted: false })
					}}
					onSaveItem={this.onAcceptedByClient}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>
				{/** Dialog to process to Sap request */}
				<AddCommonItem
					title={<Translate id='prospects.processToSap' />}
					message={<Translate id='prospects.dialogProcessSap' />}
					open={this.state.openProcessToSap}
					onClose={() => {
						this.setState({ openProcessToSap: false })
					}}
					onSaveItem={this.onProcessToSapByClient}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>

				{/** Dialog to acepted request */}
				<AddCommonItem
					title={<Translate id='prospects.cancelRequestProspect' />}
					message={<Translate id='prospects.reasonForCanceling' />}
					open={this.state.openCancel}
					onClose={() => {
						this.setState({ openCancel: false })
					}}
					onSaveItem={this.onCancelByClient}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to send doduments */}
				<AddCommonItem
					title={<Translate id='prospects.submitApplicationRequiredDocuments' />}
					message={<Translate id='prospects.commentsRequest' />}
					open={this.state.openSendDocuments}
					onClose={() => {
						this.setState({ openSendDocuments: false })
					}}
					onSaveItem={this.onSendDocumentsProspect}
					isSaving={this.props.isSaving}
					isTextArea={true}
				/>

				{/** Dialog to send doduments */}
				<AddCommonItem
					title={<Translate id='prospects.sendApplicationReminderEmail' />}
					message={<Translate id='prospects.addCommentSendApplicationLead' />}
					open={this.state.openSendMail}
					onClose={() => {
						this.setState({ openSendMail: false })
					}}
					onSaveItem={this.onSendEmailRecordProspect}
					isSaving={this.props.isSaving}
					isTextArea={true}
					saveButtonTranslate={'common.sendBotton'}
				/>

				{/** Dialog to remove prospect */}
				<AddCommonItem
					title={<Translate id='prospects.deleteLeadRequest' />}
					message={<Translate id='prospects.dialogRemoveProspect' />}
					open={this.state.openRemove}
					onClose={() => {
						this.setState({ openRemove: false })
					}}
					onSaveItem={this.onRemoveProspect}
					isSaving={this.props.isSaving}
					isMessage={true}
				/>
				{this.state.openRegistrationProvider && (
					<RegistrationSupplier
						onCloseButtonClick={this.onCloseRegistrationProvider}
						onSave={this.onSave}
						isSaving={this.props.isSaving}
						uploadFiles={this.uploadFiles}
						token={prospect.token}
						prospect={prospect}
						removeFiles={this.removeFiles}
					/>
				)}
			</Card>
		)
	}
}

/**
 *  Defines the properties injecteded from the store to view container
 * @param {*} state
 */
function mapStateToProps(state) {
	let user = state.oidc.user ? state.oidc.user : { profile: { name: '', email: '', user_type: '' } }
	let userRolesByCompany = state.profile.get('userRolesByCompany')
	let selectedCompanyIdentifier = state.profile.get('selectedCompany').get('get_company_identifier')
	return {
		currentUser: user,
		isLoading: state.prospects.get('isLoadingProspect'),
		prospect: state.prospects.get('prospect') ? state.prospects.get('prospect').toJS() : null,
		prospectHistory: state.prospects.get('prospectHistory') ? state.prospects.get('prospectHistory').toJS() : null,
		translate: getTranslate(state.localize),
		userRolesByCompany: userRolesByCompany ? converListToArray(userRolesByCompany) : [],
		isSaving: state.prospects.get('isSavingPropect'),
		selectedCompanyIdentifier: selectedCompanyIdentifier ? selectedCompanyIdentifier : null,
		activeprivacyNotice: state.companies.get('activePrivacyNotice')
			? state.companies.get('activePrivacyNotice').toJS()
			: null,
	}
}

/**
 * Defines the actions injectes to the component
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		loadProspect: (token) => {
			return dispatch(loadProspect(token))
		},
		acceptedByClient: (token, comments) => {
			return dispatch(acceptedByClient(token, comments))
		},
		processToSapByClient: (token) => {
			return dispatch(processToSapByClient(token))
		},
		rejectedByClient: (token, comments) => {
			return dispatch(rejectedByClient(token, comments))
		},
		updateStatusFileInPlant: (token, status) => {
			return dispatch(updateStatusFileInPlant(token, status))
		},
		updatePropertyProspect: (fileToken, key, value) => {
			return dispatch(updatePropertyProspect(fileToken, key, value))
		},
		canceledByClient: (token, comments) => {
			return dispatch(canceledByClient(token, comments))
		},
		sendDocumentsToProspect: (token, comments) => {
			return dispatch(sendDocumentsToProspect(token, comments))
		},
		sendEmailReminderProspect: (token, comments) => {
			return dispatch(sendEmailReminderProspect(token, comments))
		},
		removeProspectByClient: (token) => {
			return dispatch(removeProspectByClient(token))
		},
		removeDocumentByProspect: (itemToken, prospectToken) => {
			return dispatch(removeDocumentByProspect(itemToken, prospectToken))
		},
		loadProspectHistory: (token) => {
			return dispatch(loadProspectHistory(token))
		},
		updateProspect: (token, value, name) => {
			return dispatch(updateProspect(token, value, name))
		},
		uploadDocumentsSendProspect: (fileToken, files) => {
			return dispatch(uploadDocumentsSendProspect(fileToken, files))
		},
		removeProspectFile: (fileToken, files) => {
			return dispatch(removeFilesSendProspect(fileToken, files))
		},
		registrationProspect: (token, feedback, emails) => {
			return dispatch(registration(token, feedback, emails))
		},
		saveDocumentToAddProspect: (expedientToken, documentToken) => {
			return dispatch(saveDocumentToAddProspect(expedientToken, documentToken))
		},
		uploadDocumentsProspect: (fileToken, files) => {
			return dispatch(uploadDocumentsProspect(fileToken, files))
		},
		removeFiles: (fileName) => {
			return dispatch(removeFilesEvidence(fileName))
		},
	}
}

const styles = (theme) => ({
	actionsToolbar: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	inline: {
		display: 'inline',
	},
	logoCompany: {
		maxHeight: 100,
	},
	logoSupplier: {
		marginLeft: 80,
		maxHeight: 80,
	},
	styleTab: {
		backgroundColor: '#fff',
	},
	margin: {
		margin: theme.spacing.unit * 2,
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textFieldStyles: {
		// height: 15,
		width: 190,
		marginLeft: '25px',
	},
	titleStyles: {
		marginLeft: '15px',
	},
})

export default withauthenticatedLayout(
	withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Show)))
)
