import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import Select from 'react-select'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

/** Import component section */
import Dialog from '../../../common/Dialog'
import DialogActions from '../../../common/DialogActions'
import Evidence from '../../../common/Evidence'
import DatePicker from '../../../common/DateTimePicker/DatePicker'

/** Actions and helpers imports section */
import { COMPANY_IMBERA, COMPANY_FERSA, SHOW_EDIT_VIEW } from '../../../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { FormControlLabel, Checkbox, IconButton, Tooltip } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import {
	ContractorsProjectServiceOptions,
	ContractorsProjectServiceOptionsImbera,
	getSelectedValue,
} from '../../../../store/helpers/SelectOptions'

/**
 * Class compoenent to add a service/project
 *
 * @class AddServiceProjectDialog
 * @extends {Component}
 */
class AddServiceProjectDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = this.initialState()
	}

	/**
	 * Return initial state
	 *
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	initialState() {
		return {
			start_date: this.props.data ? this.props.data.start_date : null,
			end_date: this.props.data ? this.props.data.end_date : null,
			description: this.props.data ? this.props.data.description : '',
			purchase_order: this.props.data ? this.props.data.purchase_order : '',
			service_areas: this.props.data ? this.props.data.service_areas : '',
			enter_weekends: this.props.data ? this.props.data.enter_weekends : false,
			request_order: this.props.data ? this.props.data.request_order : '',
			option_select: 0,
		}
	}

	/**
	 * Handle update properties to set data in local state
	 *
	 * @param {*} property
	 * @param {*} data
	 * @memberof AddServiceProjectDialog
	 */
	updateProperty(property, data) {
		this.setState({
			[property]: data,
		})
	}

	/**
	 * Function to trigger blur event
	 *
	 * @param {*} event
	 * @memberof AddServiceProjectDialog
	 */
	onKeyDown(event) {
		if (event.key === 'Enter') document.activeElement.blur()
	}

	onGetPurchaseOrder(data) {
		return this.props.onGetOrders(data).then((purchaseOrder) => {
			this.setState({
				description: purchaseOrder.description,
				request_order: purchaseOrder.requestOrder,
			})
		})
	}

	onGetLegalFolio(data) {
		return this.props.onGetFolios(data).then((purchaseOrder) => {
			this.setState({
				description: purchaseOrder.description,
				request_order: purchaseOrder.requestOrder,
				end_date: purchaseOrder.EndDate,
			})
		})
	}

	/**
	 * method to call action and add payment
	 *
	 * @memberof AddServiceProjectDialog
	 */
	onAddServiceProject() {
		this.props.addServiceProject(this.state).then((response) => {
			if (response === 'Ok') this.handleCloseCancel()
		})
	}

	onEditServiceProject() {
		this.props.editServiceProject(this.state).then((response) => {
			if (response === 'Ok') this.handleCloseCancel()
		})
	}

	/**
	 * Update option_select
	 * @param {*} select
	 */
	onUpdateOptionSelect(select) {
		this.setState({
			option_select: select,
		})
	}

	/**
	 * Handle  Close or cancel event
	 *
	 * @memberof AddServiceProjectDialog
	 */
	handleCloseCancel() {
		this.setState(this.initialState(), () => this.props.onClose())
	}

	/**
	 * Method to validate if required properties has data
	 *
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	isValidToSave() {
		var regexp = /^[A-Z]\d{2,3}\/\d{4}$/
		if (this.state.option_select === 1) regexp = /^\d{10}$/
		if (this.state.option_select === 2) regexp = /^[A-Z]\d{2,3}\/\d{4}$/

		if (
			this.props.selectedCompany.company_code === COMPANY_IMBERA &&
			(this.state.option_select === 1 || this.state.option_select === 2) &&
			(IsNullOrEmpty(this.state.purchase_order) || !regexp.test(this.state.purchase_order))
		) {
			return false
		}
		if (
			this.state.start_date &&
			!IsNullOrEmpty(this.state.start_date) &&
			this.state.end_date &&
			!IsNullOrEmpty(this.state.end_date) &&
			this.state.description &&
			!IsNullOrEmpty(this.state.description) &&
			this.state.service_areas &&
			!IsNullOrEmpty(this.state.service_areas)
		) {
			if (
				this.props.selectedCompany.company_code === COMPANY_IMBERA ||
				this.props.selectedCompany.company_code === COMPANY_FERSA
			) {
				if (this.state.option_select !== 0) return true
				else return false
			} else return true
		}
		return false
	}

	/**
	 * Method to render text field
	 *
	 * @param {*} textFieldId
	 * @param {*} textFieldTranslateId
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	renderTextArea(
		textFieldId,
		textFieldTranslateId,
		isMultiline = false,
		isRequired = true,
		helpMessageTranslateId = null
	) {
		return (
			<TextField
				id={textFieldId}
				label={<Translate id={textFieldTranslateId} />}
				value={this.state[textFieldId] ? this.state[textFieldId] : ''}
				margin='normal'
				type={'text'}
				fullWidth
				required={isRequired}
				onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
				onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
				onKeyDown={!isMultiline && this.onKeyDown}
				multiline={isMultiline}
				variant={isMultiline ? 'outlined' : 'standard'}
				rowsMax={4}
				helperText={isRequired && helpMessageTranslateId ? <Translate id={helpMessageTranslateId} /> : null}
			/>
		)
	}

	renderTextAreaAndButton(
		textFieldId,
		textFieldTranslateId,
		isMultiline = false,
		isRequired = true,
		helpMessageTranslateId = null,
		isContract = false
	) {
		return (
			<Grid container justify='space-between'>
				<TextField
					id={textFieldId}
					label={<Translate id={textFieldTranslateId} />}
					value={this.state[textFieldId] ? this.state[textFieldId] : ''}
					type={'text'}
					required={isRequired}
					onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
					onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
					onKeyDown={!isMultiline && this.onKeyDown}
					multiline={isMultiline}
					variant={isMultiline ? 'outlined' : 'standard'}
					rowsMax={6}
					helperText={isRequired && helpMessageTranslateId ? <Translate id={helpMessageTranslateId} /> : null}
					FormHelperTextProps={{
						style: { color: '#f44336' },
					}}
				/>
				<div required={isRequired} className={this.props.classes.buttonDescription}>
					<Tooltip title={<Translate id='contractorsModule.searchInSap' />}>
						<IconButton
							className={this.props.classes.icon}
							onClick={() =>
								isContract ? this.onGetLegalFolio(this.state) : this.onGetPurchaseOrder(this.state)
							}
							disabled={this.props.isSaving || this.props.isLoadingPurchaseOrders}
							required={isRequired}
						>
							<AssignmentIcon required={isRequired} />
						</IconButton>
					</Tooltip>
				</div>
			</Grid>
		)
	}

	/**
	 * Render datetime component
	 *
	 * @param {*} key
	 * @param {*} translationId
	 * @param {number} [xs=12]
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	renderDateTime(key, translationId, xs = 12) {
		return (
			<Grid item xs={xs} className={this.props.classes.textField}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} />*
				</Typography>
				<DatePicker
					onChangeRange={(event) => {
						this.updateProperty(key, event)
					}}
					startDate={this.state[key] ? moment(this.state[key], 'DD/MM/YYYY') : ''}
				/>
			</Grid>
		)
	}
	/**
	 * Render Evidence component
	 *
	 * @param {*} key
	 * @param {*} translationId
	 * @param {number} [xs=12]
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	renderEvidence(key, translationId, xs = 12) {
		return (
			<Grid item xs={xs} className={this.props.classes.checkBox}>
				<Typography variant='subtitle2' color={'textSecondary'}>
					<Translate id={translationId} />*
				</Typography>
				<Evidence
					baseApi={process.env.REACT_APP_SIP_URL_FILES}
					removeFiles={(fileName) => this.removeFiles(fileName, key)}
					uploadFiles={(files) => this.uploadFiles(files, key)}
					files={this.state[key] ? this.state[key] : []}
					disabledLink={!this.props.item}
				/>
			</Grid>
		)
	}

	/**
	 * Render Select component
	 * @param {*} options
	 * @returns
	 */
	renderSelector(options) {
		return (
			<Grid
				item
				xs={12}
				className={this.props.classes.textField}
				style={{ marginTop: '12px', marginBottom: '6px' }}
			>
				<Typography variant='subtitle2' color={'textSecondary'}>
					<Translate id='contractorsModule.types' />*
				</Typography>
				<Select
					className={this.props.classes.Select}
					options={options}
					defaultValue={getSelectedValue(options, 0)}
					onChange={(event) => this.onUpdateOptionSelect(event.value)}
					styles={{
						menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
						menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
						menuPortal: (base) => ({ ...base, zIndex: 9999 }),
					}}
					menuPosition='fixed'
					menuPlacement={'auto'}
				/>
			</Grid>
		)
	}

	/**
	 * Render Check to set boolean enter_weekends
	 *
	 * @returns
	 * @memberof AddServiceProjectDialog
	 */
	renderCheck(key, translationId) {
		return (
			<FormControlLabel
				control={
					<Checkbox
						onChange={(event) => {
							this.updateProperty(key, event.target.checked)
						}}
						color='primary'
						checked={this.state[key] ? this.state[key] : false}
					/>
				}
				label={<Translate id={translationId} />}
			/>
		)
	}

	render() {
		const { classes } = this.props
		let newServiceProjectOptions = ContractorsProjectServiceOptions()
		let serviceProjectOptions = ContractorsProjectServiceOptionsImbera(this.props.partnership)
		return (
			<Dialog
				open={this.props.open}
				onClose={() => this.handleCloseCancel()}
				header={
					<Toolbar>
						<Typography variant='h6'>
							{
								<Translate
									id={
										this.props.data
											? 'contractorsModule.editProjectService'
											: 'contractorsModule.addProjectService'
									}
								/>
							}
						</Typography>
					</Toolbar>
				}
				actions={
					<div>
						{!this.isValidToSave() && (
							<Typography variant='caption' color='error'>
								{<Translate id='common.requiredFields' />}
							</Typography>
						)}
						<DialogActions
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => this.handleCloseCancel()}
							onClose={() => this.handleCloseCancel()}
							onSaveItem={this.props.data ? this.onEditServiceProject : this.onAddServiceProject}
							isSaving={this.props.isSaving || this.props.isLoadingPurchaseOrders}
							loadingItem={
								this.props.isLoadingPurchaseOrders ? (
									<Translate id='contractorsModule.getToSap' />
								) : (
									<Translate id='common.saving' />
								)
							}
							canBeSaved={this.isValidToSave()}
						/>
					</div>
				}
			>
				{this.props.selectedCompany.company_code !== COMPANY_IMBERA ? (
					<Grid container className={classes.modal} justify='space-between'>
						{this.renderDateTime('start_date', 'contractorsModule.startDate', 5)}
						{this.renderDateTime('end_date', 'contractorsModule.endDate', 5)}
						{this.renderTextArea('description', 'contractorsModule.serviceProjectDescription', true, true)}
						{(this.props.selectedCompany.company_code === COMPANY_IMBERA ||
							this.props.selectedCompany.company_code === COMPANY_FERSA) &&
							this.renderSelector(newServiceProjectOptions)}
						{((this.props.selectedCompany.company_code !== COMPANY_IMBERA &&
							this.props.selectedCompany.company_code !== COMPANY_FERSA) ||
							((this.props.selectedCompany.company_code === COMPANY_IMBERA ||
								this.props.selectedCompany.company_code === COMPANY_FERSA) &&
								this.state.option_select === 1)) &&
							this.renderTextArea(
								'purchase_order',
								'contractorsModule.purchaseOrder',
								false,
								true,
								'contractorsModule.purchaseOrderRequired'
							)}
						{(this.props.selectedCompany.company_code === COMPANY_IMBERA ||
							this.props.selectedCompany.company_code === COMPANY_FERSA) &&
							this.state.option_select === 2 &&
							this.renderTextArea('purchase_order', 'common.folio', false, false)}
						{this.renderTextArea('service_areas', 'contractorsModule.serviceProjectAreas', false, true)}
						{this.props.data &&
							this.renderCheck('enter_weekends', 'contractorsModule.requireWeekendsAccess')}
					</Grid>
				) : (
					<Grid container className={classes.modal} justify='space-between'>
						{this.renderDateTime('start_date', 'contractorsModule.startDate', 5)}
						{this.renderDateTime('end_date', 'contractorsModule.endDate', 5)}
						{(this.props.selectedCompany.company_code === COMPANY_IMBERA ||
							this.props.selectedCompany.company_code === COMPANY_FERSA) &&
							this.renderSelector(serviceProjectOptions)}
						{((this.props.selectedCompany.company_code !== COMPANY_IMBERA &&
							this.props.selectedCompany.company_code !== COMPANY_FERSA) ||
							((this.props.selectedCompany.company_code === COMPANY_IMBERA ||
								this.props.selectedCompany.company_code === COMPANY_FERSA) &&
								this.state.option_select === 1)) &&
							this.renderTextAreaAndButton(
								'purchase_order',
								'contractorsModule.purchaseOrder',
								false,
								false,
								'contractorsModule.purchaseOrderRequiredImb',
								false
							)}
						{(this.props.selectedCompany.company_code === COMPANY_IMBERA ||
							this.props.selectedCompany.company_code === COMPANY_FERSA) &&
							this.state.option_select === 2 &&
							this.renderTextAreaAndButton(
								'purchase_order',
								'common.folio',
								false,
								false,
								'contractorsModule.folioLegal',
								true
							)}
						{this.renderTextArea('description', 'contractorsModule.serviceProjectDescription', true, true)}
						{this.renderTextArea('service_areas', 'contractorsModule.serviceProjectAreas', false, true)}
						{this.props.data &&
							this.renderCheck('enter_weekends', 'contractorsModule.requireWeekendsAccess')}
					</Grid>
				)}
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		maxWidth: 500,
	},
	icon: {
		color: '#2196f3',
	},
	buttonDescription: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: '20px',
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddServiceProjectDialog))
