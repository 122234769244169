//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI imports section */
import { Paper, withStyles } from '@material-ui/core'
//import common components
import HeaderComponent from './HeaderComponent'
import LoadingPage from '../../../common/LoadingPage'
import TabsInShow from './TabsInShow'
import GeneralFiles from './GeneralFiles'

const DesktopView = (props) => {
	// //bool props section
	const { isLoadingItem, exportRequest } = props
	if (isLoadingItem) return <LoadingPage />

	return (
		<Paper style={{ padding: '10px' }}>
			<HeaderComponent exportRequest={exportRequest} />
			<div style={{ marginTop: '2rem' }}>
				<TabsInShow {...props} />
			</div>
			<GeneralFiles exportRequest={exportRequest} />
		</Paper>
	)
}

/** Resources import section */
const useStyle = (theme) => ({})

export default withRouter(withStyles(useStyle, { withTheme: true })(DesktopView))
