/** Import ReactJS */
import React from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'
/**Import section components */

import { Divider, List, ListItem, ListItemText, TextField, Typography, Grid } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ListDocumentsComponent from './ListDocumentsComponent'
import SelectSupplierComponent from './SelectSupplierComponent'

/** Resources import section */
const useStyle = (theme) => ({})

const NationalComponent = (props) => {
	//function props section
	const {
		updateLoadAlertItemProperty,
		onSendNotificationToSupplier,
		onLateralPanelConfig,
		deleteInvoiceFiles,
		deleteAnnexesFiles,
		deleteInspectionFiles,
	} = props
	//object props section
	const { nacionalData } = props
	//tab props section
	const { tabSelected } = props
	//bool props section
	const { canBeEditData, canBeEditInternalUserData, isSaving } = props

	const onUpdatePropertyCommon = (property, value) => {
		let objectProperties = {
			token: nacionalData.token,
		}
		objectProperties[property] = value
		updateLoadAlertItemProperty(objectProperties)
	}

	return (
		<>
			{tabSelected === '2' && (
				<List>
					<SelectSupplierComponent
						title={<Translate id='loadAlerts.nationalSuppluier' />}
						updateLoadAlertItemProperty={updateLoadAlertItemProperty}
						item={nacionalData}
						onSendNotificationToSupplier={onSendNotificationToSupplier}
						//bool props section
						canBeEditInternalUserData={canBeEditInternalUserData}
					/>
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.referenceNumber' />:
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.referenceNumber} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.referenceNumber) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon(
																'ReferenceNumber',
																event.target.value
															)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.referenceNumber}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.collectionDate' />:
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.collectionDate} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.collectionDate) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														type='date'
														onBlur={(event) => {
															onUpdatePropertyCommon('CollectionDate', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.collectionDate}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														<Translate id='loadAlerts.transportLine' />:
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.transportLine} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.transportLine) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														// label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('TransportLine', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.transportLine}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{'Cuenta espejo'}
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.trackingGuide} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.trackingGuide) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('TrackingGuide', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.trackingGuide}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.departureCity' />:
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.departureCity} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.departureCity) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('DepartureCity', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.departureCity}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.arrivalCity' />:
													</strong>
												</Typography>
												{/* {nacionalData && nacionalData.arrivalCity} */}
												{/* {nacionalData && IsNullOrEmpty(nacionalData.arrivalCity) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('ArrivalCity', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.arrivalCity}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.etd' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.etd} */}
												{/* {internacionalData && IsNullOrEmpty(internacionalData.etd) && ( */}
												{nacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														type='date'
														onBlur={(event) => {
															onUpdatePropertyCommon('Etd', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={nacionalData.etd}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Typography
												component='span'
												variant='body2'
												color='textPrimary'
												style={{ whiteSpace: 'nowrap' }}
											>
												<strong>
													{' '}
													<Translate id='loadAlerts.eta' />:
												</strong>
											</Typography>
											{/* {internacionalData && internacionalData.eta} */}
											{/* {internacionalData && IsNullOrEmpty(internacionalData.eta) && ( */}
											{nacionalData && (
												<TextField
													fullWidth
													id='outlined-size-small'
													label={''}
													type='date'
													onBlur={(event) => {
														onUpdatePropertyCommon('Eta', event.target.value)
													}}
													variant='outlined'
													InputProps={{
														style: {
															padding: '5px',
															height: '32px',
														},
													}}
													disabled={!canBeEditData}
													defaultValue={nacionalData.eta}
												/>
											)}
										</div>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<ListDocumentsComponent
						panel='AddInvoiceDocuments'
						title='loadAlerts.invoice'
						documents={nacionalData.invoiceDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						item={nacionalData}
						deleteFiles={deleteInvoiceFiles}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddAnnexesDocumentes'
						title='loadAlerts.annexes'
						documents={nacionalData.annexesDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						item={nacionalData}
						deleteFiles={deleteAnnexesFiles}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddInspectionDocument'
						title='loadAlerts.inspectionDocument'
						documents={nacionalData.inspectionDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						deleteFiles={deleteInspectionFiles}
						item={nacionalData}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
				</List>
			)}
		</>
	)
}

NationalComponent.propTypes = {}

NationalComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(NationalComponent))
