import React from 'react'
import { IconButton, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import AttachmentIcon from '@material-ui/icons/Attachment'

const useStyle = (theme) => ({})

const DocumentsSit = (props) => {
	const { documents = [], title } = props

	return (
		<List>
			<ListItem>
				<ListItemText
					primary={
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', gap: '15px' }}>
								<AttachmentIcon />
								<Typography
									component='span'
									variant='body2'
									color='textSecondary'
									style={{ whiteSpace: 'nowrap' }}
								>
									<strong>
										{' '}
										<Translate id={title} />
									</strong>
								</Typography>
							</div>
						</div>
					}
				/>
			</ListItem>
			{documents && documents.length > 0 ? (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 250px))',
						gap: '15px 30px',
					}}
				>
					{documents.map((document, index) => (
						<Paper
							style={{
								display: 'flex',
								backgroundColor: '#F0F4F8',
								padding: '0rem 1rem 0rem 1rem',
								alignItems: 'center',
								height: '3.5rem',
							}}
						>
							<ListItemText
								primary={
									<Typography
										title={document.name}
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											maxWidth: '100%',
										}}
									>
										{document.Name}
									</Typography>
								}
							/>
							<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
								<IconButton target='_blank' style={{ padding: '5px' }} href={`${document.FilePath}`}>
									<CloudDownload style={{ color: '#4377C4', fontSize: '20px' }} />
								</IconButton>
							</div>
						</Paper>
					))}
				</div>
			) : (
				'No exiten archivos compartidos.'
			)}
		</List>
	)
}
DocumentsSit.defaultProps = {
	editable: false,
}

export default withRouter(withStyles(useStyle, { withTheme: true })(DocumentsSit))
