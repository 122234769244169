//import react section
import React from 'react'
import { withRouter } from 'react-router-dom'
//Import mui section
import {
	Card,
	Grid,
	Typography,
	CardContent,
	FormGroup,
	FormControlLabel,
	Checkbox,
	IconButton,
	Tooltip,
} from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { withStyles } from '@material-ui/core/styles'
//import resources section
import { COLOR_STATUS_DANGER, COLOR_STATUS_SUCCESS } from '../../../../store/helpers/StatusColorConstants'
import { GetCuponFerryMotiveOptions } from '../../../../store/helpers/SelectOptions'
import { FerryCuponStatus } from '../../../../store/helpers/AppConstants'

const useStyle = (theme) => ({
	card: {
		backgroundColor: '#f0f4f8',
		margin: '16px 0',
		borderLeft: `5px solid ${theme.palette.primary.main}`,
		boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
		transition: '0.3s',
	},
	cardAccepted: {
		borderLeft: `5px solid ${COLOR_STATUS_SUCCESS}`,
		backgroundColor: '#e6ffe6', // Fondo verde suave
	},
	cardRejected: {
		borderLeft: `5px solid ${COLOR_STATUS_DANGER}`,
		backgroundColor: '#ffe6e6', // Fondo rojo suave
	},
	cardDraft: {
		borderLeft: `5px solid ${theme.palette.primary.main}`,
		backgroundColor: '#e6f7ff', // Fondo azul suave
	},
	acceptedItem: {
		color: COLOR_STATUS_SUCCESS,
	},
	rejectedItem: {
		color: COLOR_STATUS_DANGER,
	},
	textTitle: {
		fontWeight: 'bold !important',
	},
	flex: {
		display: 'flex',
	},
})

const FerryCupon = (props) => {
	const {
		classes,
		cuponFerry,
		isSaving,
		onOpenModalConfirmForChangeStatus,
		canbeUpdatePlant,
		onOpenPanelEditCuponFerry,
		onDeleteFerryCupon,
	} = props
	console.log('cuponFerry', cuponFerry)
	// Destructuramos las propiedades con valores predeterminados
	const {
		DriverName = '-',
		DriverPhoneNumber = '-',
		CrossingDateShort = '-',
		TransportType = { Description: '-' },
		CrossingType = '-',
		CrossingPoint = '-',
		DropOffPoint = '-',
		IsRouteChange = false,
		Status = 1,
	} = cuponFerry || {}

	let cardClass =
		Status === FerryCuponStatus.ACCEPTED
			? classes.cardAccepted
			: Status === FerryCuponStatus.REJECTED
			? classes.cardRejected
			: classes.cardDraft

	let cuponTypeSelected = GetCuponFerryMotiveOptions().find((x) => x.value === CrossingType)
	let canBeUpdatePropertyFromItem = Status === FerryCuponStatus.ACCEPTED

	return (
		<Card className={`${classes.card} ${cardClass}`}>
			<CardContent className={classes.cardContent}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>Motivo de la solicitud</Typography>
								<Typography>{cuponTypeSelected ? cuponTypeSelected.label : '-'}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>Chofer</Typography>
								<Typography>{DriverName}</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography className={classes.textTitle}>Fecha de cruce</Typography>
								<Typography>{CrossingDateShort}</Typography>
							</Grid>
							<Grid item xs={2}>
								<div className={classes.flex}>
									<FormGroup row>
										<FormControlLabel
											label='Cambio de ruta'
											control={<Checkbox checked={IsRouteChange} disabled />}
										/>
									</FormGroup>
								</div>
							</Grid>
							<Grid item xs={1}>
								{Status !== FerryCuponStatus.ACCEPTED && (
									<>
										<Tooltip title={'Aceptar'}>
											<IconButton
												onClick={() =>
													onOpenModalConfirmForChangeStatus(
														cuponFerry,
														FerryCuponStatus.ACCEPTED
													)
												}
												aria-label='show more'
												className={Status === FerryCuponStatus.ACCEPTED && classes.acceptedItem}
												disabled={canBeUpdatePropertyFromItem || isSaving || !canbeUpdatePlant}
											>
												<ThumbUpIcon
													className={
														Status === FerryCuponStatus.ACCEPTED && classes.acceptedItem
													}
												/>
											</IconButton>
										</Tooltip>
										<Tooltip title={'Rechazar'}>
											<IconButton
												onClick={() =>
													onOpenModalConfirmForChangeStatus(
														cuponFerry,
														FerryCuponStatus.REJECTED
													)
												}
												className={Status === FerryCuponStatus.REJECTED && classes.rejectedItem}
												aria-label='show more'
												// disabled={isSaving || !canBeEditSupplier}
												disabled={canBeUpdatePropertyFromItem || isSaving || !canbeUpdatePlant}
											>
												<ThumbDownIcon
													className={
														Status === FerryCuponStatus.REJECTED && classes.rejectedItem
													}
												/>
											</IconButton>
										</Tooltip>
									</>
								)}
								{canbeUpdatePlant && (
									<Tooltip title={'Eliminar'}>
										<IconButton
											onClick={() => onDeleteFerryCupon(cuponFerry)}
											aria-label='show more'
											className={classes.rejectedItem}
											disabled={isSaving}
										>
											<DeleteIcon className={classes.rejectedItem} />
										</IconButton>
									</Tooltip>
								)}
								{canbeUpdatePlant && cuponFerry.IsRouteChange && (
									<Tooltip title={'Editar'}>
										<IconButton
											onClick={() => onOpenPanelEditCuponFerry(cuponFerry)}
											aria-label='show more'
											className={Status === FerryCuponStatus.ACCEPTED && classes.acceptedItem}
											disabled={isSaving}
										>
											<EditIcon
												className={Status === FerryCuponStatus.ACCEPTED && classes.acceptedItem}
											/>
										</IconButton>
									</Tooltip>
								)}
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={3}>
						<Typography className={classes.textTitle}>Tipo de unidad</Typography>
						<Typography>{TransportType.Description}</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography className={classes.textTitle}>Teléfono</Typography>
						<Typography>{DriverPhoneNumber}</Typography>
					</Grid>

					<Grid item xs={3}>
						<Typography className={classes.textTitle}>Punto de cruce</Typography>
						<Typography>{CrossingPoint}</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography className={classes.textTitle}>Punto de desembarque</Typography>
						<Typography>{DropOffPoint}</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default withRouter(withStyles(useStyle, { withTheme: true })(FerryCupon))
