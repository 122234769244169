import React from 'react'
import ListDocumentsSit from './DocumentsSit'

const GeneralFiles = ({ exportRequest }) => {
	return (
		<>
			{exportRequest && exportRequest.FilesFromSIT&& (
				<ListDocumentsSit
					title='common.packingList'
					documents={exportRequest.FilesFromSIT.PackingListAttachments}
				/>
			)}

			{exportRequest && exportRequest.FilesFromSIT&&(
				<ListDocumentsSit
					title='common.certificateOfOrigin'
					documents={exportRequest.FilesFromSIT.CertificateOriginAttachments}
				/>
			)}
			{exportRequest && exportRequest.FilesFromSIT&& (
				<ListDocumentsSit
					folderPathIdentifier={''}
					title='common.salesInvoice'
					documents={exportRequest.FilesFromSIT.SalesInvoiceAttachments}
				/>
			)}
			{exportRequest && exportRequest.FilesFromSIT&& (
				<ListDocumentsSit
					folderPathIdentifier={''}
					title='common.consignmentNoteAttachments'
					documents={exportRequest.FilesFromSIT.ConsignmentNoteAttachments}
				/>
			)}
		</>
	)
}

export default GeneralFiles
