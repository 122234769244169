import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
///Import react section
import { withRouter } from 'react-router-dom'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NumberIcon from '@material-ui/icons/FormatListNumberedOutlined'
import { withStyles } from '@material-ui/core/styles'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import PartNumberSelector from '../common/PartNumberSelector'
import { doesUrlExist, showPlotsFromPartNumber } from '../../store/helpers/ShowPlotToPartNumber'
import { BACKGROUND_COLOR_GRAY } from '../../store/helpers/StatusColorConstants'
import Toaster from '../common/Toaster'
const SearchPartNumberIndex = (props) => {
	const { classes } = props
	const [partNumberSelected, setPartNumeberSelected] = useState(null)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const onSelectedPartNumber = (partNumber) => {
		setPartNumeberSelected(partNumber)
	}

	const onViewPartNumber = async (namePlot, companyToken) => {
		try {
			// const kind = 1;  // Ajusta el tipo si es necesario
			// const formatype = '0' // Ajusta el formato si es necesario
			for (let plotNameIndex = 0; plotNameIndex <= 5; plotNameIndex++) {
				// Intentar hasta 5 peticiones
				console.log("revision", plotNameIndex)
				console.log("revision", plotNameIndex)
				let plotName =""
				plotName = `${namePlot}R0${plotNameIndex}.pdf`;
				for (let revision = 0; revision <= 9; revision++) {
	
					for (let formatype = 0; formatype <= 9; formatype++) {
						const urlPlot = `${process.env.REACT_APP_SIP_SUPPLIERS}/partnumbers/${plotName}/${companyToken}/${revision}/${formatype}`
						// Check if the URL exists
						const urlExists = await doesUrlExist(urlPlot)
		
						if (urlExists) {
							// Show the plot if the URL exists
							showPlotsFromPartNumber(plotName, companyToken, revision, formatype)
							return // Salir de la función si se encuentra una URL válida
						}
					}
				}
			}

			// Si ninguna URL es válida
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'plotSelector.requestedPlanNotAvailable',
				toasterVariant: 'info',
			})

			// alert('El plano solicitado no está disponible.')
		} catch (error) {
			console.error('Error fetching part number:', error)
			// alert('Hubo un problema al obtener el plano.')
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'plotSelector.problemObtainingPlan',
				toasterVariant: 'info',
			})
		}
	}

	return (
		<Card className={classes.card}>
			<CardHeader
				avatar={
					<Avatar aria-label='recipe'>
						<NumberIcon />
					</Avatar>
				}
				title={<Translate id='searchPartNumbers.title' />}
			/>
			<CardContent className={classes.cardContent}>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<Typography color='primary' variant='h6' align='center'>
							<Translate id='searchPartNumbers.searchByPartNumber' />
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<PartNumberSelector
							onPartNumberSelected={(partNumber) => {
								onSelectedPartNumber(partNumber)
							}}
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							color='primary'
							fullWidth
							variant='contained'
							disabled={!partNumberSelected}
							onClick={() =>
								onViewPartNumber(
									partNumberSelected.drawingName,
									partNumberSelected.companyToken
								)
							}
						>
							<Translate id='searchPartNumbers.searchSAP' />
						</Button>
					</Grid>
				</Grid>
			</CardContent>

			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</Card>
	)
}

const styles = (theme) => ({
	cardContent: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	card: {
		height: '100%',
		padding: '10px',
	},
})
export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(SearchPartNumberIndex)))
